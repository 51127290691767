<script>
  /**
   * This component is intended to be used with MenuBarItem.svelte
   * For how to use this component, see DispatchingViewControl.svelte.
   */

  /**
   * Click event handler called when the hamburger menu button is pressed.
   * If this prop is not given, the hamburger menu is not shown.
   * @type {(e: Event) => void | undefined | null}
   */
  export let onClickDetailedMenuOpen = null
  export let gap = "36px"
</script>

<div class="root" style="--gap: {gap}" class:with-hamburger={!!onClickDetailedMenuOpen}>
  {#if onClickDetailedMenuOpen}
    <div class="hamburger-container">
      <button class="hamburger-button" on:click={onClickDetailedMenuOpen}>
        ☰
      </button>
    </div>
  {/if}
  <div class="menubar-main" class:with-hamburger={!!onClickDetailedMenuOpen}>
    {#if $$slots.title}
      <div class="title-container">
        <slot name="title"/>
      </div>
    {/if}
    <slot name="settings" />
    <div class="pagination-container">
      <slot name="pagination" />
    </div>
  </div>
</div>

<style>
  button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .root {
    display: grid;
    line-height: 1.5;
    padding: 8px 0 0 var(--gap);
  }
  .root.with-hamburger {
    grid-template-columns: min-content auto;
  }
  .hamburger-container {
    min-width: 1px;
    width: fit-content;
    padding-right: var(--gap);
  }
  .hamburger-button {
    font-size: 30px;
    font-weight: bold;
  }
  .menubar-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    grid-row: 1;
    grid-column: 1;
    row-gap: 8px;
    column-gap: 32px;
    flex-wrap: wrap;
  }
  .menubar-main > :not(.pagination-container) {
    padding-bottom: 8px;
  }
  .menubar-main.with-hamburger {
    grid-column: 2;
  }
  .title-container {
    font-size: 1.5rem;
  }
  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
</style>