<script>
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import GeneralSelect from "../../components/select/GeneralSelect.svelte";
  import GeneralInput from "../../components/input/GeneralInput.svelte";
  import { translate } from "../../i18n/i18next.js";
  import MenuBar from "../../components/menuBar/MenuBar.svelte";
  import MenuBarItem from "../../components/menuBar/MenuBarItem.svelte";
  import PageSelector2 from "../../components/layout/PageSelector2.svelte";
  import {
    saveIntegratedMasterConfiguration
  } from "../../utils/configurationStorage/views/IntegratedMasterConfigurationStorage.js";

  export let iStyle = 0;
  export let styles = [];
  export let totalPageNumber = 1;
  export let currentPageIndex
  export let searchKeyword = "";
  export let onClickSearch;
  export let hidePagination = false;
  export let send
  export let userId
  export let projectId

  function onChangeSettings(settingName, newValue, callback = null) {
    if (!userId) return

    saveIntegratedMasterConfiguration({[settingName]: newValue}, userId, projectId)
    if (callback) callback()
  }

  function onChangeStyleSettings(event) {
    const newStyleIndex = event.target.value;
    const newStyleName = styles[newStyleIndex];
    saveIntegratedMasterConfiguration({ styleName: newStyleName }, userId, projectId);
    iStyle = parseInt(newStyleIndex, 10)
  }
</script>

<MenuBar>
  <svelte:fragment slot="settings">
    <MenuBarItem>
      <span slot="label">{translate("asprova.terms.style")}</span>
      <GeneralSelect
        onChangeEvent={onChangeStyleSettings}
        bind:value={iStyle}
        options={styles.map((style, index) => ({text: style, value: index}))}
      />
    </MenuBarItem>
    <MenuBarItem>
      <span slot="label">{translate("generic.searchShort")}</span>
      <GeneralInput bind:value={searchKeyword} extraStyle="display: inline"/>
      <GeneralButton
        buttonText={translate("generic.search")}
        buttonFunction={() => onChangeSettings("searchKeyword", searchKeyword, onClickSearch)}
        buttonType="secondary"
      />
    </MenuBarItem>
    <MenuBarItem>
      <GeneralButton buttonText={translate("generic.updateVerb")} buttonFunction={send} />
    </MenuBarItem>
  </svelte:fragment>
  <MenuBarItem slot="pagination">
    {#if !hidePagination}
      <PageSelector2
        totalPageNumber={totalPageNumber}
        bind:currentIndex={currentPageIndex}
      />
    {/if}
  </MenuBarItem>
</MenuBar>

<style>
  #root {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #f5f5f5;
  }
  #root > :first-child {
    margin-left: 3%;
  }
  #root > :last-child {
    margin-right: 3%;
  }
  .control-item {
    flex: 1;
    align-items: center;
    display: flex;
  }
</style>
