<script>
  export let title;
</script>

<div class="chip">
  {title}
</div>

<style>
    .chip {
        border-radius: 0.8rem;
        font-size: 0.8rem;
        border: 1px solid #184398;
        padding: 0 0.2rem;
        color: #184398;
        flex-shrink: 0;
        flex-grow: 0;
        background-color: white;
    }
</style>