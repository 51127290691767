import dayjs from "dayjs";
import { I18N_GetString } from "./i18n";

/**
 * @typedef {"error:network"|"error:json:malformed"|"error:unknown"|"error:license:max-ccu-exceeded"} FetchErrorType
 * @typedef {{isSuccess: false, type: FetchErrorType, error: any}} FetchError
 * @typedef {{isSuccess: true, data: any}} FetchSuccess
 * @typedef {FetchSuccess | FetchError} FetchResult
 */


// Let Han know if this function is changed. 
// This function should be consistent with Plugin's validatePath function.
/**
 * Remove illegal chars
 * @param {string} url
 * @returns {string} url
 */

function validatePath(s) {
  const illegalChars = /[*;:"<>|/?]/g
  const replacementChar = '_'

  return s.replace(illegalChars, replacementChar)
}


export class FetchError extends Error {
  constructor(fetchError) {
    super("fetch error")
    this.error = fetchError
  }
}

/**
 * fetch json
 * @param {string} url
 * @param {function | null} dataTransformer
 * @returns {Promise<any>}
 */
export async function myJsonFetcher(url, dataTransformer = null) {
  // try to make the request
  // if it fails, it is network error
  let response
  try {
    response = await fetch(url)
  } catch (e) {
    throw new FetchError({error: e, type: "error:network"})
  }

  // try to parse the response as json
  // if it fails, it is malformed json error (if the request succeeded)
  let json
  try {
    json = await response.json()
  } catch (e) {
    if (!response.ok) throw new FetchError({error: null, type: "error:unknown"})
    throw new FetchError({error: e, type: "error:json:malformed"})
  }

  if (!response.ok) {
    throw new FetchError( {error: json, type: json.type})
  }

  // apply the data transformer if given
  if (dataTransformer) {
    try {
      return dataTransformer(json)
    } catch (e) {
      throw new FetchError({error: e, type: "error:json:malformed"})
    }
  }

  return json
}

/**
 * fetch json with SYNC xhr
 * @param {string} url
 * @param {function | null} dataTransformer
 * @returns {any}
 */
function myJsonSyncXhrFetcher(url, dataTransformer = null) {
  const xhr = new XMLHttpRequest()
  xhr.open("GET", url, false)
  xhr.send()

  const isSuccessResponse = xhr.status.toString(10).startsWith("2")
  let data
  try {
    data = JSON.parse(xhr.responseText)
  } catch (e) {
    if (isSuccessResponse) {
      throw new FetchError({error: null, type: "error:unknown"})
    }
    throw new FetchError({error: e, type: "error:json:malformed"})
  }

  if (!isSuccessResponse) {
    throw new FetchError( {error: data, type: data.type})
  }
  if (xhr.status !== 200) {

    const data = JSON.parse(xhr.responseText);
    return data;
  }

  // apply the data transformer if given
  if (dataTransformer) {
    try {
      return dataTransformer(data)
    } catch (e) {
      throw new FetchError({error: e, type: "error:json:malformed"})
    }
  }

  return data
}

// Three fixed properties: operation(work) code, start time and end time.
const fixedRowIdxMap = {"OperationCode": 0, "StartTime": 1, "EndTime": 2, "Status": 3};

function getFormattedDate(date) {
  const formattedDate = dayjs(date).format("YYYY-MM-DD");
  return formattedDate;
}

function getStartDate(row) {
  return getFormattedDate(row["MyScheReservedFixedProperty"][fixedRowIdxMap.StartTime]);
}

function getOperationCode(row) {
  return row["MyScheReservedFixedProperty"][fixedRowIdxMap.OperationCode];
}

function getStatus(row) {
  return row["MyScheReservedFixedProperty"]?.[fixedRowIdxMap.Status] ?? null;
}

function setStatus(row, val) {
  row["MyScheReservedFixedProperty"]?.[fixedRowIdxMap.Status] !== undefined &&
    (row["MyScheReservedFixedProperty"][fixedRowIdxMap.Status] = val);
}


function parseData(data, upts, dlts)
{
  let cols;
  let rows;
  let dstrings;

  if (data.Columns !== undefined) {
    cols = Object.entries(data.Columns);
  } else {
    throw new Error("Columns not valid");
  }
  // console.log("columns: ", cols);


  rows = data.Rows;
  // console.log("rows: ", rows);

  dstrings = Object.entries(data.DisplayedStrings);
  // console.log("display strings: ", dstrings);

  let allData = [];
  for (let i = 0; i < rows.length; i++) {
    let tmp = {};
    for (let j = 0; j < cols.length; j++) {
      tmp[cols[j][0]] = rows[i][j];
    }
    allData.push(tmp);
  }
  // console.log("allData: ", allData);

  let displayPermutation = new Array(dstrings.length).fill(-1); // DisplayedStrings の i 番目の要素が Columns の何番目に対応するかを示す配列
  for (let i = 0; i < cols.length; i++) {
    for (let j = 0; j < dstrings.length; j++) {
      if (cols[i][0] === dstrings[j][0]) {
        displayPermutation[j] = i;
        break;
      }
    }
  }
  // console.log("displayPermutation: ", displayPermutation);

  let title = [];
  let title_key = [];
  for (let i = 0; i < displayPermutation.length; i++) {
    if (displayPermutation[i] !== -1) {
      title.push(dstrings[i][1]);
      title_key.push(dstrings[i][0]);
    }
  }

  let operations = [];
  for (let i = 0; i < rows.length; i++) {
    let operation = [];
    for (let j = 0; j < displayPermutation.length; j++) {
      if (displayPermutation[j] !== -1) {
        operation.push(rows[i][displayPermutation[j]]);
      }
    }
    operations.push(operation);
  }

  // convert string to date
  let operations_Converted = convertStringToDate(operations, title_key);

  const UploadTimeStamp = (upts !== undefined) ? upts : null;
  const DownloadTimeStamp = (dlts !== undefined) ? dlts : null;
  
  return [operations_Converted, allData, title, title_key, UploadTimeStamp, DownloadTimeStamp];
  // return [operations, allData, title, title_key];
}


// fetch functions
/**
 *
 * @param {("DispatchingView"|"OperationTable")} viewName
 * @param {string} projectId
 * @param {string} itemCode
 * @returns {Promise<any>}
 */
async function fetchResourceDetail(viewName, projectId, itemCode) { // itemCode be styleName for OperationTable
  itemCode = validatePath(itemCode);
  const server_url = viewName === "DispatchingView"
    ? `/api/projects/${projectId}/${viewName}/oper/${itemCode}.json`
    : `/api/projects/${projectId}/${viewName}/styles/${itemCode}.json`
  // console.log(server_url);
    return myJsonFetcher(server_url, data => parseData(data, data.UploadTimeStamp, data.DownloadTimeStamp))
}

/**
 * Fetch function for DispatchingView resource detail
 * Note that this function does not do conversions like "fetchResourceDetailWithStyleIdx"
 * @param {string} projectId
 * @param {string} itemCode
 * @returns {Promise<FetchResult>}
 */
async function fetchDispatchingViewResourceDetail(projectId, itemCode) {
  itemCode = validatePath(itemCode);
  return myJsonFetcher(
    `/api/projects/${projectId}/DispatchingView/oper/${itemCode}.json`
  )
}

/**
 * fetch styles (for dispatching view)
 * @param {string} projectId
 * @returns {Promise<FetchResult>}
 */
async function fetchStyles(projectId) {
  const styles_url = `/api/projects/${projectId}/DispatchingView/styles.json`
  return myJsonFetcher(styles_url, data => data.Styles)
}

// fetch with styles (for dispatching view)
async function fetchResourceDetailWithStyleIdx(projectId, itemCode, iStyle) {
  itemCode = validatePath(itemCode);
  const server_url = `/api/projects/${projectId}/DispatchingView/oper/${itemCode}.json`;
  return myJsonFetcher(server_url, data => parseData(data.Data[iStyle], data.UploadTimeStamp, data.DownloadTimeStamp))
}

async function fetchTransactionData(projectId, timestamp) {
  let url = `/api/projects/${projectId}/transactions`
  if (!!timestamp) url += "?timestamp=" + timestamp;
  return myJsonFetcher(url)
}

async function fetchResourceGroup(projectId) {
  const resource_group_url = `/api/projects/${projectId}/DispatchingView/resGroup.json`;
  return myJsonFetcher(resource_group_url, data => data.ResGroup)
}

async function fetchResource(projectId) {
  const url = `/api/projects/${projectId}/DispatchingView/res.json`
  return myJsonFetcher(url)
}

async function fetchStyleOperationTable(projectId) {
  const url =  `/api/projects/${projectId}/OperationTable/style_OperationTable.json`;
  return myJsonFetcher(url, data => data.Rows)
}

async function fetchResultData(projectId) {
  const url = `/api/projects/${projectId}/Dashboard/works.json`;
  return myJsonFetcher(url)
}

async function fetchIntegratedMasterData(projectId){
  const url= `/api/projects/${projectId}/IntegratedMaster/im.json`;
  return myJsonFetcher(url)
}

async function fetchIntegratedMasterTransactionData(projectId, timestamp) {
  const baseUrl= `/api/projects/${projectId}/transactions`;
  const urlSearchParams = new URLSearchParams({
    folder: "IntegratedMaster",
  })
  if (timestamp) urlSearchParams.append("timestamp", timestamp)
  let url = baseUrl + "?" + urlSearchParams.toString()
  return myJsonFetcher(url)
}

async function fetchOrderGanttStyleData(projectId, styleName){
  styleName = validatePath(styleName);
  const url = `/api/projects/${projectId}/OrderGantt/styles/${styleName}.json`;
  return fetch(url).then(res => res.json())
}

async function getOrderGanttDownloadUrl(projectId){
  return `/api/projects/${projectId}/OrderGantt/Order/`
}

async function fetchOrderLeadTimeOrders(projectId){
  const url = `/api/projects/${projectId}/OrderLeadTime/orders.json`;
  return myJsonFetcher(url)
}

async function fetchProjectData(projectId, viewID){
  const url = `/api/projects/${projectId}/${viewID}/project.json`;
  return myJsonFetcher(url)
}

async function fetchProductionWorks(projectId){
  const url = `/api/projects/${projectId}/ProductionOrder/works.json`;
  return myJsonFetcher(url)
}

async function fetchProductionOrders(projectId){
  const url = `/api/projects/${projectId}/ProductionOrder/orders.json`;
  return myJsonFetcher(url)
}

async function fetchProductionOrderData(projectId, Order_ObjectID){
  const url = `/api/projects/${projectId}/ProductionOrder/orders/${Order_ObjectID}.json`;
  return myJsonFetcher(url)
}

async function fetchResultGraphMeta(projectId){
  const url = `/api/projects/${projectId}/ResultGraph/resources.json`;
  return myJsonFetcher(url)
}

async function fetchResultGraphObject(projectId, itemCode){
  // itemCode = validatePath(itemCode);
  const url = `/api/projects/${projectId}/ResultGraph/resources/${itemCode}.json`;
  return myJsonFetcher(url)
}

async function fetchResGanttProjectInfo(projectId) {
  const url = `/api/projects/${projectId}/ResGantt/project.json`
  return myJsonFetcher(url)
}

async function fetchOrderGanttProjectInfo(projectId) {
  const url = `/api/projects/${projectId}/OrderGantt/project.json`
  return myJsonFetcher(url)
}

async function fetchLoadGraphProjectResources(projectId) {
  const url = `/api/projects/${projectId}/LoadGraph/resources.json`;
  return myJsonFetcher(url);
}

async function fetchLoadGraphProjectLoads(projectId, itemCode) {
  const url = `/api/projects/${projectId}/LoadGraph/loads/${itemCode}.json`;
  return myJsonFetcher(url);
}

export async function fetchLoadGraphMPSProject(projectId) {
  const url = `/api/projects/${projectId}/LoadGraph/project.json`;
  return myJsonFetcher(url)
}

export async function fetchLoadGraphMPSResources(projectId) {
  const url = `/api/projects/${projectId}/LoadGraph/resources.json`;
  return myJsonFetcher(url)
}

export async function fetchLoadGraphMPSResourceLoads(projectId, objectId) {
  const url = `/api/projects/${projectId}/LoadGraph/loads/${objectId}.json`;
  return myJsonFetcher(url);
}

function convertStringToDate(operations, title_key) {
  // 各operationについて
  // Work_ResultStartTime, Work_ResultEndTime については
  //  ・（"YYYY/MM/DD HH:mm:ss"）の形式の文字列に変換する

  let operationsFormat = [];
  for (let i = 0; i < operations.length; i++) {
    let tmp_operation = [];
    for (let j = 0; j < operations[i].length; j++) {
      if (title_key[j] == "Work_ResultStartTime") {
        if (operations[i][j] === "") {
          tmp_operation.push("");
        } else {
          let date = new Date(operations[i][j]);
          tmp_operation.push(dayjs(date).format("YYYY/MM/DD HH:mm:ss"));
        }
      } else if (title_key[j] == "Work_ResultEndTime") {
        if (operations[i][j] === "") {
          tmp_operation.push("");
        } else {
          let date = new Date(operations[i][j]);
          tmp_operation.push(dayjs(date).format("YYYY/MM/DD HH:mm:ss"));
        }
      } else {
        tmp_operation.push(operations[i][j]);
      }
    }
    operationsFormat.push(tmp_operation);
  }

  return operationsFormat;
}

function addDateColumn(operations, title, title_key) {
  for (let i = 0; i < operations.length; i++) {
    let operation_date = null;
    for (let j = 0; j < operations[i].length; j++) {
      if (title_key[j] == "UseInst_StartTime") {
        let date = new Date(operations[i][j]);
        operation_date = dayjs(date).format("YYYY/MM/DD");
      }
      if (operation_date == null) { // No Date Column
        return [operations, title, title_key];
      }
    }
    operations[i].unshift(operation_date);
  }

  title.unshift(I18N_GetString("日付"));
  title_key.unshift("");

  return [operations, title, title_key];
}

function addReliefColumn(operations, title, title_key) {
  for (let i = 0; i < operations.length; i++) {
    operations[i].unshift("(relief dummy value)");
  }

  title.unshift(I18N_GetString("リリーフする"));
  title_key.unshift("relief");

  return [operations, title, title_key];
}

export {
  validatePath,
  fixedRowIdxMap,
  getStartDate,
  getOperationCode,
  getFormattedDate,
  fetchDispatchingViewResourceDetail,
  fetchResourceDetail,
  fetchResourceDetailWithStyleIdx,
  fetchStyles,
  fetchResourceGroup,
  fetchResource,
  fetchStyleOperationTable,
  fetchTransactionData,
  addDateColumn,
  addReliefColumn,
  fetchResultData,
  fetchIntegratedMasterData,
  fetchIntegratedMasterTransactionData,
  fetchOrderGanttProjectInfo,
  fetchOrderGanttStyleData,
  getOrderGanttDownloadUrl,
  fetchOrderLeadTimeOrders,
  fetchProductionWorks,
  fetchProductionOrders,
  fetchProductionOrderData,
  fetchResGanttProjectInfo,
  parseData,
  myJsonSyncXhrFetcher,
  fetchResultGraphObject,
  fetchResultGraphMeta,
  setStatus,
  getStatus,
  fetchLoadGraphProjectResources,
  fetchLoadGraphProjectLoads,
  fetchProjectData
};
