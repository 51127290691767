<script>
  import {useQuery} from "@sveltestack/svelte-query"
  import Loading from "../pages/Loading.svelte";

  const queryResult = useQuery("/api/me", () => fetch("/api/me").then(res => res.json()))

  function isServerAdmin(user) {
    return user?.roles.includes("admin")
  }
</script>

<div>
  {#if $queryResult.data}
    {#if isServerAdmin($queryResult.data)}
      <slot />
    {:else}
      <h3>表示する権限がありません。</h3>
    {/if}
  {:else}
    <Loading/>
  {/if}
</div>