class SelectRectangle {
  static minX = 0;
  static minY = 0;
  static maxX = 0;
  static maxY = 0;
  static selectFunction = () => {};
  static unselectFunction = () => {};
  static singleCallFunction = () => {};
  static singleCallFunctionNoDOM = () => {};
  static selectedItems = [];

  static IsSelectedId(id) {
    return SelectRectangle.selectedItems.includes(id);
  }

  static AttachUnselectFunction(f) {
    SelectRectangle.unselectFunction = f;
  }

  static AttachSelectFunction(f) {
    SelectRectangle.selectFunction = f;
  }

  static AttachSingleCallFunction(f) {
    SelectRectangle.singleCallFunction = f;
  }

  static AttachSingleCallFunctionNoDOM(f) {
    SelectRectangle.singleCallFunctionNoDOM = f;
  }

  static SingleClickEventWrapper(el) {
    el.onclick = (e) => {
      e.preventDefault();
      SelectRectangle.UnselectAll();
      //el.classList.add("selected");
      const _id = el.id;
      SelectRectangle.selectFunction(_id);
      SelectRectangle.selectedItems = [_id];
      SelectRectangle.singleCallFunction(SelectRectangle.selectedItems);
    };
  }

  static SingleClickEventWrapperPassive() {
    return (e) => {
      e.preventDefault();
      SelectRectangle.UnselectAll();
      //e.currentTarget.classList.add("selected");
      const _id = e.currentTarget.id;
      SelectRectangle.selectFunction(_id);
      SelectRectangle.selectedItems = [_id];
      SelectRectangle.singleCallFunction(SelectRectangle.selectedItems);
    };
  }

  static ResetCoords() {
    SelectRectangle.minX = -1;
    SelectRectangle.minY = -1;
    SelectRectangle.maxX = -1;
    SelectRectangle.maxY = -1;
  }

  static ResetValues() {
    SelectRectangle.ResetCoords();
    SelectRectangle.selectFunction = () => {};
    SelectRectangle.unselectFunction = () => {};
    SelectRectangle.singleCallFunction = () => {};
    SelectRectangle.selectedItems = [];
  }

  static UnselectAll() {
    const selectableElements = document.querySelectorAll(".selectable");
    let toSelect = [];
    let toUnselect = [];

    selectableElements.forEach((e) => {
      toUnselect.push(e.id);
    });

    // Remove elements that aren't on the selected list yet
    toUnselect.filter(e => !SelectRectangle.selectedItems.includes(e));
    // Call the functions to each elements
    toUnselect.forEach(e => SelectRectangle.unselectFunction(e));
    // Update the selected array
    SelectRectangle.selectedItems = [];
    // Calls an empty single call function after the process
    SelectRectangle.singleCallFunction(SelectRectangle.selectedItems);
  }

  static ForceSelect(ids, data) {
    // Unselect all first
    SelectRectangle.UnselectAll();

    // Forcefully select the given new elements
    ids.forEach(e => SelectRectangle.selectFunction(e));
    // Update the selected array
    SelectRectangle.selectedItems = ids;
    // Calls a single call function after the process
    SelectRectangle.singleCallFunctionNoDOM(SelectRectangle.selectedItems, data);
  }

  static AttachToDiv(div) {
    let isSelecting = false;
    let startX, startY;
    SelectRectangle.ResetValues();

    div.style.display = "none";
    div.style.border = "1px dashed #00f";
    div.style.position = "absolute";
    div.style.pointerEvents = "none";
    div.style.backgroundColor = "rgba(0, 0, 255, 0.2)";
    div.style.zIndex = "999";

    document.addEventListener("mousedown", (e) => {
      if (e.target.classList.contains("pullable") && e.button === 0) {
        isSelecting = true;
        startX = e.pageX;
        startY = e.pageY;

        div.style.display = "inline";
        div.style.left = startX + "px";
        div.style.top = startY + "px";
        div.style.width = 0;
        div.style.height = 0;
        SelectRectangle.ResetCoords();
      }
    });

    document.addEventListener("mousemove", (e) => {
      if (isSelecting) {
        const currX = e.pageX;
        const currY = e.pageY;

        SelectRectangle.minX = Math.max(0, Math.min(startX, currX));
        SelectRectangle.minY = Math.max(0, Math.min(startY, currY));
        SelectRectangle.maxX = Math.min(window.innerWidth - 2, Math.max(startX, currX));
        SelectRectangle.maxY = Math.min(window.innerHeight - 2, Math.max(startY, currY));

        div.style.left = SelectRectangle.minX + "px";
        div.style.top = SelectRectangle.minY + "px";
        div.style.width = SelectRectangle.maxX - SelectRectangle.minX + "px";
        div.style.height = SelectRectangle.maxY - SelectRectangle.minY + "px";

        // Select loop
      }
    });

    document.addEventListener("mouseup", (e) => {
      //e.preventDefault();
      if (isSelecting) {
        isSelecting = false;
        div.style.display = "none";
        selectElements();
      }
    });



    function selectElements() {
      const selectableElements = document.querySelectorAll(".selectable");
      let toSelect = [];
      let toUnselect = [];

      selectableElements.forEach((e) => {
        const rect = e.getBoundingClientRect();
        const elementX = rect.left + window.scrollX;
        const elementY = rect.top + window.scrollY;

        if (
          elementX + rect.width > SelectRectangle.minX && elementX < SelectRectangle.maxX
          && elementY + rect.height > SelectRectangle.minY && elementY < SelectRectangle.maxY
        ) {
          toSelect.push(e.id);
        }
        else {
          toUnselect.push(e.id);
        }
      });

      // Remove elements that aren't on the selected list yet
      toUnselect.filter(e => !SelectRectangle.selectedItems.includes(e));
      // Call the functions to each elements
      toUnselect.forEach(e => SelectRectangle.unselectFunction(e));
      toSelect.forEach(e => SelectRectangle.selectFunction(e));
      // Update the selected array
      SelectRectangle.selectedItems = toSelect;
      // Calls a single call function after the process
      SelectRectangle.singleCallFunction(SelectRectangle.selectedItems);
    }
  }
}

export { SelectRectangle };
