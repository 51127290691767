import dayjs from "dayjs"
import {getStartDate} from "../../getData.js"

export function determinePageToDisplay(allData, dateList, totalPageNumber) {
  // 今日以前の中で最も新しい日付のページ番号を取得
  function getTodayPageNumber(dateList) {
    const today = dayjs().format("YYYY-MM-DD");
    // const today = "2023-02-27";  // for test
    let todayPageNumber = 1;
    for (let i = 0; i < dateList.length; i++) {
      if (dateList[i] <= today) {
        todayPageNumber = i + 1;
      } else {
        break;
      }
    }
    return todayPageNumber;
  }

  // 最も早い未完了のページ番号を取得
  function getEarliestUncompletedPageNumber(dateList, allData) {
    let earliestUncompletedDate = "9999-99-99";
    for (const row of allData) {
      if (row["Work_ResultEndTime"] === "") {
        const operationDate = getStartDate(row);
        if (operationDate < earliestUncompletedDate) {
          earliestUncompletedDate = operationDate;
        }
      }
    }

    if (earliestUncompletedDate === "9999-99-99") {
      // すべての作業が完了している場合
      return dateList.length;
    }

    let earliestUncompletedPageNumber = 1;
    for (let i = 0; i < dateList.length; i++) {
      if (dateList[i] <= earliestUncompletedDate) {
        earliestUncompletedPageNumber = i + 1;
      } else {
        break;
      }
    }
    return earliestUncompletedPageNumber;
  }

  const todayPageNumber = getTodayPageNumber(dateList);
  const earliestUncompletedPageNumber = getEarliestUncompletedPageNumber(dateList, allData);

  return Math.min(todayPageNumber, earliestUncompletedPageNumber);
}