<script>
  import { navigateI18n } from "../../i18n/navigate.js";
  import { translate } from "../../i18n/i18next.js";
  import { useIsDemoServer } from "../../hooks/useIsDemoServer.js";
  import { onMount } from "svelte";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import { getNotificationsContext } from "svelte-notifications";
  import { login } from "../../utils/login.js"
  import { isDemoServerGuestUsername } from "../../utils/demo.js";

  const { addNotification } = getNotificationsContext();

  const params = new URLSearchParams(window.location.search)
  const redirectTo = params.get("redirect")

  const {isDemoServer} = useIsDemoServer()

  let username = "";
  let password = "";

  async function onClickLogin() {
    const isLoginSuccess = await login(username, password)
    if (isLoginSuccess) {
      if (redirectTo) {
        navigateI18n(redirectTo)
      } else {
        navigateI18n("/")
      }
    } else {
      addNotification({
        text: translate("frontend:pages.login.loginError"),
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      });
    }
  }

  onMount(async () => {
    const loginUsername = params.get("username")
    if ($isDemoServer && isDemoServerGuestUsername(loginUsername)) {
      username = loginUsername
      password = ""
      await onClickLogin()
    }
  })
</script>

<div class="container">
  <input
    class="centered"
    id="username"
    name="username"
    placeholder={translate("generic.username")}
    bind:value={username}
    autofocus
  />
  <input
    class="centered"
    id="password"
    type="password"
    name="password"
    placeholder={translate("generic.password")}
    bind:value={password}
  />
  <GeneralButton buttonFunction={onClickLogin} buttonText={translate("generic.login")} buttonExtraStyle="width: 310px; margin: 0 auto; margin-top: 20px"/>
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
  }
  .centered {
    margin: 0 auto;
  }
  #username {
    margin-top: 20px;
    height: 40px;
    width: 300px;
    border-radius: 8px;
    text-align: center;
  }
  #password {
    margin-top: 20px;
    height: 40px;
    width: 300px;
    border-radius: 8px;
    text-align: center;
  }
  ::placeholder {
    text-align: center;
  }
</style>
