<script>
  import { getContext, onMount } from "svelte";
  import { SelectRectangle } from "../../utils/selectRectangle";
  import UpperPane from "./UpperPane.svelte";
  import ChartContainer from "./ChartContainer.svelte";
  import TimelineArea from "./TimelineArea.svelte";
  import GraphArea from "./GraphArea.svelte";
  import DataFetcher from "./DataFetcher.js";
  import {
    getResGanttConfiguration,
    saveResGanttConfiguration,
  } from "../../utils/configurationStorage/views/ResGanttConfigurationStorage.js";
  import {
    getOrderGanttConfiguration,
    saveOrderGanttConfiguration,
  } from "../../utils/configurationStorage/views/OrderGanttConfigurationStorage";
  import { get } from "svelte/store";
  import { tick } from "svelte";

  export let boxDimensions = "width: 100vw; height: 100vh;";

  // Gantt store imports
  export let GanttStore

  // Main chart imports
  export let styleDict;
  export let rowDataUrl;
  export let transactionsUrl;
  export let uploadTimeStamp;
  export let downloadTimeStamp;
  export let projectData;
  export let transactions;
  export let registerTransaction;
  export let projectId;
  export let ganttChartType;
  export let userId

  let currenStyle;

  // fetch error handler
  const { handleNonBlockingFetchErrors } = getContext("fetchErrorHandler");

  const {RowsAllOwnedByStyle} = GanttStore

  onMount(async () => {
    console.log(GanttStore)
    GanttStore.GanttChartType.update((prevType) => {
      let changed = false; // Changed to different UI? e.g., resGantt -> orderGantt
      // if (prevType == GanttStore.GanttChartTypes.SimpleResGantt || prevType == GanttStore.GanttChartTypes.ResGantt ) {
      //   changed = (ganttChartType == GanttStore.GanttChartTypes.SimpleOrderGantt) || (ganttChartType == GanttStore.GanttChartTypes.OrderGantt);
      // } else {
      //   changed = (ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt) || (ganttChartType == GanttStore.GanttChartTypes.ResGantt);
      // }
      changed = (ganttChartType != prevType);
      if (changed) {
        // GanttStore.ExtraColumns.set(new Array());
        // To implement jump function, this may not be necessary to clear, though, seems no effect
        // GanttStore.SelectedObject.set(null); //
        // GanttStore.SelectedId.set(null);

        if (ganttChartType == GanttStore.GanttChartTypes.SimpleOrderGantt) {
          GanttStore.ExtraColumns.set([
            { name: "製番", code: "Order_Seiban"},
            { name: "品目コード", code: "Order_ItemCode" },
            { name: "品目名", code: "Order_ItemName" },
            { name: "納期", code: "Order_LET"},
            { name: "納期遅れ", code: "Order_Lateness" },
          ]);
        } else if (ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt) {
          GanttStore.ExtraColumns.set([
            { name: "資源グループ", code: "ResourceGroup" },
            { name: "資源名", code: "ResourceName" },
          ]);
        } else {
          GanttStore.ExtraColumns.set(new Array());
        }
        GanttStore.RowSearchPattern.set("");
      }

      return ganttChartType;
    });
    // Reset variables
    GanttStore.TimelineItems.update((_) => []);
    GanttStore.TimelineHighlight.update((_) => null);
    GanttStore.SelectedObject.update((_) => null);
    GanttStore.SelectedId.update((_) => null);

    // Init store
    GanttStore.BasisTime.update((_) => new Date(projectData[0]));
    GanttStore.StartDate.update((_) => new Date(projectData[1]));
    GanttStore.EndDate.update((_) => new Date(projectData[2]));
    GanttStore.DisplayedStartDate.update((_) => new Date(projectData[3]));
    GanttStore.DisplayedEndDate.update((_) => new Date(projectData[4]));
    GanttStore.ProjectName.update((_) => projectData[5]);
    GanttStore.ProjectId.set(projectId)
    GanttStore.UserId.set(userId)
    GanttStore.Comment.update((_) => projectData[6]);
    GanttStore.StyleObjects.update((_) => projectData[7]);
    GanttStore.ValidStartDate.update((_) => new Date(projectData[8]));
    GanttStore.ValidEndDate.update((_) => new Date(projectData[9]));
    GanttStore.LastUploadTimestamp.update((_) => uploadTimeStamp);
    GanttStore.LastTransactionDownloadTimestamp.update((_) => downloadTimeStamp);
    GanttStore.StylesDict.update((_) => styleDict);
    GanttStore.RowDownloadUrl.update((_) => rowDataUrl);
    GanttStore.Transactions.update((_) => transactions);
    GanttStore.TransactionsUrl.update((_) => transactionsUrl);
    GanttStore.TransactionRegisteringFunction.update(
      (_) => registerTransaction
    );
    GanttStore.CurrentStyle.subscribe((val) => (currenStyle = val));

    // To replace by saved (session storage?) values ?
    GanttStore.CurrentDisplayedStartDate.update(
      (_) => new Date(projectData[3]) > new Date(projectData[8]) ? new Date(projectData[3]) : new Date(projectData[8])
    );

    if (
      ganttChartType == GanttStore.GanttChartTypes.ResGantt ||
      ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt ||
      ganttChartType == GanttStore.GanttChartTypes.CustomizedResGantt
    ) {
      // Init select rectangle api
      SelectRectangle.AttachToDiv(
        document.getElementById("gantt_select-rectangle")
      );


      SelectRectangle.AttachSingleCallFunction((arr) => {

        const newArr = arr.map((el) => {
          let _newEl = [];
          _newEl.push(el); // Ope object id

          // Resource object id
          const _domEl = document.getElementById(el);
          const _row = _domEl.classList.value.match(/r_(\d+)/)[1];
          _newEl.push(_row);

          return _newEl;
        })

        GanttStore.TimelineItems.set(newArr);
      });

      // Used for forced operations (select without using the select rectangle)
      SelectRectangle.AttachSingleCallFunctionNoDOM((ids, data) => {
        if (ids.length === 0) return;

        const newArr = [[ids[0], data]];
        GanttStore.TimelineItems.set(newArr);
      });
    }

    // apply settings from the localStorage

    let saveFunc = null;
    let getFunc = null;
    if (
      ganttChartType == GanttStore.GanttChartTypes.ResGantt ||
      ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt ||
      ganttChartType == GanttStore.GanttChartTypes.CustomizedResGantt
    ) {
      saveFunc = (config) => saveResGanttConfiguration(config, userId, projectId);
      getFunc = () => getResGanttConfiguration(userId, projectId);
    } else {
      saveFunc = (config) => saveOrderGanttConfiguration(config, userId, projectId);
      getFunc = () => getOrderGanttConfiguration(userId, projectId);
    }

    const savedSettings = getFunc();
    console.log("SavedSettings: ", savedSettings);

    // set the current style
    //   load the style name setting from localStorage, and if it is a valid style, set it
    //   else use the first style in projectData and update localStorage

    GanttStore.CurrentStyle.update((prevStyle) => {
      const savedStyle = savedSettings.styleObject;

      if (savedStyle && projectData[7].find((s) => s.ObjectID == savedStyle.ObjectID)) {
        console.log(`Current style: ${JSON.stringify(prevStyle)}, setting to saved style: ${JSON.stringify(savedStyle)}`);
        return savedStyle;
      } else {
        console.log('Style not selected, default to', projectData[7][0]);
        saveFunc({ styleObject: projectData[7][0] });
        return projectData[7][0];
      }
    });
    // set display span
    if (savedSettings.displaySpanInSeconds) {
      GanttStore.DisplayedSpanInSeconds.set(savedSettings.displaySpanInSeconds);
    }
    // set startDayDisplayTime
    if (savedSettings.startDayDisplayTime) {
      GanttStore.StartDayDisplayTime.set(savedSettings.startDayDisplayTime);
    }
    // set endDayDisplayTime
    if (savedSettings.endDayDisplayTime) {
      GanttStore.EndDayDisplayTime.set(savedSettings.endDayDisplayTime);
    }
    // set preventMistakes
    if (savedSettings.preventMistakes !== undefined) {
      GanttStore.PreventMistakes.set(savedSettings.preventMistakes);
    }
    // set rowSearchPattern
    if (savedSettings.rowSearchPattern) {
      GanttStore.RowSearchPattern.set(savedSettings.rowSearchPattern);
    }
    if (savedSettings.currentDisplayedStartDate) {
      const saved = new Date(savedSettings.currentDisplayedStartDate)
      // console.log(saved);
      // load the saved currentDisplayedStartDate only when it is in the displayed time range
      if (saved.getTime() >= get(GanttStore.ValidStartDate).getTime() && saved.getTime() <= get(GanttStore.ValidEndDate).getTime()) {
        GanttStore.CurrentDisplayedStartDate.set(saved)
      } else {
        // If the saved time is out of valid period, set a initial value
        GanttStore.CurrentDisplayedStartDate.set(new Date(projectData[3]) > new Date(projectData[8]) ?
                    new Date(projectData[3]) : new Date(projectData[8]));
      }
    }

    if (ganttChartType == GanttStore.GanttChartTypes.OrderGantt) {
      if (savedSettings.unfoldedOperations) {
        GanttStore.RowsUnfoldedForOrderGantt.set(new Set(savedSettings.unfoldedOperations));
      }
    }

    // we have to call tick(), because DisplayedRowCount and rowsDisplayedStart are reset by setting RowSearchPattern.
    await tick()
    // set rowCount
    if (savedSettings.rowCount) {
      console.log(savedSettings.rowCount);
      GanttStore.DisplayedRowCount.set(savedSettings.rowCount);
    }
    await tick()
    if (savedSettings.rowsDisplayedStart) {
      const saved = savedSettings.rowsDisplayedStart
      console.log("total:", get(GanttStore.RowsCountTotal), "saved", saved);
      // load the saved rowsDisplayedStart only when it is in the rows range
      if (saved > 0 && saved < get(GanttStore.RowsCountTotal)) {
        GanttStore.RowsDisplayedStart.set(saved)
      }
    }
  });

  const {RowsDisplayedStart} = GanttStore
  $: console.log($RowsDisplayedStart)
</script>

<div id="gantt_select-rectangle" />
<div id="gantt" style={boxDimensions}>
  <div id="gantt_main">
    <UpperPane
      GanttStore={GanttStore}
      projectId={projectId}
      userId={userId}
    />
    <ChartContainer
      GanttStore={GanttStore}
    />
    {#if ganttChartType == GanttStore.GanttChartTypes.ResGantt || ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt || ganttChartType == GanttStore.GanttChartTypes.CustomizedResGantt}
      <GraphArea
        GanttStore={GanttStore}
      />
    {/if}
  </div>
  <TimelineArea
    GanttStore={GanttStore}
    project={projectId}
  />
</div>

<style>
  div#gantt {
    user-select: none;
    display: flex;
    flex-direction: row;
    background-color: #f0f0f0;
  }

  div#gantt_main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
</style>
