<div id="chartArea">
  <slot />
  <div id="chartArea_footerPadding"></div>
</div>

<style>
  div#chartArea_footerPadding {
    height: 40px;
    width: 100%;
    background-color: white;
    border-top: 2px solid #888888;
  }

  div#chartArea {
    height: 100%;
    flex: 1;
    display: flex;
    border: 2px solid #888888;
    flex-direction: column;
    background-color: white;
  }
</style>
