import parseErr from "parse-err"
import { getSourceMapConsumer } from "./sourceMap.js";

export async function sendLogsToBackend(message, level, error, meta) {
  const parsedError =  parseErr(error)

  // if SourceMap is available, add its information to each frame of the stack
  const smc = getSourceMapConsumer()
  if (smc) {
    parsedError.stack = parsedError.stack?.map(stackFrame => {
      const original = smc.originalPositionFor({line: stackFrame.lineNumber, column: stackFrame.columnNumber})
      return {
        ...stackFrame,
        original,
      }
    })
  }
  const response = await fetch("/api/logs/frontend", {
    method: "POST",
    body: JSON.stringify({
      message,
      level,
      error: parsedError,
      meta,
    }),
    headers: {
      "Content-Type": "application/json",
    }
  })
  return response.ok
}
