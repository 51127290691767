import { I18N_ProcessHeaderDate } from "../../utils/i18n";
import { translate } from "../../i18n/i18next.js";
import { combine, parseAdvancedFilterObjString, StringFilter } from "../../utils/filter.js";
import dayjs from "dayjs";

export const _GANTSVGNS = 'http://www.w3.org/2000/svg';

export const GHSECOND = 1;
export const GHMINUTE = 60;
export const GHHOUR = 3600;
export const GHDAY = 86400;
export const GHWEEK = 604800;
export const GHMONTH = 2592000;
export const GHYEAR = 31536000;

export const COLOR_STATUS_COMPLETE = "#808080";
export const COLOR_STATUS_STARTED = "#808080";
export const COLOR_STATUS_NOTSTARTED = "#808080";
export const COLOR_STATUS_UNSET = "#808080";
export const COLOR_STATUS_RELEASED = "#12dea7";
export const COLOR_STATUS_CONFIRMED = "#000000";

export const GetStatusColor = (status) => {
  switch (status) {
    case (EStatus.COMPLETE):
      return COLOR_STATUS_COMPLETE;
    case (EStatus.STARTED):
      return COLOR_STATUS_STARTED;
    case (EStatus.UNSET):
      return COLOR_STATUS_UNSET;
    case (EStatus.DESIGNED):
      return COLOR_STATUS_RELEASED;
    case (EStatus.CONFIRMED):
      return COLOR_STATUS_CONFIRMED;
    case (EStatus.NOTSTARTED):
    default:
      return COLOR_STATUS_NOTSTARTED;
  }
}

export const GetStatusText = (status) => {
  switch (status) {
    case (EStatus.COMPLETE):
      return translate("asprova.terms.operationStatuses.completed")
    case (EStatus.STARTED):
      return translate("asprova.terms.operationStatuses.started")
    case (EStatus.NOTSTARTED):
      return translate("asprova.terms.operationStatuses.scheduled")
    case (EStatus.DESIGNED):
      return translate("asprova.terms.operationStatuses.released")
    case (EStatus.CONFIRMED):
      return translate("asprova.terms.operationStatuses.frozen")
    case (EStatus.UNSET):
    default:
      return translate("asprova.terms.operationStatuses.unset")
  }
}

export const _gantHelper_adjustDateWithinADay = (timestamp, startTime, endTime) => {
  const _old_date = new Date(timestamp);
  const _hour = _gantHelper_formatHour(_old_date);
  const _current_percent = _gantHelper_hourToPercentageOfTheDay(_hour);
  const _start_percent = _gantHelper_hourToPercentageOfTheDay(startTime);
  let _end_percent = _gantHelper_hourToPercentageOfTheDay(endTime);
  if (_start_percent > _end_percent) _end_percent += 1; // To fix
  const _new_percent = _gantHelper_clamp100(_gantHelper_scale(_current_percent, _end_percent, _start_percent) * 100) / 100;
  const _truncated_date = new Date(new Date(timestamp).toDateString());
  return new Date(_truncated_date.getTime() + 86400000 * _new_percent);
}

export const _gantHelper_convertToSeconds = (input) => parseInt(input) * {Second: GHSECOND, Minute: GHMINUTE, Hour: GHHOUR, Day: GHDAY, Week: GHWEEK, Month: GHMONTH, Year: GHYEAR}[input.match(/[a-zA-Z]+$/)[0]];
export const _gantHelper_daysInMonth = (year, month) => new Date(year, month, 0).getDate();
export const _gantHelper_millisecondsToDays = (milliseconds) => milliseconds / (1000 * 60 * 60 * 24);
export const _gantHelper_millisecondsInADay = 86400000;
export const _gantHelper_millisecondsInAMinute = 60000;
export const _gantHelper_millisecondsToSecondsRange = (milliseconds, range) => milliseconds / (1000 * range);
export const _gantHelper_scale = (val, max, min) => (val - min) / (max - min);
export const _gantHelper_hourToPercentageOfTheDay = (hour) => ((new Date(`1970-01-01T${hour}Z`) - new Date(`1970-01-01T00:00:00Z`)) / 86400000) * 100;
export const _gantHelper_hourToPercentageOfTheSecondsRange = (hour, range) => ((new Date(`1970-01-01T${hour}Z`) - new Date(`1970-01-01T00:00:00Z`)) / (1000 * range)) * 100;
export const _gantHelper_formatHour = (date) => {
  const padZero = (num) => num.toString().padStart(2, '0');
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
};
export const _gantHelper_clamp100 = (n) => Math.max(0,Math.min(100,n));
export const _gantHelper_addOneMonth = (date) => {
  const newDate = new Date(date);
  const currentMonth = newDate.getMonth();
  newDate.setMonth(currentMonth + 1);
  if (newDate.getMonth() !== (currentMonth + 1) % 12) {
    newDate.setDate(0);
  }
  return newDate;
}
export const _gantHelper_darkenColor = (color, percentage) => "#" + color.slice(1).match(/../g).map(c => Math.floor(parseInt(c, 16) * (100 - percentage) / 100).toString(16).padStart(2, "0")).join("");
export const _gantHelper_rgbToHex = (rgb) => "#" + rgb.match(/\d+/g).map((component) => parseInt(component).toString(16).padStart(2, "0")).join("");

export const _gantHelper_getFormatedDateTime = (
  _dt = new Date(),
  _fmt = 'YYYY/MM/DD hh:mm:ss',
) => [
  ['YYYY', _dt.getFullYear()],
  ['MM', _dt.getMonth() + 1],
  ['DD', _dt.getDate()],
  ['hh', _dt.getHours()],
  ['mm', _dt.getMinutes()],
  ['ss', _dt.getSeconds()],
].reduce(
  (s, a) => s.replace(a[0], `${a[1]}`.padStart(a[0].length, '0')),
  _fmt
)

export const _gantHelper_firstDayOfNextYear = (date) => new Date(date.getFullYear() + 1, 0, 1, 0, 0, 0, 0);
export const _gantHelper_firstDayOfNextMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);
export const _gantHelper_firstDayOfNextWeek = (date) => dayjs(date).add(1, "week").startOf("week").toDate()
export const _gantHelper_nextDayAtMidnight = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0);
export const _gantHelper_nextHourFlat = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 1, 0, 0, 0);
export const _gantHelper_previousHourFlat = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() - 1, 0, 0, 0);
export const _gantHelper_nextMinuteFlat = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + 1, 0, 0);

const _ganttHelper_advancedFilterArray = (
  _items = [],
  _filterObj = {}
) => {
  const leftCondition = _filterObj.left.query ? _filterObj.left : null
  const rightCondition = _filterObj.right.query ? _filterObj.right: null

  if (leftCondition && rightCondition) {
    return _items.filter(item => combine(
      StringFilter.filter(leftCondition.type, leftCondition.query),
      StringFilter.filter(rightCondition.type, rightCondition.query),
      _filterObj.combinator,
    )(item.Alias))
  }
  if (leftCondition) return _items.filter(item => StringFilter.filter(leftCondition.type, leftCondition.query)(item.Alias))
  if (rightCondition) return _items.filter(item => StringFilter.filter(rightCondition.type, rightCondition.query)(item.Alias))
  return _items
}

export const _gantHelper_filterArray = (
  _items = [],
  _pattern = "",
) => {
  // handling for advanced filter
  const [isAdvancedFilter, filterObj] = parseAdvancedFilterObjString(_pattern)
  if (isAdvancedFilter) {
    return _ganttHelper_advancedFilterArray(_items, filterObj)
  }

  if (_pattern === "") return _items;
  return _items.filter(item => item.Alias.indexOf(_pattern) !== -1);
}

export const EScaleUnit = {
  YEARMONTH: [GHYEAR, GHMONTH],
  MONTHDAY: [GHMONTH, GHDAY],
  DAYHOUR: [GHDAY, GHHOUR],
  HOURMINUTES : [GHHOUR, GHMINUTE]
}

export const EStatus = {
  UNSET: 1, // 未計画
  NOTSTARTED: 2, //	計画済み
  DESIGNED: 3, // 指示済み
  CONFIRMED: 4, // 確定
  STARTED: 5, // 着手済み
  COMPLETE: 6 // 	完了
}

export const GetStatus = (statusString) => {
  switch (statusString) {
    case ("B"):
      return EStatus.COMPLETE;
    case ("T"):
      return EStatus.STARTED;
    case ("A"):
      return EStatus.NOTSTARTED;
    case ("I"):
      return EStatus.DESIGNED;
    case ("D"):
      return EStatus.CONFIRMED;
    case ("N"):
    default:
      return EStatus.UNSET;
  }
}

export const GetStatusString = (status) => {
  switch (status) {
    case (EStatus.COMPLETE):
      return "B";
    case (EStatus.STARTED):
      return "T";
    case (EStatus.NOTSTARTED):
      return "A";
    case (EStatus.DESIGNED):
      return "I";
    case (EStatus.CONFIRMED):
      return "D";
    case (EStatus.UNSET):
    default:
      return "N";
  }
}

export const GetScaleUnit = (spanInSeconds) => {
  if (spanInSeconds >= 2 * GHMONTH)
    return EScaleUnit.YEARMONTH;
  else if (spanInSeconds >= 2 * GHDAY)
    return EScaleUnit.MONTHDAY;
  return EScaleUnit.MONTHDAY; // Temporary ?
  //else if (spanInSeconds >= 2 * GHHOUR)
    //return EScaleUnit.DAYHOUR;
  //return EScaleUnit.HOURMINUTES;
}

export const GetProcessingUnit = (scaleUnits) => {
  switch (scaleUnits) {
    case EScaleUnit.YEARMONTH:
      return ["Y", "M"];
    case EScaleUnit.MONTHDAY:
    default:
      return ["M", "D"];
    case EScaleUnit.DAYHOUR:
      return ["D", "H"];
    case EScaleUnit.HOURMINUTES:
      return ["H", "m"];
  }
}

export const GetNextDateGraduation = (date, unit) => {
  switch (unit) {
    case "Y":
      return _gantHelper_firstDayOfNextYear(date);
    case "M":
      return _gantHelper_firstDayOfNextMonth(date);
    case "W":
      return _gantHelper_firstDayOfNextWeek(date);
    case "D":
    default:
      return _gantHelper_nextDayAtMidnight(date);
    case "H":
      return _gantHelper_nextHourFlat(date);
    case "m":
      return _gantHelper_nextMinuteFlat(date);
  }
}

export const GetDateCalendarText = (date, unit) => {
  switch (unit) {
    case "Y":
      return [I18N_ProcessHeaderDate(date, "年"),null];
    case "M":
      return [I18N_ProcessHeaderDate(date, "月"),null];
    case "D":
    default:
      return [date.getDate(),I18N_ProcessHeaderDate(date, "曜日")];
    case "H":
      return [date.getHours(),null];
    case "m":
      return [date.getMinutes(), null];
  }
}

export const GetDateCalendarBlockColor = (date, unit) => {
  switch (unit) {
    case "Y":
    case "M":
    case "H":
    case "W":
    case "m":
      return "white";
    case "D":
    default:
      return (date.getDay() === 0)
        ? "#ffcccb"
        : (date.getDay() === 6)
          ? "lightblue"
          : "white";
  }
}

export default {
  _GANTSVGNS,
  GHSECOND,
  GHMINUTE,
  GHHOUR,
  GHDAY,
  GHWEEK,
  GHMONTH,
  GHYEAR,
  COLOR_STATUS_COMPLETE,
  COLOR_STATUS_STARTED,
  COLOR_STATUS_NOTSTARTED,
  GetStatusColor,
  GetStatusText,
  _gantHelper_convertToSeconds,
  _gantHelper_daysInMonth,
  _gantHelper_millisecondsToDays,
  _gantHelper_millisecondsInADay,
  _gantHelper_millisecondsInAMinute,
  _gantHelper_millisecondsToSecondsRange,
  _gantHelper_scale,
  _gantHelper_hourToPercentageOfTheDay,
  _gantHelper_hourToPercentageOfTheSecondsRange,
  _gantHelper_formatHour,
  _gantHelper_clamp100,
  _gantHelper_addOneMonth,
  _gantHelper_darkenColor,
  _gantHelper_rgbToHex,
  _gantHelper_getFormatedDateTime,
  _gantHelper_filterArray,
  _gantHelper_firstDayOfNextYear,
  _gantHelper_firstDayOfNextMonth,
  _gantHelper_nextDayAtMidnight,
  _gantHelper_nextHourFlat,
  _gantHelper_previousHourFlat,
  _gantHelper_nextMinuteFlat,
  _gantHelper_adjustDateWithinADay,
  EScaleUnit,
  EStatus,
  GetStatus,
  GetStatusString,
  GetScaleUnit,
  GetProcessingUnit,
  GetNextDateGraduation,
  GetDateCalendarText,
  GetDateCalendarBlockColor,
}
