<script>
  import CloseIconButton from "../IconButtons/CloseIconButton.svelte"
  import {z} from "zod"
  import {createForm} from "felte"
  import {validator} from "@felte/validator-zod"
  import PrimaryButton from "../button/generic/PrimaryButton.svelte"
  import {useProjects} from "../../hooks/useProjects.js"
  import {useLicenseData} from "../../hooks/useLicenseData.js"
  import {isResultInputsEnabledPlan} from "../../utils/project.js"
  import { translate } from "../../i18n/i18next.js";

  export let project = {}
  /**
   * @type {("create"|"edit")}
   */
  export let type = "create"
  export let onSubmit
  export let dialog
  export let onClose

  const projects = useProjects()
  const licenseData = useLicenseData()

  const schema = z.object({
    name: z.string()
      .min(1, translate("frontend:components.editProjectDialog.projectNameTooShortError"))
      .refine((val) => {
        try {
          return type !== "create" || $projects.map(project => project.name).findIndex(name => name === val) === -1
        } catch (e) {
          console.log(e)
          return false
        }
      }, {message: translate("errors.projectNameAlreadyTakenError")}),
    description: z.union([
      z.string(),
      z.null(),
    ]),
    timezone: z.union([
      z.string(),
      z.undefined(),
      z.null()
    ]).transform(val => val ?? "Asia/Tokyo"),
    plan: z.union([
      z.literal("free"),
      z.literal("viewer"),
      z.literal("standard"),
    ])
      .refine(val => {
        try {
          if (type === "create" && val === "standard") {
            return $projects.filter(project => project.plan === "standard").length + 1 <= $licenseData.maxStandardPlanProjects
          }
          return true
        } catch (e) {
          console.log(e)
          return false
        }
      }, {message: translate("errors.maxStandardProjectsExceededError")})
      .refine(val => {
        try {
          if (type === "create" && val === "viewer") {
            return $projects.filter(project => project.plan === "viewer").length + 1 <= $licenseData.maxViewerPlanProjects
          }
          return true
        } catch (e) {
          console.log(e)
          return false
        }
      }, {message: translate("errors.maxViewerProjectsExceededError")})
      .refine(val => {
        //
        try {
          // Reject plan change on the following conditions:
          // * Editing a project
          // * Trying to set the plan to "viewer"
          // * The project being edited is on result inputs enabled plan and has "IntegratedMaster" view
          if (type === "edit" && val === "viewer") {
            const thisProject = $projects.find(p => p.id === project.id)
            if (!thisProject) throw new Error("The project being edited is not found in the projects list.")

            if (isResultInputsEnabledPlan(thisProject.plan) && thisProject.views.includes("IntegratedMaster")) return false
          }
          return true
        } catch (e) {
          console.log(e)
          return true
        }
      }, {message: translate("frontend:components.editProjectDialog.integratedMasterNotAvailableInViewerPlanError")})
  })

  const {form, errors, isValid, reset} = createForm({
    onSubmit,
    initialValues: project,
    extend: validator({schema})
  })

  function handleClose() {
    reset()
    onClose()
  }
</script>

<dialog bind:this={dialog}>
  <div class="title-container">
    {#if type === "create"}<div class="title">{translate("frontend:components.editProjectDialog.title.create")}</div>{/if}
    {#if type === "edit"}<div class="title">{translate("frontend:components.editProjectDialog.title.edit")}</div>{/if}
    <CloseIconButton onClick={handleClose} />
  </div>
  <form use:form>
    <div class="input-container">
      <label for="input-name">{translate("generic.projectName")}</label>
      <input
        id="input-name"
        name="name"
      />
      {#if $errors.name}
        <span class="error">{$errors.name}</span>
      {/if}
    </div>
    <div class="input-container">
      <label for="input-name">{translate("generic.projectDescription")}</label>
      <input
        id="input-description"
        name="description"
      />
      {#if $errors.description}
        <span class="error">{$errors.description}</span>
      {/if}
    </div>
    <div class="input-container">
      <label for="input-name">{translate("generic.plan")}</label>
      <select name="plan">
        <option value="free">{translate("mySchedule.plans.free.name")}</option>
        <option value="viewer">{translate("mySchedule.plans.viewer.name")}</option>
        <option value="standard">{translate("mySchedule.plans.standard.name")}</option>
      </select>
      {#if $errors.plan}
        <span class="error">{$errors.plan}</span>
      {/if}
    </div>
    <div class="buttons-container">
      <PrimaryButton type="submit" disabled={!$isValid}>
        {type === "create" ? translate("generic.create") : translate("generic.save")}
      </PrimaryButton>
    </div>
  </form>
</dialog>

<style>
  dialog {
    position: absolute;
    top: 100px;
    width: 700px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 18px rgba(0,0,0,.4);
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  input, select {
    font-size: 1rem;
    padding: 0.4rem;
    outline: none;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
  }
  input:focus, select:focus {
    outline: 2px solid #006bdf;
    outline-offset: -1px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: bold;
    flex-grow: 1;
  }
  .buttons-container {
    display: flex;
    justify-content: end;
  }
  .error {
    color: red;
    font-size: 0.9rem;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
  }
</style>