<script>
  import { fade } from "svelte/transition";

  export let viewIconSrc = "/assets/icons/icon_temp.png"
  export let onClick = () => {}

  let cloneElement = null;
  let cloneContainer = null;
  function handleCardClick(event) {
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();

    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const screenCenterX = window.innerWidth / 2;
    const screenCenterY = window.innerHeight / 2;

    // Create a clone element to perform animation
    cloneElement = target.cloneNode(true);
    cloneElement.style.position = "fixed";
    cloneElement.style.top = `${rect.top}px`;
    cloneElement.style.left = `${rect.left}px`;
    cloneElement.style.width = `${rect.width}px`;
    cloneElement.style.height = `${rect.height}px`;
    cloneElement.style.zIndex = 1000;
    cloneElement.style.transition = "transform 0.4s, opacity 0.4s, filter 0.4s";
    cloneElement.classList.add('clone');

    cloneContainer = document.createElement('div');
    cloneContainer.appendChild(cloneElement);
    document.body.appendChild(cloneContainer);

    requestAnimationFrame(() => {
      const translateX = screenCenterX - centerX;
      const translateY = screenCenterY - centerY;
      cloneElement.style.transform = `translate(${translateX}px, ${translateY}px) scale(3)`;
      cloneElement.style.opacity = "0";
      cloneElement.style.filter = "blur(5px)";
    });

    setTimeout(() => {
      document.body.removeChild(cloneContainer);
      onClick()
    }, 400);
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="view-button-card"
  in:fade={{ duration: 300 }}
  on:click={handleCardClick}
>
  <img src={viewIconSrc} alt="icon" class="view-icon"/>
  <div class="view-button-card-title">
    <slot />
  </div>
</div>

<style>
   .view-button-card {
    border: 1px solid darkgray;
    border-radius: 5px;
    width: 220px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  .view-button-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .view-icon {
    width: 100%;
    height: auto;
  }
  .view-button-card-title {
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-align: center;
    background-color: #f7f7f7;
    flex-grow: 1;
  }
</style>