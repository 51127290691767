<script>
  import AuthenticationRequired from "../../../components/functional/AuthenticationRequired.svelte";
  import ProjectPageBase from "../../../components/pages/projects/[project]/ProjectPageBase.svelte";
  import LoadGraph_MPS from "../../../views/LoadGraph_MPS/LoadGraph_MPS.svelte";
  import FetchLoadGraphData from "../../../components/pages/projects/[project]/fetchers/FetchLoadGraphData.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase
    let:projectId
  >
    <FetchLoadGraphData
      projectId={projectId}
      let:project
      let:resources
    >
      <LoadGraph_MPS
        projectId={projectId}
        project={project}
        resources={resources}
      />
    </FetchLoadGraphData>
  </ProjectPageBase>
</AuthenticationRequired>
