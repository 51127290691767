<script>
  import {onMount} from 'svelte';
  import GanttHelper from './GanttHelper.js';
  import CalendarBlock from './CalendarBlock.svelte';
  import { get } from "svelte/store";

  export let GanttStore

  let startDate;
  let endDate;
  let spanInSeconds;

  let validStartDate;
  let validEndDate;

  let scaleUnits;
  let processingUnit;

  // Format (Sorted by timestamp)
  /*
  ** {
  **  StartX: 5.5,
  **  EndX: 21,
  **  TextUp: "3",
  **  TextDown: "Tue", // If null, TextUp is centered
  **  BlockColor: "red",
  ** }
  */
  let calendarUpperBlocks = [];
  let calendarLowerBlocks = [];

  const ReloadCalendarBlocks = () => {
    if (processingUnit === undefined) return;

    let tmpcalendarUpperBlocks = [];
    let tmpcalendarLowerBlocks = [];

    for (let i = 0; i < 2; i++) {
      const unit = processingUnit[i];
      let timestamp = startDate;
      while (timestamp < endDate) {
        const next = GanttHelper.GetNextDateGraduation(timestamp, unit);
        const text = GanttHelper.GetDateCalendarText(timestamp, unit);
        const color = GanttHelper.GetDateCalendarBlockColor(timestamp, unit);
        const insertable = {
          StartX: 100 * GanttHelper._gantHelper_scale(timestamp.getTime(), endDate.getTime(), startDate.getTime()),
          EndX: 100 * GanttHelper._gantHelper_scale(Math.min(next.getTime(), endDate.getTime()), endDate.getTime(), startDate.getTime()),
          TextUp: text[0],
          TextDown: text[1],
          BlockColor: color,
        }

        if (i === 0) tmpcalendarUpperBlocks.push(insertable);
        else tmpcalendarLowerBlocks.push(insertable);

        timestamp = next;
      }
    }
    calendarUpperBlocks = tmpcalendarUpperBlocks;
    calendarLowerBlocks = tmpcalendarLowerBlocks;
    GanttStore.InternalLowerSplits.update((_) => calendarLowerBlocks);
  };

  onMount(() => {
    GanttStore.CurrentDisplayedEndDate.subscribe(
      (newDate) => {
        endDate = newDate;
        ReloadCalendarBlocks();
    });
    GanttStore.CurrentDisplayedStartDate.subscribe(
      (newDate) => {
        startDate = newDate;
    });
    GanttStore.ValidEndDate.subscribe(
      (newDate) => {
        validEndDate = newDate;
    });
    GanttStore.ValidStartDate.subscribe(
      (newDate) => {
        validStartDate = newDate;
    });
    GanttStore.DisplayedSpanInSeconds.subscribe(
      (newSpan) => {
        spanInSeconds = newSpan;
        scaleUnits = GanttHelper.GetScaleUnit(newSpan);
        processingUnit = GanttHelper.GetProcessingUnit(scaleUnits);
        ReloadCalendarBlocks();
    });
  });

  const ScrollTime = (e) => {
    e.preventDefault();
    if (e.ctrlKey) {
      const currentDisplayedSpanInSeconds = get(GanttStore.DisplayedSpanInSeconds)
      let newDisplayedSpanInSeconds
      // Zoom
      if (e.deltaY > 0) {
        // If we want a more strict restriction use the following code. Currently, I think it's unnecessary
        // GanttStore.DisplayedSpanInSeconds.update((d) => Math.min(d * 1.05, GanttHelper.GHYEAR, (validEndDate.getTime() - validStartDate.getTime()) / 1000));
        newDisplayedSpanInSeconds = Math.min(currentDisplayedSpanInSeconds * 1.05, GanttHelper.GHYEAR)
      } else if (e.deltaY < 0) {
        newDisplayedSpanInSeconds = Math.max(currentDisplayedSpanInSeconds / 1.05, GanttHelper.GHDAY)
      }
      GanttStore.DisplayedSpanInSeconds.set(newDisplayedSpanInSeconds)
      get(GanttStore.SaveConfigurationFunc)({displaySpanInSeconds: Math.floor(newDisplayedSpanInSeconds)})
    }
    else {
      // Move
      const currentDisplayedStartDate = get(GanttStore.CurrentDisplayedStartDate)
      let newCurrentDisplayedStartDate
      if (e.deltaY > 0) {
        if (isNaN(validEndDate.getTime())) {
          newCurrentDisplayedStartDate = new Date(currentDisplayedStartDate.getTime() + (endDate.getTime() - currentDisplayedStartDate.getTime()) / 48);
        } else {
          newCurrentDisplayedStartDate = new Date(Math.min(validEndDate.getTime() - spanInSeconds * 1000, currentDisplayedStartDate.getTime() + (endDate.getTime() - currentDisplayedStartDate.getTime()) / 48));
        }
        if (newCurrentDisplayedStartDate < currentDisplayedStartDate) {
          newCurrentDisplayedStartDate = currentDisplayedStartDate;
        }
      }
      else if (e.deltaY < 0) {
        if (isNaN(validStartDate.getTime())) {
          newCurrentDisplayedStartDate = new Date(currentDisplayedStartDate.getTime() - (endDate.getTime() - currentDisplayedStartDate.getTime()) / 48);
        } else {
          newCurrentDisplayedStartDate = new Date(Math.max(validStartDate.getTime(), currentDisplayedStartDate.getTime() - (endDate.getTime() - currentDisplayedStartDate.getTime()) / 48))
        }
        if (newCurrentDisplayedStartDate > currentDisplayedStartDate) {
          newCurrentDisplayedStartDate = currentDisplayedStartDate;
        }
      }
      GanttStore.CurrentDisplayedStartDate.set(newCurrentDisplayedStartDate)
      get(GanttStore.SaveConfigurationFunc)({currentDisplayedStartDate: newCurrentDisplayedStartDate.toISOString()})
    }
  }

</script>

<div
  id="gantt_chartarea_calendarheader"
  on:wheel={ScrollTime}
>
  <svg id="gantt_chartarea_calendarsvg">
    {#each calendarUpperBlocks as upperBlock}
      <CalendarBlock
        StartY=2
        Item={upperBlock}
      />
    {/each}
    {#each calendarLowerBlocks as lowerBlock}
      <CalendarBlock
        StartY=50
        Item={lowerBlock}
      />
    {/each}
    <line
      x1="0"
      x2="100%"
      y1="50%"
      y2="50%"
      stroke="#888888"
      stroke-width="2"
    />
  </svg>
</div>

<style>
  div#gantt_chartarea_calendarheader {
    width: 100%;
    height: 80px;
  }

  svg#gantt_chartarea_calendarsvg {
    border-bottom: 2px solid #888888;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 80px;
    position: relative;
  }
</style>
