import App from "./App.svelte";
import uncaucht from "uncaught"
import StackTrace from "stacktrace-js"
import { sendLogsToBackend } from "./logging/sendLogsToBackend.js";
import { initSourceMapConsumer } from "./logging/sourceMap.js"

const appMainDiv = document.getElementById("app-main")
const app = new App({
  target: appMainDiv,
  hydrate: true,
});

// initialization of source map consumer, which translates positions on a bundled script into original source's position
initSourceMapConsumer()

// detect uncaught errors and send logs about that to the backend
uncaucht.start()
uncaucht.addListener(async (err, event) => {
  if (!err) {
    if (typeof ErrorEvent === 'function' && event instanceof ErrorEvent) {
      console.error(event.message, { event: event })
      await sendLogsToBackend(event.message, "error", {}, {})
    } else {
      console.error({ event: event });
    }
  }

  const stackFrames = await StackTrace.fromError(err)
  err.stack = stackFrames
  await sendLogsToBackend(`(Uncaught) ${err.toString()}`, "error", err, {})
})

export default app;
