<script>
  import GuestDispatchingViewDetails from "../../../../routes/DispatchingView/DispatchingViewDetails.svelte"
  import AuthenticationRequired from "../../../../components/functional/AuthenticationRequired.svelte"
  import ProjectPageBase from "../../../../components/pages/projects/[project]/ProjectPageBase.svelte";
  import CheckDataStatus from "../../../../components/pages/projects/[project]/CheckDataStatus.svelte";
  import FetchDispatchingViewData from "../../../../components/pages/projects/[project]/fetchers/FetchDispatchingViewData.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase  parentView="DispatchingView" let:code let:projectId let:user>
    <CheckDataStatus projectId={projectId} viewName="DispatchingView">
      <FetchDispatchingViewData
        projectId={projectId}
        itemCode={code}
        refetchInterval={false}
        let:styles
        let:resourceDetail
        let:transactions
        let:reloadTransactions
        let:isInputAllowed
      >
        <GuestDispatchingViewDetails
          styles={styles}
          resourceDetail={resourceDetail}
          transactions={transactions}
          reloadTransactions={reloadTransactions}
          isInputAllowed={isInputAllowed}
          projectId={projectId}
          user={user}
          code={code}
        />
      </FetchDispatchingViewData>
    </CheckDataStatus>
  </ProjectPageBase>  
</AuthenticationRequired>