<script>
  import { translate } from "../../i18n/i18next.js";

  /**
   * Total number of pages
   * @type {number}
   */
  export let totalPageNumber = 1;
  /**
   * Current page index state. This value is 0-indexed, so it is 0 when the selector is at page #1.
   * This value mutates, therefore you should use bind:currentIndex
   * @type {number}
   */
  export let currentIndex = 0;
  /**
   * If this prop is given and if it is non-empty array, "Date mode" will be used.
   * The array should be of strings formatted as "yyyy-MM-dd" if any.
   * For the behaviour of "Date mode", see DispatchingView.
   * @type {undefined|string[]}
   */
  export let dateList = [];
  let buttonTextList = [];
  $: isDisplayingByDate = Array.isArray(dateList) && dateList.length > 0

  $: handleDateListChange(dateList);

  function handleDateListChange(dateList) {
    if (Array.isArray(dateList) && dateList.length > 0) {
      buttonTextList = [];

      dateList.forEach((date) => {
        const splitDate = date.split("-");
        const year = splitDate[0];
        const month = splitDate[1];
        const day = splitDate[2];

        buttonTextList.push(`${year}/${month}/${day}`);
      });
    }
  }

  // if the currentIndex exceeds the last index, set it to the last index.
  $: if (currentIndex > 0 && currentIndex >= totalPageNumber) {
    currentIndex = totalPageNumber - 1
  }
</script>

<article>
  <div class="btn-pagination-wrapper">
    <button class="btn-pagination" on:click={() => currentIndex = 0}>
      <img src="/assets/Pager_FL.svg" alt="forward left arrow" />
    </button>
    <button
      class="btn-pagination"
      on:click={() => currentIndex = Math.max(0, currentIndex - 1)}
    >
      <img src="/assets/Pager_L.svg" alt="left arrow" />
    </button>
    <button class="btn-pagination">
      <span>
        { !isDisplayingByDate
          ? currentIndex + 1
          : buttonTextList[currentIndex]}
      </span>
      {#if !isDisplayingByDate && !Number.isNaN(totalPageNumber)}
        <span class="small-text">/{translate("generic.pageWithCount", null, {count: totalPageNumber})}</span>
      {/if}
    </button>
    <button
      class="btn-pagination"
      on:click={() => currentIndex = Math.min(totalPageNumber - 1, currentIndex + 1)}
    >
      <img src="/assets/Pager_R.svg" alt="right arrow" />
    </button>
    <button
      class="btn-pagination"
      on:click={() => currentIndex = totalPageNumber - 1}
    >
      <img src="/assets/Pager_FR.svg" alt="forward right arrow" />
    </button>
  </div>
</article>

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }

  .btn-pagination-wrapper {
    position: relative;
    display: flex;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0;
    width: fit-content;
    min-width:300px;
  }
  .btn-pagination {
    position: relative;
    padding: 0.32em 1em;
    color: #00468f;
    font-size: 1em;
    text-align: center;
    background-color: white;
    border-top: solid 2px #888;
    border-bottom: solid 2px #888;
  }
  .btn-pagination img {
    padding-top: 0.25em;
  }
  .btn-pagination .small-text {
    font-size: 0.8em;
    color: #888888;
  }

  .btn-pagination:first-child {
    border-left: solid 2px #888;
    border-top-left-radius: 0.6em;
    border-bottom-left-radius: 0.6em;
  }

  .btn-pagination:last-child {
    border-right: solid 2px #888;
    border-top-right-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
  }

  .btn-pagination:not(:last-child)::after {
    content: "";
    display: block;
    width: 2px;
    height: 28px;
    background-color: #ccc;
    position: absolute;
    right: -1px;
    top: calc((100% - 28px) / 2);
  }
</style>
