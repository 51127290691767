<script>
  import ToggleSwitch from "../../components/input/ToggleSwitch.svelte";
  import ItemPerPageSelectBox from "../../components/select/ItemPerPageSelectBox.svelte";
  import {
    saveDispatchingViewConfiguration,
  } from "../../utils/configurationStorage/views/DispatchingViewConfigurationStorage.js";
  import GeneralSelect from "../../components/select/GeneralSelect.svelte";
  import { translate } from "../../i18n/i18next.js";
  import MenuBar from "../../components/menuBar/MenuBar.svelte";
  import MenuBarItem from "../../components/menuBar/MenuBarItem.svelte";
  import PageSelector2 from "../../components/layout/PageSelector2.svelte";

  export let iStyle = 0;
  export let styles = [];
  export let displayCompletedTasks = false;
  export let usingDatePageMode = false;
  export let itemPerPage;

  export let styleSelectOnChange;
  export let pageModeOnChange;
  export let totalPageNumber = 1;
  export let currentIndex = 0
  export let dateList = [];
  export let projectId
  export let userId

  function onChangeSettings(settingName, newValue, callback = null) {
    if (!userId) return

    saveDispatchingViewConfiguration({ [settingName]: newValue }, userId, projectId);
    if (callback) callback();
  }

  function onChangeStyleSettings(event) {
    const newStyleIndex = event.target.value;
    const newStyleName = styles[newStyleIndex];
    saveDispatchingViewConfiguration({ styleName: newStyleName }, userId, projectId);
    iStyle = parseInt(newStyleIndex, 10)
    styleSelectOnChange();
  }
</script>


<MenuBar>
  <svelte:fragment slot="settings">
    <!-- SettingItem: styles -->
    <MenuBarItem>
      <svelte:fragment slot="label">{translate("asprova.terms.style")}</svelte:fragment>
      <GeneralSelect
        options={styles.map((style, index) => ({text: style, value: index}))}
        bind:value={iStyle}
        onChangeEvent={onChangeStyleSettings}
      />
    </MenuBarItem>
    <!-- SettingItem: should completed tasks be displayed?  -->
    <MenuBarItem>
      <ToggleSwitch
        bind:on={displayCompletedTasks}
        label={translate("frontend:views.dispatchingView.control.displayCompletedTasks") + ":"}
        onChange={(e) => onChangeSettings("displayCompletedTasks", e.target.checked)}
      />
    </MenuBarItem>
    <!-- SettingItem: enable date page mode? -->
    <MenuBarItem>
      <ToggleSwitch
        bind:on={usingDatePageMode}
        onChange={(e) => onChangeSettings("usingDatePageMode", e.target.checked, pageModeOnChange)}
        label={translate("frontend:views.dispatchingView.control.dateMode") + ":"}
      />
    </MenuBarItem>
    <!-- SettingItem: set items per page; gets disabled in date page mode. -->
    {#if !usingDatePageMode}
      <MenuBarItem>
        <svelte:fragment slot="label">{translate("frontend:generic.itemsPerPage")}</svelte:fragment>
        <ItemPerPageSelectBox
          bind:value={itemPerPage}
          onChange={(e) => onChangeSettings("itemPerPage", parseInt(e.target.value, 10))}
        />
      </MenuBarItem>
    {/if}
  </svelte:fragment>
  <MenuBarItem slot="pagination">
    {#if usingDatePageMode && dateList && dateList.length > 0}
      <PageSelector2
        totalPageNumber={dateList.length}
        {dateList}
        bind:currentIndex={currentIndex}
      />
    {:else}
      <PageSelector2
        totalPageNumber={totalPageNumber}
        bind:currentIndex={currentIndex}
      />
    {/if}
  </MenuBarItem>
</MenuBar>

