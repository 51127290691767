<script>
  import GanttHelper from './GanttHelper.js';

  export let Timestamps;
  export let Color;
  export let StartY;
  export let HeightY;
  export let StartDate;
  export let EndDate;
  export let AdjustStamp;

  // Optimization parameters
  export let HiddenBarBorders;

  const CalculateStampX = (stamp) => Math.max(0, Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime())));
  const CalculateStampXDifferential = (stamp1, stamp2) => CalculateStampX(stamp2) - CalculateStampX(stamp1);
  const DarkenColor = (color) => GanttHelper._gantHelper_darkenColor(GanttHelper._gantHelper_rgbToHex(color), 30);

</script>


<rect
  x="{CalculateStampX(Timestamps[0])}%"
  y="{StartY}%"
  height="{HeightY}%"
  width="{CalculateStampXDifferential(Timestamps[0],Timestamps[Timestamps.length-1])}%"
  fill={Color}
  stroke={(HiddenBarBorders === true) ? "none" : DarkenColor(Color)}
  stroke-width={(HiddenBarBorders === true) ? 0 : 1}
/>

<style>

</style>
