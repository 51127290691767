<script>
  import DialogBase from "../dialog/DialogBase.svelte"
  import DialogTitle from "../dialog/DialogTitle.svelte"
  import DialogCaption from "../dialog/DialogCaption.svelte"
  import SelectPermission from "./SelectPermission.svelte"
  import { useQueryClient } from "@sveltestack/svelte-query"
  import { getNotificationsContext } from "svelte-notifications"
  import { translate } from "../../i18n/i18next.js";
  import DialogContent from "../dialog/DialogContent.svelte";
  import DialogBottomControls from "../dialog/DialogBottomControls.svelte";
  import DialogBottomControlButton from "../dialog/DialogBottomControlButton.svelte";
  import DialogSplitter from "../dialog/DialogSplitter.svelte";

  export let projectId
  export let dialog
  export let user
  export let onClose

  const {addNotification} = getNotificationsContext()
  const queryClient = useQueryClient()

  let newPermission = null
  async function handlePermissionChange(){
    const response = await fetch(`/api/projects/${projectId}/users/${user.id}/permission`, {
      method: "PUT",
      body: JSON.stringify({type: newPermission}),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      addNotification({
        text: translate("generic.saved"),
        type: "success",
        removeAfter: 4000,
        position: "top-right",
      })
      await queryClient.invalidateQueries(`/api/projects/${projectId}/users`)
      await queryClient.invalidateQueries(`/api/projects/${projectId}/users/${user.id}/permission`)
      onClose()
    } else {
      addNotification({
        text: translate("generic.saveFailed"),
        type: "error",
        removeAfter: 4000,
        position: "top-right",
      })
    }
  }
</script>

<DialogBase bind:dialog={dialog}>
  <DialogTitle title={translate("frontend:components.editProjectUserPermissionDialog.title")} />
  <DialogSplitter />
  <DialogCaption>
    {translate("frontend:components.editProjectUserPermissionDialog.description.main")}
    <dl>
      <dt>{translate("mySchedule.permissions.projectAdmin.name")}</dt>
      <dd>{translate("frontend:components.editProjectUserPermissionDialog.description.projectAdmin")}</dd>
      <dt>{translate("mySchedule.permissions.resultInput.name")}</dt>
      <dd>{translate("frontend:components.editProjectUserPermissionDialog.description.resultInput")}</dd>
      <dt>{translate("mySchedule.permissions.readOnly.name")}</dt>
      <dd>{translate("frontend:components.editProjectUserPermissionDialog.description.readOnly")}</dd>
    </dl>
  </DialogCaption>
  <DialogContent>
    <SelectPermission permission={user.permission} onChange={event => {newPermission = event.target.value}} />
  </DialogContent>
  <DialogSplitter />
  <DialogBottomControls>
    <DialogBottomControlButton type="secondary" onClick={onClose}>
      {translate("generic.cancel")}
    </DialogBottomControlButton>
    <DialogBottomControlButton type="primary" onClick={handlePermissionChange}>
      {translate("generic.change")}
    </DialogBottomControlButton>
  </DialogBottomControls>
</DialogBase>

<style>
  dl {
    padding: 0.2rem 0.5rem;
  }
  dd {
    font-size: 0.9rem;
    color: #333333;
    padding-bottom: 0.2rem;
  }
</style>