<script>
  export let i;
  export let onClick
  export let disabled = false
  export let checked = false
</script>

<input
  bind:checked={checked}
  type="checkbox"
  id={`checkbox-${i}`}
  disabled={disabled}
  on:click={onClick}
/>

<style>
  input {
    width: 20px;
    height: 20px;
  }
</style>
