<script>
  import Profile1ResGantt from "../../../../routes/WebViewer_ResGantt1/Profile1ResGantt.svelte"
  import AuthenticationRequired from "../../../../components/functional/AuthenticationRequired.svelte"
  import ProjectPageBase from "../../../../components/pages/projects/[project]/ProjectPageBase.svelte"
  import CheckDataStatus from "../../../../components/pages/projects/[project]/CheckDataStatus.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase let:projectId let:user let:permission>
    <CheckDataStatus viewName="ResGantt" projectId={projectId}>
      <Profile1ResGantt
        projectId={projectId}
        user={user}
        permission={permission}
      />
    </CheckDataStatus>
  </ProjectPageBase>
</AuthenticationRequired>