<div class="menu-bar-item">
  {#if $$slots.label}
    <div class="label-container">
      <slot name="label" />
      <span>:</span>
    </div>
  {/if}
  <div class="main-container">
    <slot />
  </div>
</div>

<style>
  .menu-bar-item {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 8px;
  }
  .label-container {
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    padding-right: 8px;
  }
  .main-container {
    flex: 1;
    text-wrap: nowrap;
  }
</style>