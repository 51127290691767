<script>
  /**
   * @type {"info"|"warn"}
   */
  export let type = "info"
</script>

<div id="root">
  <div
    class="alert"
    class:info={type === "info"}
    class:warn={type === "warn"}>
    <slot />
  </div>
</div>

<style>
  #root {
    width: 100%;
  }
  .alert {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
  }

  .info {
    background-color: #e5f6fd;
    color: rgb(1, 67, 97);
  }
  .warn {
    background-color: #fcf8e3;
    color: #8a6d3b
  }
</style>