<script>
  import Profile1DispatchingViewSearch
    from "../../../../routes/WebViewer_DispatchingView1/Profile1DispatchingViewSearch.svelte"
  import AuthenticationRequired from "../../../../components/functional/AuthenticationRequired.svelte"
  import ProjectPageBase from "../../../../components/pages/projects/[project]/ProjectPageBase.svelte"
  import FetchDispatchingViewData
    from "../../../../components/pages/projects/[project]/fetchers/FetchDispatchingViewData.svelte"
  import CheckDataStatus from "../../../../components/pages/projects/[project]/CheckDataStatus.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase let:user let:projectId>
    <CheckDataStatus projectId={projectId} viewName="DispatchingView">
      <FetchDispatchingViewData
        projectId={projectId}
        refetchInterval={10000}
        let:resources
        let:resetTransactions
        let:resourceGroup
      >
        <Profile1DispatchingViewSearch
          projectId={projectId}
          resources={resources}
          resourceGroup={resourceGroup}
          resetTransactions={resetTransactions}
          user={user}
        />
      </FetchDispatchingViewData>
    </CheckDataStatus>
  </ProjectPageBase>
</AuthenticationRequired>