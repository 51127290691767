<script>
  import MapArea from './processMap/MapArea.svelte';
  import { translate } from "../../i18n/i18next.js";

  export let GanttStore

  let currentGraph = "ProcessMap";
  let isExpanded = false;

  const ToggleExpand = (e) => {
    isExpanded = !isExpanded;
  }

</script>

<div id="gantt_grapharea_toolbar">
  <div
    class="gantt_grapharea_toolbar_option"
    id="gantt_processmap_menu"
  >
    {translate("frontend:ganttChart.mapDisplay.title")}
  </div>
  <div
    class="gantt_grapharea_toolbar_option"
    id="gantt_grapharea_expand"
    on:click={ToggleExpand}
  >
    {isExpanded === true ? "▼" : "▲"}
  </div>
</div>
<div id="gantt_grapharea" style="height: {isExpanded === true ? 300 : 0}px">
  {#if isExpanded === true}
    {#if currentGraph === "ProcessMap"}
      <MapArea
        GanttStore={GanttStore}
      />
    {/if}
  {/if}
</div>

<style>
  div.gantt_grapharea_toolbar_option {
    height: 30px;
    background-color: white;
    border-top: solid 2px #888;
    border-left: solid 2px #888;
    border-right: solid 2px #888;
    border-top-left-radius: 0.2em;
    border-top-right-radius: 0.2em;
    padding: 0.3em 0.6em 0.3em 0.6em;
  }

  div#gantt_grapharea_expand:hover {
    background-color: #eee;
    cursor: pointer;
  }

  div#gantt_grapharea_expand:active {
    background-color: #ddd;
    cursor: pointer;
  }

  div#gantt_grapharea_toolbar {
    height: 40px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: flex-end;
  }

  div#gantt_grapharea {
    transition: height 0.5s ease;
    border: solid 2px #888;
  }
</style>
