<script>
  const colors = ["white","purple","lime","blue"];
  const angles = ["0deg", "90deg", "180deg", "270deg"];
  const refX = {
    "0deg": "2",
    "90deg": "0",
    "180deg": "2",
    "270deg": "0",
  };
  const refY = {
    "0deg": "0",
    "90deg": "2",
    "180deg": "0",
    "270deg": "2",
  };
  const thickness = {
    "white": "1.8",
    "purple": "2.5",
    "lime": "2.5",
    "blue": "2.5",
  };
</script>


<defs>

{#each colors as c}
  {#each angles as a}
    <marker
      id="triangle_{c}_{a}"
      viewBox="0 0 10 10"
      refX="{refX[a] / ((c === "white") ? 2 : 1)}"
      refY="{refY[a] / ((c === "white") ? 2 : 1)}"
      markerWidth="{thickness[c]}"
      markerHeight="{thickness[c]}"
      orient="{a}"
      fill="{c}"
      >
    <path d="M 0 0 L 0 10 L 10 0 z" />
    </marker>
  {/each}
{/each}

</defs>


<style>

</style>
