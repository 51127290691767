<div class="dialog-content">
  <slot />
</div>

<style>
  .dialog-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
</style>