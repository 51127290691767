<script>
  import CloseIconButton from "../IconButtons/CloseIconButton.svelte"

  export let notification = {}
  export let onRemove = null
</script>

<div
  id="root"
  class:warning={notification.type === "warning"}
  class:error={notification.type === "error"}
  class:success={notification.type === "success"}
>
  <div class="text">
    {notification.text}
  </div>
  <div class="close-button">
    <CloseIconButton onClick={onRemove} color="white" />
  </div>
</div>

<style>
  #root {
    margin: 1rem;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
    min-width: 300px;
    max-width: 500px;
    min-height: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
  }
  .error {
    color: white;
    background-color: #d32f2f;
  }
  .warning {
    color: black;
    background-color: #fff200;
  }
  .success {
    color: white;
    background-color: #4d8d53;
  }
  .text {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .close-button {
    flex-grow: 0;
    flex-shrink: 0;
    color: white;
  }
</style>
