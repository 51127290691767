<script>
  import ViewCardBase from "./ViewCardBase.svelte";
  import {translate} from "../../../i18n/i18next.js";
  import HelpTooltip from "../../tooltip/HelpTooltip.svelte";

  export let onClick
</script>

<ViewCardBase
  viewIconSrc="/assets/icons/icon_operation_table_l.png"
  onClick={onClick}
>
  <span>{translate("mySchedule.views.operationTable.name")}</span>
  <HelpTooltip title={translate("mySchedule.views.operationTable.name")}>
    {translate("frontend:help.views.operationTable.description")}
  </HelpTooltip>
</ViewCardBase>
