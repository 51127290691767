<script>
  import ToggleSwitch from "../../components/input/ToggleSwitch.svelte";
  import PageSelector2 from "../../components/layout/PageSelector2.svelte";
  import MenuBar from "../../components/menuBar/MenuBar.svelte";
  import MenuBarItem from "../../components/menuBar/MenuBarItem.svelte";
  import GeneralSelect from "../../components/select/GeneralSelect.svelte";
  import {
    saveDispatchingViewProfile1Configuration
  } from "../../utils/configurationStorage/views/DispatchingViewProfile1ConfigurationStorage.js";

  export let iStyle = 0;
  export let styles = [];
  export let displayCompletedTasks = false;
  export let resourceGroup = {};
  export let currentPageIndex

  export let selectedGroup = "";
  export let selectedResource = "";

  export let styleSelectOnChange;

  export let dateList = [];
  export let userId
  export let projectId

  function onChangeSettings(settingName, newValue, callback = null) {
    if (!userId) return

    saveDispatchingViewProfile1Configuration({ [settingName]: newValue }, userId, projectId);
    if (callback) {
      callback();
    }
  }

  function onChangeStyleSettings(event) {
    const newStyleIndex = event.target.value;
    const newStyleName = styles[newStyleIndex];
    saveDispatchingViewProfile1Configuration({ styleName: newStyleName }, userId, projectId);
    iStyle = parseInt(newStyleIndex, 10)
    styleSelectOnChange();
  }

  function onChangeSelectedResourceGroup() {
    if (resourceGroup[selectedGroup]?.includes(selectedResource) ?? true) {
      selectedResource = ""
    }
  }
</script>

<MenuBar gap="18px">
  <svelte:fragment slot="settings">
    <!-- SettingItem: which style to use? -->
    <MenuBarItem>
      <span slot="label">スタイル</span>
      <GeneralSelect
        options={styles.map((style, index) => ({text: style, value: index}))}
        bind:value={iStyle}
        onChangeEvent={onChangeStyleSettings}
      />
    </MenuBarItem>
    <!-- SettingItem: should completed tasks be displayed?  -->
    <MenuBarItem>
      <ToggleSwitch
        bind:on={displayCompletedTasks}
        label={"完了作業の表示:"}
        onChange={(e) => onChangeSettings("displayCompletedTasks", e.target.checked)}
      />
    </MenuBarItem>
    <!-- SettingItem: what resource should be used when recording a relief?  -->
    <MenuBarItem>
      <span slot="label">リリーフ</span>
      <GeneralSelect
        options={Object.keys(resourceGroup).map(group => ({text: group, value: group})).concat({text: "グループ", value: ""})}
        bind:value={selectedGroup}
        onChangeEvent={e => onChangeSettings("reliefResourceGroup", e.target.value, onChangeSelectedResourceGroup)}
      />
      <GeneralSelect
        options={resourceGroup?.[selectedGroup]?.map(res => ({text: res, value: res}))?.concat({text: "リソース", value: ""}) ?? [{text: "リソース", value: ""}]}
        bind:value={selectedResource}
        onChangeEvent={e => onChangeSettings("reliefResource", e.target.value)}
      />
    </MenuBarItem>
  </svelte:fragment>
  <MenuBarItem slot="pagination">
    <PageSelector2
      bind:currentIndex={currentPageIndex}
      totalPageNumber={dateList.length}
      {dateList}
    />
  </MenuBarItem>
</MenuBar>
