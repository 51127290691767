<script>
  import {getContext} from "svelte"
  import {useQuery} from "@sveltestack/svelte-query"
  import {fetchTransactionData} from "../../../../../utils/getData.js"
  import {useIsInputAllowed} from "../../../../../hooks/useIsInputAllowed.js"
  import {reset} from "../../../../../utils/putData.js"

  // props
  /**
   * @type {string}
   */
  export let projectId
  /**
   * @type {string}
   */
  export let downloadTimestamp
  /**
   * This prop will determine at what interval the data will be refetched.
   * Set this to "false" to disable refetching, or provide refetching interval in milliseconds.
   * @type {false|number}
   */
  export let refetchInterval = false

  // error handlers
  const {handleNonBlockingFetchErrors} = getContext("fetchErrorHandler")

  const {isInputAllowed} = useIsInputAllowed(projectId)

  const queryKey = `/api/projects/${projectId}/transactions?timestamp=${downloadTimestamp}`
  const transactionsQueryResult = useQuery(
    queryKey,
    () => fetchTransactionData(projectId, downloadTimestamp),
    {
      onError: err => {
        console.log(err)
        if (err instanceof FetchError) {
          handleNonBlockingFetchErrors(err.error)
        }
      },
      retry: false,
      // enabled: !!downloadTimestamp,
      refetchInterval,
    }
  )
  // reset query options to react on downloadTimeStamp change
  $: {
    transactionsQueryResult.setOptions(
      queryKey,
      () => fetchTransactionData(projectId, downloadTimestamp),
      {
        onError: err => {
          console.log(err)
          if (err instanceof FetchError) {
            handleNonBlockingFetchErrors(err.error)
          }
        },
        retry: false,
        // enabled: !!downloadTimestamp,
        refetchInterval,
      }
    )
  }

  function reloadTransactions() {
    $transactionsQueryResult.refetch()
  }

  async function resetTransactions() {
    try {
      await reset(projectId)
      await $transactionsQueryResult.refetch()
    } catch (e) {
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error)
      }
    }
  }
</script>

<slot
  transactions={$transactionsQueryResult.data ?? {}}
  reloadTransactions={reloadTransactions}
  resetTransactions={resetTransactions}
  isInputAllowed={$isInputAllowed}
/>