const ADVANCED_FILTER_MARKER = "##ADVANCED##\t"


function equals(query) {
  return targetString => targetString === query
}

function doesNotEqual(query) {
  return targetString => targetString !== query
}

function contains(query) {
  return targetString => targetString.indexOf(query) !== -1
}

function doesNotContain(query) {
  return targetString => targetString.indexOf(query) === -1
}

function starts(query) {
  return targetString => targetString.startsWith(query)
}

function doesNotStart(query) {
  return targetString => !targetString.startsWith(query)
}

function ends(query) {
  return targetString => targetString.endsWith(query)
}

function doesNotEnd(query) {
  return targetString => !targetString.endsWith(query)
}

function filter(type, query) {
  switch (type) {
    case "equals":
      return equals(query)
    case "doesNotEqual":
      return doesNotEqual(query)
    case "contains":
      return contains(query)
    case "doesNotContain":
      return doesNotContain(query)
    case "starts":
      return starts(query)
    case "doesNotStart":
      return doesNotStart(query)
    case "ends":
      return ends(query)
    case "doesNotEnd":
      return doesNotEnd(query)
  }
}

export const StringFilter = {
  equals,
  doesNotEqual,
  contains,
  doesNotContain,
  starts,
  doesNotStart,
  ends,
  doesNotEnd,
  filter,
}

export function combine(left, right, combinator) {
  switch (combinator) {
    case "and":
      return target => left(target) && right(target)
    case "or":
      return target => left(target) || right(target)
    default:
      throw new Error("Unexpected combinator " + combinator)
  }
}

export function stringifyAdvancedFilterObj(filterObj) {
  return ADVANCED_FILTER_MARKER + JSON.stringify(filterObj)
}

export function parseAdvancedFilterObjString(str) {
  if (str.startsWith(ADVANCED_FILTER_MARKER)) {
    try {
      return [true, JSON.parse(str.slice(ADVANCED_FILTER_MARKER.length))]
    } catch (e) {
      console.error("Failed to parse filter obj")
      return [true, {}]
    }
  }
  return [false, null]
}

export function buildAdvancedFilterObj(str, assumedType = "contains") {
  const [isAdvancedFilter, filterObj] = parseAdvancedFilterObjString(str)
  if (isAdvancedFilter) return filterObj
  if (str !== "") return {left: {query: str, type: assumedType}, right: {query: "", type: assumedType}, combinator: "and"}
  return {}
}

export function buildQueryString(str) {
  const [isAdvancedFilter, filterObj] = parseAdvancedFilterObjString(str)
  if (!isAdvancedFilter) return str
  if (!filterObj.right.query && filterObj.left.type === "contains") return filterObj.left.query
  return str
}
