<script>
  import { setMyLanguage } from "../../i18n/i18next.js";
  import { onMount } from "svelte";
  import { useIsDemoServer } from "../../hooks/useIsDemoServer.js";
  import { useMe } from "../../hooks/useMe.js";
  import { getGuestUsernameForLanguage, isDemoServerGuest } from "../../utils/demo.js";
  import { navigateI18n } from "../../i18n/navigate.js";
  import { login } from "../../utils/login.js";

  export let buttonEl
  let menuEl

  const {isDemoServer} = useIsDemoServer()
  const me = useMe()

  // menuEl を表示する場所を決定する処理
  // positioned ancestor の位置を加味する
  function placeMenuEl(buttonEl, menuEl) {
    menuEl.style.position = "absolute"
    const rect = buttonEl.getBoundingClientRect()
    menuEl.style.top = `-11px`
    menuEl.style.right = `${rect.right - rect.left + 1}px`
  }

  // ウィンドウがリサイズされたとき、menuEL の位置を再計算する処理
  function resizeListener() {
    placeMenuEl(buttonEl, menuEl)
  }

  async function changeLanguage(targetLanguage) {
    // if it is on the demo server and the user is logged in as one of the Guests,
    // switch the account with the language
    if ($isDemoServer && isDemoServerGuest($me)) {
      const guestUsername = getGuestUsernameForLanguage(targetLanguage)
      if (guestUsername && await login(guestUsername, "")) {
        navigateI18n(`/${targetLanguage}`)
        window.location.reload()
        return
      }
    }

    await setMyLanguage(targetLanguage)
  }

  onMount(() => {
    console.log(buttonEl.getBoundingClientRect())
    placeMenuEl(buttonEl, menuEl)

    // ウィンドウがリサイズされたときには menuElの位置を再計算する
    window.addEventListener("resize", resizeListener)
    return () => {
      // remove the event listener when the component unmounts
      window.removeEventListener("resize", resizeListener)
    }
  })
</script>

<div id="languages-menu" bind:this={menuEl}>
  <button class="menu-item" on:click={() => changeLanguage("ja")}>
    日本語
  </button>
  <button class="menu-item" on:click={() => changeLanguage("en")}>
    English
  </button>
  <button class="menu-item" on:click={() => changeLanguage("zh")}>
    中文
  </button>
</div>

<style>
  button {
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  #languages-menu {
    position: absolute;
    display: block;
    width: 100px;
    z-index: 100;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    box-shadow: 0 1px 5px rgba(0,0,0,0.1);
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
  }
  .menu-item {
    display: block;
    line-height: 1.5;
    padding: 7px 20px 7px 20px;
    background-color: inherit;
    width: 100%;
    color: #184398;
    white-space: nowrap;
  }
  .menu-item:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }
</style>
