<script>
  import { useLocation } from "svelte-navigator";
  import { fade } from "svelte/transition";
  import { translate } from "../../../../i18n/i18next.js";
  import MyLink from "../../../../i18n/MyLink.svelte";

  const location = useLocation()
</script>

<div id="side-panel-container">
  <MyLink href="/">
    <div class="side-panel-card" class:current={$location.pathname==="/"} in:fade>
      <div class="side-panel-card-title">
        {translate("frontend:pages.index.sidePanel.myProjects.title")}
      </div>
      <div class="side-panel-card-description">
        {translate("frontend:pages.index.sidePanel.myProjects.caption")}
      </div>
    </div>
  </MyLink>
  <MyLink href="/admin/users">
    <div class="side-panel-card" class:current={$location.pathname==="/admin/users"} in:fade>
      <div class="side-panel-card-title">
        <img src="/assets/icons/icon_setting_inactive.png" alt="settings icon" class="side-panel-card-title-icon"/>
        {translate("frontend:pages.index.sidePanel.users.title")}
      </div>
      <div class="side-panel-card-description">
        {translate("frontend:pages.index.sidePanel.users.caption")}
      </div>
    </div>
  </MyLink>
  <MyLink href="/admin/projects">
    <div class="side-panel-card" class:current={$location.pathname==="/admin/projects"} in:fade>
      <div class="side-panel-card-title">
        <img src="/assets/icons/icon_setting_inactive.png" alt="settings icon" class="side-panel-card-title-icon"/>
        {translate("frontend:pages.index.sidePanel.projects.title")}
      </div>
      <div class="side-panel-card-description">
        {translate("frontend:pages.index.sidePanel.projects.caption")}
      </div>
    </div>
  </MyLink>
</div>

<style>
   a {
    text-decoration: none;
    color: inherit;
  }
  #side-panel-container {
    flex-grow: 0;
    flex-shrink: 0;
    width: 300px;
    background-color: #f0f0f0;
    color: #808080;
    overflow-y: hidden;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .side-panel-card {
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: background-color 0.1s, color 0.1s;
  }
  .side-panel-card.current {
    background-color: white;
    color: black;
  }
  .side-panel-card-title {
    font-weight: bold;
    font-size: 1.1rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }
  .side-panel-card-title-icon {
    height: 1.3rem;
    aspect-ratio: 1;
  }
  .side-panel-card-description {
    font-size: 0.9rem;
  }
</style>