<script>
  import OperationTableSearch from "../../../../routes/OperationTable/OperationTableSearch.svelte"
  import AuthenticationRequired from "../../../../components/functional/AuthenticationRequired.svelte"
  import ProjectPageBase from "../../../../components/pages/projects/[project]/ProjectPageBase.svelte"
  import CheckDataStatus from "../../../../components/pages/projects/[project]/CheckDataStatus.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase let:projectId>
    <CheckDataStatus projectId={projectId} viewName="OperationTable">
      <OperationTableSearch />
    </CheckDataStatus>
  </ProjectPageBase>
</AuthenticationRequired>