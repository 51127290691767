<script>
    import dayjs from "dayjs"

    import {
        RawData,
        TimeStamp,
        DisplayResultOrExpected,
        selectedDisplayMode,

        FilteredProcessIndices,

        ColumnDisplay,
        monthColumns,
        RowDisplay,
        ResCompanyArray,

        syncEarlyTime, 
        syncOverTime,
        syncEarlyTimeActive,
        syncOverTimeActive,
        resultRowLength,
        companyRowLength,
    } from "./ResultGraphStore"

    let cellHeight = null;


    function isOverEndTime(dateString) { 
        return dayjs(dateString) < dayjs($TimeStamp); 
    }

    function isInSyncRange(operation){
        let EndTime = dayjs(operation.Work_EndTime);
        let ResultEndTime = dayjs(operation.Work_ResultEndTime);
        let diffTime = EndTime.diff(ResultEndTime, 'h', true);

        if(diffTime >= 0 && diffTime <= $syncOverTime && $syncOverTimeActive) return true;
        if(diffTime <= 0 && -diffTime <= $syncEarlyTime && $syncEarlyTimeActive) return true;
        return false;
    }

    function getRateColor(rate) {
        if (rate >= 81)  { return "#4DADFF"; } 
        if (rate >= 61)  { return "#80C5FF"; } 
        if (rate >= 41)  { return "#A6D6FF"; }
        if (rate >= 21)  { return "#CCE8FF"; } 
        if (rate >= 1)   { return "#EBF6FF"; } 
        if (rate >= 0)   { return "#FFFFFF"; }
    }


    let DisplayTable = [];
    $: {
        console.log("syncEarlyTimeActive", $syncEarlyTimeActive);
        console.log("syncOverTimeActive", $syncOverTimeActive);

        DisplayTable = [];
        if($selectedDisplayMode==="工程表示"){
            cellHeight = 100 / $resultRowLength;
            for(let rowIndex = 0; rowIndex < $RowDisplay.length; rowIndex++){
                DisplayTable[rowIndex] = [];

                for(let colIndex = 0; colIndex < $ColumnDisplay.length; colIndex++){
                    let thisMonth = $monthColumns[$ColumnDisplay[colIndex]];
                    DisplayTable[rowIndex][colIndex] = {
                        ExpectedHoursOriginal: 0,
                        ExpectedHours: 0,
                        ResultHours: 0,
                        syncRate: 100,
                        syncCount: 0,
                        orderCount: 0,
                    };

                    $RawData[$RowDisplay[rowIndex]].Operations.forEach((operation) => {
                        if(dayjs(operation.Work_StartTime).format('M' + '月') !== thisMonth) return;
                        if(!isOverEndTime(operation.Work_ResultEndTime)) return;

                        DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal += operation.MyScheOnly_ExpectedTimeCostOriginal / 3600;
                        DisplayTable[rowIndex][colIndex].ExpectedHours         += operation.MyScheOnly_ExpectedTimeCost / 3600;
                        DisplayTable[rowIndex][colIndex].ResultHours           += operation.MyScheOnly_ResultTimeCost / 3600;
                        DisplayTable[rowIndex][colIndex].orderCount            += 1;
                        DisplayTable[rowIndex][colIndex].syncCount             += isInSyncRange(operation);
                    })

                    if(DisplayTable[rowIndex][colIndex].orderCount){
                        DisplayTable[rowIndex][colIndex].syncRate = Math.floor(DisplayTable[rowIndex][colIndex].syncCount / DisplayTable[rowIndex][colIndex].orderCount * 100)
                    }
                    DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal = Math.floor(DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal);
                    DisplayTable[rowIndex][colIndex].ExpectedHours         = Math.floor(DisplayTable[rowIndex][colIndex].ExpectedHours);
                    DisplayTable[rowIndex][colIndex].ResultHours           = Math.floor(DisplayTable[rowIndex][colIndex].ResultHours);
                }
            }
        }
        else{ // "カンパニー表示"
            cellHeight = 100 / $companyRowLength;
            for(let rowIndex = 0; rowIndex < $RowDisplay.length; rowIndex++){
                let thisCompany = $ResCompanyArray[rowIndex];
                DisplayTable[rowIndex] = [];

                for(let colIndex = 0; colIndex < $ColumnDisplay.length; colIndex++){
                    let thisMonth = $monthColumns[$ColumnDisplay[colIndex]];
                    DisplayTable[rowIndex][colIndex] = {
                        ExpectedHoursOriginal: 0,
                        ExpectedHours: 0,
                        ResultHours: 0,
                        syncRate: 100,
                        syncCount: 0,
                        orderCount: 0,
                    };
                    
                    $FilteredProcessIndices.forEach((index) => {
                        if($RawData[index].Res_Company !== thisCompany) return;
                        $RawData[index].Operations.forEach((operation) => {
                            if(dayjs(operation.Work_StartTime).format('M' + '月') !== thisMonth) return;
                            if(!isOverEndTime(operation.Work_ResultEndTime)) return;

                            DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal += operation.MyScheOnly_ExpectedTimeCostOriginal / 3600;
                            DisplayTable[rowIndex][colIndex].ExpectedHours         += operation.MyScheOnly_ExpectedTimeCost / 3600;
                            DisplayTable[rowIndex][colIndex].ResultHours           += operation.MyScheOnly_ResultTimeCost / 3600;
                            DisplayTable[rowIndex][colIndex].orderCount            += 1;
                            DisplayTable[rowIndex][colIndex].syncCount             += isInSyncRange(operation);
                        })
                    })

                    if(DisplayTable[rowIndex][colIndex].orderCount){
                        DisplayTable[rowIndex][colIndex].syncRate = Math.floor(DisplayTable[rowIndex][colIndex].syncCount / DisplayTable[rowIndex][colIndex].orderCount * 100)
                    }
                    DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal = Math.floor(DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal);
                    DisplayTable[rowIndex][colIndex].ExpectedHours         = Math.floor(DisplayTable[rowIndex][colIndex].ExpectedHours);
                    DisplayTable[rowIndex][colIndex].ResultHours           = Math.floor(DisplayTable[rowIndex][colIndex].ResultHours);
                }
            }
        }



        console.log("DisplayTable", DisplayTable);

        // DO NOT DELETE FOR DISPLAYTABLE UPDATE !!!
        console.log("syncEarlyTime, syncOverTime", $syncEarlyTime, $syncOverTime);
    }
</script>

<div id="result_chart_content">
    {#if DisplayTable.length == 0}
        <div id="empty_statement">
            {"全資源数 = "}{$RawData.length}{"個（該当する資源は存在しませんでした）"}
        </div>
    {:else}
        {#each $RowDisplay as _, rowIndex}
            <div id="row_cell" style="height: {cellHeight}%;">
                {#each $ColumnDisplay as _, colIndex}
                    <div id="month_cell">
                        {#if DisplayTable[rowIndex][colIndex].orderCount > 0}
                            <div id="item_cell">{DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal}</div>
                            <div id="item_cell">{$DisplayResultOrExpected ? DisplayTable[rowIndex][colIndex].ResultHours : DisplayTable[rowIndex][colIndex].ExpectedHours}</div>
                            <div id="item_cell">{DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal ? Math.floor(100 * DisplayTable[rowIndex][colIndex].ResultHours / DisplayTable[rowIndex][colIndex].ExpectedHoursOriginal) : 0}%</div>
                            {#if $syncEarlyTimeActive || $syncOverTimeActive}
                                <div id="item_cell" style="background-color: {getRateColor(DisplayTable[rowIndex][colIndex].syncRate)}">
                                    {DisplayTable[rowIndex][colIndex].syncRate}%
                                </div>
                            {:else}
                                <div id="item_cell">
                                    {"-"}
                                </div>
                            {/if}
                        {:else}
                            <div id="item_cell"></div>
                            <div id="item_cell"></div>
                            <div id="item_cell"></div>
                            <div id="item_cell"></div>
                        {/if}
                    </div>
                {/each}
            </div>
        {/each}
    {/if}
</div>

<style>
    #result_chart_content{
        width: 100%;
        flex: 1;
    }

    #empty_statement{
        padding-left: 16px;
        padding-top: 16px;
        font-size: 16px;
        color: #888888;
    }

    #row_cell{
        display: flex;
        flex-direction: row;
        background-color: white;

        white-space: nowrap;
        overflow: hidden;
    }

    #month_cell{
        display: flex;
        flex-direction: row;
        flex: 1;
        border-left: 1px solid #888888;
        border-right: 1px solid #888888;
        background-color: white;

        white-space: nowrap;
        overflow: hidden;
    }

    #item_cell{
        flex: 1;
        display: flex;
        border: 1px solid #888888;
        align-items: center;
        justify-content: center;
        background-color: white;
        font-size: 1.3rem;

        white-space: nowrap;
        overflow: hidden;
    }
</style>