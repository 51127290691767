<!-- DEPRECATED, use PageSelector2.svelte instead -->
<script>
  import { onMount } from "svelte";
  import { translate } from "../../i18n/i18next.js";
  import { guestCurrentPage, guestDateModeCurrentPage, profile1CurrentPage } from "../../utils/variable.js";

  export let totalPageNumber = 1;
  export let dateList = [];
  export let identifier;
  $: currentPage = 1;
  let currentPageWriteable;

  // console.log(totalPageNumber);
  // console.log(dateList);
  if (identifier === "Guest") {
    guestCurrentPage.subscribe((val) => {
      currentPage = val;
    });
    currentPageWriteable = guestCurrentPage;
  } else if (identifier === "Profile1") {
    profile1CurrentPage.subscribe((val) => {
      currentPage = val;
    });
    currentPageWriteable = profile1CurrentPage;
  } else if (identifier === "GuestPageMode") {
    guestDateModeCurrentPage.subscribe((val) => {
      currentPage = val;
    });
    currentPageWriteable = guestDateModeCurrentPage;
  }

  let buttonTextList = [];

  onMount(async () => {
    // currentPage = 1;
  });
  $: dateList, handleDateListChange();

  function handleDateListChange() {
    if (dateList && dateList.length > 0) {
      buttonTextList = [];

      dateList.forEach((date) => {
        const splitDate = date.split("-");
        const year = splitDate[0];
        const month = splitDate[1];
        const day = splitDate[2];

        buttonTextList.push(`${year}/${month}/${day}`);
      });
    } else {
      if (isNaN(totalPageNumber)) {
        totalPageNumber = 1;
      }
    }
  }
</script>

<article>
  <div class="btn-pagination-wrapper">
    <button class="btn-pagination" on:click={currentPageWriteable.set(1)}
    ><img src="/assets/Pager_FL.svg" /></button
    >
    <button
      class="btn-pagination"
      on:click={() => {
        if (currentPage > 1) currentPageWriteable.set(currentPage - 1);
      }}><img src="/assets/Pager_L.svg" /></button
    >
    <button class="btn-pagination">
      <span
      >{identifier === "Guest"
        ? currentPage
        : buttonTextList[currentPage - 1]}</span
      >
      {#if identifier === "Guest"}
        <span class="small-text">/{translate("generic.pageWithCount", null, {count: totalPageNumber})}</span>
      {/if}
    </button>
    <button
      class="btn-pagination"
      on:click={() => {
        if (currentPage < totalPageNumber)
          currentPageWriteable.set(currentPage + 1);
      }}><img src="/assets/Pager_R.svg" /></button
    >
    <button
      class="btn-pagination"
      on:click={currentPageWriteable.set(totalPageNumber)}
    ><img src="/assets/Pager_FR.svg" /></button
    >
  </div>
</article>

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }

  .btn-pagination-wrapper {
    position: relative;
    display: flex;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0;
    width: fit-content;
    min-width:300px;
  }
  .btn-pagination {
    position: relative;
    padding: 0.32em 1em;
    color: #00468f;
    font-size: 1em;
    text-align: center;
    background-color: white;
    border-top: solid 2px #888;
    border-bottom: solid 2px #888;
  }
  .btn-pagination img {
    padding-top: 0.25em;
  }
  .btn-pagination img + span {
    margin-left: 0.4em;
  }
  .btn-pagination span + img {
    margin-left: 0.4em;
  }
  .btn-pagination .small-text {
    font-size: 0.8em;
    color: #888888;
  }

  .btn-pagination:first-child {
    border-left: solid 2px #888;
    border-top-left-radius: 0.6em;
    border-bottom-left-radius: 0.6em;
  }

  .btn-pagination:last-child {
    border-right: solid 2px #888;
    border-top-right-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
  }

  .btn-pagination:not(:last-child)::after {
    content: "";
    display: block;
    width: 2px;
    height: 28px;
    background-color: #ccc;
    position: absolute;
    right: -1px;
    top: calc((100% - 28px) / 2);
  }
</style>
