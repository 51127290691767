<script>
  export let onClick = undefined
  export let type = "button"
  export let disabled = false
</script>

<button
  class="primary-bordered-button"
  class:disabled={disabled}
  disabled={disabled}
  on:click={onClick}
  type={type}
>
  <slot/>
</button>

<style>
  button {
    background-color: white;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }

  .primary-bordered-button {
    color: #184398;
    border: 1px solid #184398;
    border-radius: 3px;
    padding: 0.2rem 0.4rem;
  }

  .disabled {
    color: darkgray;
    border-color: darkgray;
  }
</style>