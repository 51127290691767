import i18next from "i18next";
import HttpApi from "i18next-http-backend"
import { createI18nStore, isLoading } from "svelte-i18next";
import { get } from "svelte/store";
import { saveLanguagesConfiguration } from "../utils/configurationStorage/languageConfigurationStorage.js";
import { SUPPORTED_LANGUAGES } from "./languages.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import jaLocale from "dayjs/locale/ja"
import enLocale from "dayjs/locale/en-gb"
import zhLocale from "dayjs/locale/zh-cn"

export async function init(displayLanguage) {
  await i18next
    .use(HttpApi)
    .init({
      lng: displayLanguage,
      ns: ["common", "frontend"],
      defaultNS: "common",
      backend: {
        loadPath: "/api/locales/{{lng}}/{{ns}}",
      },
      interpolation: {
        escapeValue: false, // not needed for svelte as it escapes by default
      },
      fallbackNS: false,
      fallbackLng: "en",
      debug: false,
    });

  dayjs.extend(utc)
  dayjs.extend(timezone)
  setMyLocale(displayLanguage)
}

/**
 * Returns {lang} translation for the given key. If {lang} is not given, the current language will be used.
 * Note that if translations for {lang} have not been loaded, the key itself will be returned.
 * To avoid the problem mentioned above, use $svelteI18next.t() where it is possible.
 * See #translations section in /docs/guideline.md for details.
 * @param {string} key
 * @param {string|null} lang
 * @param {any} opt
 * @returns {string}
 */
export function translate(key, lang = null, opt = {}) {
  if (!lang) return get(svelteI18next).t(key, opt)
  return get(svelteI18next).getFixedT(lang, null, null)(key, opt)
}

/**
 * Returns font-family property value that should be set according to the specification given by the designer.
 * See https://github.com/Asprova-Corporation/MySchedule_FrontEnd/issues/381 for details.
 * @param {string} language
 */
export function getFontFamily(language) {
  switch (language) {
    case "en":
      return "\"Roboto\", sans-serif"
    case "zh":
      return "\"Roboto\", \"Noto Sans SC\", sans-serif"
    case "ja":
    default:
      return "\"Roboto\", \"Noto Sans JP\", sans-serif"
  }
}

/**
 * Set my language and save it in the localStorage
 * @param {string} language
 */
export async function setMyLanguage(language) {
  if (!SUPPORTED_LANGUAGES.includes(language)) throw new Error(`Language ${language} is not supported.`)

  await get(svelteI18next).changeLanguage(language)
  await saveLanguagesConfiguration({language}, "default")

  // if the url path is like /en/..., setting a translation language does not affect the displayed language,
  // as the language specified by the pathname is prioritized.
  // we have to remove the language part from the pathname if any when setting a translation language.
  const url = new URL(window.location.href)
  const pathname = url.pathname
  let newPathname = pathname
  for (const supportedLanguage of SUPPORTED_LANGUAGES) {
    if (pathname.startsWith(`/${supportedLanguage}`)) newPathname = newPathname.replace(`/${supportedLanguage}`, ``)
  }
  url.pathname = newPathname

  // reload to apply the new language selection
  window.location.href = url.href
}

export function setMyLocale(localeOrLanguage) {
  switch (localeOrLanguage) {
    case "ja-JP":
    case "ja":
      return dayjs.locale(jaLocale)
    case "en-GB":
    case "en":
      return dayjs.locale(enLocale)
    case "zh-CN":
    case "zh":
      return dayjs.locale(zhLocale)
  }
}

const svelteI18next = createI18nStore(i18next);

export {
  isLoading,
}
export default svelteI18next