<script>
  import { navigateI18n } from "../../i18n/navigate.js";

  const pathname = window.location.pathname
  async function init() {
    const response = await fetch("/api/me")
    if (!response.ok) {
      const json = await response.json()
      if (json.type === "error:unauthorized") {
        navigateI18n(`/login?redirect=${pathname}`)
        window.location.reload()
      }
    }
  }

  init()
</script>

<slot />