<script>
  export let dialog
  export let width = "500px"
</script>

<dialog
  bind:this={dialog}
  style:width={width}
>
  <slot />
</dialog>

<style>
  dialog {
    position: absolute;
    top: 100px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 18px rgba(0,0,0,.4);
    padding: 0;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
</style>