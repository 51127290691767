import { initChartStore } from "../store/chartStore.js";
import { derived, writable } from "svelte/store";

export function initLoadGraphMPSStore(initialValues) {
  const DisplayLoadNumbers = writable(initialValues.DisplayLoadNumbers ?? false)
  const DisplayedDates = writable(initialValues.DisplayedDates)
  const GetItemColor = writable(initialValues.GetItemColor)

  const chartStore = initChartStore(initialValues)

  const CurrentDisplayedDates = derived([chartStore.CurrentDisplayedStartDate, chartStore.CurrentDisplayedEndDate, DisplayedDates], ([CurrentDisplayedStartDate, CurrentDisplayedEndDate, DisplayedDates]) => {
    const oneDayBeforeStart = new Date(CurrentDisplayedStartDate)
    oneDayBeforeStart.setDate(oneDayBeforeStart.getDate() - 1)
    const oneDayAfterEnd = new Date(CurrentDisplayedEndDate)
    oneDayAfterEnd.setDate(oneDayAfterEnd.getDate() + 1)
    return DisplayedDates
      .map((date, index) => ({date, originalIndex: index}))
      .filter(date => oneDayBeforeStart <= date.date && date.date <= oneDayAfterEnd)
  })

  return {
    ...chartStore,
    CurrentDisplayedDates,
    DisplayLoadNumbers,
    GetItemColor,
  }
}
