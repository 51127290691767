<script>
  import { usePageContext } from "../pages/pageContext.js";

  const pageContext = usePageContext()
  const {context} = pageContext

  let width = window.innerWidth * 0.2  // in px
  let draggable = null

  function handleDrag(e) {
    const newWidth = window.innerWidth - e.clientX;
    if (newWidth < 100) width = 100
    width = newWidth
  }

  function stopDrag(e) {
    draggable.onpointermove = null
    draggable.releasePointerCapture(e.pointerId)
  }

  function startDrag(e) {
    draggable.setPointerCapture(e.pointerId)
    draggable.onpointermove = handleDrag
  }

  function onClickCloseRightPane() {
    pageContext.toggleRightPane(false)
  }
</script>

{#if $context.rightPane.isOpen}
  <div id="right-pane" style="--right-pane-width: {width}px">
    <div class="draggable" on:pointerdown={startDrag} on:pointerup={stopDrag} bind:this={draggable}>
      <div class="inner"></div>
    </div>
    <iframe
      src={window.location.origin + $context.rightPane.href}
      title="knowledge"
    ></iframe>
    <button id="close-right-pane-button" on:click={onClickCloseRightPane}>
      <img src="/assets/icons/icon_help_close.svg" alt="close icon" />
    </button>
  </div>
{/if}

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  #right-pane {
    width: var(--right-pane-width);
    overflow-y: clip;
    height: 100%;
    display: flex;
    position: relative;
  }
  #right-pane > .draggable {
    height: 100%;
    width: 10px;
    cursor: col-resize;
  }
  .draggable .inner {
    margin: 0 2px;
    height: 100%;
    background-color: darkgrey;
  }
  #right-pane > iframe {
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    scrollbar-width: thin;
  }
  #right-pane > button#close-right-pane-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
</style>