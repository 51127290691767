<script>
  import { useProjects } from "../../hooks/useProjects.js";
  import ProjectsListEntry from "./ProjectsListEntry.svelte";
  import { derived, writable } from "svelte/store";
  import { translate } from "../../i18n/i18next.js";

  const projects = useProjects();
  const sortOrder = writable('name_asc');  // Default sort order
  const planOrder = {"standard": 1, "viewer": 2, "free": 3};

  const sortedProjects = derived([projects, sortOrder], ([$projects, $sortOrder]) => {
    if ($sortOrder === 'name_asc') {
      return $projects.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if ($sortOrder === 'name_desc') {
      return $projects.slice().sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    } else if ($sortOrder === 'created_at_asc') {
      return $projects;
    } else if ($sortOrder === 'created_at_desc') {
      return $projects.slice().reverse();
    } else if ($sortOrder === 'plan_asc') {
      return $projects.slice().sort((a, b) => {
        return planOrder[a.plan] - planOrder[b.plan];
      });
    } else if ($sortOrder === 'plan_desc') {
      return $projects.slice().sort((a, b) => {
        return planOrder[b.plan] - planOrder[a.plan];
      });
    }
  });
</script>

<div style="text-align: right;">
  <label for="sort-order">{translate("generic.sortOrder")}</label>
  <select bind:value={$sortOrder} style="width: 125px" id="sort-order">
    <option value="name_asc">{translate("generic.sortOrders.nameAsc")}</option>
    <option value="name_desc">{translate("generic.sortOrders.nameDesc")}</option>
    <option value="created_at_asc">{translate("generic.sortOrders.createdAtAsc")}</option>
    <option value="created_at_desc">{translate("generic.sortOrders.createdAtDesc")}</option>
    <option value="plan_asc">{translate("generic.sortOrders.planAsc")}</option>
    <option value="plan_desc">{translate("generic.sortOrders.planDesc")}</option>
  </select>
</div>

<div>
  {#each $sortedProjects as project}
    <ProjectsListEntry project={project} />
  {/each}
</div>