<script>
    import GeneralInput from "../input/GeneralInput.svelte";

    import {
        selectedDisplayMode,
        resultRowLength, 
        resultRowLimit, 
        companyRowLength, 
        companyRowLimit, 
        resultColumnLength, 
        resultColumnLimit
    } from "./ResultGraphStore"
    import { saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage";

    let RowLength = 0;

    $: {
        if($selectedDisplayMode=='工程表示'){
            resultRowLength.subscribe(value => { RowLength = value; })
        }
        else{
            companyRowLength.subscribe(value => { RowLength = value; })
        }
    }

    function ChangeRowLength() {
        if($selectedDisplayMode=='工程表示'){
            resultRowLength.set(Number(RowLength));
            saveResultGraphConfiguration({"resultRowLength": $resultRowLength}, 'default', 'default');
        }
        else{
            companyRowLength.set(Number(RowLength));
            saveResultGraphConfiguration({"companyRowLength": $companyRowLength}, 'default', 'default');
        }
    }

    // Why is resultRowLength converted to String Obj through GeneralInput(DialogDisplaySection.svelte) ???

    $: resultRowLength.set(Number($resultRowLength));
    $: resultColumnLength.set(Number($resultColumnLength));
    $: {
        const configuration = {
            "resultRowLength": $resultRowLength,
            "resultColumnLength": $resultColumnLength,
        }
        saveResultGraphConfiguration(configuration, 'default', 'default');
    }

</script>

<div id="result_display_settings">
    <div id="title">{"表示設定"}</div>

    <div id="section_block">
        <div id="block_title">{"表示期間"}</div>
        <GeneralInput
            type="number"
            bind:value={$resultColumnLength}
            min={1}
            max={$resultColumnLimit}
        />
    </div>

    <div id="section_block">
        <div id="block_title">{"表示数"}</div>
        <GeneralInput
            type="number"
            bind:value={RowLength}
            min={1}
            max={$resultRowLimit}
            onChange={ChangeRowLength}
        />
    </div>
</div>


<style>
    #result_display_settings{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    #title{
        font-size: 16px;
        color: #CCCCCC;
    }


    #section_block{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
</style>