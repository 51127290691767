<script>
  import {createForm} from "felte"
  import {z} from "zod"
  import {validator} from "@felte/validator-zod"
  import GithubLikeButton from "../button/GithubLikeButton.svelte"
  import {getNotificationsContext} from "svelte-notifications"
  import {useQueryClient} from "@sveltestack/svelte-query"
  import CloseIconButton from "../IconButtons/CloseIconButton.svelte"
  import {translate} from "../../i18n/i18next.js";

  export let dialog
  export let onClose

  const {addNotification} = getNotificationsContext()
  const queryClient = useQueryClient()

  const schema = z.object({
    name: z.string().min(1, translate("frontend:components.createUserDialog.usernameTooShortError")),
    password: z.string().min(1, translate("frontend:components.createUserDialog.passwordTooShortError")),
  })

  async function handleSubmit(values) {
    const response = await fetch("/api/users", {
      method: "POST",
      body: JSON.stringify({
        name: values.name,
        password: values.password,
        roles: [],
      }),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      addNotification({
        position: "top-right",
        type: "success",
        removeAfter: 4000,
        text: translate("frontend:components.createUserDialog.createdUser")
      })
      await queryClient.invalidateQueries("/api/users")
      onClose()
    } else {
      try {
        const data = await response.json()
        const errorType = data.type
        if (errorType === "error:server:user-already-defined") {
          errors.set({name: translate("errors.alreadyDefinedUserError")})
          return
        }
      } catch (e) {}
      addNotification({
        position: "top-right",
        type: "error",
        text: translate("frontend:components.createUserDialog.createUserFailed"),
        removeAfter: 4000,
      })
    }
  }

  const {form, errors} = createForm({
    onSubmit: handleSubmit,
    extend: validator({schema})
  })
</script>

<dialog bind:this={dialog}>
  <div class="title-container">
    <div class="title">
      {translate("frontend:components.createUserDialog.title")}
    </div>
    <CloseIconButton onClick={onClose} />
  </div>
  <form use:form>
    <div class="input-container">
      <label for="input-name">{translate("generic.username")}</label>
      <input
        id="input-name"
        name="name"
        type="text"
        autocomplete="off"
      />
      {#if $errors.name}
        <span class="error">{$errors.name}</span>
      {/if}
    </div>
    <div class="input-container">
      <label for="input-password">{translate("generic.password")}</label>
      <input
        id="input-password"
        name="password"
        type="password"
        autocomplete="new-password"
      />
      {#if $errors.password}
        <span class="error">{$errors.password}</span>
      {/if}
    </div>
    <div class="buttons-container">
      <GithubLikeButton color="green" label={translate("generic.create")} type="submit" />
    </div>
  </form>
</dialog>

<style>
  dialog {
    position: absolute;
    top: 100px;
    width: 500px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 18px rgba(0,0,0,.4);
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  input {
    font-size: 1rem;
    padding: 0.4rem;
  }
  .input-container {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: bold;
    flex-grow: 1;
  }
  .buttons-container {
    display: flex;
    justify-content: end;
  }
  .error {
    color: red;
    font-size: 0.9rem;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
  }
</style>