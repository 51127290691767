<script>
  export let label
  export let error
</script>

<div class="dialog-content-input-container">
  <span>{label}</span>
  <input {...$$restProps}/>
  {#if error}
    <span class="error">{error}</span>
  {/if}
</div>

<style>
  .dialog-content-input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .dialog-content-input-container span {
    line-height: 1;
  }
  .dialog-content-input-container input {
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
    padding: 0.3em 0.8em;
    border: solid 1px #888888;
    border-radius: 5px;
    outline: none;
  }
  .dialog-content-input-container input:focus {
    border-color: #1a3a91;
  }
  .error {
    color: #de5555;
  }
</style>