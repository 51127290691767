import {useQuery} from "@sveltestack/svelte-query"
import {derived} from "svelte/store"

export function useTimezone() {
  const queryResult =  useQuery("/api/locales/timezone", {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  const data= derived(queryResult, values => {
    return values.data ?? {}
  })
  const getOffset = derived(data, data => {
    return (timezone) => {
      const now = Date.now()
      if (!Array.isArray(data[timezone])) return 0
      for (const info of data[timezone]) {
        if ((now / 1000) > info.timestamp) {
          return info.offset
        }
      }
      return 0
    }
  })

  return {
    data,
    getOffset,
  }
}

export function getNextTimeShift(timezone, timezoneData, basisDate = new Date()) {
  let current = null
  let next = null
  if (!timezoneData[timezone]) return {current, next}
  let basis = basisDate.getTime() / 1000
  for (let i = 0; i < timezoneData[timezone].length; i++) {
    if (basis > timezoneData[timezone][i].timestamp) {
      current = timezoneData[timezone][i]
      if (timezoneData[timezone].length > i+1) {
        next = timezoneData[timezone][i+1]
      }
      break
    }
  }
  return {current, next}
}
