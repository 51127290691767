import {useQuery} from "@sveltestack/svelte-query"
import {derived} from "svelte/store"

export function useMyProjectPermission(projectId) {
  const queryResult = useQuery(
    `/api/me/projects/${projectId}/permission`
  )
  return {
    permission: derived(
      queryResult,
      values => {
        return values.data?.type
      }
    ),
  }
}