<script>
  import { translate } from "../../i18n/i18next.js";
  import { onMount, tick } from "svelte";
  import { buildQueryString, parseAdvancedFilterObjString } from "../../utils/filter.js";

  /**
   * Called when the filter query is determined.
   * the query is considered determined in the following cases:
   *   * onblur event is triggered on the input
   *   * Enter is pressed on the input
   *   * One of the candidates is clicked
   * @param {string} query
   */
  export let onQueryDetermined

  /**
   * Choices displayed when the user is inputting.
   * @type {string[]|(() => string[])}
   */
  export let choices = []
  function getChoices() {
    return Array.isArray(choices) ? choices : choices()
  }

  /**
   * Optional prop that will be used to determine a candidate's match to the query.
   * If left unspecified, partial string match will be used.
   * @param {string} choice
   * @param {string} query
   */
  export let matcher = (choice, query) => choice.indexOf(query) !== -1
  function getFilteredChoices(query) {
    choices = getChoices()
    if (!query) return choices
    return choices.filter(item => matcher(item, query))
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Can be used to set the query string from outside.
   * Note that this does not trigger onQueryDetermined().
   */
  export function setQuery(newQuery) {
    query = buildQueryString(newQuery)
  }

  export let displayClearButton = false
  export let displayAdvancedFilter = true
  export let openAdvancedFilterDialog = () => {}

  // internal states
  let query = ""
  $: filteredChoices = getFilteredChoices(query)
  let selectedChoiceIndex = null
  let showChoices = false
  let inputEl = null

  function onMousedownEvent(event) {
    event.preventDefault()

    if (isAdvancedFilter) {
      showChoices = false
      openAdvancedFilterDialog()
    } else {
      showChoices = true
    }
    event.target.focus()
  }

  function onBlurInput(event) {
    event.preventDefault()
    onQueryDetermined(query)
    showChoices = false
    clipLeft()
  }

  function onClickCandidate(event, candidate) {
    event.preventDefault()
    event.stopPropagation()
    query = candidate
    onQueryDetermined(query)
    showChoices = false
  }

  function onClickDropdown(event) {
    event.preventDefault()
    showChoices = !showChoices
    inputEl?.focus()
  }

  function navigateCandidates(movement) {
    if (movement === "up") {
      if (selectedChoiceIndex === 0) selectedChoiceIndex = null
      else selectedChoiceIndex -= 1
    } else {
      if (selectedChoiceIndex == null && filteredChoices.length > 0) {
        showChoices = true
        selectedChoiceIndex = 0
      } else {
        showChoices = true
        if (filteredChoices.length > selectedChoiceIndex + 1) selectedChoiceIndex += 1
      }
    }
  }

  function onKeydownQueryInput(event) {
    switch (event.key) {
      case "Enter":
        if (showChoices && selectedChoiceIndex != null) {
          query = filteredChoices[selectedChoiceIndex]
        }
        onQueryDetermined(query)
        showChoices = false
        break
      case "ArrowDown":
        navigateCandidates("down")
        break
      case "ArrowUp":
        navigateCandidates("up")
        break
      default:
        selectedChoiceIndex = null
        showChoices = true
    }
  }

  function onHoverCandidate(event, candidateIndex) {
    event.preventDefault()
    selectedChoiceIndex = candidateIndex
  }

  // move the cursor to the end of the input to display the right-most part of the text
  function clipLeft() {
    if (inputEl) {
      inputEl.selectionStart = inputEl.selectionEnd = inputEl.value.length
    }
  }

  function onClickClearFilter(event) {
    event.preventDefault()
    event.stopPropagation()

    query = ""
    onQueryDetermined("")
    inputEl.focus()
  }

  function onClickAdvancedSearch(event) {
    event.preventDefault()
    event.stopPropagation()

    showChoices = false
    openAdvancedFilterDialog()
  }

  let innerContainerDiv = null
  let choicesContainerWidth = 0
  $: {
    if (showChoices) {
      choicesContainerWidth = innerContainerDiv?.getBoundingClientRect()?.width
    }
  }

  $: isAdvancedFilter = parseAdvancedFilterObjString(query)?.[0] ?? false

  onMount(async () => {
    await tick()
    clipLeft()
  })
</script>

<div class="filter-input-container">
  <div class="filter-input-inner-container" class:inputting={showChoices} bind:this={innerContainerDiv}>
    {#if isAdvancedFilter}
      <input
        placeholder={translate("frontend:components.filterInput.noFilterSet")}
        class="filter-input"
        on:blur={onBlurInput}
        on:keydown={onKeydownQueryInput}
        on:mousedown={onMousedownEvent}
        value={translate("frontend:components.filterInput.hasFilter")}
        bind:this={inputEl}
      />
      <button on:click={onClickDropdown}>
        <img src="/assets/dropdown.svg" alt="down arrow icon" />
      </button>
    {:else}
      <input
        placeholder={translate("frontend:components.filterInput.noFilterSet")}
        class="filter-input"
        on:blur={onBlurInput}
        on:keydown={onKeydownQueryInput}
        on:mousedown={onMousedownEvent}
        bind:value={query}
        bind:this={inputEl}
      />
      <button on:click={onClickDropdown}>
        <img src="/assets/dropdown.svg" alt="down arrow icon" />
      </button>
    {/if}
  </div>
  {#if showChoices}
    <div class="choices-container" style="--choices-container-width: {choicesContainerWidth}px">
      {#each filteredChoices as candidate, index}
        <button
          class="choice"
          class:selected={selectedChoiceIndex === index}
          on:mousedown={e => onClickCandidate(e, candidate)}
          on:mouseenter={e => onHoverCandidate(e, index)}
        >
          {candidate}
        </button>
      {:else}
        <div class="choice">
          {translate("frontend:components.filterInput.noChoiceMatched")}
        </div>
      {/each}
      {#if displayClearButton || displayAdvancedFilter}
        <div class="buttons-container">
          {#if displayClearButton}
            <button
              class="choice clear-button"
              on:mousedown={onClickClearFilter}
            >
              {translate("frontend:components.filterInput.clearFilter")}
            </button>
          {/if}
          {#if displayAdvancedFilter}
            <button
              class="choice advanced-search-button"
              on:mousedown={onClickAdvancedSearch}
            >
              {translate("frontend:components.filterInput.advancedSearch")}
            </button>
          {/if}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .filter-input-container {
    position: relative;
    --border-color: #00468f;
    --hover-color: #00468f;
    --hover-text-color: white;
  }
  .filter-input-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: solid 2px var(--border-color);
    border-radius: 6px;
    padding: 0 8px;
    gap: 8px;
    font-size: 1rem;
    position: relative;
  }
  .filter-input-inner-container > input {
    flex: 1 1;
    line-height: 2;
    border: none;
    outline: none;
    overflow-x: clip;
    text-overflow: clip clip;
  }
  .filter-input-inner-container.inputting {
    border-radius: 6px 6px 0 0;
  }
  .filter-input-inner-container img {
    cursor: pointer;
  }
  .choices-container {
    position: fixed;
    border-left: solid 2px var(--border-color);
    border-right: solid 2px var(--border-color);
    border-bottom: solid 2px var(--border-color);
    border-radius: 0 0 6px 6px;
    width: var(--choices-container-width);
    background-color: white;
    max-height: 300px;
    z-index: 10;
    overflow-y: auto;
    scrollbar-width: none;
  }
  .choice {
    display: block;
    text-align: left;
    width: 100%;
    padding: 0 8px;
    line-height: 2;
  }
  .choice:not(:first-of-type) {
    border-top: 1px solid #a9a9a9;
  }
  .choice.selected {
    background-color: var(--hover-color);
    color: var(--hover-text-color);
  }
  .buttons-container {
    border-top: 2px solid var(--border-color);
    position: sticky;
    bottom: 0;
    background-color: white;
  }
  .clear-button {
    font-weight: bold;
    color: #DE5555;
    text-align: center;
  }
  .advanced-search-button {
    font-weight: bold;
    color: #00468f;
    text-align: center;
  }
</style>
