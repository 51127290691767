<script>
  import ProjectUsersList2Entry from "./ProjectUsersList2Entry.svelte"
  import EditProjectUserPermissionDialog from "./EditProjectUserPermissionDialog.svelte"
  import GithubLikeButton from "../button/GithubLikeButton.svelte"
  import {getNotificationsContext} from "svelte-notifications"
  import {getContext, tick} from "svelte"
  import {myJsonUpdater} from "../../utils/putData.js"
  import {FetchError} from "../../utils/getData.js"
  import {useQueryClient} from "@sveltestack/svelte-query"
  import {translate} from "../../i18n/i18next.js"
  import DeleteConfirmationDialog from "../DeleteConfirmationDialog.svelte";
  import { useProject } from "../../hooks/useProject.js";

  export let projectId
  export let user

  const project = useProject(projectId)

  let editProjectUserPermissionDialog

  let deleteConfirmationDialog
  let isDeleteConfirmationDialogOpen = false
  async function openDeleteConfirmationDialog() {
    isDeleteConfirmationDialogOpen = true
    await tick()
    deleteConfirmationDialog.showModal()
  }
  function closeDeleteConfirmationDialog() {
    deleteConfirmationDialog.close()
    isDeleteConfirmationDialogOpen = false
  }

  const {addNotification} = getNotificationsContext()
  const {handleNonBlockingFetchErrors} = getContext("fetchErrorHandler")
  const queryClient = useQueryClient()
  async function handleConfirmedUserRemoval() {
    try {
      await myJsonUpdater(`/api/projects/${projectId}/users/${user.id}/permission`, "PUT", JSON.stringify({type: null}))
      addNotification({
        text: translate("frontend:pages:projects.project.admin.sections.users.list.userRemoved"),
        position: "top-right",
        type: "success",
        removeAfter: 3000,
      })
      await queryClient.invalidateQueries(`/api/projects/${projectId}/users`)

      closeDeleteConfirmationDialog()
    } catch (e) {
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error)
      }
    }
  }

</script>

<ProjectUsersList2Entry projectUser={user}>
  <EditProjectUserPermissionDialog
    projectId={projectId}
    bind:dialog={editProjectUserPermissionDialog}
    onClose={()=>editProjectUserPermissionDialog.close()}
    user={user}
  />
  <GithubLikeButton color="gray" label={translate("generic.changePermission")} onClick={()=>editProjectUserPermissionDialog.showModal()} />
  <GithubLikeButton color="gray-alert" label={translate("generic.remove")} onClick={openDeleteConfirmationDialog} />
  {#if isDeleteConfirmationDialogOpen}
    <DeleteConfirmationDialog
      target={user.name}
      title={translate("frontend:pages.projects.project.admin.sections.users.deleteUserConfirmationDialogTitle", null, {projectName: $project.name, userName: user.name})}
      message={translate("frontend:pages.projects.project.admin.sections.users.deleteUserConfirmationDialogMessage", null, {projectName: $project.name, userName: user.name})}
      bind:dialog={deleteConfirmationDialog}
      onClose={closeDeleteConfirmationDialog}
      onConfirmed={handleConfirmedUserRemoval}
    />
  {/if}
</ProjectUsersList2Entry>