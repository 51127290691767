<script>
  import OrderGantt from "../../../routes/WebViewer_ForGuest/OrderGantt.svelte"
  import AuthenticationRequired from "../../../components/functional/AuthenticationRequired.svelte"
  import ProjectPageBase from "../../../components/pages/projects/[project]/ProjectPageBase.svelte"
  import CheckDataStatus from "../../../components/pages/projects/[project]/CheckDataStatus.svelte"
</script>

<AuthenticationRequired>
  <ProjectPageBase let:projectId let:user>
    <CheckDataStatus projectId={projectId} viewName="OrderGantt">
      <OrderGantt
        projectId={projectId}
        user={user}
      />
    </CheckDataStatus>
  </ProjectPageBase>
</AuthenticationRequired>