<script>
  import SettingsMenuSection from "./SettingsMenuSection.svelte";
  import { get } from "svelte/store";

  export let ImportedSettings;
  export let GanttStore

  const SectionToProcessedEntries = (section) => {
    return {
      SectionLabel: ImportedSettings.Sections[section],
      SectionEntries: ImportedSettings.Categories[section].map((ct) => get(ImportedSettings.Entries())[ct]),
    };
  };

</script>

<div id="settingsmenu_categories">
  {#each Object.keys(ImportedSettings.Sections) as sec}
    <SettingsMenuSection
      GanttStore={GanttStore}
      Section={SectionToProcessedEntries(sec)}
    />
  {/each}
</div>

<style>
  div#settingsmenu_categories {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 3rem;
  }
</style>
