<script>
  import ProjectsPage from "../../routes/admin/ProjectsPage.svelte"
  import AuthenticationRequired from "../../components/functional/AuthenticationRequired.svelte"
  import PageBase from "../../components/pages/PageBase.svelte"
  import ServerAdminRequired from "../../components/functional/ServerAdminRequired.svelte";
</script>

<AuthenticationRequired>
  <ServerAdminRequired>
    <PageBase backTo={null}>
      <ProjectsPage />
    </PageBase>
  </ServerAdminRequired>
</AuthenticationRequired>