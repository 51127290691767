<script>
  import { translate } from "../i18n/i18next.js";
</script>

<main>
  <div class="container">
    <h1>404</h1>
    <h2>{translate("frontend:generic.errors.notFound")}</h2>
  </div>
</main>

<style>

</style>
