<script>
  /**
   * color options are: gray, green, gray-alert
   * @type {"gray" | "gray-alert" | "green"}
   */
  export let color = "gray"
  /**
   * button type
   * @type {"button" | "submit"}
   */
  export let type = "button"
  export let label
  export let onClick = () => {}
  export let fullWidth = false
  export let disabled = false
</script>

<button
  class={`github-like-button`}
  type={type}
  class:green={color === "green"}
  class:gray={color === "gray"}
  class:gray-alert={color === "gray-alert"}
  class:fullWidth={fullWidth}
  on:click={onClick}
  disabled={disabled}
>
  {label}
</button>

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    appearance: none;
    padding: 3px 12px;
    border-radius: 6px;
  }
  .green {
    color: white;
    background-color: #1a7f37;
  }
  .green:disabled {
    color: #c3e6cb;
    background-color: #94d3a2;
  }
  .gray {
    color: rgb(36, 41, 47);
    background-color: rgb(246, 248, 250);
    border: 0.5px solid rgba(31, 35, 40, 0.15)
  }
  .gray:disabled {
    color: rgb(36, 41, 47, 0.5);
  }
  .gray-alert {
    color: rgb(209, 36, 47);
    background-color: rgb(246, 248, 250);
    border: 0.5px solid rgba(31, 35, 40, 0.15);
    font-weight: bold;
  }
  .gray-alert:disabled {
    color: rgba(209, 36, 47, 0.5)
  }
  .fullWidth {
    display: block;
    width: 100%;
    text-align: center;
  }
</style>