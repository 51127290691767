<script>
  import { onMount } from "svelte";

  export let header;
  export let rowHeight;
  export let visibleRows;

  export let handleScrollRowNum;

  export let border;
  let rowWidth;

  function assignWidth(header) {
    if (header === "資源コード") {
      rowWidth = 180;
    } else if (header === "工程") {
      rowWidth = 190;
    } else if (header === "勤務シフト") {
      rowWidth = 150;
    }
  }
  onMount(() => {
    assignWidth(header);
  });
</script>

<table on:wheel={handleScrollRowNum}>
  <thead>
    <tr>
      <th>
        <div class="res-cell" style="width: {rowWidth}px">
          <div class="header-text">{header}</div>
          <div class="empty"></div>
        </div>
      </th>    
    </tr>
  </thead>
  <tbody>
    {#each visibleRows as row}
      <tr>
        <!--  on:wheel={handleScrollRowNum} -->
        <td>
          <div class="normal-data" style="height: {rowHeight}px; max-height: {rowHeight}px; width: {rowWidth}px;">
            <p class="data-text">{header === '資源コード' ? row.resourceCode : 
              header === '工程' ? row.process : 
              header === '勤務シフト' ? row.shift : ''}
            </p>
          </div>
          
        </td>
      </tr>
    {/each}
  </tbody>
</table>
  
<style>

  table {
    height: 100%;
    border-collapse: collapse;
    table-layout: fixed;

  }

  th,td {
    padding: 0 !important;
    border-bottom: 2px solid #888888;
    border-top: 2px solid #888888;
    border-left: 2px solid #888888;
  }

  th {
    height: 80px;
    background-color: white;
    
  }

  td {
    background-color: #ffffff;
  }

  .res-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%; 
    box-sizing: border-box;
  }

  .header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 100%;
  }

  .empty {
    width: 100%;
    height: 26px;
    flex-shrink: 0;
  }

  .normal-data {
    background-color: #e9e9ef;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .data-text {
    margin: 0;
  }
</style>