import {getConfiguration, saveConfiguration} from "../configurationStorage.js"
import {z} from "zod"

/**
 * @typedef {{
 *   styleName?: string | null,
 *   displayCompletedTasks?: boolean | null,
 *   usingDatePageMode?: boolean | null,
 *   itemPerPage?: number | null
 * }} DispatchingViewConfiguration
 */

const VIEW_NAME = "DispatchingView"

/**
 * @type {DispatchingViewConfiguration}
 */
const defaultValue = {
  styleName: null,
  displayCompletedTasks: false,
  usingDatePageMode: false,
  itemPerPage: 10,
}

const DispatchingViewConfigurationSchema = z.object({
  styleName: z.union([
    z.string(),
    z.null(),
  ]),
  displayCompletedTasks: z.union([
    z.boolean(),
    z.null(),
  ]),
  usingDatePageMode: z.union([
    z.boolean(),
    z.null(),
  ]),
  itemPerPage: z.union([
    z.number(),
    z.null(),
  ])
})

/**
 * @param {string} userId
 * @param {string} projectId
 * @returns {DispatchingViewConfiguration}
 */
export function getDispatchingViewConfiguration(userId = "default", projectId = "default") {
  return getConfiguration(VIEW_NAME, userId, projectId, defaultValue)
}

/**
 * @param {Partial<DispatchingViewConfiguration>} configuration
 * @param {string} userId
 * @param {string} projectId
 */
export function saveDispatchingViewConfiguration(configuration, userId = "default", projectId = "default") {
  const saved = {...defaultValue, ...getDispatchingViewConfiguration(userId, projectId), ...configuration}
  try {
    DispatchingViewConfigurationSchema.parse(saved)
    saveConfiguration(VIEW_NAME, userId, projectId, saved)
  } catch (e) {
    console.log(e)
    console.error("Malformed configuration for DispatchingView")
  }
}
