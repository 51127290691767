<script>
    import FilterInput from "../input/FilterInput.svelte";
    import GeneralButton from "../button/GeneralButton.svelte";
    import { saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage";
    import {
        filteredResGroup,
        filteredResCode,
        filteredResName,
        filteredResCompany,
        ResGroupChoices,
        ResCodeChoices,
        ResNameChoices,
        ResCompanyChoices,
        ResCompanyArray,
        resultRowTotal,
        resultStartRow,
        FilteredProcessIndices,
        FilteredCompanyIndices,
        RawData,
        selectedDisplayMode,
    } from "./ResultGraphStore"

    let ResGroupFilterInput = null;
    let ResCodeFilterInput = null;
    let ResNameFilterInput = null;
    let ResCompanyFilterInput = null;

    const filterByResGroup = (query) => {
        ResGroupFilterInput.setQuery(query)
        filteredResGroup.set(query);
        saveResultGraphConfiguration({"filteredResGroup": $filteredResGroup}, 'default', 'default');
        if(query==""){
            filteredResGroup.set(null);
            saveResultGraphConfiguration({"filteredResGroup": null}, 'default', 'default');
        }
    }

    const filterByResCode = (query) => {
        ResCodeFilterInput.setQuery(query)
        filteredResCode.set(query);
        saveResultGraphConfiguration({"filteredResCode": $filteredResCode}, 'default', 'default');
        if(query==""){
            filteredResCode.set(null);
            saveResultGraphConfiguration({"filteredResCode": null}, 'default', 'default');
        }
    }

    const filterByResName = (query) => {
        ResNameFilterInput.setQuery(query)
        filteredResName.set(query);
        saveResultGraphConfiguration({"filteredResName": $filteredResName}, 'default', 'default');
        if(query==""){
            filteredResName.set(null);
            saveResultGraphConfiguration({"filteredResName": null}, 'default', 'default');
        }
    }

    const filterByResCompany = (query) => {
        ResCompanyFilterInput.setQuery(query)
        filteredResCompany.set(query);
        saveResultGraphConfiguration({"filteredResCompany": $filteredResCompany}, 'default', 'default');
        if(query==""){
            filteredResCompany.set(null);
            saveResultGraphConfiguration({"filteredResCompany": null}, 'default', 'default');
        }
    }

    function resetFilter(){
        filterByResGroup("");
        filterByResCode("");
        filterByResName("");
        filterByResCompany("");
        FilterItemFunc();
    };

    function FilterItemFunc(){
        const newFilteredProcessIndices = [];

        for(let index = 0; index < $RawData.length; index++){
            let isFilteredItem = true;
            if($filteredResGroup !== null && $filteredResGroup !== $RawData[index].Res_Group) isFilteredItem = false;
            if($filteredResCode !== null && $filteredResCode !== $RawData[index].Res_Code) isFilteredItem = false;
            if($filteredResName !== null && $filteredResName !== $RawData[index].Res_Name) isFilteredItem = false;
            if($filteredResCompany !== null && $filteredResCompany !== $RawData[index].Res_Company) isFilteredItem = false;
            if(isFilteredItem) newFilteredProcessIndices.push(index);
        }

        FilteredProcessIndices.set(newFilteredProcessIndices);
        resultRowTotal.set(newFilteredProcessIndices.length);
        resultStartRow.set(0);
        
        const newFilteredCompanyIndices = [];
        for(let index = 0; index < $ResCompanyArray.length; index++){
            if($filteredResCompany !== null && $filteredResCompany !== $ResCompanyArray[index])continue; 
            newFilteredCompanyIndices.push(index);
        }
        FilteredCompanyIndices.set(newFilteredCompanyIndices);
    }


    $: {
        if($filteredResGroup !== null) ResGroupFilterInput?.setQuery($filteredResGroup);
        if($filteredResCode !== null) ResCodeFilterInput?.setQuery($filteredResCode);
        if($filteredResName !== null) ResNameFilterInput?.setQuery($filteredResName);
        if($filteredResCompany !== null) ResCompanyFilterInput?.setQuery($filteredResCompany);
        FilterItemFunc();
    }
</script>

<div id="result_filter_settings">
    <div id="title">{"表示設定"}</div>

    <div id="section_block">
        <div id="block_title">{"グループ"}</div>
        <FilterInput
            bind:this={ResGroupFilterInput}
            choices={$ResGroupChoices}
            onQueryDetermined={filterByResGroup}
            displayAdvancedFilter={false}
        />
    </div>

    <div id="section_block">
        <div id="block_title">{"資源コード"}</div>
        <FilterInput
            bind:this={ResCodeFilterInput}
            choices={$ResCodeChoices}
            onQueryDetermined={filterByResCode}
            displayAdvancedFilter={false}
        />
    </div>

    <div id="section_block">
        <div id="block_title">{"工程"}</div>
        <FilterInput
            bind:this={ResNameFilterInput}
            choices={$ResNameChoices}
            onQueryDetermined={filterByResName}
            displayAdvancedFilter={false}
        />
    </div>

    <div id="section_block">
        <div id="block_title">{"カンパニー"}</div>
        <FilterInput
            bind:this={ResCompanyFilterInput}
            choices={$ResCompanyChoices}
            onQueryDetermined={filterByResCompany}
            displayAdvancedFilter={false}
        />
    </div>

    <GeneralButton
        buttonFunction={resetFilter}
        buttonText="フィルタのクリア"
        buttonType="negative"
        buttonExtraStyle="
        margin-left: 0px; 
        margin-right: 32px; 
        margin-top: 16px;
        width: 100%"; 
    />
</div>

<style>
    #result_filter_settings{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    #title{
        font-size: 16px;
        color: #CCCCCC;
    }

    #section_block{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
</style>