<script>
  import { useParams } from "svelte-navigator"
  import ProjectUsersList2 from "../../../components/project/ProjectUsersList2.svelte"
  import InvitationDialog from "../../../components/project/InvitationDialog.svelte"
  import SectionTitle from "../../../components/layout/SectionTitle.svelte"
  import ProjectAdminPageUISection from "../../../components/project/adminPage/ProjectAdminPageUISection.svelte"
  import ProjectAdminPageGeneralInfoSection
    from "../../../components/project/adminPage/ProjectAdminPageGeneralInfoSection.svelte"
  import { useProject } from "../../../hooks/useProject.js"
  import Alert from "../../../components/Alerts/Alert.svelte"
  import PrimaryButton from "../../../components/button/generic/PrimaryButton.svelte"
  import { translate } from "../../../i18n/i18next.js"

  const params = useParams();
  const projectId = $params.project

  const project = useProject(projectId)

  let invitationDialog
</script>

<div id="content-container">
  {#if $project}
    <div class="section-container">
      <ProjectAdminPageGeneralInfoSection project={$project} />
    </div>
    <div class="section-container">
      <ProjectAdminPageUISection projectId={projectId} />
    </div>
    <div class="section-container">
      <div style="display: flex; flex-direction: column; gap: 1rem;">
        <SectionTitle title={translate("frontend:pages:projects.project.admin.sections.users.title")} />
        {#if $project?.plan === "viewer"}
          <Alert type="warn">{translate("frontend:pages:projects.project.admin.sections.users.viewerPlanPermissionWarning")}</Alert>
        {/if}
        <ProjectUsersList2 />
        <div>
          <PrimaryButton onClick={() => invitationDialog.showModal()}>
            {translate("frontend:pages:projects.project.admin.sections.users.inviteUser")}
          </PrimaryButton>
        </div>
        <InvitationDialog
          projectId={projectId}
          bind:dialog={invitationDialog}
          onClose={() => invitationDialog.close()}
        />
      </div>
    </div>
  {/if}
</div>

<style>
  #content-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }
  .section-container {
    padding: 1rem 1rem;
    border: 1px solid darkgray;
    border-radius: 5px;
  }
</style>