<script>
  import { onMount, setContext } from "svelte"
  import { derived } from "svelte/store";
  import { useQuery } from "@sveltestack/svelte-query";
  import PageHeader from "./PageHeader.svelte"
  import { createPageContextValue, usePageContext } from "./pageContext.js"
  import { useParams } from "svelte-navigator";
  import svelteI18next, { getFontFamily, isLoading, setMyLocale } from "../../i18n/i18next.js";
  import Loading from "./Loading.svelte";
  import { getLanguagesConfiguration } from "../../utils/configurationStorage/languageConfigurationStorage.js";
  import DisplayVersions from "./DisplayVersions.svelte";
  import RightPane from "../layout/RIghtPane.svelte";

  // setContext() needs to be before usePageContext(), because the latter refers to the context created in the former.
  setContext("page", createPageContextValue())
  const pageContext = usePageContext()
  const params = useParams()

  async function determineLanguage() {
    const paramsLang = $params.lang
    if (paramsLang) return paramsLang

    const languageConfiguration = await getLanguagesConfiguration("default")
    return languageConfiguration.language
  }

  onMount(async () => {
    const language = await determineLanguage()

    // set the font-family accordingly
    document.body.style.fontFamily = getFontFamily(language)

    await $svelteI18next.changeLanguage(language)
    setMyLocale(language)
  })

  export let backTo = "/"

  // retrieve backend version
  const backendInfoQuery = useQuery("/api/features/backend", {refetchInterval: false, retry: 1})
  const backendVersion = derived(backendInfoQuery, backendInfoQuery => {
    return backendInfoQuery.data?.version ?? null
  })
  backendVersion.subscribe(v => pageContext.setVersions({backend: v}))
</script>

<main>
  {#if $isLoading}
    <Loading />
  {:else}
    {#key $svelteI18next}
      <slot name="header">
        <PageHeader backTo={backTo} />
      </slot>
      <div id="contents-container">
        <div id="main-content">
          <slot />
        </div>
        <RightPane />
      </div>
      <DisplayVersions />
    {/key}
  {/if}
</main>

<style>
  main {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  #contents-container {
    flex: 1 1;
    display: flex;
    height: calc(100vh - 50px);
  }
  #main-content {
    flex: 1 1;
    overflow-y: auto;
  }
</style>