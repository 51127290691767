<script>
  import TableAreaRow from "./TableAreaRow.svelte";
  import { derived } from "svelte/store";
  import { translate } from "../../i18n/i18next.js";

  export let GanttStore;
  export let width = 100;

  const {
    RowName,
    RowsDisplayedStart,
    RowsCountTotal,
    RowsCountTotalIncludingUnfoldedOperations,
    DisplayedRowCount,
    GanttChartType,
    RowsCurrentlyDisplayed,
  } = GanttStore;

  // Array of RealIndexes of $RowsCurrentlyDisplayed. Only used for OrderGantt
  const rowIndexList = derived([RowsCurrentlyDisplayed], ([rows]) => {
    if ($GanttChartType === GanttStore.GanttChartTypes.OrderGantt) {
      return rows
        .filter((row) => row && row.RealIndex !== undefined)
        .map((row) => row.RealIndex);
    }
    return [];
  });

  const PageScroll = (pj) => {
    GanttStore.RowsDisplayedStart.update((n) =>
      Math.max(0, Math.min($RowsCountTotal - 1, n + pj * $DisplayedRowCount))
    );
  };

  const PageUp = (e) => {
    e.preventDefault();
    PageScroll(-1);
  };

  const PageDown = (e) => {
    e.preventDefault();
    PageScroll(1);
  };

  const RowUp = (e) => {
    e.preventDefault();
    GanttStore.RowsDisplayedStart.update((n) => (n > 0 ? n - 1 : n));
  };

  const RowDown = (e) => {
    e.preventDefault();
    if ($GanttChartType === GanttStore.GanttChartTypes.OrderGantt)
      GanttStore.RowsDisplayedStart.update((n) =>
        n + 1 >= $RowsCountTotalIncludingUnfoldedOperations ? n : n + 1
      );
    else
      GanttStore.RowsDisplayedStart.update((n) =>
        n + 1 >= $RowsCountTotal ? n : n + 1
      );
  };

  const ScrollRows = (e) => {
    e.preventDefault();

    if (e.ctrlKey) {
      // Row nb scroll
      if (e.deltaY > 0)
        GanttStore.DisplayedRowCount.update((n) => (n + 1 >= 100 ? n : n + 1));
      else if (e.deltaY < 0)
        GanttStore.DisplayedRowCount.update((n) => (n > 1 ? n - 1 : n));
    } else {
      // Row idx scroll
      if (e.deltaY > 0) RowDown(e);
      else if (e.deltaY < 0) RowUp(e);
    }
  };

  function formatTableHeader(ganttChartType, displayedRowCount, startRowIndex, rowIndexList, totalRows) {
    function getRowName() {
      switch (ganttChartType) {
        case GanttStore.GanttChartTypes.ResGantt:
        case GanttStore.GanttChartTypes.SimpleResGantt:
        case GanttStore.GanttChartTypes.CustomizedResGantt:
          return translate("asprova.terms.resource")
        case GanttStore.GanttChartTypes.OrderGantt:
        case GanttStore.GanttChartTypes.SimpleOrderGantt:
        case GanttStore.GanttChartTypes.ExpectedActualCmp:
          return translate("asprova.terms.order")
        default:
          return ""
      }
    }
    const rowName = getRowName()
    if (!rowName) return ""

    if (ganttChartType === GanttStore.GanttChartTypes.OrderGantt) {
      if (displayedRowCount > 1) {
        return translate("frontend:ganttChart.table.header.title.multipleRows", null, {
          rowName,
          rowIndexStart: rowIndexList[0]+1,
          rowIndexEnd: rowIndexList[rowIndexList.length - 1] + 1,
          totalRows
        })
      }
    }

    if (displayedRowCount > 1) {
      return translate("frontend:ganttChart.table.header.title.multipleRows", null, {
        rowName,
        rowIndexStart: startRowIndex+1,
        rowIndexEnd: Math.min(totalRows, startRowIndex + displayedRowCount),
        totalRows
      })
    }
    return translate("frontend:ganttChart.table.header.title.singleRow", null, {
      rowName,
      rowIndex: startRowIndex + 1,
      totalRows,
    })
  }

  $: headerTitleString = formatTableHeader(
    $GanttChartType,
    $DisplayedRowCount,
    $RowsDisplayedStart,
    $rowIndexList,
    $RowsCountTotal,
  )
</script>

<div id="gantt_tablearea" style="--table-width: {width}%" on:wheel={ScrollRows}>
  <div id="gantt_tablearea_headcolumns">
    <span id="gantt_tablearea_header">{headerTitleString}</span>
    <div id="gantt_tablearea_headbuttons">
      <div id="gantt_tablearea_headbutton" on:click={RowUp}>
        <img src="/assets/rss_up.svg" />
      </div>
      <div id="gantt_tablearea_headbutton" on:click={PageUp}>
        <img src="/assets/rss_pageup.svg" />
      </div>
    </div>
  </div>

  <div id="gantt_tablearea_bottomdiv">
    <svg id="gantt_tablearea_resourcesvg">
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1="30"
          y1="0"
          y2="0"
          id="truncateText"
        >
          <stop offset="90%" stop-opacity="1" />
          <stop offset="100%" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="truncateRowText"
          gradientTransform="translate(0)"
          xlink:href="#truncateText"
        />
      </defs>
      {#each $RowsCurrentlyDisplayed as row, index}
        <TableAreaRow
          {GanttStore}
          RowIndex={row !== null && row.RealIndex != undefined
            ? row.RealIndex
            : index}
          Row={row === null ? "" : row.Alias}
          Color={row === null ? "white" : row.Color}
          IsNull={row === null}
          Height={$DisplayedRowCount > 0 ? 100 / $DisplayedRowCount : 100}
          OriginY={($DisplayedRowCount > 0 ? 100 / $DisplayedRowCount : 100) *
            index}
          IsOperation={row?.IsOperation}
          bind:displayedRows={$DisplayedRowCount}
        />
      {/each}
    </svg>
  </div>

  <div id="gantt_tablearea_footerbuttons">
    <div id="gantt_tablearea_footerbutton" on:click={RowDown}>
      <img src="/assets/rss_down.svg" />
    </div>
    <div id="gantt_tablearea_footerbutton" on:click={PageDown}>
      <img src="/assets/rss_pagedown.svg" />
    </div>
  </div>
</div>

<style>
  div#gantt_tablearea_footerbuttons {
    width: 100%;
    height: 40px;
    gap: 4%;
    line-height: 32px;
    color: white;
    background-color: white;
    display: flex;
    flex-direction: row;
    overflow: none;
    text-align: center;
    border-top: 2px solid #888888;
  }

  div#gantt_tablearea_footerbutton {
    background-color: #d2d0d2;
    width: 48%;
    height: 80%;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div#gantt_tablearea_footerbutton:hover {
    background-color: #e2e0e2;
    cursor: pointer;
  }

  div#gantt_tablearea_headbutton {
    background-color: #d2d0d2;
    width: 48%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div#gantt_tablearea_headbutton:hover {
    background-color: #e2e0e2;
    cursor: pointer;
  }

  div#gantt_tablearea_headbuttons {
    background-color: white;
    height: 40%;
    width: 100%;
    gap: 4%;
    line-height: 32px;
    color: white;
    display: flex;
    flex-direction: row;
  }

  span#gantt_tablearea_header {
    height: 60%;
    line-height: 48px;
  }

  div#gantt_tablearea {
    height: 100%;
    width: var(--table-width);
    display: flex;
    flex-direction: column;
    overflow: none;
    border: 2px solid #888888;
    background-color: white;
  }

  div#gantt_tablearea_headcolumns {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    overflow: none;
    text-align: center;
    border-bottom: 2px solid #888888;
  }

  div#gantt_tablearea_bottomdiv {
    flex: 1;
    background-color: white;
  }

  svg#gantt_tablearea_resourcesvg {
    float: left;
    border-right: 2px solid #888888;
    width: 100%;
    height: 100%;
  }
</style>
