<script>
  import SettingsMenuCategories from "./SettingsMenuCategories.svelte"
  import { translate } from "../../i18n/i18next.js";

  export let ImportedSettings;
  export let onClose;
  export let GanttStore;
</script>

<div class="dialog settings_bg">
  <div class="escape_door" on:click={onClose}>
  </div>
  <div class="title-container">
    <button class="close-button" on:click={onClose}>＜</button>
    <span class="title">{translate("frontend:ganttChart.menu.title")}</span>
  </div>
  <div class="padtitle">
  </div>
  <div id="settings-menu">
    <SettingsMenuCategories
      GanttStore={GanttStore}
      {ImportedSettings}
    />
  </div>
</div>

<style>
  div.padtitle {
    padding-top: 3.5rem;
  }

  div.settings_bg {
    background-color: white;
    height: calc(100% - 48px);
    overflow-y: auto;
  }

  div.escape_door {
    background-color: rgba(0,0,0,0.5);
    width: 200vw;
    height: 100vh;
    position: absolute;
    z-index: -1;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .title {
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: bold;
  }
  .close-button {
    color: darkgrey;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    background-color: white;
    width: 280px;
    position: fixed;
    z-index: 50000;
  }
</style>
