/**
 * @typedef {{
 *   language: "ja" | "en" | "zh"
 * }} LanguagesConfiguration
 */
import { z } from "zod";
import { getConfiguration, saveConfiguration } from "./configurationStorage.js";

const VIEW_NAME = "Languages"

const defaultValue = {
  language: "ja"
}

const LanguagesConfigurationSchema = z.object({
  language: z.union([
    z.literal("ja"),
    z.literal("en"),
    z.literal("zh"),
  ])
})

/**
 * Get the user's languages configuration
 * @param {string} userId
 * @returns {Promise<LanguagesConfiguration>}
 */
export async function getLanguagesConfiguration(userId) {
  return getConfiguration(VIEW_NAME, userId, "default", {})
}

/**
 * Save the user's languages configuration
 * @param {Partial<LanguagesConfiguration>} configuration
 * @param {string} userId
 * @returns {Promise<void>}
 */
export async function saveLanguagesConfiguration(configuration, userId) {
  const saved = {...defaultValue, ...(await getLanguagesConfiguration(userId)), ...configuration}
  try {
    LanguagesConfigurationSchema.parse(saved)
    saveConfiguration(VIEW_NAME, userId, "default", saved)
  } catch (e) {
    console.log(e)
    console.error("Malformed configuration for Languages")
  }
}
