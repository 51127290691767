<script>
  import { createForm } from "felte";
  import { translate } from "../i18n/i18next.js";
  import DialogBase from "./dialog/DialogBase.svelte";
  import DialogTitle from "./dialog/DialogTitle.svelte";
  import DialogSplitter from "./dialog/DialogSplitter.svelte";
  import DialogCaption from "./dialog/DialogCaption.svelte";
  import DialogContent from "./dialog/DialogContent.svelte";
  import DialogBottomControls from "./dialog/DialogBottomControls.svelte";
  import DialogBottomControlButton from "./dialog/DialogBottomControlButton.svelte";

  export let dialog
  /**
   * @type {string}
   */
  export let title = ""
  /**
   * @type {string}
   */
  export let target
  /**
   * @type {string}
   */
  export let message = ""
  /**
   * @type {() => void}
   */
  export let onClose
  /**
   * @type {() => void}
   */
  export let onConfirmed

  const {form, errors, reset, createSubmitHandler} = createForm({
    onSubmit: async (data) => {
      await onConfirmed(data)
      reset()
    },
    validate: values => {
      if (values.confirmation !== "DELETE") {
        return {confirmation: translate("frontend:components.deleteConfirmationDialog.inputIncorrectError")}
      }
    }
  })
  const submitHandler = createSubmitHandler()
</script>

<DialogBase bind:dialog={dialog}>
  <DialogTitle
    title={title || translate("frontend:components.deleteConfirmationDialog.title", null, {target})}
  />
  <DialogSplitter />
  <DialogCaption>
    <div>
      {#if message}
        {message}
      {:else}
        {translate("frontend:components.deleteConfirmationDialog.defaultMessage", null, {target})}
      {/if}
    </div>
    <div>
      {translate("frontend:components.deleteConfirmationDialog.deleteInstruction")}
    </div>
  </DialogCaption>
  <DialogSplitter />
  <DialogContent>
    <form use:form>
      <div class="input-container">
        <input
          id="input-confirmation"
          name="confirmation"
        />
        {#if $errors.confirmation}
          <span class="error">{$errors.confirmation}</span>
        {/if}
      </div>
    </form>
  </DialogContent>
  <DialogSplitter />
  <DialogBottomControls>
    <DialogBottomControlButton type="secondary" onClick={onClose}>
      {translate("generic.cancel")}
    </DialogBottomControlButton>
    <DialogBottomControlButton type="negative" onClick={submitHandler}>
      {translate("generic.remove")}
    </DialogBottomControlButton>
  </DialogBottomControls>
</DialogBase>

<style>
  form {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  input {
    font-size: 1rem;
    padding: 0.4rem;
  }
  .input-container {
    display: flex;
    flex-direction: column;
  }

  .buttons-container {
    display: flex;
    justify-content: end;
  }
  .error {
    color: red;
    font-size: 0.9rem;
  }
</style>