import {writable} from "svelte/store";

const isDemoServer = writable(function (){
  if (window.location.hostname === "demo.myschedule.asprova.com") return true
  const params = new URLSearchParams(window.location.search)
  return params.get("demo") === "true"
}())

export function useIsDemoServer() {
  return {
    isDemoServer,
  }
}