<script>
  export let store
  const {TableAreaWidth} = store

  function handleDrag(e) {
    const newTableWidth = e.clientX;
    if (newTableWidth < 100 || window.innerWidth - newTableWidth < 300) return;
    TableAreaWidth.set(newTableWidth)
  }

  function stopDrag() {
    window.removeEventListener('mousemove', handleDrag);
    window.removeEventListener('mouseup', stopDrag);
  }

  function startDrag() {
    window.addEventListener('mousemove', handleDrag);
    window.addEventListener('mouseup', stopDrag);
  }
</script>

<div class="chart-container">
  <div class="table-area" style="width: {$TableAreaWidth}px; display: flex">
    <slot name="table-area" />
  </div>
  <div class="resizer" on:mousedown={startDrag}></div>
  <div class="chart-area">
    <slot name="chart-area" />
  </div>
</div>

<style>
  .chart-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    z-index: 60;
  }
  .table-area {
    height: 100%;
    background-color: white;
    border-right: 2px solid #888888;
  }
  .chart-area {
    flex-grow: 1;
    height: 100%;
    background-color: white;
  }
  .resizer {
    width: 5px;
    cursor: ew-resize;
    background-color: #ccc;
    height: 100%;
  }
</style>
