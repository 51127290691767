import {getConfiguration, saveConfiguration} from "../configurationStorage.js";
import {z} from "zod";

/**
 * @typedef {{
 *   styleName?: string | null,
 *   searchKeyword?: string | null,
 * }} IntegratedMasterConfiguration
 */

const VIEW_NAME = "IntegratedMaster"

/**
 * @type {IntegratedMasterConfiguration}
 */
const defaultValue = {
  styleName: null,
  searchKeyword: null,
}

const IntegratedMasterConfigurationSchema = z.object({
  styleName: z.union([
    z.string(),
    z.null(),
  ]),
  searchKeyword: z.union([
    z.string(),
    z.null(),
  ]),
})

/**
 * @param {string} userId
 * @param {string} projectId
 * @returns {IntegratedMasterConfiguration}
 */
export function getIntegratedMasterConfiguration(userId = "default", projectId = "default") {
  return getConfiguration(VIEW_NAME, userId, projectId, defaultValue)
}

/**
 * @param {Partial<IntegratedMasterConfiguration>} configuration
 * @param {string} userId
 * @param {string} projectId
 */
export function saveIntegratedMasterConfiguration(configuration, userId = "default", projectId = "default") {
  const saved = {...defaultValue, ...getIntegratedMasterConfiguration(userId, projectId), ...configuration}
  console.log(userId, projectId, saved)
  try {
    IntegratedMasterConfigurationSchema.parse(saved)
    saveConfiguration(VIEW_NAME, userId, projectId, saved)
  } catch (e) {
    console.log(e)
    console.error("Malformed configuration for DispatchingView")
  }
}
