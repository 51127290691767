import { derived, writable } from "svelte/store";

/**
 * @type {import("svelte").Writable}
 */
/**
 * @typedef {{
 *   CurrentDisplayedStartDate?: Date,
 *   DisplayedEndDate?: Date,
 *   DisplayedRowCount: number,
 *   DisplayedSpanInSeconds?: number,
 *   DisplayedStartDate?: Date,
 *   RowDataFetcher: (rowData: any) => any,
 *   Rows: Array,
 *   RowsDisplayedStart: number,
 *   TableAreaColumnTitle: string,
 *   ValidEndDate?: Date,
 *   ValidStartDate?: Date,
 * }} ChartStoreInitialValues
 */

/**
 * @typedef {{
 *   CurrentDisplayedEndDate: Writable<Date>,
 *   CurrentDisplayedStartDate: Writable<Date>,
 *   DisplayedEndDate: Writable<Date>,
 *   DisplayedRowCount: Writable<number>,
 *   DisplayedSpanInSeconds: Writable<number>,
 *   DisplayedStartDate: Writable<Date>,
 *   RowDataFetcher: Writable<(rowData: any) => any>,
 *   RowsCached: object,
 *   RowsCountTotal: Writable<number>,
 *   RowsCurrentlyDisplayed: Writable<Array>,
 *   RowsDisplayedStart: Writable<number>,
 *   TableAreaColumnTitle: Writable<string>
 *   TableAreaWidth: Writable<number>,
 *   ValidStartDate: Writable<Date>,
 *   ValidEndDate: Writable<Date>,
 * }} ChartStore
 */

/**
 *
 * @param {ChartStoreInitialValues} initialValues
 * @returns {ChartStore}
 */
export function initChartStore(initialValues) {
  const DisplayedStartDate = writable(initialValues.DisplayedStartDate ?? new Date())
  const DisplayedEndDate = writable(initialValues.DisplayedEndDate ?? new Date())
  const CurrentDisplayedStartDate = writable(initialValues.CurrentDisplayedStartDate ?? new Date())
  const ValidStartDate = writable(initialValues.ValidStartDate ?? new Date())
  const ValidEndDate = writable(initialValues.ValidEndDate ?? new Date())
  const DisplayedSpanInSeconds = writable(initialValues.DisplayedSpanInSeconds ?? 604800)

  const RowsDisplayedStart = writable(0);
  const DisplayedRowCount = writable(initialValues?.DisplayedRowCount ?? 5)
  const RowsCountTotal = writable(initialValues.Rows.length ?? 0)
 const TableAreaWidth = writable(200)
  const TableAreaColumnTitle = writable(initialValues.TableAreaColumnTitle)

  // cache
  const RowsCached = writable({})

  // data fetcher
  const RowDataFetcher = writable(initialValues.RowDataFetcher)

  // derivations
  const CurrentDisplayedEndDate = derived([DisplayedSpanInSeconds, CurrentDisplayedStartDate], ([$DisplayedSpanInSeconds, $CurrentDisplayedStartDate]) => {
    return new Date($CurrentDisplayedStartDate.getTime() + $DisplayedSpanInSeconds * 1000)
  })
  const RowsCurrentlyDisplayed = derived([RowsDisplayedStart, DisplayedRowCount], ([$RowsDisplayedStart, $DisplayedRowCount]) => {
    return initialValues.Rows.slice($RowsDisplayedStart, $RowsDisplayedStart + $DisplayedRowCount)
  })

  return {
    CurrentDisplayedStartDate,
    CurrentDisplayedEndDate,
    DisplayedEndDate,
    DisplayedRowCount,
    DisplayedSpanInSeconds,
    DisplayedStartDate,
    RowsCached,
    RowDataFetcher,
    RowsCountTotal,
    RowsCurrentlyDisplayed,
    RowsDisplayedStart,
    TableAreaWidth,
    TableAreaColumnTitle,
    ValidEndDate,
    ValidStartDate,
  }
}
