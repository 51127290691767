import {FetchError} from "./getData.js"

/**
 * POSTs json file
 * @param {string} url
 * @param {"POST"|"PUT"} method
 * @param {string} data
 * @returns {Promise<Response>}
 */
async function myJsonUpdater(url, method, data){
  // try to make the request
  // if it fails, it is network error
  let response
  try {
    response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    })
  } catch (e) {
    throw new FetchError({error: e, type: "error:network"})
  }

  // if the request succeeds, return
  if (response.ok) return response

  // try to parse the response as json
  // if it fails, the reason of the error is unknown
  let json
  try {
    json = await response.json()
  } catch (e) {
    throw new FetchError({error: null, type: "error:unknown"})
  }
  throw new FetchError( {error: json, type: json.type})
}

/**
 * DELETEs
 * @param {string} url
 * @returns {Promise<void>}
 */
async function myDeleter(url) {
  // try to make the request
  // if it fails, it is network error
  let response
  try {
    response = await fetch(url, {
      method: "DELETE",
    })
  } catch (e) {
    throw new FetchError({error: e, type: "error:network"})
  }

  // if the request succeeds, return
  if (response.ok) return

  // try to parse the response as json
  // if it fails, the reason of the error is unknown
  let json
  try {
    json = await response.json()
  } catch (e) {
    throw new FetchError({error: null, type: "error:unknown"})
  }
  throw new FetchError( {error: json, type: json.type})
}

async function postStartEndData(projectId, bodyData) {
  const url = `/api/projects/${projectId}/transactions`;
  return myJsonUpdater(url, "POST", bodyData)
}

async function registerIntegratedMasterTransaction(projectId, data){
  const url= `/api/projects/${projectId}/transactions?folder=IntegratedMaster`;
  return myJsonUpdater(url, "POST", JSON.stringify(data))
}

async function reset(projectId) {
  const url =  `/api/projects/${projectId}/transactions?timestamp=991231-235959`;
  return myDeleter(url)
}

export {
  myJsonUpdater,
  postStartEndData,
  registerIntegratedMasterTransaction,
  reset
};
