<script>
  import {api} from "../../utils/APIClient.js"
  import {useQueryClient} from "@sveltestack/svelte-query"
  import {getNotificationsContext} from "svelte-notifications"
  import PrimaryBorderedButton from "../button/generic/PrimaryBorderedButton.svelte"
  import DangerBorderedButton from "../button/generic/DangerBorderedButton.svelte"
  import ServerAdminChip from "../chips/ServerAdminChip.svelte"
  import {isServerAdmin} from "../../utils/user.js"
  import ChangePasswordDialog from "./ChangePasswordDialog.svelte"
  import EditUserInfoDialog from "../dialog/EditUserInfoDialog.svelte"
  import { translate } from "../../i18n/i18next.js";
  import { tick } from "svelte";

  /**
   * @type {{
   *   id: string,
   *   name: string,
   *   roles: string[],
   * }}
   */
  export let user;
  export let isUserNameAvailable

  let me
  api.me.get().then(data => {me = data})

  const {addNotification} = getNotificationsContext()

  const queryClient = useQueryClient()

  async function onClickDelete() {
    const me = await api.me.get()
    if (me.id === user.id) {
      addNotification({
        text: translate("frontend:pages.admin.users.userDeleteSelfError"),
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      })
      return
    }

    // confirm if the user really intends to do the deletion
    if (!window.confirm(translate("frontend:pages.admin.users.userDeleteConfirmation"))) return

    // dispatch the request
    try {
      await api.users.id(user.id).delete()
      await queryClient.invalidateQueries("/api/users")
      addNotification({
        text: translate("frontend:pages.admin.users.userDeleted"),
        position: "top-right",
        removeAfter: 4000,
        type: "success",
      })
    } catch (e) {
      addNotification({
        text: translate("frontend:pages.admin.users.userDeletionFailed"),
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      })
    }
  }

  let changePasswordDialog
  let isChangePasswordDialogOpen = false
  async function onClickChangePassword() {
    isChangePasswordDialogOpen = true
    await tick()
    changePasswordDialog.showModal()
  }
  async function handleChangePassword(data) {
    try{
      const response = await api.users.id(user.id).password.post(data.newPassword)
      addNotification({
        text: translate("generic.passwordChanged"),
        position: "top-right",
        removeAfter: 4000,
        type: "success",
      })
      return response.ok
    } catch (e) {
      console.log(e)
      addNotification({
        text: translate("generic.passwordChangeFailed"),
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      })
      return false
    }
  }

  let editUserInfoDialog
  let isEditUserInfoDialogOpen = false
  // click handlers for panels
  async function onClickEdit() {
    isEditUserInfoDialogOpen = true
    await tick()
    editUserInfoDialog.showModal()
  }
  async function handleEditUserInfo(name) {
    try {
      const response = await api.users.id(user.id).put({name})
      await queryClient.invalidateQueries("/api/users")
      await queryClient.invalidateQueries(`/api/users/${user.id}`)
      if(response.ok) {
        addNotification({
          text: translate("frontend:pages.admin.users.userUpdated"),
          type: "success",
          position: "top-right",
          removeAfter: 2000,
        })
      } else {
        addNotification({
          text: translate("frontend:pages.admin.users.userUpdateFailed"),
          type: "error",
          position: "top-right",
          removeAfter: 2000,
        })
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }
</script>

<div class="user-container">
  <div class="user-main">
    <div class="title-container">
      <div class="user-name">{user.name}</div>
      {#each user.roles as role}
        {#if role === "admin"}
          <ServerAdminChip/>
        {/if}
      {/each}
    </div>
    <div class="info-container">
      <div class="info-id">
        id: {user.id}
      </div>
    </div>
  </div>
  <div class="user-actions-container">
    <PrimaryBorderedButton onClick={onClickEdit}>
      {translate("generic.edit")}
    </PrimaryBorderedButton>
    <PrimaryBorderedButton disabled={isServerAdmin(user)} onClick={onClickChangePassword}>
      {translate("generic.passwordChange")}
    </PrimaryBorderedButton>
    <DangerBorderedButton disabled={me?.id === user.id} onClick={onClickDelete}>
      {translate("generic.remove")}
    </DangerBorderedButton>
  </div>
  {#if isEditUserInfoDialogOpen}
    <EditUserInfoDialog
      bind:dialog={editUserInfoDialog}
      isUsernameAvailable={isUserNameAvailable}
      onClose={() => {editUserInfoDialog.close(); isEditUserInfoDialogOpen = false}}
      onSubmit={handleEditUserInfo}
      user={user}
    />
  {/if}
  {#if isChangePasswordDialogOpen}
    <ChangePasswordDialog
      bind:dialog={changePasswordDialog}
      onClose={() => {changePasswordDialog.close(); isChangePasswordDialogOpen = false}}
      onSubmit={handleChangePassword}
      requireOldPassword={false}
    />
  {/if}
</div>

<style>
  .user-container {
    padding: 1rem 0;
    border-bottom: 1px solid lightgrey;
    display: flex;
  }
  .user-container:is(:first-child) {
    border-top: 1px solid lightgrey;
  }
  .user-main {
    flex-grow: 1;
  }
  .user-actions-container {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .user-name {
    font-size: 1.1rem;
  }
  .info-container {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.9rem;
    color: darkgray;
  }
</style>
