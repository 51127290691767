<script>
  import {useProjectUsers} from "../../hooks/useProjectUsers.js"
  import {useParams} from "svelte-navigator"
  import ProjectUsersList2EntryWithPermissionEdit from "./ProjectUsersList2EntryWithPermissionEdit.svelte"
  import {writable, derived} from 'svelte/store'
  import {translate} from "../../i18n/i18next.js"

  const params = useParams()
  const projectId = $params.project
  const projectUsers = useProjectUsers(projectId)
  const sortOrder = writable('name_asc');  // Default sort order

  const sortedProjectUsers = derived([projectUsers, sortOrder], ([$projectUsers, $sortOrder]) => {
    if ($sortOrder === 'name_asc') {
      return $projectUsers.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if ($sortOrder === 'name_desc') {
      return $projectUsers.slice().sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    } else if ($sortOrder === 'created_at_asc') {
      return $projectUsers;
    } else if ($sortOrder === 'created_at_desc') {
      return $projectUsers.slice().reverse();
    }
  });
</script>

<div style="text-align: right;">
  <label for="sort-order">{translate("generic.sortOrder")}</label>
  <select bind:value={$sortOrder} style="width: 125px" id="sort-order">
    <option value="name_asc">{translate("generic.sortOrders.nameAsc")}</option>
    <option value="name_desc">{translate("generic.sortOrders.nameDesc")}</option>
    <option value="created_at_asc">{translate("generic.sortOrders.createdAtAsc")}</option>
    <option value="created_at_desc">{translate("generic.sortOrders.createdAtDesc")}</option>
  </select>
</div>

<div>
  {#each $sortedProjectUsers as user}
    <ProjectUsersList2EntryWithPermissionEdit
      projectId={projectId}
      user={user}
    />
  {:else}
    <div>
      {translate("frontend:pages:projects.project.admin.sections.users.list.noUser")}
    </div>
  {/each}
</div>

