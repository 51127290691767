<script>
  import SelectPermission from "./SelectPermission.svelte"
  import GithubLikeButton from "../button/GithubLikeButton.svelte"
  import { translate } from "../../i18n/i18next.js";

  export let onAdded
  export let disabled = false

  let permission = "ReadOnly"
  function handleSelection(event) {
    permission = event.target.value
  }
</script>

<div>
  <SelectPermission permission={permission} onChange={handleSelection} />
  <GithubLikeButton
    color="green"
    label={translate("generic.add")}
    disabled={disabled}
    onClick={()=>onAdded(permission)}
  />
</div>
