<script>
  export let title;
</script>

<article id="root">
  <div id="headline-container">
    <div id="headline-middle">
      <img src="/icon-192.png" alt="Asprova My Schedule Icon" />
      <span class="header-title">
        {title}
      </span>
    </div>
  </div>
</article>

<style>
  #root {
    position: sticky;
    top: 0;
    z-index: 100;
  }
  #headline-container {
    background-color: #e9e9f0;
    border-bottom: solid #cdcdcd;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #headline-middle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  #headline-middle > img {
    height: 60px;
    aspect-ratio: 1;
  }
  .header-title {
    font-size: x-large;
    font-weight: bold;
  }
</style>
