export function isDemoServerGuest(user) {
  switch (user?.id) {
    case "legacy-Guest":
    case "legacy-GuestEN":
    case "legacy-GuestZH":
      return true
    default:
      return false
  }
}

export function isDemoServerGuestUsername(username) {
  switch (username) {
    case "Guest":
    case "GuestEN":
    case "GuestZH":
      return true
    default:
      return false
  }
}

export function getGuestUsernameForLanguage(language) {
  switch (language) {
    case "ja":
      return "Guest"
    case "en":
      return "GuestEN"
    case "zh":
      return "GuestZH"
    default:
      return ""
  }
}
