import {getConfiguration, saveConfiguration} from "../configurationStorage.js"
import {z} from "zod"

/**
 * @typedef {{
 *   usingCompactMode?: boolean | null,
 *   itemPerPage?: number | null
 * }} OperationTableConfiguration
 */

const VIEW_NAME = "OperationTable"

/**
 * @type {OperationTableConfiguration}
 */
const defaultValue = {
  usingCompactMode: false,
  itemPerPage: 10
}

const OperationTableConfigurationSchema = z.object({
  usingCompactMode: z.union([
    z.boolean(),
    z.null()
  ]),
  itemPerPage: z.union([
    z.number(),
    z.null(),
  ]),
})

/**
 * @param {string} userId
 * @param {string} projectId
 * @returns {Promise<OperationTableConfiguration>}
 */
export async function getOperationTableConfiguration(userId, projectId) {
  return getConfiguration(VIEW_NAME, userId, projectId, defaultValue)
}

/**
 * @param {Partial<OperationTableConfiguration>} configuration
 * @param {string} userId
 * @param {string} projectId
 */
export async function saveOperationTableConfiguration(configuration, userId, projectId) {
  const saved = {...defaultValue, ...(await getOperationTableConfiguration(userId, projectId)), ...configuration}
  try {
    OperationTableConfigurationSchema.parse(saved)
    saveConfiguration(VIEW_NAME, userId, projectId, saved)
  } catch (e) {
    console.log(e)
    console.error("Malformed configuration for OperationTable")
  }
}
