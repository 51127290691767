<script>
  import DialogBase from "../dialog/DialogBase.svelte";
  import DialogTitle from "../dialog/DialogTitle.svelte";
  import {translate} from "../../i18n/i18next.js";
  import DialogSplitter from "../dialog/DialogSplitter.svelte";
  import DialogContent from "../dialog/DialogContent.svelte";
  import DialogBottomControls from "../dialog/DialogBottomControls.svelte";
  import DialogBottomControlButton from "../dialog/DialogBottomControlButton.svelte";
  import FilterInput from "../input/FilterInput.svelte";
  import GeneralSelect from "../select/GeneralSelect.svelte";
  import RadioGroup from "../input/RadioGroup.svelte";
  import { onMount, tick } from "svelte";

  /**
   * @typedef {
   *   | "equals"
   *   | "doesNotEqual"
   *   | "contains"
   *   | "doesNotContain"
   *   | "starts"
   *   | "doesNotStart"
   *   | "ends"
   *   | "doesNotEnd"
   *  } Condition
   */
  /**
   * @type {string}
   */
  export let filterTarget
  /**
   * Choices displayed when the user is inputting.
   * @type {string[]|(() => string[])}
   */
  export let choices = []
  /**
   * Optional prop that will be used to determine a candidate's match to the query.
   * If left unspecified, partial string match will be used.
   * @param {string} choice
   * @param {string} query
   */
  export let matcher = (choice, query) => choice.indexOf(query) !== -1
  export let dialog
  export let onClearFilter = () => {}
  /**
   * Called when the dialog's OK button is pressed
   * @param {{
   *   left: {
   *     query: string,
   *     type: Condition
   *   },
   *   right: {
   *     query: string,
   *     type: Condition,
   *   },
   *   combinator: "and" | "or"
   * }} filterObj
   */
  export let onFilterDetermined = filterObj => {}
  export let initialFilterObj

  let leftFilterInput = null
  let rightFilterInput = null

  export function setInternalStates(filterObj) {
    console.log(filterObj)
    leftQuery = filterObj?.left?.query ?? ""
    rightQuery = filterObj?.right?.query ?? ""
    leftCondition = filterObj?.left?.type ?? "contains"
    rightCondition = filterObj?.right?.type ?? "contains"
    combinator = filterObj?.combinator ?? "and"

    if (leftFilterInput) leftFilterInput.setQuery(leftQuery)
    if (rightFilterInput) rightFilterInput.setQuery(rightQuery)
  }

  // internal states
  // if a query string is empty, it will be ignored.
  let leftQuery = ""
  let rightQuery = ""

  /**
   * @type {Condition}
   */
  let leftCondition = "contains"
  /**
   * @type {Condition}
   */
  let rightCondition = "contains"
  /**
   * @type {"and"|"or"}
   */
  let combinator = "and"
  setInternalStates(initialFilterObj)

  const conditionOptions = [
    {translationKey: "frontend:components.filterDialog.conditions.equals", value: "equals"},
    {translationKey: "frontend:components.filterDialog.conditions.doesNotEqual", value: "doesNotEqual"},
    {translationKey: "frontend:components.filterDialog.conditions.contains", value: "contains"},
    {translationKey: "frontend:components.filterDialog.conditions.doesNotContain", value: "doesNotContain"},
    {translationKey: "frontend:components.filterDialog.conditions.starts", value: "starts"},
    {translationKey: "frontend:components.filterDialog.conditions.doesNotStart", value: "doesNotStart"},
    {translationKey: "frontend:components.filterDialog.conditions.ends", value: "ends"},
    {translationKey: "frontend:components.filterDialog.conditions.doesNotEnd", value: "doesNotEnd"},
  ]
  const combinatorOptions = [
    {translationKey: "frontend:components.filterDialog.combinators.and", value: "and"},
    {translationKey: "frontend:components.filterDialog.combinators.or", value: "or"},
  ]

  function onClickDetermineFilter(event) {
    event.preventDefault()

    const conditions = {
      left: {
        query: leftQuery,
        type: leftCondition,
      },
      right: {
        query: rightQuery,
        type: rightCondition,
      },
      combinator,
    }
    onFilterDetermined(conditions)

    // clear inputs
    leftQuery = ""
    leftCondition = "contains"
    rightQuery = ""
    rightCondition = "contains"
    combinator = "and"
  }

  onMount(async () => {
    await tick()

    if (leftCondition) leftFilterInput?.setQuery(leftQuery)
    if (rightCondition) rightFilterInput?.setQuery(rightQuery)
  })
</script>

<DialogBase bind:dialog={dialog}>
  <DialogTitle title={translate("frontend:components.filterDialog.title", null, {target: filterTarget})} />
  <DialogSplitter />
  <DialogContent>
    <input style="display: none" />
    <div class="filter-dialog-condition-item">
      <div class="filter-dialog-condition-item-query">
        <FilterInput
          bind:this={leftFilterInput}
          onQueryDetermined={(q) => {leftQuery = q}}
          choices={choices}
          displayAdvancedFilter={false}
          matcher={matcher}
        />
      </div>
      <div class="filter-dialog-condition-item-condition">
        <GeneralSelect
          bind:value={leftCondition}
          options={conditionOptions}
        />
      </div>
    </div>
    <div class="filter-dialog-combinator">
      <RadioGroup bind:value={combinator} options={combinatorOptions} name="combinator" />
    </div>
    <div class="filter-dialog-condition-item">
      <div class="filter-dialog-condition-item-query">
        <FilterInput
          bind:this={rightFilterInput}
          onQueryDetermined={(q) => {rightQuery = q}}
          choices={choices}
          displayAdvancedFilter={false}
          matcher={matcher}
        />
      </div>
      <div class="filter-dialog-condition-item-condition">
        <GeneralSelect
          bind:value={rightCondition}
          options={conditionOptions}
        />
      </div>
    </div>
  </DialogContent>
  <DialogSplitter />
  <DialogBottomControls>
    <DialogBottomControlButton type="secondary" onClick={onClearFilter}>
      {translate("generic.clear")}
    </DialogBottomControlButton>
    <DialogBottomControlButton type="primary" onClick={onClickDetermineFilter}>
      {"OK"}
    </DialogBottomControlButton>
  </DialogBottomControls>
</DialogBase>

<style>
  .filter-dialog-condition-item {
    display: flex;
    gap: .5rem;
    justify-content: space-between;
  }
  .filter-dialog-condition-item-query {
    flex: 1;
  }
</style>