<script>
  import GeneralButton from "../../components/button/GeneralButton.svelte"
  import { I18N_GetString } from "../../utils/i18n"
  import { usePageContext } from "../../components/pages/pageContext.js"
  import {
    getDispatchingViewProfile1Configuration,
    saveDispatchingViewProfile1Configuration
  } from "../../utils/configurationStorage/views/DispatchingViewProfile1ConfigurationStorage.js"
  import { navigateI18n } from "../../i18n/navigate.js";

  export let projectId = ""
  export let resources = null
  export let resourceGroup = {}
  export let resetTransactions = null
  export let user

  const {setHeaderProps} = usePageContext()
  setHeaderProps({title: I18N_GetString("担当者CD選択")})

  let operations = []
  $: {
    if (resources) {
      operations = [...resources.Rows.sort((a, b) => {
        if (a[3] < b[3]) {
          return -1;
        } else if (a[3] > b[3]) {
          return 1;
        } else {
          return 0;
        }
      })]
    }
  }
  let selected = "未指定"

  $: selectableResources = determineSelectableResources(resources?.Rows ?? [], resourceGroup)

  function determineSelectableResources(resources, resourceGroup) {
    const resourcesInResourceGroup = new Set(Object.values(resourceGroup).flat())
    const returned = resources.filter(resource => resourcesInResourceGroup.has(resource[0]))
    returned.sort((a, b) => {
      if (a[3] < b[3]) {
        return -1;
      } else if (a[3] > b[3]) {
        return 1;
      } else {
        return 0;
      }
    })
    return returned
  }

  async function onReset() {
    if (!resetTransactions) return

    const input = window.prompt(I18N_GetString("ResetPrompt"));
    if(input === "Reset") {
      alert(I18N_GetString("ResetConfirm"))
      await resetTransactions()
    }else{
      alert(I18N_GetString("ResetDeny"))
    }
  }
  const configuration = getDispatchingViewProfile1Configuration(user?.id, projectId)
  if (configuration.lastUsedResourceObjectID) {
    selected = configuration.lastUsedResourceObjectID
  }

  function gotoDetail() {
    if (selected !== "未指定") {
      saveDispatchingViewProfile1Configuration({...configuration, lastUsedResourceObjectID: selected}, user?.id, projectId)
      navigateI18n(`/projects/${projectId}/DispatchingView_Profile1/${selected}`)
    }
  }
</script>

<!-- Reset Button -->
<div style="display: flex; margin-top: 25px">
  <GeneralButton
    buttonFunction={onReset}
    buttonText={"すべての実績をリセット"}
    width={"100"}
    height={"30"}
    buttonType="negative"
  />
</div>

<!-- Select box and confirm button -->
<div id="center-container">
  <div>
    担当者CD:
  </div>
  <!-- Select box -->
  <select id="group-selector" bind:value={selected}>
    {#if configuration.lastUsedResourceObjectID && resources && resources.Rows.find((r) => r[4] == configuration.lastUsedResourceObjectID)}
      <option value={configuration.lastUsedResourceObjectID}>前回の資源: {resources.Rows.find((r) => r[4] == configuration.lastUsedResourceObjectID)[0]}</option>
    {:else}
      <option value={"未指定"}>未指定</option>
    {/if}
    {#each selectableResources as col, _}
      <option value={col[4]}>{col[2]}&nbsp;&nbsp;&nbsp;&nbsp;{col[0]}</option>
    {:else}
      <option value="">{I18N_GetString("500")}</option>
    {/each}
  </select>

  <!-- Confirm button -->
  <GeneralButton buttonText={"OK"} buttonFunction={gotoDetail}/>
</div>

<style>
  #center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #group-selector {
    color: #6280ba;
    border-color: #6280ba;
    border-radius: 5px;
    text-align: center;
    width: 600px;
    height: 30px;
    margin-left: 25px;
  }
  @media (max-width: 992px) {
    #group-selector {
      width: 90%;
    }
  }

</style>
