/**
 * Checks if input operations are allowed to a user with the permission
 * @param {ProjectPermission|null} permission
 */
export function isInputAllowed(permission) {
  switch (permission) {
    case "ServerAdmin":
    case "ProjectAdmin":
    case "ResultInput":
      return true
    default:
      return false
  }
}