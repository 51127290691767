import {useQuery} from "@sveltestack/svelte-query"
import {FetchError, fetchResourceGroup} from "../../../../utils/getData.js"

/**
 * svelte query hook for Dispatching view resource group
 * @param {string} projectId
 * @param fetchErrorHandler
 * @param {false|number} refetchInterval
 */
export function useDispatchingViewResourceGroupQuery(projectId, fetchErrorHandler, refetchInterval = false) {
  return useQuery(`/api/projects/${projectId}/DispatchingView/resGroup.json`, () => fetchResourceGroup(projectId), {
    onError: err => {
      console.log(err)
      if (err instanceof FetchError) {
        fetchErrorHandler(err.error)
      }
    },
    retry: false,
    refetchInterval,
  })
}