<script>
  import { navigate, useLocation } from "svelte-navigator"
  import { useMe } from "../../hooks/useMe.js"
  import { isServerAdmin } from "../../utils/user.js"
  import { getNotificationsContext } from "svelte-notifications"
  import AdminSidePanel from "../../components/layout/pageLayouts/admin/AdminSidePanel.svelte"
  import { usePageContext } from "./pageContext.js"
  import DemoServerBanner from "../../components/demo/DemoServerBanner.svelte";
  import Loading from "../../components/pages/Loading.svelte";
  import { useMyProjects } from "../../hooks/useMyProjects.js";
  import { derived } from "svelte/store";
  import DemoProjectsListTitle from "../demo/DemoProjectsListTitle.svelte";
  import DemoProjectsListWithPricing from "../demo/DemoProjectsListWithPricing.svelte";
  import DemoProjectsList from "../demo/DemoProjectsList.svelte";
  import { translate } from "../../i18n/i18next.js";

  const version = "__MY_SCHEDULE_VERSION__"

  const me = useMe()
  const {addNotification} = getNotificationsContext()
  const location = useLocation()
  const {setHeaderProps} = usePageContext()
  setHeaderProps({title: `Asprova My Schedule (v${version})`})

  async function getUsernameAndProjects() {
    const response = await fetch("/api/me")
    if (!response.ok) {
      navigate("/login")
      return
    }
    const data = await response.json()
    const projects = await fetch("/api/me/projects").then(res => res.json()).then(data => data.projects)
    return {
      username: data.name,
      projects,
    }
  }


  // process invitations
  async function handleInvitation() {
    const search = $location.search
    const urlSearchParams = new URLSearchParams(search.slice(1))
    const invitationId = urlSearchParams.get("invitation")
    if (!invitationId) return

    const response = await fetch(`/api/projects/invitations/${invitationId}/accept`, {
      method: "POST",
    })
    if (response.ok) {
      addNotification({
        text: translate("frontend:pages.index.joinedProjectWithInvitation"),
        position: "top-right",
        type: "success",
        removeAfter: 4000,
      })
    }
  }
  handleInvitation()

  const myProjects = useMyProjects()
  const projectsBySection = derived(myProjects, $myProjects => {
    const sections = {
      withPricing: [],
      withHugeData: [],
      rest: []
    }
    for (const project of ($myProjects ?? [])) {
      switch (project.id) {
        case "legacy-Guest-Project1":
        case "legacy-Guest-Project2":
        case "legacy-Guest-Project3":
        case "legacy-GuestEN-Project1":
        case "legacy-GuestEN-Project2":
        case "legacy-GuestEN-Project3":
        case "legacy-GuestZH-Project1":
        case "legacy-GuestZH-Project2":
        case "legacy-GuestZH-Project3":
          sections.withPricing.push(project)
          break
        case "legacy-Guest-Project4":
        case "legacy-GuestEN-Project4":
        case "legacy-GuestZH-Project4":
          sections.withHugeData.push(project)
          break
        default:
          sections.rest.push(project)
      }
    }
    return sections
  })
</script>

{#await getUsernameAndProjects()}
  <Loading/>
{:then data}
  <div id="root">
    <div id="main-container">
      {#if isServerAdmin($me)}
        <AdminSidePanel />
      {/if}
      <div id="content-container">
        <p class="demo-text">
          {translate("frontend:demo.pages.index.myScheduleDescription.1")}<br />
          {translate("frontend:demo.pages.index.myScheduleDescription.2")}
        </p>
        <DemoProjectsListTitle>
          {translate("frontend:demo.pages.index.projectsByPlans.caption")}
        </DemoProjectsListTitle>
        <DemoProjectsListWithPricing projects={$projectsBySection.withPricing} />
        {#if $projectsBySection.withHugeData.length > 0}
          <DemoProjectsListTitle>
            {translate("frontend:demo.pages.index.projectsWithHugeData.caption")}
          </DemoProjectsListTitle>
          <DemoProjectsList projects={$projectsBySection.withHugeData} />
        {/if}
        {#if $projectsBySection.rest.length > 0}
          <DemoProjectsListTitle>
            {translate("frontend:demo.pages.index.projectsOthers.caption")}
          </DemoProjectsListTitle>
          <DemoProjectsList projects={$projectsBySection.rest} />
        {/if}
      </div>
    </div>
  </div>
{/await}
<div style="height: 120px;min-height: 120px;background-color: white"></div>
<!-- Display the banner only on the Japanese version -->
{#if $me.id === "legacy-Guest"}
  <DemoServerBanner />
{/if}

<style>
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: white;
  }
  #main-container {
    display: flex;
    height: 100%;
  }
  #content-container {
    padding: 1.5rem 1.5rem 0 1.5rem;
    background-color: white;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
  }
  .demo-text {
    line-height: 2rem;
  }
</style>
