<script>
  import {getContext} from "svelte"
  import {useDispatchingViewStylesQuery} from "../../../../../hooks/project/views/dispatchingView/useDispatchingViewStylesQuery.js"
  import {useDispatchingViewResourceDetailQuery} from "../../../../../hooks/project/views/dispatchingView/useDispatchingViewResourceDetailQuery.js"
  import FetchTransactions from "./FetchTransactions.svelte"
  import {useDispatchingViewResourceGroupQuery} from "../../../../../hooks/project/views/dispatchingView/useDispatchingViewResourceGroupQuery.js"
  import {useDispatchingViewResourcesQuery} from "../../../../../hooks/project/views/dispatchingView/useDispatchingViewResourcesQuery.js"

  // props
  /**
   * @type {string}
   */
  export let projectId
  /**
   * @type {string}
   */
  export let itemCode
  /**
   * This prop will determine at what interval the data will be refetched.
   * Set this to "false" to disable refetching, or provide refetching interval in milliseconds.
   * @type {false|number}
   */
  export let refetchInterval = false

  // error handlers
  const {handleFetchErrors} = getContext("fetchErrorHandler")

  // svelte-query
  const resourcesQueryResult = useDispatchingViewResourcesQuery(projectId, handleFetchErrors, refetchInterval)
  const stylesQueryResult = useDispatchingViewStylesQuery(projectId, handleFetchErrors, refetchInterval)
  const resourceDetailQueryResult = useDispatchingViewResourceDetailQuery(projectId, itemCode, handleFetchErrors, refetchInterval)
  const resourceGroupQueryResult = useDispatchingViewResourceGroupQuery(projectId, handleFetchErrors, refetchInterval)

  $: lastDownloadedTimeStamp = $resourceDetailQueryResult.data?.DownloadTimeStamp
</script>

<FetchTransactions
  projectId={projectId}
  downloadTimestamp={lastDownloadedTimeStamp}
  refetchInterval={refetchInterval}
  let:transactions
  let:reloadTransactions
  let:resetTransactions
  let:isInputAllowed
>
  <slot
    projectId={projectId}
    resources={$resourcesQueryResult.data}
    styles={$stylesQueryResult.data}
    resourceDetail={$resourceDetailQueryResult.data}
    resourceGroup={$resourceGroupQueryResult.data}
    transactions={transactions}
    reloadTransactions={reloadTransactions}
    resetTransactions={resetTransactions}
    isInputAllowed={isInputAllowed}
  />
</FetchTransactions>