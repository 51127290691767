<script>
  import OperationTableDetails from "../../../../routes/OperationTable/OperationTableDetails.svelte";
  import AuthenticationRequired from "../../../../components/functional/AuthenticationRequired.svelte";
  import ProjectPageBase from "../../../../components/pages/projects/[project]/ProjectPageBase.svelte";
  import CheckDataStatus from "../../../../components/pages/projects/[project]/CheckDataStatus.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase  parentView="OperationTable" let:projectId let:code let:user let:permission>
    <CheckDataStatus projectId={projectId} viewName="OperationTable">
      <OperationTableDetails
        projectId={projectId}
        code={code}
        user={user}
        permission={permission}
      />
    </CheckDataStatus>
  </ProjectPageBase>
</AuthenticationRequired>
