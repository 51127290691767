<script>
  export let title
</script>

<span class="chip">
  {title}
</span>

<style>
  .chip {
    border-radius: 0.8rem;
    font-size: 0.8rem;
    border: 1px solid lightgrey;
    padding: 0 0.2rem;
    color: darkgray;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: white;
    white-space: nowrap;
  }
</style>