<script>
  import { getContext } from "svelte";

  const pageContext = getContext("page")
</script>

<div class="display-versions">
  Ver. F_{$pageContext.versions.frontend}&nbsp;
  {#if $pageContext.versions.backend}B_{$pageContext.versions.backend}&nbsp;{/if}
  {#if $pageContext.versions.plugin}P_{$pageContext.versions.plugin}&nbsp;{/if}
</div>

<style>
  .display-versions {
    position: absolute;
    bottom: 5px;
    right: 20px;
    color: darkgray;
    font-size: 11px;
    z-index: 1000;
  }
</style>