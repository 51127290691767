<script>
  import { useParams } from "svelte-navigator";
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import dayjs from "dayjs";
  import { fetchProductionWorks, fetchProductionOrders, fetchProductionOrderData, fetchTransactionData, fetchProjectData } from "../../utils/getData.js"
  import { postStartEndData } from "../../utils/putData.js"
  import PageSelector from "../../components/layout/PageSelector.svelte"
  import { guestCurrentPage } from "../../utils/variable";
  import { saveConfiguration, getConfiguration } from "../../utils/configurationStorage/configurationStorage.js";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import Loading from "../../components/pages/Loading.svelte";

  import { getNotificationsContext } from "svelte-notifications";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import GeneralSelect from "../../components/select/GeneralSelect.svelte";
  const { addNotification } = getNotificationsContext();

  let uploadTimeStamp = undefined;

  const params = useParams();
  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({ title: "生産指示書" });
  $: setHeaderProps({ uploadTime: uploadTimeStamp});

  const project = $params.project;
  const token = null

  let ProductionWorks = null;
  let ProductionOrders = null;
  let TransactionData = null;
  let ProductionOrderDatas = null;
  let all_tableData = [];
  let filter_applied = false;
  let filtered_tableData = [];
  let tableData = [];
  let max_work_num = 1;
  let totalPageNumber = -1;
  const rowsPerPage = 10;
  let currentPage;
  guestCurrentPage.subscribe((val) => {
    currentPage = val;
    tableData = filtered_tableData.slice((currentPage - 1) * rowsPerPage, Math.min(currentPage * rowsPerPage, filtered_tableData.length));
  });
  let disable_buttons = false;  // While sending data, disable buttons

  // State for group visibility
  let visibility = writable({
    group1: true,
    group2: true,
    group3: true
  });
  // Function to toggle visibility
  function toggleGroupVisibility(group) {
    visibility.update(v => {
      v[group] = !v[group];
      return v;
    });
  }

  // Define columns
  let group1_cols = [
    {
      "key": "WorkUser_TaSeiban",
      "name": "製番",
      "width": 90,
      "group": "group1"
    },
    {
      "key": "Work_OrderItem",
      "name": "品目",
      "width": 90,
      "group": "group1"
    },
    {
      "key": "Work_OrderItemName",
      "name": "品番<br>品名",
      "width": 125,
      "group": "group1"
    }
  ];
  let group2_cols = [
    {
      "key": "Order_Qty",
      "name": "製造<br>数量",
      "width": 60,
      "group": "group2"
    },
    {
      "key": "Order_LET",
      "name": "納期",
      "width": 60,
      "group": "group2"
    },
    {
      "key": "Work_OperationMainRes",
      "name": "資源名",
      "width": 90,
      "group": "group2"
    },
    {
      "key": "Work_OperationProductionStartTime",
      "name": "製造<br>開始日時",
      "width": 125,
      "group": "group2"
    },
    {
      "key": "Work_OperationProductionEndTime",
      "name": "製造<br>終了日時",
      "width": 125,
      "group": "group2"
    },
    {
      "key": "Work_ResultStartTime",
      "name": "実績<br>開始日時",
      "width": 125,
      "group": "group2"
    },
    {
      "key": "Work_ResultEndTime",
      "name": "実績<br>終了日時",
      "width": 125,
      "group": "group2"
    },
    {
      "key": "completedReset",
      "name": "着完<br>削除",
      "width": 110,
      "group": "group2"
    }
  ];
  let group3_cols = [
    {
      "key": "Order_Code",
      "name": "オーダ",
      "width": 120,
      "group": "group3"
    },
    {
      "key": "Work_OperationProcNo",
      "name": "工順",
      "width": 75,
      "group": "group3"
    },
    {
      "key": "Work_OperationProcName",
      "name": "工程",
      "width": 75,
      "group": "group3"
    },
    {
      "key": "Work_Lateness",
      "name": "遅れ<br>日数",
      "width": 75,
      "group": "group3"
    }
  ];

  // State for filter inputs
  let filter_year = ""; // for '加工年'
  let filter_years = [];
  let filter_month = ""; // for '加工月'
  let filter_months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  let filter_process = ""; // for '工程'
  let filter_processes = []
  let filter_Work_OrderItemName = ""; // for '品番'
  let filter_Work_OrderItemNames = [];
  let filter_order = ""; // for 'オーダ'
  let filter_orders = [];
  let filter_completed = "true"; // for '完了品'
  // Function to filter table data
  function filterByConditions() {
    if (filter_year === "" && filter_month === "" && filter_process === "" && filter_Work_OrderItemName === "" && filter_order === "" && filter_completed === "true") {
      filtered_tableData = [];
      totalPageNumber = 0;
      guestCurrentPage.set(1);
      tableData = [];
      filter_applied = false;
      // Save filter_process, filter_Work_OrderItemName, filter_order, and filter_completed
      const configuration = {
        "filter_process": filter_process,
        "filter_Work_OrderItemName": filter_Work_OrderItemName,
        "filter_order": filter_order,
        "filter_completed": filter_completed,
        "group1_cols": group1_cols,
        "group2_cols": group2_cols,
        "group3_cols": group3_cols,
      }
      saveConfiguration("ProductionOrder", "default", "default", configuration);
      return;
    }

    // Check if each order is completed
    let unique_orders = new Set();
    for (let i = 0; i < all_tableData.length; i++) {
      unique_orders.add(all_tableData[i].Order_Code);
    }
    let order_completed_map = {};
    for (let order of unique_orders) {
      order_completed_map[order] = true;
    }
    for (let i = 0; i < all_tableData.length; i++) {
      let row = all_tableData[i];
      if (row.Work_ResultEndTime === "") {
        order_completed_map[row.Order_Code] = false;
      }
    }

    filtered_tableData = [];
    for (let i = 0; i < all_tableData.length; i++) {
      let row = all_tableData[i];
      if (filter_year && Number(row.Work_OperationProductionStartTime.replace('<span class="date-part">', '').split("/")[0]) !== Number(filter_year)) {
        continue;
      }
      if (filter_month && Number(row.Work_OperationProductionStartTime.replace('<span class="date-part">', '').split("/")[1]) !== Number(filter_month)) {
        continue;
      }
      if (filter_process && row.Work_OperationProcName !== filter_process) {
        continue;
      }
      if (filter_Work_OrderItemName && row.Work_OrderItemName !== filter_Work_OrderItemName) {
        continue;
      }
      if (filter_order && row.Order_Code !== filter_order) {
        continue;
      }
      if (filter_completed === "false" && order_completed_map[row.Order_Code]) {
        continue;
      }
      filtered_tableData.push(row);
    }
    totalPageNumber = Math.ceil(filtered_tableData.length / rowsPerPage);
    guestCurrentPage.set(Math.max(1, Math.min(currentPage, totalPageNumber)));
    tableData = filtered_tableData.slice((currentPage - 1) * rowsPerPage, Math.min(currentPage * rowsPerPage, filtered_tableData.length));
    filter_applied = true;

    // Save filter_process, filter_Work_OrderItemName, filter_order, and filter_completed
    const configuration = {
      "filter_process": filter_process,
      "filter_Work_OrderItemName": filter_Work_OrderItemName,
      "filter_order": filter_order,
      "filter_completed": filter_completed,
      "group1_cols": group1_cols,
      "group2_cols": group2_cols,
      "group3_cols": group3_cols,
    }
    saveConfiguration("ProductionOrder", "default", "default", configuration);
  }

  // Function to delete completed works
  async function deleteCompleted(row) {
    if (!confirm("着完を削除しますか？")) {
      return;
    }

    let payload;
    if (row.Work_ResultEndTime === "") {
      // Delete Work_ResultStartTime
      payload = JSON.stringify({
        [row.Work_OperationCode]: {
          "Work_Status": "A",
          "Work_ResultStartTime": "",
          "Work_ResultEndTime": "",
        }
      });
    } else {
      // Delete Work_ResultEndTime
      let dateTime = row.Work_ResultStartTime.replace('<span class="date-part">', '');
      dateTime = dateTime.replace('<span class="time-part">', '');
      dateTime = dateTime.replace(/<\/span>/g, '');
      let [datePart, timePart] = dateTime.split(" ");
      payload = JSON.stringify({
        [row.Work_OperationCode]: {
          "Work_Status": "T",
          "Work_ResultStartTime": datePart + " " + timePart,
        }
      });
    }

    disable_buttons = true;
    try {
      await postStartEndData(project, payload);
      await refresh();
      disable_buttons = false;
    } catch (e) {
      addNotification({
        text: "着完の削除に失敗しました。",
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      })
      disable_buttons = false;
    }
  }

  // Function to send result time
  async function sendResultTime(row, isStart) {
    const currentDatetime = dayjs().format("YYYY/MM/DD HH:mm:ss");
    if (!isStart && row.Work_ResultStartTime === "") {
      addNotification({
        text: "開始していない作業です。",
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      })
      return;
    }

    const payload = JSON.stringify({
      [row.Work_OperationCode]: {
        "Work_Status": isStart ? "T" : "B",
        "Work_ResultStartTime": isStart ? currentDatetime : undefined,
        "Work_ResultEndTime": isStart ? undefined : currentDatetime,
      }
    });

    disable_buttons = true;
    try {
      await postStartEndData(project, payload);
      await refresh();
      disable_buttons = false;
    } catch (e) {
      addNotification({
        text: "実績の送信に失敗しました。",
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      })
      disable_buttons = false;
    }
  }

  function get_style(row, col) {
    if (col.key == "Work_Lateness") {
      if (row == null || row[col.key] <= 0) {
        return `width: ${col.width}px;`;
      } else {
        return `width: ${col.width}px; color: red; font-weight: bold;`;
      }
    } else {
      return `width: ${col.width}px;`
    }
  }

  let dragging_col_key = null;
  function handleDrag(e) {
    // get th of col_key left x position
    const th = document.getElementById(`th_${dragging_col_key}`);
    const thRect = th.getBoundingClientRect();
    const thLeft = thRect.left;

    const newColWidth = e.clientX - thLeft - 18;
    if (newColWidth < 50) {
      console.log("newColWidth < 50")
      return;
    }

    for (let i = 0; i < group1_cols.length; i++) {
      if (group1_cols[i].key === dragging_col_key) {
        group1_cols[i].width = newColWidth;
        return;
      }
    }
    for (let i = 0; i < group2_cols.length; i++) {
      if (group2_cols[i].key === dragging_col_key) {
        group2_cols[i].width = newColWidth;
        return;
      }
    }
    for (let i = 0; i < group3_cols.length; i++) {
      if (group3_cols[i].key === dragging_col_key) {
        group3_cols[i].width = newColWidth;
        return;
      }
    }
  }

  function stopDrag(e) {
    dragging_col_key = null;
    window.removeEventListener('mousemove', handleDrag);
    window.removeEventListener('mouseup', stopDrag);
    // Save filter_process, filter_Work_OrderItemName, filter_order, and filter_completed
    const configuration = {
      "filter_process": filter_process,
      "filter_Work_OrderItemName": filter_Work_OrderItemName,
      "filter_order": filter_order,
      "filter_completed": filter_completed,
      "group1_cols": group1_cols,
      "group2_cols": group2_cols,
      "group3_cols": group3_cols,
    }
    saveConfiguration("ProductionOrder", "default", "default", configuration);
  }

  function startDrag(e) {
    if (!e.target.id.includes("resizer_")) return;
    dragging_col_key = e.target.id.replace("resizer_", "");
    window.addEventListener('mousemove', handleDrag);
    window.addEventListener('mouseup', stopDrag);
  }

  function get_work_row_class(work) {
    let res = "";
    if (filter_process && work.Work_OperationProcName.includes(filter_process)) {
      res += "search-hitted ";
    }

    const current_time = dayjs();
    const start_time = dayjs(work.Work_OperationProductionStartTime);
    const end_time = dayjs(work.Work_OperationProductionEndTime);
    if (end_time.isValid() && current_time.isAfter(end_time)) {
      res += "completed ";
    } else if (start_time.isValid() && current_time.isAfter(start_time)) {
      res += "in-progress ";
    } else {
      res += "not-started ";
    }
    return res;
  }

  function get_work_row_block_right_content(work) {
    const current_time = dayjs();
    const start_time = dayjs(work.Work_OperationProductionStartTime);
    const end_time = dayjs(work.Work_OperationProductionEndTime);
    if (end_time.isValid() && current_time.isAfter(end_time)) {
      return "●";
    } else if (start_time.isValid() && current_time.isAfter(start_time)) {
      return "○";
    } else {
      return start_time.format("MM") + "/" + start_time.format("DD");
    }
  }

  async function refresh() {
    const projectData = await fetchProjectData(project, "ProductionOrder");
    uploadTimeStamp = projectData.UploadTimeStamp;

    ProductionWorks = await fetchProductionWorks(project);
    ProductionOrders = await fetchProductionOrders(project);
    TransactionData = await fetchTransactionData(project, null);

    console.log("ProductionWorks", ProductionWorks);
    console.log("ProductionOrders", ProductionOrders);
    console.log("TransactionData", TransactionData);

    ProductionOrderDatas = {};
    for (let i = 0; i < ProductionOrders.Orders.length; i++) {
      let Order_ObjectID = ProductionOrders.Orders[i];
      let data = await fetchProductionOrderData(project, Order_ObjectID);
      ProductionOrderDatas[Order_ObjectID] = data;
      max_work_num = Math.max(max_work_num, data.Order.Works.length);
    }

    function extract_month_and_day(date) {
      if (date === "") {
        return "";
      }
      return date.split(" ")[0].split("/").slice(1).join("/");
    }
    function format_date_time(date) {
      if (date === "") {
        return "";
      }
      const [datePart, timePart] = dayjs(date).format("YYYY/MM/DD HH:mm").split(" ");
      if (dayjs(date).format("YYYY/MM/DD HH:mm") === "Invalid Date") {
        return "";
      }
      return `<span class="date-part">${datePart}</span> <span class="time-part">${timePart}</span>`;
    }

    all_tableData = [];
    for (let i = 0; i < ProductionWorks.Works.length; i++) {
      let row_data = ProductionWorks.Works[i];
      let Order_ObjectID = row_data.Order_ObjectID;
      if (!ProductionOrderDatas[Order_ObjectID]) {
        continue;
      }
      let data = ProductionOrderDatas[Order_ObjectID];
      row_data["Order_LET"] = extract_month_and_day(row_data["Order_LET"]);
      row_data["Work_OperationProductionStartTime"] = format_date_time(row_data["Work_OperationProductionStartTime"]);
      row_data["Work_OperationProductionEndTime"] = format_date_time(row_data["Work_OperationProductionEndTime"]);
      row_data["Work_ResultStartTime"] = format_date_time(row_data["Work_ResultStartTime"]);
      row_data["Work_ResultEndTime"] = format_date_time(row_data["Work_ResultEndTime"]);
      row_data["Work_Lateness"] = Math.max(0, row_data["Work_Lateness"]);
      row_data["completedReset"] = "";
      row_data["Works"] = data.Order.Works;
      all_tableData.push(row_data);
    }

    for (let Work_OperationCode in TransactionData) {
      if (Work_OperationCode == "DownloadedTimeStamp") {
        continue;
      }
      let Work_Status = TransactionData[Work_OperationCode].Work_Status;
      if (Work_Status === "A") {
        for (let i = 0; i < all_tableData.length; i++) {
          let row = all_tableData[i];
          if (row.Work_OperationCode === Work_OperationCode) {
            row.Work_ResultStartTime = "";
            row.Work_ResultEndTime = "";
            break;
          }
        }
      } else if (Work_Status === "T") {
        let Work_ResultStartTime = TransactionData[Work_OperationCode].Work_ResultStartTime;
        for (let i = 0; i < all_tableData.length; i++) {
          let row = all_tableData[i];
          if (row.Work_OperationCode === Work_OperationCode) {
            row.Work_ResultStartTime = format_date_time(Work_ResultStartTime);
            row.Work_ResultEndTime = "";
            break;
          }
        }
      } else if (Work_Status === "B") {
        let Work_ResultStartTime = TransactionData[Work_OperationCode].Work_ResultStartTime || "";
        let Work_ResultEndTime = TransactionData[Work_OperationCode].Work_ResultEndTime || "";
        for (let i = 0; i < all_tableData.length; i++) {
          let row = all_tableData[i];
          if (row.Work_OperationCode === Work_OperationCode) {
            if (Work_ResultStartTime !== "") row.Work_ResultStartTime = format_date_time(Work_ResultStartTime);
            if (Work_ResultEndTime !== "") row.Work_ResultEndTime = format_date_time(Work_ResultEndTime);
            break;
          }
        }
      }
    }

    all_tableData.sort((a, b) => {
      if (a.Work_OperationProductionStartTime < b.Work_OperationProductionStartTime) {
        return -1;
      } else if (a.Work_OperationProductionStartTime > b.Work_OperationProductionStartTime) {
        return 1;
      } else {
        return 0;
      }
    });

    filter_years = [];
    filter_processes = [];
    filter_Work_OrderItemNames = [];
    filter_orders = [];
    for (let i = 0; i < all_tableData.length; i++) {
      let row = all_tableData[i];
      let year = row.Work_OperationProductionStartTime.replace('<span class="date-part">', '').split("/")[0];
      let order = row.Order_Code;
      if (!filter_years.includes(year)) {
        filter_years.push(year);
      }
      if (!filter_orders.includes(order)) {
        filter_orders.push(order);
      }
      if (!filter_Work_OrderItemNames.includes(row.Work_OrderItemName)) {
        filter_Work_OrderItemNames.push(row.Work_OrderItemName);
      }
      for (let j = 0; j < row.Works.length; j++) {
        let process = row.Works[j].Work_OperationProcName;
        if (!filter_processes.includes(process)) {
          filter_processes.push(process);
        }
      }
    }

    filter_years.sort();
    filter_processes.sort();
    filter_Work_OrderItemNames.sort();
    filter_orders.sort();

    filterByConditions();
  }

  onMount(async () => {
    const configuration = getConfiguration("ProductionOrder", "default", "default");
    filter_process = configuration["filter_process"] || "";
    filter_Work_OrderItemName = configuration["filter_Work_OrderItemName"] || "";
    filter_order = configuration["filter_order"] || "";
    filter_completed = configuration["filter_completed"] || "true";
    group1_cols = configuration["group1_cols"] || group1_cols;
    group2_cols = configuration["group2_cols"] || group2_cols;
    group3_cols = configuration["group3_cols"] || group3_cols;

    if (filter_process === "" && filter_Work_OrderItemName === "" && filter_order === "") {
      addNotification({
        text: "検索したい工程or品番orオーダを入力し絞り込みボタンを押してください。",
        position: "top-right",
        removeAfter: 4000,
        type: "success",
      });
    }

    const current_datetime = dayjs();
    filter_year = current_datetime.format("YYYY");
    filter_month = current_datetime.format("M");
    await refresh();
  });
</script>

{#if all_tableData.length === 0}
  <Loading/>
{:else}
  <div class="input-container">
    <div style="margin-right: 20px">
      <label for="filter_year" style="margin-right: 10px;">加工年</label>
      <GeneralSelect options={filter_years.map((y) => ({text: y, value: y}))} bind:value={filter_year} defaultText="---" defaultValue=""/>
    </div>

    <div style="margin-right: 20px">
      <label for="filter_month" style="margin-right: 10px;">加工月</label>
      <GeneralSelect options={filter_months.map((m) => ({text: m, value: m}))} bind:value={filter_month} defaultText="---" defaultValue=""/>
    </div>

    <div style="margin-right: 20px">
      <label for="filter_process" style="margin-right: 10px;">工程</label>
      <GeneralSelect options={filter_processes.map((p) => ({text: p, value: p}))} bind:value={filter_process} defaultText="---" defaultValue="" extraStyle="max-width: 80px"/>
    </div>

    <div style="margin-right: 20px">
      <label for="filter_Work_OrderItemName" style="margin-right: 10px;">品番</label>
      <GeneralSelect options={filter_Work_OrderItemNames.map((w) => ({text: w, value: w}))} bind:value={filter_Work_OrderItemName} defaultText="---" defaultValue="" extraStyle="max-width: 80px"/>
    </div>

    <div style="margin-right: 20px">
      <label for="filter_order" style="margin-right: 10px;">オーダ</label>
      <GeneralSelect options={filter_orders.map((o) => ({text: o, value: o}))} bind:value={filter_order} defaultText="---" defaultValue="" extraStyle="max-width: 80px"/>
    </div>

    <div style="margin-right: 20px">
      <label for="filter_completed" style="margin-right: 10px;">完了品/未完了品</label>
      <GeneralSelect options={[{text: "どちらも表示", value: "true"}, {text: "未完了品のみ", value: "false"}]} bind:value={filter_completed}/>
    </div>

    <GeneralButton buttonFunction={filterByConditions} buttonDisabled={disable_buttons} buttonText="条件で絞り込み" buttonType="secondary" buttonExtraStyle="margin-left: 20px"/>

    <div>
      <PageSelector {totalPageNumber} identifier={"Guest"} />
    </div>
  </div>

  {#if tableData.length === 0}
    {#if filter_applied}
      <div style="margin-top: 20px; text-align: center;">全作業数 = {all_tableData.length}個 (該当する作業は存在しませんでした)</div>
    {:else}
      <div style="margin-top: 20px; text-align: center;">全作業数 = {all_tableData.length}個 (フィルタを設定すると表示されます)</div>
    {/if}
  {:else}
    <div class="table-container">
      <div class="fixed-table-container">
        <table>
          <thead>
            <tr>
              {#each [group1_cols, group2_cols, group3_cols] as group_cols}
                {#each group_cols as col, i}
                  {#if i === 0}
                    <th id="th_{col.key}" class="{col.group}" style="{get_style(null, col)}">
                      <div class="button-container">
                        <button style="cursor:pointer" on:click={() => toggleGroupVisibility(col.group)}>{#if $visibility[col.group]} - {:else} + {/if}</button>
                      </div>
                      {@html col.name}
                    </th>
                    <th id="resizer_{col.key}" class="{col.group}" style="width: 5px; cursor: ew-resize; border: 0; padding: 0;" on:mousedown={startDrag}></th>
                  {:else}
                    {#if $visibility[col.group]}
                      <th id="th_{col.key}" class="{col.group}" style="{get_style(null, col)}">{@html col.name}</th>
                      <th id="resizer_{col.key}" class="{col.group}" style="width: 5px; cursor: ew-resize; border: 0; padding: 0;" on:mousedown={startDrag}></th>
                    {/if}
                  {/if}
                {/each}
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each tableData as row}
              <tr>
                {#each [group1_cols, group2_cols, group3_cols] as group_cols}
                  {#each group_cols as col, i}
                    {#if i === 0 || $visibility[col.group]}
                      {#if col.key === "completedReset"}
                        {#if row.Work_ResultStartTime !== "" || row.Work_ResultEndTime !== ""}
                          <td class="{col.group}" style="{get_style(row, col)}">
                            <GeneralButton buttonFunction={() => deleteCompleted(row)} buttonDisabled={disable_buttons} buttonText="削除" buttonType="negative" buttonExtraStyle="min-width: 5rem"/>
                          </td>
                        {:else}
                          <td class="{col.group}" style="{get_style(row, col)}"></td>
                        {/if}
                      {:else if col.key === "Work_ResultStartTime"}
                        {#if row.Work_ResultStartTime !== ""}
                          <td class="{col.group}" style="{get_style(row, col)}; color: #57B59F">{@html row[col.key]}</td>
                        {:else}
                          <td class="{col.group}" style="{get_style(row, col)}">
                            <GeneralButton buttonFunction={() => sendResultTime(row, true)} buttonDisabled={disable_buttons} buttonText="開始" buttonExtraStyle="min-width: 5rem"/>
                          </td>
                        {/if}
                      {:else if col.key === "Work_ResultEndTime"}
                        {#if row.Work_ResultEndTime !== ""}
                          <td class="{col.group}" style="{get_style(row, col)}; color: #57B59F">{@html row[col.key]}</td>
                        {:else}
                          <td class="{col.group}" style="{get_style(row, col)}">
                            <GeneralButton buttonFunction={() => sendResultTime(row, false)} buttonDisabled={disable_buttons} buttonText="終了" buttonExtraStyle="min-width: 5rem"/>
                          </td>
                        {/if}
                      {:else}
                        <td class="{col.group}" style="{get_style(row, col)}">{@html row[col.key]}</td>
                      {/if}
                      <td id="resizer_{col.key}" class="{col.group}" style="width: 5px; cursor: ew-resize; border: 0; padding: 0;" on:mousedown={startDrag}></td>
                    {/if}
                  {/each}
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>

      <div class="scrollable-table-container">
        <table style="min-width: {160 * max_work_num}px">
          <thead>
            <tr>
              {#each Array(max_work_num) as _, i}
                <th class="work-header" style="width: 160px">
                  <div class="work-header-block-container">
                    <div class="work-header-block top">{i + 1}</div>
                    <div class="work-header-block bottom-left">工程</div>
                    <div class="work-header-block bottom-right">開始</div>
                  </div>
                </th>
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each tableData as row}
              <tr>
                {#each Array(max_work_num) as _, i}
                  {#if i < row.Works.length}
                    <td class="work-row" style="width: 160px">
                      <div class="work-row-block-container {get_work_row_class(row.Works[i])}">
                        <div class="work-row-block left">{@html row.Works[i].Work_OperationProcName}</div>
                        <div class="work-row-block right">{@html get_work_row_block_right_content(row.Works[i])}</div>
                      </div>
                    </td>
                  {:else}
                    <td class="work-row"></td>
                  {/if}
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </div>
  {/if}
{/if}

<style>
  /* style for input-container */
  .input-container {
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: #f5f5f5;
    align-items: center;
  }

  .input-container > :last-child {
    margin-left: auto;
  }

  /* style for tables */

  .table-container {
    display: flex;
    align-items: flex-start;
    overflow: hidden;
  }

  .fixed-table-container {
    flex-shrink: 0;
  }
  
  .scrollable-table-container {
    overflow-x: auto;
    flex-grow: 1;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    background-color: #fff;
  }

  th {
    height: 90px;
  }

  td {
    height: 65px;
  }

  /* style for fixed-table-container */
  th.group1 {
    background-color: #C2DDF8;
    position: relative;
  }

  th.group2 {
    background-color: #BFCCF7;
    position: relative;
  }

  th.group3 {
    background-color: #C5BCF7;
    position: relative;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
  }

  .button-container {
    position: absolute;
    top: 2px;
    left: 2px;
  }

  button.deleteCompleted {
    background-color: #FCF1F0;
    color: #DC9492;
    border: solid 2px #DC9492;
    border-radius: 5px;
    width: 80%;
    text-align: center;
    display: inline-block;
  }

  button.deleteCompleted:disabled {
    background-color: #F0F0F0;
    color: #B0B0B0;
    border: solid 2px #B0B0B0;
  }

  button.sendResult {
    background-color: #253A91;
    color: white;
    border: solid 2px #253A91;
    border-radius: 5px;
    width: 80%;
    text-align: center;
    display: inline-block;
  }

  button.sendResult:disabled {
    background-color: #F0F0F0;
    color: #B0B0B0;
    border: solid 2px #B0B0B0;
  }

  .date-part, .time-part {
    display: block;
  }

  @media (min-width: 400px) {
    .date-part, .time-part {
      display: inline;
    }
  }

  /* style for scrollable-table-container */
  .work-header {
    background-color: #E6E6E6;
  }
  .work-header .work-header-block-container {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 50% 50%;
    height: 100%;
    align-items: center;
    justify-items: center;
  }

  .work-row {
    padding-left: 0;
    padding-right: 0;
  }

  .work-header .work-header-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .work-header .top {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }

  .work-header .bottom-left {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  .work-header .bottom-right {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  .work-row .work-row-block-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    height: 100%;
    align-items: center;
    justify-items: center;
  }

  .work-row .work-row-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .work-row .left {
    grid-row: 1 / 1;
    grid-column: 1 / 2;
    width: 100%;
    height: 100%;
  }

  .work-row .right {
    grid-row: 1 / 1;
    grid-column: 2 / 3;
    width: 100%;
    height: 100%;
  }

  .search-hitted .work-row-block.left {
    background-color: #8DC2F9;
  }
  .completed {
    background-color: #DBDCE3;
  }

  .in-progress {
    background-color: #F9F666;
  }

  .page-selector-container {
    display: flex; 
    align-items: center; 
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* style for table */
</style>
