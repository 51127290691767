<script>
    import {resultStartRow, resultRowLength, resultRowTotal, resultRowLimit} from "./ResultGraphStore"
    import { RowDisplay, FilteredProcessIndices, RawData} from "./ResultGraphStore"
    import {filteredResGroup} from "./ResultGraphStore"
    import BadgeFilterSpace from "./BadgeFilterSpace.svelte";
    import { saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage";
    export let width = 150;
    let column = "グループ"

    const _PageScroll = (pj) => {
        resultStartRow.update((n) =>
            Math.max(0, Math.min($resultRowTotal - 1, n + pj * $resultRowLength))
        );
        saveResultGraphConfiguration({'resultStartRow': $resultStartRow}, 'default', 'default')
    };

    const PageUp = (e) => {
        e.preventDefault();
        _PageScroll(-1);
    };

    const PageDown = (e) => {
        e.preventDefault();
        _PageScroll(1);
    };

    const RowUp = (e) => {
        e.preventDefault();
        resultStartRow.update((n) => (n > 0 ? n - 1 : n));
        saveResultGraphConfiguration({'resultStartRow': $resultStartRow}, 'default', 'default')
    };

    const RowDown = (e) => {
        e.preventDefault();
        resultStartRow.update((n) => (n + 1 >= $resultRowTotal ? n : n + 1));
        saveResultGraphConfiguration({'resultStartRow': $resultStartRow}, 'default', 'default')
    };

    const ScrollRows = (e) => {
        e.preventDefault();

        if(e.ctrlKey){
            if (e.deltaY > 0) {
                resultRowLength.update((n) => (n + 1 > $resultRowLimit ? n : n + 1));
            }
            else if (e.deltaY < 0) {
                resultRowLength.update((n) => (n > 1 ? n - 1 : n));
            }
            saveResultGraphConfiguration({'resultRowLength': $resultRowLength}, 'default', 'default')
        }
        else{
            if (e.deltaY > 0) RowDown(e);
            else if (e.deltaY < 0) RowUp(e);
            saveResultGraphConfiguration({'resultStartRow': $resultStartRow}, 'default', 'default')
        }
    };

    $: {
        const newArray = [];
        for(let index = 0; index < $FilteredProcessIndices.length; index++){
            if(($resultStartRow <= index) && (index < $resultStartRow + $resultRowLength)){
                newArray.push($FilteredProcessIndices[index]);
            }
        }
        RowDisplay.set(newArray);
        console.log("RowDisplay", $RowDisplay);
    }

</script>


<div id="result_tablearea" style="--table-width: {width}px;" on:wheel={ScrollRows}>
    <BadgeFilterSpace filterItem={$filteredResGroup}/>

    <div id="result_tablearea_headcolumns">
        <span id="result_tablearea_header">{column}</span>
        <div id="result_tablearea_headbuttons">
            <div id="result_tablearea_headbutton" on:click={RowUp}>
                <img src="/assets/rss_up.svg" />
            </div>
            <div id="result_tablearea_headbutton" on:click={PageUp}>
                <img src="/assets/rss_pageup.svg" />
            </div>
        </div>
    </div>

    <div id="main_area">
        {#each $RowDisplay as row, index}
            {#if index < $resultRowLength} <!-- not needed -->
            <div id="item_cell" style="height: {100 / $resultRowLength}%;">{$RawData[row].Res_Group}</div>
            {/if}
        {/each}
    </div>

    <div id="result_tablearea_footerbuttons">
        <div id="result_tablearea_footerbutton" on:click={RowDown}>
            <img src="/assets/rss_down.svg" />
        </div>
        <div id="result_tablearea_footerbutton" on:click={PageDown}>
            <img src="/assets/rss_pagedown.svg" />
        </div>
    </div>
</div>


<style>
    #result_tablearea{
        height: 100%;
        width: var(--table-width);
        display: flex;
        flex-direction: column;
        overflow: none;
        border: 2px solid #888888;
        background-color: white;
    }

    /* header */
    #result_tablearea_headcolumns{
        width: 100%;
        height: 80px;  /* TODO */
        display: flex;
        flex-direction: column;
        overflow: none;
        text-align: center;
        border-bottom: 2px solid #888888;
    }

    #result_tablearea_header{
        height: 60%;
        line-height: 48px; 
    }

    #result_tablearea_headbuttons {
        background-color: white;
        height: 40%;  
        width: 100%;
        gap: 4%;
        line-height: 32px;
        color: white;
        display: flex;
        flex-direction: row;
    }

    #result_tablearea_headbutton {
        background-color: #d2d0d2;
        width: 48%;
        height: 100%;
        border-radius: 10px 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #result_tablearea_headbutton:hover {
        background-color: #e2e0e2;
        cursor: pointer;
    }


    /* footer */
    #result_tablearea_footerbuttons {
        width: 100%;
        height: 40px;
        gap: 4%;
        line-height: 32px;
        color: white;
        background-color: white;
        display: flex;
        flex-direction: row;
        overflow: none;
        text-align: center;
        border-top: 2px solid #888888;
    }

    #result_tablearea_footerbutton {
        background-color: #d2d0d2;
        width: 48%;
        height: 80%;
        border-radius: 0 0 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #result_tablearea_footerbutton:hover {
        background-color: #e2e0e2;
        cursor: pointer;
    }

    /* main_area */
    #main_area{
        flex: 1;
        background-color: white;
        display: flex;
        flex-direction: column;
    }

    #item_cell{
        /* flex: 1; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #888888;
        border-bottom: 1px solid #888888;
        background-color: #e9e9f0;

        white-space: nowrap;
        overflow: hidden;
    }
</style>