<script>
  // props
  import { getContext } from "svelte";
  import { useLoadGraphProjectQuery } from "../../../../../hooks/project/views/loadGraph/useLoadGraphProjectQuery.js";
  import {
    useLoadGraphResourcesQuery
  } from "../../../../../hooks/project/views/loadGraph/useLoadGraphResourcesQuery.js";
  import Loading from "../../../Loading.svelte";

  /**
   * @type {string}
   */
  export let projectId

  // error handlers
  const {handleFetchErrors} = getContext("fetchErrorHandler")

  // svelte-query
  const projectQuery = useLoadGraphProjectQuery(projectId, handleFetchErrors)
  const resourcesQuery = useLoadGraphResourcesQuery(projectId, handleFetchErrors)
</script>

{#if $projectQuery.data && $resourcesQuery.data}
  <slot
    projectId={projectId}
    project={$projectQuery.data}
    resources={$resourcesQuery.data}
  />
{:else}
  <Loading />
{/if}
