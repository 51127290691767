<script>
  import { useParams } from "svelte-navigator";
  import { getContext, onMount } from "svelte";
  import Loading from "../../components/pages/Loading.svelte";
  import { FetchError, fetchResource, fetchResourceGroup, } from "../../utils/getData";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import GeneralSelect from "../../components/select/GeneralSelect.svelte";
  import { reset } from "../../utils/putData";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import { translate } from "../../i18n/i18next.js";
  import { navigateI18n } from "../../i18n/navigate.js";

  const params = useParams();
  const projectId = $params.project;
  const { setHeaderProps } = usePageContext();
  setHeaderProps({ title: translate("frontend:views.dispatchingView.resourceSelection") });

  let operations;
  let resourceGroup = {};
  $: selectedValue = "unspecified"
  let resourceBox;

  const { handleFetchErrors, handleNonBlockingFetchErrors } =
    getContext("fetchErrorHandler");
  onMount(async () => {
    try {
      const resource = await fetchResource(projectId);
      if (resource.isCustomized) {
        navigateI18n(`/projects/${projectId}/DispatchingView_Profile1`, {replace: true});
      }

      operations = resource.Rows;
      resourceGroup = await fetchResourceGroup(projectId) ?? {};
    } catch (e) {
      console.error(e);
      if (e instanceof FetchError) {
        handleFetchErrors(e.error);
      }
    }
  });

  async function onReset() {
    try {
      await reset(projectId);
      alert(translate("frontend:views:dispatchingView:resetAllTransactionsConfirmation"));
    } catch (e) {
      console.log(e);
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error);
      }
    }
  }

  function onSelectedChanged(event) {
    selectedValue = event.target.value;
    if (selectedValue !== "unspecified") {
      let group = resourceGroup[selectedValue];
      console.log(group);
      if (resourceBox) {
        [...resourceBox.childNodes].forEach((resource) => {
          if (resource.nodeName === "BUTTON") {
            if (group.includes(resource.value)) {
              console.log(resource.value);
              resource.style.display = "";
            } else resource.style.display = "none";
          }
        });
      }
    } else {
      if (resourceBox) {
        [...resourceBox.childNodes].forEach((resource) => {
          if (resource.nodeName === "BUTTON") {
            resource.style.display = "";
          }
        });
      }
    }
  }
  $: options = Object.keys(resourceGroup).map(res => ({text: res, value: res}));
</script>

{#if operations && resourceGroup}
  {#if operations.length > 0}
    <div class="container">
      <div class="horizontal">{translate("asprova.terms.resourceGroup")}:</div>
      <GeneralSelect
        value={selectedValue}
        onChangeEvent={onSelectedChanged}
        {options}
        extraStyle="max-width: 100px"
        defaultValue={"unspecified"}
        defaultText={translate("generic.unspecified")}
      />
      <GeneralButton
        buttonText={translate("frontend:views.dispatchingView.resetAllTransactions")}
        buttonFunction={onReset}
        width="100"
        height="30"
        buttonType="negative"
      />
    </div>

    <div bind:this={resourceBox} style="margin-top: 25px;">
      {#each operations as col, _}
        <GeneralButton
          buttonText={col[0]}
          buttonFunction={() =>
            navigateI18n(`/projects/${projectId}/DispatchingView/${col[3]}`)}
          buttonValue={col[0]}
          buttonType="secondary"
        />
      {/each}
    </div>


  {:else}
    <p>{translate("frontend:views.dispatchingView.noOperation")}</p>
  {/if}
{:else}
  <Loading />
{/if}

<style>
  .horizontal {
    display: inline-block;
  }
  .container {
    display: flex;
    align-items: center;
  }
  .horizontal {
    margin: 10px;
  }
</style>
