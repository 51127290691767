import {FetchError, myJsonSyncXhrFetcher} from "../../utils/getData.js";

const _SemicolonDateToArray = (semicolonSeparatedDate) => {
  if (semicolonSeparatedDate === "") return [];
  return semicolonSeparatedDate.split(";").map((d) => new Date(d));
}

const __getConnectedComponents = (pairs) => {
    const parent = {};
    const rank = {};

    // Find the root of the set in which element `i` belongs
    const find = (i) => {
        if (parent[i] !== i) {
            parent[i] = find(parent[i]); // Path compression
        }
        return parent[i];
    };

    // Perform the union of two subsets
    const union = (i, j) => {
        const rootI = find(i);
        const rootJ = find(j);

        if (rootI !== rootJ) {
            // Union by rank
            if (rank[rootI] > rank[rootJ]) {
                parent[rootJ] = rootI;
            } else if (rank[rootI] < rank[rootJ]) {
                parent[rootI] = rootJ;
            } else {
                parent[rootJ] = rootI;
                rank[rootI] += 1;
            }
        }
    };

    // Initialize the Union-Find structure
    for (const [u, v] of pairs) {
        if (parent[u] === undefined) {
            parent[u] = u;
            rank[u] = 0;
        }
        if (parent[v] === undefined) {
            parent[v] = v;
            rank[v] = 0;
        }
        union(u, v);
    }

    // Find all the connected components
    const components = {};
    for (const node in parent) {
        const root = find(node);
        if (!components[root]) {
            components[root] = [];
        }
        components[root].push(node);
    }

    // Convert the components object into an array of arrays
    return Object.values(components);
};

export const FetchLocs = (url, fetchErrorHandler) => {
  try {
    const data = myJsonSyncXhrFetcher(url)
    return Object.fromEntries(Object.entries(data).map(([k, [v, n]]) => [k, [String(v), n]]))
  } catch (e) {
    if (e instanceof FetchError) {
      fetchErrorHandler(e.error)
    }
    return {}
  }
}

export const PostProcessOperations = (oper) => {
  let retOps = oper;
  let clusterCount = 0;

  for (let i = 0; i < retOps.length; i++) {
    let _o = retOps[i];

    // Full ope duration
    {
      let itemStart = new Date(0);
      let itemEnd = new Date(0);

      // Begin
      if (_o._setupArr.length >= 2) itemStart = _o._setupArr[0];
      else if (_o._productionArr.length >= 2) itemStart = _o._productionArr[0];
      else if (_o._teardownArr.length >= 2) itemStart = _o._teardownArr[0];

      // End
      if (_o._teardownArr.length >= 2) itemEnd = _o._teardownArr[_o._teardownArr.length-1];
      else if (_o._productionArr.length >= 2) itemEnd = _o._productionArr[_o._productionArr.length-1];
      else if (_o._setupArr.length >= 2) itemEnd = _o._setupArr[_o._setupArr.length-1];

      // Output
      retOps[i]._operationStartEnd = [itemStart, itemEnd];
      retOps[i]._influenceStart = itemStart;
      retOps[i]._influenceEnd = itemEnd;
    }
  }

  // Area of influence
  {
    let elementCount = Object.keys(retOps).length;
    let edgeList = [];

    // Populate
    for (let i = 0; i < retOps.length; i++) {
      let _o = retOps[i];

      let startOfInfluence = _o._operationStartEnd[0];
      let endOfInfluence = _o._operationStartEnd[1];
      for (let j = i + 1; j < retOps.length; j++) {
        let _other = retOps[j];

        if (_other._operationStartEnd[0] >= endOfInfluence) continue;
        edgeList.push([i, j]);
        break;
      }
      for (let j = i - 1; j >= 0; j--) {
        let _other = retOps[j];

        if (_other._operationStartEnd[1] <= startOfInfluence) continue;
        edgeList.push([i, j]);
        break;
      }
    }

    const components = __getConnectedComponents(edgeList);
    clusterCount = components.length;

    components.forEach((component, idx) => {
      const startDates = component.map((e) => retOps[e]._operationStartEnd[0]);
      const endDates = component.map((e) => retOps[e]._operationStartEnd[1]);
      const startInfluence = new Date(Math.min(...startDates));
      const endInfluence = new Date(Math.max(...endDates));
      const clusterNo = idx;
      component.forEach((node) => {
        retOps[node]._influenceStart = startInfluence;
        retOps[node]._influenceEnd = endInfluence;
        retOps[node]._clusterNo = clusterNo; // undefined = non-stacked operation
      });
    });
  }

  // 多重資源
  {
    // 1st step: Get the vertical position of each operation checking the previous ones
    for (let i = 0; i < retOps.length; i++) {
      let _o = retOps[i];

      let verticalPosition = 0;
      let startOfInfluence = _o._operationStartEnd[0]; // ! Not _influenceStart as we just want to take into account the places where the block cannot absolutely be placed at its starting time
      let socles = [0];
      let takenRanges = [];
      for (let j = i - 1; j >= 0; j--) {
        let _other = retOps[j];
        if (_other._operationStartEnd[1] <= startOfInfluence) continue;

        socles.push(_other._verticalPosition + _other._takenResourceCount);
        // [A,B[
        takenRanges.push([_other._verticalPosition, _other._verticalPosition + _other._takenResourceCount]);
      }

      // Take the lowest available spot
      socles.sort((a, b) => a - b);
      for (let k = 0; k < socles.length; k++) {
        const socle = socles[k];
        const top = socle + _o._takenResourceCount;

        const collides = takenRanges.some((range) => {
          return (range[1] <= top) ? !(range[1] <= socle) : !(top <= range[0]);
        });

        if (!collides) {
          verticalPosition = socle;
          break;
        }
      }

      // Output
      retOps[i]._verticalPosition = verticalPosition;
    }

    // 2nd step: Set the maximum necessary space for each operation within its sphere of influence

    // 2.1: Determine cluster-size heights
    let clusterHeights = {};

    for (let i = 0; i < retOps.length; i++) {
      let _o = retOps[i];
      const _cluster = _o._clusterNo;

      // Not in any cluster
      if (_cluster === undefined) {
        retOps[i]._necessarySpace = _o._takenResourceCount;
        retOps[i]._clusterNo = -1;
      }
      // In cluster
      else if (_cluster >= 0) {
        if (!(_cluster in clusterHeights)) clusterHeights[_cluster] = 0;
        clusterHeights[_cluster] = Math.max(_o._verticalPosition + _o._takenResourceCount, clusterHeights[_cluster]);
      }
    }

    // 2.2: Apply cluster specific values to each operation
    for (let i = 0; i < retOps.length; i++) {
        let _o = retOps[i];
        const _cluster = _o._clusterNo;
        if (_cluster >= 0) {
            retOps[i]._necessarySpace = clusterHeights[_cluster];
        }

        // Easy fix for the reverse Y-axis issue, not the prettiest but the most efficient without altering too much the code
        retOps[i]._verticalPosition = retOps[i]._necessarySpace - _o._verticalPosition - _o._takenResourceCount;
    }

  }

  return oper;
}

export const FetchResourceInfo = (res, url, fetchErrorHandler) => {
  const _resUrl = url;//this.rowDownloadUrl + urlIdx + ".json";
  let data
  try {
    data = myJsonSyncXhrFetcher(_resUrl)
  } catch (e) {
    console.error("Failed to fetch : " + res)
    if (e instanceof FetchError) {
      fetchErrorHandler(e.error)
    }
    return {
      Data: [],
      Shift: [],
      Label: [],
      IntervalQty: [],
    }
  }

  let AllOperations = [];
  let AllShifts = data.WorkingTime.map((el) => [new Date(el[0]), new Date(el[1])]);
  let AllLabels = [];

  //this.registerShifts(res, data.WorkingTime);

  const PDA = Object.keys(data.PropertyDef_Operation);
  const _indexes = {
    ObjectID: PDA.indexOf('ObjectID'),
    OrderCode: PDA.indexOf('WorkInst_Order'),
    OutputItem: PDA.indexOf('Work_OperationOutMainItem'),
    OperationCode: PDA.indexOf('WorkInst_Operation'),
    OutputItemQuantity: PDA.indexOf('Work_OperationOutMainItemQty'),
    Color: PDA.indexOf('WebViewerOnly_Order_Color_RGB'),
    UIMType: PDA.indexOf('WebViewerOnly_UseInstMaster_Type'),
    PreviousTask: PDA.indexOf('WebViewerOnly_LeftWorkObjectID'),
    NextTask: PDA.indexOf('WebViewerOnly_RightWorkObjectID'),
    PreviousTaskLocalLocs: PDA.indexOf('WebViewerOnly_LeftWorkLocalLocs'),
    NextTaskLocalLocs: PDA.indexOf('WebViewerOnly_RightWorkLocalLocs'),
    Lateness: PDA.indexOf('Work_Lateness'),
    Earlyness: PDA.indexOf('Work_Earliness'),
    ProductionArray: PDA.indexOf('WebViewerOnly_TimeArray_Prod'),
    SetupArray: PDA.indexOf('WebViewerOnly_TimeArray_Setup'),
    TeardownArray: PDA.indexOf('WebViewerOnly_TimeArray_Teardown'),
    Status: PDA.indexOf('Work_Status'),
    TaskText: PDA.indexOf('WebViewerOnly_BarString'),
    TaskTooltip: PDA.indexOf('WebViewerOnly_DataTipString'),
    ResultStartTime: PDA.indexOf('Work_ResultStartTime'),
    ResultEndTime: PDA.indexOf('Work_ResultEndTime'),
    ResultQty: PDA.indexOf('Work_ResultQty'),
    TimeConstraintMethod: PDA.indexOf('WebViewerOnly_MasterInputInst_LeftWorkTimeConstraintMethods'),
    ProcNo: PDA.indexOf('Work_OperationProcNo'),
    ProcName: PDA.indexOf('Work_OperationProcName'),
    WorkOrderItem: PDA.indexOf('WorkUser_orderitem')
  };

  const PDL = Object.keys(data.PropDef_Label);
  const _labelIndexes = {
    ObjectID: PDL.indexOf('ObjectID'),
    LabelString: PDL.indexOf('Label_String'),
    LabelTextHorizontalAlignment: PDL.indexOf('Label_TextHorizontalAlignment'),
    LabelTextVerticalAlignment: PDL.indexOf('Label_TextVerticalAlignment'),
    LabelShape: PDL.indexOf('Label_Shape'),
    LabelTimePoint: PDL.indexOf('Label_TimePoint'),
    LabelTimeSpan: PDL.indexOf('Label_TimeSpan'),
    LabelRelYRatio: PDL.indexOf('Label_RelYRatio'),
    LabelRectRelX: PDL.indexOf('Label_RectRelX'),
    LabelRectRelY: PDL.indexOf('Label_RectRelY'),
    LabelZ: PDL.indexOf('Label_Z'),
    LabelHeight: PDL.indexOf('Label_Height'),
    LabelWidth: PDL.indexOf('Label_Width'),
    LabelArrowDepthRatio: PDL.indexOf('Label_ArrowDepthRatio'),
    LabelArrowStemHeightRatio: PDL.indexOf('Label_ArrowStemHeightRatio'),
    LabelBackColor: PDL.indexOf('Label_BackColor'),
    LabelBorderColor: PDL.indexOf('Label_BorderColor'),
    LabelTextColor: PDL.indexOf('Label_TextColor'),
    MyScheduleOnlyLabelResourceCode: PDL.indexOf('MyScheduleOnly_Label_ResourceCode'),
    MyScheduleOnlyLabelResourceObjectID: PDL.indexOf('MyScheduleOnly_Label_ResourceObjectID'),
    MyScheduleOnlyLabelOperationCode: PDL.indexOf('MyScheduleOnly_Label_OperationCode'),
    MyScheduleOnlyLabelOperationObjectID: PDL.indexOf('MyScheduleOnly_Label_OperationObjectID'),
    MyScheduleOnlyLabelUseInstCode: PDL.indexOf('MyScheduleOnly_Label_UseInstCode'),
    MyScheduleOnlyLabelUseInstObjectID: PDL.indexOf('MyScheduleOnly_Label_UseInstObjectID'),
  };

  data.Operation.sort((a, b) => {
    return a[4].split(";").map((d) => new Date(d))[0] - b[4].split(";").map((d) => new Date(d))[0];
  })
  data.Operation.forEach((uib) => {
    const _taskIdx = uib[_indexes.ObjectID];
    const _opeCode = uib[_indexes.OperationCode];
    const _taskObj = {
      _row: res,
      _res: data.Resource[0],
      _order: uib[_indexes.OrderCode],
      _operation: _opeCode,
      _objectID: _taskIdx,
      _outputItem: uib[_indexes.OutputItem],
      _outputItemQty: uib[_indexes.OutputItemQuantity],
      _color: uib[_indexes.Color],
      _useinstmaster_type: uib[_indexes.UIMType],
      _prevTask: uib[_indexes.PreviousTask],
      _nextTask: uib[_indexes.NextTask],
      _prevTaskLocalLocs: uib[_indexes.PreviousTaskLocalLocs],
      _nextTaskLocalLocs: uib[_indexes.NextTaskLocalLocs],
      _late: (uib[_indexes.Lateness] > 0),
      _early: (uib[_indexes.Earlyness] > 0),
      _productionArr: _SemicolonDateToArray(uib[_indexes.ProductionArray]),
      _resStatus: uib[_indexes.Status],
      _taskText: uib[_indexes.TaskText],
      _hoverTooltip: uib[_indexes.TaskTooltip],
      _setupArr: _SemicolonDateToArray(uib[_indexes.SetupArray]),
      _teardownArr: _SemicolonDateToArray(uib[_indexes.TeardownArray]),
      _resultStart: uib[_indexes.ResultStartTime],
      _resultEnd: uib[_indexes.ResultEndTime],
      _resultQty: uib[_indexes.ResultQty],
      _timeConstraintMethod: uib[_indexes.TimeConstraintMethod],
      _procNo: uib[_indexes.ProcNo],
      _procName: uib[_indexes.ProcName],
      _orderItem: uib[_indexes.WorkOrderItem],
      _takenResourceCount: 1, // Temporary
    };
    AllOperations.push(_taskObj);
  });

  data.Labels.forEach((lb) => {
    const _labelObj = {
      _objectID: lb[_labelIndexes.ObjectID],
      _string: lb[_labelIndexes.LabelString],
      _textHorizontalAlignment: lb[_labelIndexes.LabelTextHorizontalAlignment],
      _textVerticalAlignment: lb[_labelIndexes.LabelTextVerticalAlignment],
      _shape: lb[_labelIndexes.LabelShape],
      _timePoint: lb[_labelIndexes.LabelTimePoint],
      _timeSpan: lb[_labelIndexes.LabelTimeSpan],
      _relYRatio: lb[_labelIndexes.LabelRelYRatio],
      _rectRelX: lb[_labelIndexes.LabelRectRelX],
      _rectRelY: lb[_labelIndexes.LabelRectRelY],
      _z: lb[_labelIndexes.LabelZ],
      _height: lb[_labelIndexes.LabelHeight],
      _width: lb[_labelIndexes.LabelWidth],
      _arrowDepthRatio: lb[_labelIndexes.LabelArrowDepthRatio],
      _arrowStemHeightRatio: lb[_labelIndexes.LabelArrowStemHeightRatio],
      _backColor: lb[_labelIndexes.LabelBackColor],
      _borderColor: lb[_labelIndexes.LabelBorderColor],
      _textColor: lb[_labelIndexes.LabelTextColor],
      _resourceCode: lb[_labelIndexes.MyScheduleOnlyLabelResourceCode],
      _resourceObjectID: lb[_labelIndexes.MyScheduleOnlyLabelResourceObjectID],
      _operationCode: lb[_labelIndexes.MyScheduleOnlyLabelOperationCode],
      _operationObjectID: lb[_labelIndexes.MyScheduleOnlyLabelOperationObjectID],
      _useInstCode: lb[_labelIndexes.MyScheduleOnlyLabelUseInstCode],
      _useInstObjectID: lb[_labelIndexes.MyScheduleOnlyLabelUseInstObjectID],
    };
    AllLabels.push(_labelObj);
  });

  return {
    Data: PostProcessOperations(AllOperations),
    Shift: AllShifts,
    Label: AllLabels,
    IntervalQty: data?.IntervalQty
  };
}

export const FetchOrderInfo = (res, url, token) => {
  const _orderUrl = url;//this.rowDownloadUrl + urlIdx + ".json";
  const xhr = new XMLHttpRequest();
  xhr.open("GET", _orderUrl, false);
  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.send();

  if (xhr.status === 200) {
    const data = JSON.parse(xhr.responseText);

    const OrderPDA =  Object.keys(data.PropertyDef_Order);
    let indexes = {
      ObjectID: OrderPDA.indexOf('ObjectID'),
      TaskText: OrderPDA.indexOf('WebViewerOnly_BarStrings'),
      TaskTooltip: OrderPDA.indexOf('WebViewerOnly_DataTipStrings'),
      StartTime: OrderPDA.indexOf('Work_StartTime'),
      EndTime: OrderPDA.indexOf('Work_EndTime'),
      EST:  OrderPDA.indexOf('Order_EST'),
      LET:  OrderPDA.indexOf('Order_LET'),
      BarColors: OrderPDA.indexOf('WebViewerOnly_BarColors'),
      ResultStartTime: OrderPDA.indexOf('Order_ResultStartTime'),
      ResultEndTime: OrderPDA.indexOf('Order_ResultEndTime'),
      StoredStartTime: OrderPDA.indexOf('Order_StoredStartTime'),
      StoredEndTime: OrderPDA.indexOf('Order_StoredEndTime'),
    };



    const OpertaionPDA = Object.keys(data.PropertyDef_Operation);
    const _indexes = {
      ObjectID: OpertaionPDA.indexOf('ObjectID'),
      OrderCode: OpertaionPDA.indexOf('WorkInst_Order'),
      OutputItem: OpertaionPDA.indexOf('Work_OperationOutMainItem'),
      OperationCode: OpertaionPDA.indexOf('WorkInst_Operation'),
      OutputItemQuantity: OpertaionPDA.indexOf('Work_OperationOutMainItemQty'),
      // Color: OpertaionPDA.indexOf('WebViewerOnly_Order_Color_RGB'),
      UIMType: OpertaionPDA.indexOf('WebViewerOnly_UseInstMaster_Type'),
      PreviousTask: OpertaionPDA.indexOf('WebViewerOnly_LeftWorkObjectID'),
      NextTask: OpertaionPDA.indexOf('WebViewerOnly_RightWorkObjectID'),
      Lateness: OpertaionPDA.indexOf('Work_Lateness'),
      ProductionArray: OpertaionPDA.indexOf('WebViewerOnly_TimeArray_Prod'),
      SetupArray: OpertaionPDA.indexOf('WebViewerOnly_TimeArray_Setup'),
      TeardownArray: OpertaionPDA.indexOf('WebViewerOnly_TimeArray_Teardown'),
      Status: OpertaionPDA.indexOf('Work_Status'),
      TaskText: OpertaionPDA.indexOf('WebViewerOnly_BarStrings'),
      TaskTooltip: OpertaionPDA.indexOf('WebViewerOnly_DataTipStrings'),
      ResultStartTime: OpertaionPDA.indexOf('Work_ResultStartTime'),
      ResultEndTime: OpertaionPDA.indexOf('Work_ResultEndTime'),
      BarColors: OpertaionPDA.indexOf('WebViewerOnly_BarColors')
    };

    let AllOrders = [];
    let AllOperations = [];

    let ord = data.Order[0]; // Only one order
    const ordObj = {
      _objectID: ord[indexes.ObjectID],
      _taskText: ord[indexes.TaskText],
      _hoverTooltip: ord[indexes.TaskTooltip],
      _est: ord[indexes.EST] ? new Date(ord[indexes.EST]) : null,
      _let: ord[indexes.LET] ? new Date(ord[indexes.LET]) : null,
      _productionArr: [new Date(ord[indexes.StartTime]), new Date(ord[indexes.EndTime])],
      _barColors: ord[indexes.BarColors],
      _resultStartTime: ord[indexes.ResultStartTime] ? new Date(ord[indexes.ResultStartTime]) : null,
      _resultEndTime: ord[indexes.ResultEndTime] ? new Date(ord[indexes.ResultEndTime]) : null,
      _storedStartTime:  ord[indexes.StoredStartTime] ? new Date(ord[indexes.StoredStartTime]) : null,
      _storedEndTime:  ord[indexes.StoredEndTime] ? new Date(ord[indexes.StoredEndTime]) : null,
    }
    AllOrders.push(ordObj);


    // data.Operation.sort((a, b) => {
    //   return a[4].split(";").map((d) => new Date(d))[0] - b[4].split(";").map((d) => new Date(d))[0];
    // })

    data.Operation.forEach((uib) => {
      const _taskIdx = uib[_indexes.ObjectID];
      const _opeCode = uib[_indexes.OperationCode];
      const _taskObj = {
        _row: res,
        _order: uib[_indexes.OrderCode],
        _operation: _opeCode,
        _objectID: _taskIdx,
        _outputItem: uib[_indexes.OutputItem],
        _outputItemQty: uib[_indexes.OutputItemQuantity],
        // _color: uib[_indexes.Color],
        _useinstmaster_type: uib[_indexes.UIMType],
        _prevTask: uib[_indexes.PreviousTask],
        _nextTask: uib[_indexes.NextTask],
        _late: (uib[_indexes.Lateness] > 0),
        _productionArr: _SemicolonDateToArray(uib[_indexes.ProductionArray]),
        _resStatus: uib[_indexes.Status],
        _taskText: uib[_indexes.TaskText],
        _hoverTooltip: uib[_indexes.TaskTooltip],
        _setupArr: _SemicolonDateToArray(uib[_indexes.SetupArray]),
        _teardownArr: _SemicolonDateToArray(uib[_indexes.TeardownArray]),
        _resultStart: uib[_indexes.ResultStartTime],
        _resultEnd: uib[_indexes.ResultEndTime],
        _barColors: uib[_indexes.BarColors]
      };
      AllOperations.push(_taskObj);
    });


    return {
      // Data: {"Order": ordObj, "Operation": AllOperations},
      // Data: AllOperations
      // Data: AllOrders
      OrderData: AllOrders,
      OperationData: AllOperations
    };
  }
  console.error("Failed to fetch : " + res);
  return {
    OrderData: [],
    OperationData: []
  };
}

const FetchTransactionData = (url, timestamp) => {
  try {
    console.log("timestamp: " + timestamp);
    let _trUrl = url;
    if (timestamp)
      _trUrl += "?timestamp=" + timestamp;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", _trUrl, false);
    xhr.send();

    if (xhr.status === 200) {
      const data = JSON.parse(xhr.responseText);

      return data;
    }
    else {
      console.error("Failed to fetch transactions");
      return null;
    }
  }
  catch {
    console.error("Failed to fetch transactions");
    return null;
  }
}

const FetchTransactionDataAsync = (url, timestamp, success_callback, error_callback) => {
  try {
    console.log("timestamp: " + timestamp);
    let _trUrl = url;
    if (timestamp)
      _trUrl += "?timestamp=" + timestamp;

    const xhr = new XMLHttpRequest();
    xhr.open("GET", _trUrl, true);
    xhr.timeout = 2000;
    xhr.onload = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          success_callback(data);
        }
        else {
          console.error("Failed to fetch transactions");
          error_callback();
        }
      }
    };
    xhr.ontimeout = (e) => {
      console.error("Connection to server timed out");
      error_callback();
    };
    xhr.onerror = (e) => {
      console.error("Failed to fetch transactions");
      error_callback();
    };

    xhr.send(null);


  }
  catch {
    console.error("Failed to fetch transactions");
    error_callback();
  }
}

export default {
  FetchResourceInfo,
  FetchOrderInfo,
  FetchTransactionData,
  FetchTransactionDataAsync,
  FetchLocs,
}
