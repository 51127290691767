class APIClientError extends Error {
  /**
   * @param {("network"|"http")} type
   * @param {number} [status]
   */
  constructor(type, status) {
    super(`APIClientError: type=${type} status=${status}`)
    this.type = type
    this.status = status

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIClientError)
    }

    this.name = "APIClientError"
  }
}


/**
 * Returns response's json data. Throws an error if the request is not successful.
 * @param {string} url
 * @param {RequestInit} [init]
 * @returns {Promise<any>}
 */
const myFetcher = (url, init={}) => fetch(url, init)
  .catch(() => {throw new APIClientError("network")})
  .then(res => {
    if (!res.ok) {throw new APIClientError("http", res.status)}
    return res
  })
  .then(res => res.json())

function projectUsersClient(projectId, userId) {
  return {
    put: async (permissionType) => {
      const data = await myFetcher(`/api/projects/${projectId}/users/${userId}/permission`, {
        method: "PUT",
        body: JSON.stringify({type: permissionType}),
        headers: {
          "Content-Type": "application/json",
        }
      })
      return data
    }
  }
}

/**
 * @param {string} projectId
 */
function projectClient(projectId) {
  return {
    users: {
      get: async () => {
        /**
         * @type {{users: Array.<{
         *   id: string,
         *   name: string,
         *   roles: string[],
         *   permission: string
         * }>}}
         */
        const data = await myFetcher(`/api/projects/${projectId}/users`)
        return data
      },
      id: userId => projectUsersClient(projectId, userId)
    },
  }
}

function userClient(userId) {
  return {
    delete: async () => {
      await fetch(`/api/users/${userId}`, {
        method: "DELETE",
      })
    },
    password: {
      post: async (newPassword) => {
        return fetch(`/api/users/${userId}/password`, {
          method: "POST",
          body: JSON.stringify({password: newPassword}),
          headers: {
            "Content-Type": "application/json",
          }
        })
      }
    },
    put: async (data) => {
      return fetch(`/api/users/${userId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
    }
  }
}

const api = {
  me: {
    get: async () => {
      /**
       * @type {{name: string, id: string, roles: Array.<ServerRole>}}
       */
      const data = await myFetcher("/api/me")
      return data
    },
    projects: {
      get: async () => {
        /**
         * @type {{
         *   projects: Array.<{
         *     id: string,
         *     name: string,
         *     description: string | undefined,
         *     permission: ProjectPermission
         *   }>
         * }}
         */
        const data = await myFetcher("/api/me/projects")
        return data
      },
      id: (projectId) => ({
        permission: {
          get: async () => {
            /**
             * @type {{type: ProjectPermission|null}}
             */
            const data = await myFetcher(`/api/me/projects/${projectId}/permission`)
            return data
          }
        }
      })
    },
  },
  projects: {
    id: projectClient,
  },
  users: {
    get: async (query) => {
      const url = query
        ? "/api/users?" + new URLSearchParams({search: query})
        : "/api/users"
      /**
       * @type {{users: Array.<{id: string, name: string, roles: string[]}>}}
       */
      const data = await myFetcher(url)
      return data
    },
    id: userClient,
  }
}

export {
  api,
  APIClientError,
}