<script>
  import { getContext, onMount } from "svelte";
  import GanttChartBox from "../../components/ganttchart/GanttChartBox.svelte";
  import Loading from "../../components/pages/Loading.svelte";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import {
    validatePath,
    FetchError,
    fetchOrderGanttProjectInfo,
  } from "../../utils/getData";
  import { initGanttStore } from "../../components/ganttchart/ganttStore/init.js";

  export let projectId;
  export let user;

  const GanttStore = initGanttStore()

  const order_url = `/api/projects/${projectId}/OrderGantt/Order/`;
  const project_json_url = `/api/projects/${projectId}/OrderGantt/project.json`;
  let projectInfo = null;
  let styleInfo = null;
  //let resourceInfo = {};
  let ganttChart = null;

  let loaded_resources = {};

  // New GanttChart vars
  let projectData;
  let uploadTimeStamp;
  let styleDict;
  let transactions;
  let readyToRender = false;
  let ganttChartType = GanttStore.GanttChartTypes.SimpleOrderGantt;

  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({
    title: projectInfo?.Project?.[5]
      ? `${projectInfo.Project[5]} (${"オーダガントチャート(シンプル版)"})`
      : "オーダガントチャート(シンプル版)",
  });
  $: setHeaderProps({uploadTime: uploadTimeStamp})

  const { handleFetchErrors } = getContext("fetchErrorHandler");

  async function fetchProjectInfo() {
    try {
      const data = await fetchOrderGanttProjectInfo(projectId);
      projectInfo = data;
      projectData = projectInfo.Project;
      uploadTimeStamp = projectInfo.UploadTimeStamp;
      fetchStyleInfo(projectData[7]);
    } catch (e) {
      console.error(e);
      if (e instanceof FetchError) {
        handleFetchErrors(e.error);
      }
    }
  }

  function fetchStyleInfo(styles) {
    const _styleDict = {};
    styles.forEach((style) => {
      const _styleUrl = `/api/projects/${projectId}/OrderGantt/styles/${style.ObjectID}.json`;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", _styleUrl, false);
      xhr.send();
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);
        _styleDict[style.StyleName] = data;
      } else {
        console.error("Failed to fetch style " + style);
      }
    });
    styleDict = _styleDict;
    readyToRender = true;
  }

  onMount(async () => {
    fetchProjectInfo();
  });
</script>

{#if readyToRender === true}
  <GanttChartBox
    GanttStore={GanttStore}
    boxDimensions="height: calc(100% - 5px);"
    {styleDict}
    rowDataUrl={order_url}
    {uploadTimeStamp}
    {projectData}
    transactions={null}
    registerTransaction={null}
    {projectId}
    userId={user.id}
    {ganttChartType}
  />
{/if}
{#if projectInfo == null}
  <Loading />
{/if}