<script>
  import CloseIconButton from "../IconButtons/CloseIconButton.svelte"
  import SelectPermission from "./SelectPermission.svelte"
  import {useMe} from "../../hooks/useMe.js"
  import {useProject} from "../../hooks/useProject.js"
  import {createForm} from "felte"
  import {validator} from "@felte/validator-zod"
  import {z} from "zod"
  import User from "./User.svelte"
  import {api} from "../../utils/APIClient.js"
  import ProjectUsersList2Entry from "./ProjectUsersList2Entry.svelte"
  import InvitationDialogSelectUser from "./InvitationDialogSelectUser.svelte"
  import {getNotificationsContext} from "svelte-notifications"
  import {useQueryClient} from "@sveltestack/svelte-query"
  import DialogBase from "../dialog/DialogBase.svelte"
  import {useProjectUsers} from "../../hooks/useProjectUsers.js"
  import PrimaryButton from "../button/generic/PrimaryButton.svelte"
  import PrimaryBorderedButton from "../button/generic/PrimaryBorderedButton.svelte"
  import {translate} from "../../i18n/i18next.js";
  import DialogTitle from "../dialog/DialogTitle.svelte";
  import DialogSplitter from "../dialog/DialogSplitter.svelte";
  import DialogCaption from "../dialog/DialogCaption.svelte";
  import DialogContent from "../dialog/DialogContent.svelte";
  import DialogBottomControls from "../dialog/DialogBottomControls.svelte";
  import DialogBottomControlButton from "../dialog/DialogBottomControlButton.svelte";

  export let dialog
  export let projectId
  export let onClose

  let newUserProjectPermission = "ReadOnly"
  let newUserInvitationLink = null
  let users = []
  let selectedUser = null
  let displayInvitationLinkLoopBackAddressWarning = null

  function initialize() {
    users = []
    selectedUser = null
    newUserProjectPermission = "ReadOnly"
    newUserInvitationLink = null
    displayInvitationLinkLoopBackAddressWarning = null
  }

  const {addNotification} = getNotificationsContext()
  const projectUsers = useProjectUsers(projectId)

  async function searchUsers(query){
    const data = await api.users.get(query)
    users = data.users
  }
  const {form, errors} = createForm({
    extend: validator({
      schema: z.object({
        query: z.string().min(1, translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.existingUser.noSearchTextError"))
      }),
    }),
    onSubmit: async (values) => {
      await searchUsers(values.query)
      selectedUser = null
    },
    onError: () => {
      return {
        query: translate("generic.searchFailed")
      }
    }
  })

  const me = useMe()
  const project = useProject(projectId)
  const queryClient = useQueryClient()

  function handleNewUserProjectPermissionChange(event) {
    newUserProjectPermission = event.target.value
  }
  async function handleNewUserCreateInvitation() {
    const {origin, hostname} = new URL(window.location)

    // since loop back address cannot be accessed by another computer, warn users of its limited usability
    displayInvitationLinkLoopBackAddressWarning = hostname.startsWith("localhost") || hostname.startsWith("127.0.0.1")

    newUserInvitationLink = null
    const response = await fetch(`/api/projects/${projectId}/invitations`, {
      method: "POST",
      body: JSON.stringify({
        targetUserId: null,
        permission: newUserProjectPermission,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      const invitation = await response.json()
      newUserInvitationLink = origin + `/signup?invitation=${invitation.id}`
    }
  }

  function createInvitationMessage() {
    return translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.invitationMessage", null, {
      userName: $me.name,
      userId: $me.id,
      projectName: $project.name,
      projectId: $project.id,
      invitationLink: newUserInvitationLink,
    })
  }

  async function handleUserAdded(userId, permission) {
    const response = await fetch(`/api/projects/${projectId}/users/${userId}/permission`, {
      method: "PUT",
      body: JSON.stringify({
        type: permission,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    })

    if (response.ok) {
      addNotification({
        text: translate("frontend:components.invitationDialog.userAdded"),
        position: "top-right",
        removeAfter: 4000,
        type: "success",
      })
      await queryClient.invalidateQueries(`/api/projects/${projectId}/users`)
      onClose()
      initialize()
    } else {
      addNotification({
        text: translate("frontend:components.invitationDialog.userAdditionFailed"),
        position: "top-right",
        removeAfter: 4000,
        type: "error",
      })
    }
  }
</script>

<DialogBase bind:dialog={dialog} width="700px">
  <DialogTitle title={translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.title")} />
  <DialogSplitter />
  <DialogContent>
    <div class="caption">
      {translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.caption")}
    </div>
    <div class="section-title">{translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.existingUser.title")}</div>
    <div class="caption">
      {translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.existingUser.caption")}
    </div>
    <div style="display: flex; flex-direction: column; gap: 1rem">
      {#if selectedUser == null}
        <form use:form id="form-user-select">
          <div style="display: flex; gap: 2rem">
            <input
              id="input-search-user"
              name="query"
              placeholder={translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.existingUser.inputPlaceHolder")}
            />
            <PrimaryButton type="submit">
              {translate("generic.search")}
            </PrimaryButton>
          </div>
          {#if $errors.query}
            <span class="error">{$errors.query}</span>
          {/if}
        </form>
        {#if users.length > 0}
          <div id="search-result-container">
            {#each users as user}
              <ProjectUsersList2Entry projectUser={user}>
                <InvitationDialogSelectUser
                  onAdded={(permission) => handleUserAdded(user.id, permission)}
                  disabled={$projectUsers.findIndex(u => u.id === user.id) !== -1}
                />
              </ProjectUsersList2Entry>
            {/each}
          </div>
        {/if}
      {:else}
        <div id="selected-user-container">
          <User user={selectedUser} />
          <CloseIconButton onClick={() => {selectedUser = null}} />
        </div>
      {/if}
    </div>
    <div class="section-title">{translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.newUser.title")}</div>
    <div class="caption">{translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.newUser.caption")}</div>
    <div style="display: flex; justify-content: space-between; padding-top: 0.2rem">
      <div>
        <span>{translate("generic.permission")}: </span>
        <SelectPermission permission={newUserProjectPermission} onChange={handleNewUserProjectPermissionChange} />
      </div>
      <PrimaryButton onClick={handleNewUserCreateInvitation} disabled={!!newUserInvitationLink}>{translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.newUser.createInvitation")}</PrimaryButton>
    </div>
    {#if newUserInvitationLink}
      {#if displayInvitationLinkLoopBackAddressWarning}
        <div class="invitation-link-warning-message">{translate("frontend:pages:projects.project.admin.sections.users.invitationDialog.sections.newUser.loopBackAddressWarning")}</div>
      {/if}
      <div class="invitation-container">
        <textarea class="input-invitation-message" id="input-invitation-message">
          {createInvitationMessage()}
        </textarea>
        <div class="buttons-container">
          <PrimaryBorderedButton onClick={() => {
            document.querySelector("textarea#input-invitation-message").focus()
            document.querySelector("textarea#input-invitation-message").select()
            document.execCommand("copy")
          }}>
            {translate("generic.copy")}
          </PrimaryBorderedButton>
        </div>
      </div>
    {/if}
  </DialogContent>
  <DialogSplitter />
  <DialogBottomControls>
    <DialogBottomControlButton type="secondary" onClick={onClose}>
      {translate("generic.cancel")}
    </DialogBottomControlButton>
  </DialogBottomControls>
</DialogBase>

<style>
  .invitation-container {
    width: 90%;
    align-self: center;
    border-top: 1px solid lightgrey;
    padding-top: 0.3rem;
    margin-top: 0.3rem;
  }
  .input-invitation-message {
    padding: 0.2rem;
    width: 100%;
    min-height: 10rem;
  }
  .buttons-container {
    display: flex;
    justify-content: end;
    gap: 1rem;
  }

  #form-user-select {
    width: 100%;
  }

  #input-search-user {
    flex-grow: 1;
    font-size: 1rem;
    padding: 0.4rem 0.2rem;
    box-sizing: border-box;
    display: block;
    background-color: rgb(247, 249, 251);
    border: rgb(185, 202, 222) solid 1px;
    border-radius: 4px;
    color: rgb(101, 109, 118);
  }
  .error {
    color: red;
    padding-left: 1rem;
    font-size: 0.8rem;
  }
  #search-result-container {
    max-height: 300px;
    width: 95%;
    overflow-y: auto;
    border-radius: 5px;
    border: 1px solid darkgray;
    padding: 1rem;
  }
  .invitation-link-warning-message {
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: #a65403;
    background-color: #fff4e5;
  }
  .section-title {
    font-weight: bold;
  }
</style>