<script>
  import GuestDispatchingViewSearch from "../../../../routes/DispatchingView/DispatchingViewSearch.svelte"
  import AuthenticationRequired from "../../../../components/functional/AuthenticationRequired.svelte"
  import ProjectPageBase from "../../../../components/pages/projects/[project]/ProjectPageBase.svelte"
  import CheckDataStatus from "../../../../components/pages/projects/[project]/CheckDataStatus.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase let:projectId>
    <CheckDataStatus projectId={projectId} viewName="DispatchingView">
      <GuestDispatchingViewSearch />
    </CheckDataStatus>
  </ProjectPageBase>
</AuthenticationRequired>