<script>
  import { setContext } from "svelte"
  import { fetchErrorHandlerContextValueStore } from "./fetchErrorHandlerContextValueStore.js"
  import { useLocation } from "svelte-navigator"
  import { writable } from "svelte/store"
  import { getNotificationsContext } from "svelte-notifications"
  import DisplayFetchError from "./DisplayFetchError.svelte"
  import { navigateI18n } from "../../i18n/navigate.js";
  import { translate } from "../../i18n/i18next.js";

  const {addNotification} = getNotificationsContext()
  const isBlockingError = writable(false)
  function handleFetchErrors(error) {
    isBlockingError.set(true)
    fetchErrorHandlerContextValueStore.setError(error)
  }

  /**
   * Fetch error handler for cases where the error is non-blocking
   * @param {FetchError} error
   * @param {function | null} getCustomNotificationMessage
   */
  function handleNonBlockingFetchErrors(error, getCustomNotificationMessage = null) {
    function getNotificationMessage(error) {
      switch (error.type) {
        case "error:license:max-ccu-exceeded":
          return translate("frontend:generic.errors.maxCcuExceeded.title")
        case "error:file-not-found":
          return translate("frontend:generic.errors.fileNotFound.title")
        case "error:forbidden":
          return translate("frontend:generic.errors.forbidden.title")
        default:
          return translate("frontend:generic.errors.unknown.title", null, {errorCode: error.type})
      }
    }

    isBlockingError.set(false)
    addNotification({
      text: getCustomNotificationMessage ? getCustomNotificationMessage(error) : getNotificationMessage(error),
      type: "error",
      position: "top-right"
    })
    fetchErrorHandlerContextValueStore.setError(error)
  }

  const {error, clearError} = setContext("fetchErrorHandler", {
    ...fetchErrorHandlerContextValueStore,
    handleFetchErrors,
    handleNonBlockingFetchErrors,
  })
  function onClickReturnToTop() {
    isBlockingError.set(false)
    clearError()
    navigateI18n("/")
    window.location.reload()
  }

  // when the location changes (namely when the back button of browsers is pressed), clear the error
  const location = useLocation()
  location.subscribe(() => clearError())
</script>

{#if $isBlockingError}
  {#if $error.type === "error:license:max-ccu-exceeded"}
    <DisplayFetchError
      title={translate("frontend:generic.errors.maxCcuExceeded.title")}
      onClickReturnToTop={onClickReturnToTop}
    >
      {translate("frontend:generic.errors.maxCcuExceeded.caption")}
    </DisplayFetchError>
  {:else if $error.type === "error:file-not-found"}
    <DisplayFetchError
      title={translate("frontend:generic.errors.fileNotFound.title")}
      onClickReturnToTop={onClickReturnToTop}
    >
      {translate("frontend:generic.errors.fileNotFound.caption")}
    </DisplayFetchError>
  {:else if $error.type === "error:forbidden"}
    <DisplayFetchError
      title={translate("frontend:generic.errors.forbidden.title")}
      onClickReturnToTop={onClickReturnToTop}
    >
      {translate("frontend:generic.errors.forbidden.caption")}
    </DisplayFetchError>
  {:else if $error.type === "error:incompatible-plugin-version"}
    <DisplayFetchError
      title={translate("frontend:generic.errors.incompatiblePluginVersion.title")}
      onClickReturnToTop={onClickReturnToTop}
    >
      {translate("frontend:generic.errors.incompatiblePluginVersion.caption1", null, {frontendVersion: $error.thisFrontendVersion, pluginVersion: $error.pluginVersionRequirement})} <br />
      {translate("frontend:generic.errors.incompatiblePluginVersion.caption2")}
    </DisplayFetchError>
  {:else}
    <DisplayFetchError
      title={translate("frontend:generic.errors.unknown.title")}
      onClickReturnToTop={onClickReturnToTop}
    >
      {translate("frontend:generic.errors.unknown.caption", null, {errorCode: $error.type})}
    </DisplayFetchError>
  {/if}
  <div style="display: none">
    <slot />
  </div>
{:else}
  <slot />
{/if}
