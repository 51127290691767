import {getConfiguration, saveConfiguration} from "../configurationStorage.js"
import {z} from "zod"

/**
 * @typedef {{
 *   displayCompletedTasks?: boolean,
 *   lastUsedResourceCode?: string | null,
 *   reliefResourceGroup?: string | null,
 *   reliefResource?: string | null,
 *   relievedOperations?: string,
 *   styleName?: string | null,
 * }} DispatchingViewProfile1Configuration
 */

const VIEW_NAME = "DispatchingView_Profile1"

/**
 * @type {DispatchingViewProfile1Configuration}
 */
const defaultValue = {
  displayCompletedTasks: false,
  lastUsedResourceObjectID: null,
  reliefResourceGroup: null,
  reliefResource: null,
  relievedOperations: "[]",
  styleName: null,
}

const DispatchingViewProfile1ConfigurationSchema = z.object({
  displayCompletedTasks: z.boolean(),
  lastUsedResourceObjectID: z.union([
    z.null(),
    z.number()
  ]),
  reliefResourceGroup: z.union([
    z.null(),
    z.string(),
  ]),
  reliefResource: z.union([
    z.null(),
    z.string(),
  ]),
  relievedOperations: z.string(),
  styleName: z.union([
    z.string(),
    z.null(),
  ]),
})

/**
 * @param {string} userId
 * @param {string} projectId
 * @returns {DispatchingViewProfile1Configuration}
 */
export function getDispatchingViewProfile1Configuration(userId, projectId) {
  return getConfiguration(VIEW_NAME, userId, projectId, defaultValue)
}

/**
 * @param {Partial<DispatchingViewProfile1Configuration>} configuration
 * @param {string} userId
 * @param {string} projectId
 */
export function saveDispatchingViewProfile1Configuration(configuration, userId, projectId) {
  const saved = {...defaultValue, ...getDispatchingViewProfile1Configuration(userId, projectId), ...configuration}
  try {
    DispatchingViewProfile1ConfigurationSchema.parse(saved)
    saveConfiguration(VIEW_NAME, userId, projectId, saved)
  } catch (e) {
    console.error("Malformed configuration for DispatchingViewProfile1")
    console.log(e)
  }
}

export function saveDispatchingViewProfile1RelievedOperationsConfiguration(operation, isRelieving, userId, projectId) {
  const saved = {...defaultValue, ...getDispatchingViewProfile1Configuration(userId, projectId)}
  let relievedOperations = []
  try {
    relievedOperations = JSON.parse(saved.relievedOperations)
  } catch (e) {}

  if (isRelieving && !relievedOperations.includes(operation)) {
    relievedOperations.push(operation)
  }
  if (!isRelieving) {
    const index = relievedOperations.indexOf(operation)
    if (index >= 0) {
      relievedOperations.splice(index, 1)
    }
  }

  saveDispatchingViewProfile1Configuration({relievedOperations: JSON.stringify(relievedOperations)}, userId, projectId)
}


