<script>
  import { onMount } from 'svelte';
  import GanttHelper from './GanttHelper.js';
  import ChartContentRow from './ChartContentRow.svelte';
  import ResourceConnectionLines from './ResourceConnectionLines.svelte';
  import { validatePath } from '../../utils/getData.js';
  import ChartLabel from './ChartLabel.svelte';

  export let GanttStore

  let schedulingStartDate = new Date();
  let schedulingEndDate = new Date();
  let startDateAreaEnd;
  let endDateAreaStart;
  let FPendDateAreaStart;
  let FPstartDateAreaEnd;

  let startDate = new Date();
  let endDate = new Date();

  let validStartDate;
  let validEndDate;

  // Display zones
  let startDDT;
  let endDDT;
  const AdjustStamp = (stamp) => GanttHelper._gantHelper_adjustDateWithinADay(stamp, startDDT, endDDT);

  let spanInSeconds;

  let verticalSplits = [];

  let rows = [];
  let displayedRows = 0;

  let rowDownloadUrl = "";

  let ganttChartType;

  let rowlabels = [];

  let currentStyle = "";

  const CalculateStartAndEndAreas = () => {
    startDateAreaEnd = Math.min(100, 100 * GanttHelper._gantHelper_scale(schedulingStartDate.getTime(), endDate.getTime(), startDate.getTime()));
    endDateAreaStart = Math.max(0, 100 * GanttHelper._gantHelper_scale(schedulingEndDate.getTime(), endDate.getTime(), startDate.getTime()));
    FPstartDateAreaEnd = Math.min(100, 100 * GanttHelper._gantHelper_scale(validStartDate.getTime(), endDate.getTime(), startDate.getTime()));
    FPendDateAreaStart = Math.max(0, 100 * GanttHelper._gantHelper_scale(validEndDate.getTime(), endDate.getTime(), startDate.getTime()));
  }

  const GeneralZoom = (e) => {
    e.preventDefault();

    if (e.ctrlKey) {
      // Row nb scroll
      if (e.deltaY > 0)
        GanttStore.DisplayedRowCount.update((n) => (n + 1 >= 100) ? n : n + 1);
      else if (e.deltaY < 0)
        GanttStore.DisplayedRowCount.update((n) => (n > 1) ? n - 1 : n);
      // Zoom
      if (e.deltaY > 0)
        GanttStore.DisplayedSpanInSeconds.update((d) => Math.min(d * 1.05, GanttHelper.GHYEAR));
      else if (e.deltaY < 0)
        GanttStore.DisplayedSpanInSeconds.update((d) => Math.max(d / 1.05, GanttHelper.GHDAY));
    }
  }

  onMount(() => {
    GanttStore.GanttChartType.subscribe((newType) => {
      ganttChartType = newType;
    });
    GanttStore.RowDownloadUrl.subscribe((rdu) => {
      rowDownloadUrl = rdu;
    });
    GanttStore.StartDate.subscribe((ssd) => schedulingStartDate = ssd);
    GanttStore.EndDate.subscribe((sed) => schedulingEndDate = sed);
    GanttStore.ValidEndDate.subscribe(
      (newDate) => {
        validEndDate = newDate;
    });
    GanttStore.ValidStartDate.subscribe(
      (newDate) => {
        validStartDate = newDate;
    });
    GanttStore.CurrentDisplayedEndDate.subscribe((newDate) => {
      endDate = newDate;
      CalculateStartAndEndAreas();
    });
    GanttStore.CurrentDisplayedStartDate.subscribe((newDate) => startDate = newDate);
    GanttStore.DisplayedSpanInSeconds.subscribe((newSpan) => {
      spanInSeconds = newSpan;
      CalculateStartAndEndAreas();
    });
    GanttStore.InternalLowerSplits.subscribe((newSplits) => verticalSplits = newSplits);
    GanttStore.RowsCurrentlyDisplayed.subscribe((displayed) => {
      // console.log(displayed);
      rows = displayed;
    });
    GanttStore.DisplayedRowCount.subscribe((displayed) => displayedRows = displayed);
    GanttStore.StartDayDisplayTime.subscribe((sddt) => startDDT = sddt);
    GanttStore.EndDayDisplayTime.subscribe((eddt) => endDDT = eddt);

    GanttStore.CurrentStyle.subscribe((cs) => currentStyle = cs);
  });
</script>

<svg id="gantt_chartarea_chartcontent" class="pullable" on:wheel={GeneralZoom}>
  <pattern
    id="stripe"
    patternUnits="userSpaceOnUse"
    width="6.5"
    height="6.5"
    patternTransform="rotate(45)"
  >
    <line
      x1="0"
      y="0"
      x2="0"
      y2="6.5"
      stroke="#828282"
      stroke-width="1"
    />
  </pattern>
  {#if startDateAreaEnd > 0}
    <rect
      x="0"
      y="0"
      height="100%"
      width="{startDateAreaEnd}%"
      fill="#ffddcb"
      pointer-events="none"
    />
  {/if}
  {#if endDateAreaStart < 100}
    <rect
      x="{endDateAreaStart}%"
      y="0"
      height="100%"
      width={(100 - endDateAreaStart) + "%"}
      fill="#cbddff"
      pointer-events="none"
    />
  {/if}
  {#each verticalSplits as vsplit}
    <line
      x1="{vsplit.EndX}%"
      x2="{vsplit.EndX}%"
      y1="0"
      y2="100%"
      stroke="#cccccc"
      stroke-width="2"
      stroke-dasharray="5px"
    />
  {/each}
  {#if FPstartDateAreaEnd > 0}
    <rect
      x="0"
      y="0"
      height="100%"
      width="{FPstartDateAreaEnd}%"
      fill="rgba(128, 128, 128, 0.5)"
      pointer-events="none"
    />
  {/if}
  {#if FPendDateAreaStart < 100}
    <rect
      x="{FPendDateAreaStart}%"
      y="0"
      height="100%"
      width={(100 - FPendDateAreaStart) + "%"}
      fill="rgba(128, 128, 128, 0.5)"
      pointer-events="none"
    />
  {/if}


    {#each rows as row, index}
      {#if row !== null && rowDownloadUrl !== ""}
          <ChartContentRow
            GanttStore={GanttStore}
            Row={row.Row}
            RowType={row.RowType}
            Alias={row.Alias}
            IsNull={row === null}
            Height={(displayedRows > 0) ? 100 / displayedRows : 100}
            OriginY={((displayedRows > 0) ? 100 / displayedRows : 100) * index}
            DownloadUrl={rowDownloadUrl + row.Row + ".json"}
            StartDate={startDate}
            EndDate={endDate}
            AdjustStamp={AdjustStamp}
            IsOperation={row.IsOperation}
            Index={index}
            bind:ganttChartType
            bind:rowlabels
          />
      {/if}
    {/each}

    {#if ganttChartType === GanttStore.GanttChartTypes.SimpleResGantt || ganttChartType === GanttStore.GanttChartTypes.ResGantt || ganttChartType === GanttStore.GanttChartTypes.CustomizedResGantt}
      <ResourceConnectionLines
        GanttStore={GanttStore}
        AdjustStamp={AdjustStamp}
        RowDownloadUrl={rowDownloadUrl}
      />
    {/if}

    {#each rowlabels as row, index}
      {#if row != undefined && rows[index] != null}
        {#each row as label}
          <ChartLabel
            Label={label}
            StartDate={startDate}
            EndDate={endDate}
            Height={(displayedRows > 0) ? 100 / displayedRows : 100}
            OriginY={((displayedRows > 0) ? 100 / displayedRows : 100) * index}
            AdjustStamp={AdjustStamp}
            CurrentStyle={currentStyle.StyleName}
          />
        {/each}
      {/if}
    {/each}

</svg>

<style>
  svg#gantt_chartarea_chartcontent {
    width: 100%;
    flex: 1;
  }
</style>
