<script>
    export let filterItem = null;
</script>

<div class="wrapper">
    <div class="white_space"/>
    {#if filterItem !== null}
        <div class="tooltip">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="#E53935"/>
                <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" stroke="white"/>
                <rect x="5" y="8" width="14" height="2" rx="1" fill="white"/>
                <rect x="7" y="12" width="10" height="2" rx="1" fill="white"/>
                <rect x="9" y="16" width="6" height="2" rx="1" fill="white"/>
            </svg>
            <span class="tooltiptext">フィルタ：{filterItem}</span>
        </div>
    {/if}
</div>

<style>
    .wrapper{
        display: flex;
        flex-direction: row;
        height: 10px;
    }

    .white_space{
        flex: 1;
    }

    .tooltip {
        align-self: flex-end;
        position: relative;
        display: inline-block;
        height: 10px;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        background-color: white;

        width: auto;
        white-space: nowrap;

        color: black;
        text-align: center;
        border: 1px solid black;
        padding: 2px;
        position: absolute;
        z-index: 1;
        top: 100%; 
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1.0;
    }
</style>