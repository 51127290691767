<script>
  import { onMount } from "svelte";
  import GanttHelper from "./GanttHelper.js";
  import GeneralButton from "../button/GeneralButton.svelte";
  import { getTimeStringSec } from "../../utils/time";
  import { translate } from "../../i18n/i18next.js";
  import ToggleSwitch from "../input/ToggleSwitch.svelte"

  export let operationCode;
  export let onSubmit;
  export let onClose;
  export let prevStartDateTime;
  export let prevEndDateTime;
  export let prevStatus;
  export let prevQty;
  export let itemQty;
  export let scheduledStartDateTime;
  export let scheduledEndDateTime;

  let statusEnum = [
    GanttHelper.EStatus.NOTSTARTED,
    GanttHelper.EStatus.DESIGNED,
    GanttHelper.EStatus.CONFIRMED,
    GanttHelper.EStatus.STARTED,
    GanttHelper.EStatus.COMPLETE,
  ];

  const statusTranslationKeys = [
    "asprova.terms.operationStatuses.scheduled",
    "asprova.terms.operationStatuses.released",
    "asprova.terms.operationStatuses.frozen",
    "asprova.terms.operationStatuses.started",
    "asprova.terms.operationStatuses.completed"
  ];

  let startDateInput = null;
  let endDateInput = null;
  let currentStatus = null;

  let startResetButton = null;
  let endResetButton = null;

  const N_SELECT = 3;
  let startSelects = Array(N_SELECT);
  let endSelects = Array(N_SELECT);
  let quantityInputs = Array(1);
  let sendResQty = false;
  let quantityInputToggleDisabled = false;

  let ranges = [getHourRange, getMinuteRange, getSecondRange];

  function getHourRange(year, month) {
    return Array.from({ length: 24 }, (_, index) => index);
  }

  function getMinuteRange(year, month) {
    return Array.from({ length: 60 }, (_, index) => index);
  }

  function getSecondRange(year, month) {
    return Array.from({ length: 60 }, (_, index) => index);
  }

  // options: Array of options, only for dates
  function populateSelect(select, options, idx) {
    select.innerHTML = "";
    options.forEach((option) => {
      let optionElement = document.createElement("option");
      optionElement.value = option;
      optionElement.text = option;
      if (optionElement.text.length < 2) {
        optionElement.text = "0" + optionElement.text;
      }
      select.appendChild(optionElement);
    });
  }

  function selects2DateTime(dateInput, selects) {
    let date = new Date(dateInput.value);
    date.setHours(selects[0].value, selects[1].value, selects[2].value);
    return date;
  }

  function dateTimeString2selects(dateInput, selects, dateTimeString) {
    let dateTime = new Date(dateTimeString);

    if (isNaN(dateTime.getTime())) {
      return;
    }
    let initialDates = [
      dateTime.getFullYear(),
      dateTime.getMonth() + 1,
      dateTime.getDay(),
      dateTime.getHours(),
      dateTime.getMinutes(),
      dateTime.getSeconds(),
    ];

    const y = dateTime.getFullYear();
    const m = ("0" + (dateTime.getMonth() + 1)).slice(-2);
    const d = ("0" + dateTime.getDate()).slice(-2);

    dateInput.value = `${y}-${m}-${d}`; // Should be yyyy-mm-dd

    selects.forEach(function (select, index) {
      select.value = initialDates[index + 3].toString();
    });
  }

  function DisableDatesDependingOnStatus() {
    const status = parseInt(currentStatus);
    switch (status) {
      case GanttHelper.EStatus.COMPLETE:
        startSelects.forEach((select) => (select.disabled = false));
        endSelects.forEach((select) => (select.disabled = false));
        startDateInput.disabled = false;
        endDateInput.disabled = false;
        startResetButton.disabled = false;
        endResetButton.disabled = false;
        quantityInputToggleDisabled = false;
        break;
      case GanttHelper.EStatus.STARTED:
        startSelects.forEach((select) => (select.disabled = false));
        endSelects.forEach((select) => (select.disabled = true));
        startDateInput.disabled = false;
        endDateInput.disabled = true;
        startResetButton.disabled = false;
        endResetButton.disabled = true;
        quantityInputToggleDisabled = false;
        break;
      case GanttHelper.EStatus.UNSET:
      case GanttHelper.EStatus.CONFIRMED:
      case GanttHelper.EStatus.DESIGNED:
      case GanttHelper.EStatus.NOTSTARTED:
      default:
        startSelects.forEach((select) => (select.disabled = true));
        endSelects.forEach((select) => (select.disabled = true));
        startDateInput.disabled = true;
        endDateInput.disabled = true;
        startResetButton.disabled = true;
        endResetButton.disabled = true;
        quantityInputToggleDisabled = true;
        break;
    }
  }

  // Update status
  function updateStatus(selects) {
    DisableDatesDependingOnStatus();
  }

  function onConfirm() {
    let newStartDateTime = selects2DateTime(startDateInput, startSelects);
    if (startDateInput.disabled || isNaN(newStartDateTime.getTime())) {
      newStartDateTime = null;
    }
    const newStartDateTimeString = newStartDateTime ? getTimeStringSec(newStartDateTime, null) : null

    let newEndDateTime = selects2DateTime(endDateInput, endSelects);
    if (endDateInput.disabled  || isNaN(newEndDateTime.getTime())) {
      newEndDateTime = null;
    }
    const newEndDateTimeString = newEndDateTime ? getTimeStringSec(newEndDateTime, null) : null

    const newStatus = GanttHelper.GetStatusString(parseInt(currentStatus));
    const newQty = !sendResQty
      ? null
      : Number(quantityInputs[0].value);

    onSubmit(newStartDateTimeString, newEndDateTimeString, newStatus, newQty);
  }

  onMount(async () => {
    for (let i = 0; i < N_SELECT; i++) {
      populateSelect(
        startSelects[i],
        ranges[i](startSelects[0].value, startSelects[1].value),
        i
      );
      populateSelect(
        endSelects[i],
        ranges[i](endSelects[0].value, endSelects[1].value),
        i
      );
    }
    if (prevQty != -1) { // Just means resultQty set
      sendResQty = true;
    } else {
      prevQty = 0;
    }
    currentStatus = GanttHelper.GetStatus(prevStatus);
    quantityInputs[0].value = prevQty;
    dateTimeString2selects(startDateInput, startSelects, prevStartDateTime);
    dateTimeString2selects(endDateInput, endSelects, prevEndDateTime);
    DisableDatesDependingOnStatus();
  });
</script>

<div class="editWindow">
  <div class="top-panel">
    <div class="top-panel-title">
      <span>
        {translate("generic.editResultShort")}&nbsp;/&nbsp;{operationCode}
      </span>
    </div>
    <div class="top-panel-info">
      <span>
        {translate("asprova.terms.productionStartTime")}: <b>{getTimeStringSec(scheduledStartDateTime)}</b>
      </span>
      <br/>
      <span>
        {translate("asprova.terms.productionEndTime")}: <b>{getTimeStringSec(scheduledEndDateTime)}</b>
      </span>
    </div>
  </div>
  <hr class="horizontal"/>
  <div class="editWindow_contexts">
    <div class="editWindow_editstatus">
      <div class="editWindow_editstatus_section">
      <span class="result-edit-title">
        {translate("asprova.terms.status")}
      </span>
        <div>
          {#each statusTranslationKeys.map((translationKey, i) => [statusEnum[i], translationKey]) as option}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              class="status-radio"
              style="margin-top: 2%;"
              on:click={() => {
              currentStatus = option[0];
              updateStatus();
            }}
            >
              <input
                type="radio"
                bind:group={currentStatus}
                value={option[0]}
                on:change={updateStatus}
              />
              {translate(option[1])}
            </div>
          {/each}
        </div>
      </div>
      <div class="editWindow_editstatus_section">
        <span class="result-edit-title">
          {translate("asprova.terms.resultQuantity")}
          <ToggleSwitch bind:on={sendResQty} bind:disabled={quantityInputToggleDisabled}/>
        </span>
        <div style="display: inline-flex; align-items: center; gap: 1rem">
          <input
            style="max-width: 5rem;"
            type="number"
            class="quantityInputs"
            min="0"
            max={itemQty}
            bind:this={quantityInputs[0]}
            disabled={!sendResQty}
          />
          <span class="units" style="font-size: 1.3rem;">&nbsp;/ {itemQty}</span>
        </div>
      </div>
    </div>
    <div style="border-right: solid 1px #888888;min-width: 1px"></div>
    <div class="editWindow_editstamps">
      <div class="result-time-edit">
        <div class="result-edit-title">
          {translate("asprova.terms.resultStartTime")}
        </div>
        <div class="result-time-inputs-container">
          <input type="date" class="dateInput" bind:this={startDateInput} />
          <div class="result-time-time-inputs-container">
            <select
              class="startSelects"
              bind:this={startSelects[0]}
              style="margin-right: 2%;"
            />
            <span class="units">:</span>
            <select class="startSelects" bind:this={startSelects[1]} />
            <span class="units">:</span>
            <select class="startSelects" bind:this={startSelects[2]} />
          </div>
          <button
            class="resetButton"
            on:click={dateTimeString2selects(
            startDateInput,
            startSelects,
            prevStartDateTime
          )}
            bind:this={startResetButton}
          >
            {translate("generic.undo")}
          </button>
        </div>
      </div>
      <div class="result-time-edit">
        <div class="result-edit-title">
          {translate("asprova.terms.resultEndTime")}
        </div>
        <div class="result-time-inputs-container">
          <input type="date" class="dateInput" bind:this={endDateInput} />
          <div class="result-time-time-inputs-container">
            <select
              class="endSelects"
              bind:this={endSelects[0]}
              style="margin-right: 2%;"
            />
            <span class="units">:</span>
            <select class="endSelects" bind:this={endSelects[1]} />
            <span class="units">:</span>
            <select class="endSelects" bind:this={endSelects[2]} />
          </div>
          <button
            class="resetButton"
            on:click={dateTimeString2selects(
            endDateInput,
            endSelects,
            prevEndDateTime
          )}
            bind:this={endResetButton}
          >
            {translate("generic.undo")}
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr class="horizontal"/>
  <div class="bottom-controls">
    <GeneralButton
      buttonText={translate("generic.cancel")}
      buttonType="secondary"
      buttonFunction={onClose}
      buttonExtraStyle="min-width: 150px"
    />
    <GeneralButton
      buttonText={"OK"}
      buttonFunction={onConfirm}
      buttonExtraStyle="min-width: 150px"
    />
  </div>
</div>

<style>
  hr {
    height: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    border: 0;
  }
  hr.horizontal {
    border-top: 1px solid #888888;
  }
  .startSelects,
  .endSelects,
  .quantityInputs {
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    text-align: center;
    font-size: 1.5rem;
    font-family: "BIZ UDPGothic", sans-serif;
    cursor: pointer;
  }
  .startSelects:disabled,
  .endSelects:disabled,
  .quantityInputs:disabled,
  .dateInput:disabled {
    background-color: #00000022;
    /* text-emphasis-style: 'x'; */
    color: gray;
    cursor: default;
    border: none;
  }
  .top-panel {
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .top-panel-title {
    max-width: 100%;
    flex: 1;
    white-space: nowrap;
    overflow-wrap: anywhere;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .top-panel-info {
    white-space: nowrap;
    text-align: right;
    color: rgb(151, 151, 151);
    font-size: 1rem;
  }
  .editWindow {
    background-color: white;
    z-index: 102;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 92vh;
    max-width: 90%;
    overflow-y: auto;
    border: 2px solid rgb(40, 56, 93);
    border-radius: 10px;
    flex-direction: column;
    box-sizing: border-box;
  }
  .editWindow_contexts {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
  }
  .editWindow_editstatus {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    gap: 16px
  }
  .editWindow_editstatus_section {
    display: flex;
    flex-direction: column;
    gap: 8px
  }
  .editWindow_editstamps {
    display: flex;
    min-height: 100%;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 16px
  }
  .resetButton {
    white-space: nowrap;
    margin-right: 2%;
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .resetButton:disabled {
    /* opacity: 0; */
    background-color: #00000022;
    color: gray;
    cursor: default;
    border: none;
  }
  .units {
    color: rgb(0, 0, 0);
    font-size: 1rem;
    text-align: center;
  }

  .dateInput {
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    text-align: center;
    font-size: 1.5rem;
    font-family: "BIZ UDPGothic", sans-serif;
    cursor: pointer;
  }
  .status-radio {
    width: 200px;
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-family: "BIZ UDPGothic", sans-serif;
    box-sizing: border-box;
    cursor: pointer;
  }
  .result-time-inputs-container {
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    padding: 8px 0;
  }
  .result-time-time-inputs-container {
    display: inline-flex;
    align-items: center;
    gap: .3rem;
  }
  .result-edit-title {
    font-size: 1.2rem;
    font-weight: bold;
    display: inline-flex; 
    align-items: center; 
    gap: 1rem
  }
  .bottom-controls {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    font-size: 1.2rem;
  }
</style>
