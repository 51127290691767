<script>
  import { translate } from "../../i18n/i18next.js";

  export let selectId;
  export let onChangeEvent;
  export let options = [];
  export let value = null;
  export let extraStyle = null;
  export let defaultValue= null;
  export let defaultText = null;

  $: optionsArray = Array.isArray(options) ? options : Object.entries(options)
</script>

<!-- {value} -->
<select class="dropdown1" id={selectId} on:change={onChangeEvent} bind:value style={extraStyle}>
  {#if defaultValue != null && defaultText != null}
    <option value={defaultValue}>{defaultText}</option>
  {/if}
  {#each options as option}
    <option value={option.value}>
      {#if option.translationKey}
        {translate(option.translationKey)}
      {:else}
        {option.text}
      {/if}
    </option>
  {/each}
  <img src="/assets/dropdown.svg" />
</select>

<style>
  .dropdown1 {
    display: inline-block;
    background-color: white;
    border: solid 2px #00468f;
    border-radius: 6px;
    padding: 0.3em 0.8em;
    font-size: 1.0em;
    white-space: nowrap;
    cursor: pointer;
    line-height: 24px;
  }
</style>
