import dayjs from "dayjs";

import { getOperationCode, setStatus } from "./getData";
import { GetStatus, GetStatusText } from "../components/ganttchart/GanttHelper";

function mergeResourceDetail_and_TransactionData(operations, allData, title_key, transactionData) {

  // console.log(JSON.parse(JSON.stringify(operations)));
  // console.log(JSON.parse(JSON.stringify(allData)));
  // console.log(JSON.parse(JSON.stringify(title_key)));
  // console.log(JSON.parse(JSON.stringify(transactionData)));

  for(let i = 0; i < allData.length; i++) {
    if (!("Work_ResultStartTime" in allData[i])) {
      allData[i].Work_ResultStartTime = "";
    }
    if (!("Work_ResultEndTime" in allData[i])) {
      allData[i].Work_ResultEndTime = "";
    }
    if (!("Work_ResultMainRes" in allData[i])) {
      allData[i].Work_ResultMainRes = "";
    }


    let workCode = getOperationCode(allData[i]);

    // console.log("workCode: ", workCode);

    if(workCode in transactionData) {
      const work_ResultStartTime_of_allData = allData[i].Work_ResultStartTime;
      const work_ResultEndTime_of_allData = allData[i].Work_ResultEndTime;
      const work_ResultMainRes_of_allData = allData[i].Work_ResultMainRes;
      const work_ResultQty_of_allData = allData[i].Work_ResultQty;
      const work_WorkStatus_of_allData = allData[i].Work_Status;

      const work_ResultStartTime_of_transactionData = transactionData[workCode].Work_ResultStartTime;
      const work_ResultEndTime_of_transactionData = transactionData[workCode].Work_ResultEndTime;
      const work_ResultMainRes_of_transactionData = transactionData[workCode].Work_ResultMainRes;
      const work_ResultQty_of_transactionData = transactionData[workCode].Work_ResultQty;
      const work_WorkStatus_of_transactionData = transactionData[workCode].Work_Status;

      function isInvalidResult(result) {
        return result === "" || result === null || result === undefined;
      }

      function mergeResult(result_allData, result_transactionData) {
        // allData(FrontEnd用データ) と Transaction用データ のうち
        //  片方が無効なら、もう片方を採用
        //  両方無効なら、"""
        //  両方無効でないなら、新しい方を採用
        if(isInvalidResult(result_allData) && isInvalidResult(result_transactionData)) {
          return "";
        } else if(isInvalidResult(result_allData)) {
          return result_transactionData;
        } else if(isInvalidResult(result_transactionData)) {
          return result_allData;
        } else {
          // 両方無効でないなら、Transaction用を採用
          return result_transactionData;
        }
      }

      const resultStartTime = mergeResult(work_ResultStartTime_of_allData, work_ResultStartTime_of_transactionData);
      const resultEndTime = mergeResult(work_ResultEndTime_of_allData, work_ResultEndTime_of_transactionData);
      const resultMainRes = mergeResult(work_ResultMainRes_of_allData, work_ResultMainRes_of_transactionData);
      const resultQty = mergeResult(work_ResultQty_of_allData, work_ResultQty_of_transactionData);
      const resultStatus = mergeResult(work_WorkStatus_of_allData, work_WorkStatus_of_transactionData);

      if (resultStartTime != "") {
        const formatedStartTime = dayjs(resultStartTime).format("YYYY/MM/DD HH:mm:ss");
        allData[i].Work_ResultStartTime = formatedStartTime;
        if(title_key.indexOf("Work_ResultStartTime") !== -1) {
          operations[i][title_key.indexOf("Work_ResultStartTime")] = formatedStartTime;
        }
      }

      if (resultEndTime != "") {
        const formattedEndTime = dayjs(resultEndTime).format("YYYY/MM/DD HH:mm:ss");
        allData[i].Work_ResultEndTime = formattedEndTime;
        if(title_key.indexOf("Work_ResultEndTime") !== -1) {
          operations[i][title_key.indexOf("Work_ResultEndTime")] = formattedEndTime;
        }
      }

      if (resultMainRes != "") {
        allData[i].Work_ResultMainRes = resultMainRes;
        if(title_key.indexOf("Work_ResultMainRes") !== -1) {
          operations[i][title_key.indexOf("Work_ResultMainRes")] = resultMainRes;
        }
      }

      let newStatus;

      // If this result has status information, set to this status, or, set the status according to the existences of resultStart/endTime
      // This is because that a finished operation may also have no resultStart/endTime
      if (resultStatus != "") {
        newStatus = resultStatus;
      } else {
        if (resultStartTime != "") newStatus = "T";
        if (resultEndTime != "") newStatus = "B";
      }

      allData[i].Work_Status = newStatus;
      setStatus(allData[i], newStatus);

      if (title_key.indexOf("Work_Status") != -1) {
        operations[i][title_key.indexOf("Work_Status")] = GetStatusText(GetStatus(newStatus));
      }


      if (title_key.indexOf("Work_ResultQty") != -1) {
        operations[i][title_key.indexOf("Work_ResultQty")] = resultQty;
      }
    }
  }

  // console.log(JSON.parse(JSON.stringify(operations)));
  // console.log(JSON.parse(JSON.stringify(allData)));

  return [operations, allData];
}

/**
 * Apply transactions to allData (array of operations)
 * @param {object[]} originalAllData
 * @param {object[]} transactionData
 * @returns {object[]}
 */
export function applyTransactionsToOperations(originalAllData, transactionData) {
  if (!originalAllData) return []

  const allData = [...originalAllData]

  for(let i = 0; i < allData.length; i++) {
    if (!("Work_ResultStartTime" in allData[i])) {
      allData[i].Work_ResultStartTime = "";
    }
    if (!("Work_ResultEndTime" in allData[i])) {
      allData[i].Work_ResultEndTime = "";
    }
    if (!("Work_ResultMainRes" in allData[i])) {
      allData[i].Work_ResultMainRes = "";
    }

    const workCode = getOperationCode(allData[i]);

    if(workCode in transactionData) {
      const work_ResultStartTime_of_allData = allData[i].Work_ResultStartTime;
      const work_ResultEndTime_of_allData = allData[i].Work_ResultEndTime;
      const work_ResultMainRes_of_allData = allData[i].Work_ResultMainRes;
      const work_ResultQty_of_allData = allData[i].Work_ResultQty;
      const work_WorkStatus_of_allData = allData[i].Work_Status;
      
      const work_ResultStartTime_of_transactionData = transactionData[workCode].Work_ResultStartTime;
      const work_ResultEndTime_of_transactionData = transactionData[workCode].Work_ResultEndTime;
      const work_ResultMainRes_of_transactionData = transactionData[workCode].Work_ResultMainRes;
      const work_ResultQty_of_transactionData = transactionData[workCode].Work_ResultQty;
      const work_WorkStatus_of_transactionData = transactionData[workCode].Work_Status;

      function isInvalidResult(result) {
        return result === "" || result === null || result === undefined;
      }

      function mergeResult(result_allData, result_transactionData) {
        // allData(FrontEnd用データ) と Transaction用データ のうち
        //  片方が無効なら、もう片方を採用
        //  両方無効なら、"""
        //  両方無効でないなら、新しい方を採用
        if(isInvalidResult(result_allData) && isInvalidResult(result_transactionData)) {
          return "";
        } else if(isInvalidResult(result_allData)) {
          return result_transactionData;
        } else if(isInvalidResult(result_transactionData)) {
          return result_allData;
        } else {
          // 両方無効でないなら、Transaction用を採用
          return result_transactionData;
        }
      }
      
      const resultStartTime = mergeResult(work_ResultStartTime_of_allData, work_ResultStartTime_of_transactionData);
      const resultEndTime = mergeResult(work_ResultEndTime_of_allData, work_ResultEndTime_of_transactionData);
      const resultMainRes = mergeResult(work_ResultMainRes_of_allData, work_ResultMainRes_of_transactionData);
      const resultQty = mergeResult(work_ResultQty_of_allData, work_ResultQty_of_transactionData);
      const resultStatus = mergeResult(work_WorkStatus_of_allData, work_WorkStatus_of_transactionData);
      
      if (resultStartTime !== "") {
        allData[i].Work_ResultStartTime = dayjs(resultStartTime).format("YYYY/MM/DD HH:mm:ss");
      }

      if (resultEndTime !== "") {
        allData[i].Work_ResultEndTime = dayjs(resultEndTime).format("YYYY/MM/DD HH:mm:ss");
      }
      
      if (resultMainRes !== "") {
        allData[i].Work_ResultMainRes = resultMainRes;
      }

      let newStatus;

      // If this result has status information, set to this status, or, set the status according to the existences of resultStart/endTime
      // This is because that a finished operation may also have no resultStart/endTime
      if (resultStatus !== "") {
        newStatus = resultStatus;
      } else {
        if (resultStartTime !== "") newStatus = "T";
        if (resultEndTime !== "") newStatus = "B";
      }

      allData[i].Work_Status = newStatus;
      setStatus(allData[i], newStatus);
    }
  }

  // console.log(JSON.parse(JSON.stringify(operations)));
  // console.log(JSON.parse(JSON.stringify(allData)));

  return allData
}

export { mergeResourceDetail_and_TransactionData };
