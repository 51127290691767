<script>
  import UsersList from "../../components/users/UsersList.svelte";
  import CreateUserDialog from "../../components/users/CreateUserDialog.svelte";
  import AdminPageLayout from "../../components/layout/pageLayouts/admin/AdminPageLayout.svelte";
  import PrimaryButton from "../../components/button/generic/PrimaryButton.svelte";
  import { translate } from "../../i18n/i18next.js";
  import { tick } from "svelte";

  let createUserDialog
  let isCreateUserDialogOpen = false
  async function handleAddUser(){
    isCreateUserDialogOpen = true
    await tick()
    createUserDialog.showModal()
    createUserDialog.addEventListener("click", (event) => {
      if (event.target === createUserDialog) {
        createUserDialog.close()
      }
    })
  }
</script>

<AdminPageLayout title={translate("frontend:pages.admin.users.title")}>
  <div id="content-container">
    <p>
      {translate("frontend:pages.admin.users.caption1")}<br/>
      {translate("frontend:pages.admin.users.caption2")}
    </p>
    <UsersList />
    <div>
      <PrimaryButton onClick={handleAddUser}>
        <span style="font-weight: bold">
          ＋
        </span>
        {translate("frontend:pages.admin.users.createUser")}
      </PrimaryButton>
    </div>
    {#if isCreateUserDialogOpen}
      <CreateUserDialog bind:dialog={createUserDialog} onClose={() => {createUserDialog.close(); isCreateUserDialogOpen = false}} />
    {/if}
  </div>
</AdminPageLayout>

<style>
  #content-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
</style>
