import {parseData} from "../../getData.js"

/**
 * @param data
 * @param {string} iStyle
 */
export function parseDispatchingViewResourceDetail(data, iStyle) {
  return parseData(data.Data[iStyle], data.UploadTimeStamp, data.DownloadTimeStamp)
}

/**
 * Check if the operation has been started or not.
 * returns false if the operation specified by the id is not defined.
 * @param {object} data
 * @param {string} operationId
 * @returns boolean
 */
export function hasOperationStarted(data, operationId) {
  const propertyName = "Work_ResultStartTime"
  if (!data || !data[operationId] || !data[operationId][propertyName]) return false

  return data[operationId][propertyName] !== ""
}

/**
 * Check if the operation has been completed or not/
 * returns false if the operation specified by the id is not defined.
 * @param {object} data
 * @param {string} operationId
 * @returns boolean
 */
export function hasOperationCompleted(data, operationId) {
  const propertyName = "Work_ResultEndTime"
  if (!data || !data[operationId] || !data[operationId][propertyName]) return false
  return data[operationId][propertyName] !== ""
}