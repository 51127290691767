<script>
  import { useLocation } from "svelte-navigator"
  import { useMe } from "../../hooks/useMe.js"
  import { isServerAdmin } from "../../utils/user.js"
  import { getNotificationsContext } from "svelte-notifications"
  import MyProjects from "../../components/project/MyProjects.svelte"
  import AdminSidePanel from "../../components/layout/pageLayouts/admin/AdminSidePanel.svelte"
  import { usePageContext } from "../../components/pages/pageContext.js"
  import Loading from "../../components/pages/Loading.svelte";
  import { navigateI18n } from "../../i18n/navigate.js";
  import { translate } from "../../i18n/i18next.js";

  const version = "__MY_SCHEDULE_VERSION__"

  const me = useMe()
  const {addNotification} = getNotificationsContext()
  const location = useLocation()
  const {setHeaderProps} = usePageContext()
  setHeaderProps({title: `Asprova My Schedule (v${version})`})

  async function getUsernameAndProjects() {
    const response = await fetch("/api/me")
    if (!response.ok) {
      navigateI18n("/login")
      return
    }
    const data = await response.json()
    const projects = await fetch("/api/me/projects").then(res => res.json()).then(data => data.projects)
    return {
      username: data.name,
      projects,
    }
  }


  // process invitations
  async function handleInvitation() {
    const search = $location.search
    const urlSearchParams = new URLSearchParams(search.slice(1))
    const invitationId = urlSearchParams.get("invitation")
    if (!invitationId) return

    const response = await fetch(`/api/projects/invitations/${invitationId}/accept`, {
      method: "POST",
    })
    if (response.ok) {
      addNotification({
        text: translate("frontend:pages.index.joinedProjectWithInvitation"),
        position: "top-right",
        type: "success",
        removeAfter: 4000,
      })
    }
  }
  handleInvitation()
</script>

{#await getUsernameAndProjects()}
  <Loading/>
{:then data}
  <div id="root">
    <div id="main-container">
      {#if isServerAdmin($me)}
        <AdminSidePanel />
      {/if}
      <div id="content-container">
        <MyProjects />
      </div>
    </div>
  </div>
{/await}

<style>
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #main-container {
    display: flex;
    height: 100%;
  }
  #content-container {
    background-color: white;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
  }
</style>
