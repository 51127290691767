import {useQuery} from "@sveltestack/svelte-query"
import {fetchDispatchingViewResourceDetail, FetchError} from "../../../../utils/getData.js"

export function useDispatchingViewResourceDetailQuery(projectId, itemCode, fetchErrorHandler, refetchInterval= false) {
  const options = {
    onError: err => {
      console.log(err)
      if (err instanceof FetchError) {
        fetchErrorHandler(err.error)
      }
    },
    retry: false,
    refetchInterval,
  }

  const queryResult = useQuery(
    `/api/projects/${projectId}/DispatchingView/oper/${itemCode}.json`,
    () => fetchDispatchingViewResourceDetail(projectId, itemCode),
    {...options, enabled: !!itemCode}
  )

  $: {
    queryResult.setOptions(
      `/api/projects/${projectId}/DispatchingView/oper/${itemCode}.json`,
      () => fetchDispatchingViewResourceDetail(projectId, itemCode),
      {...options, enabled: !!itemCode}
    )
  }

  return queryResult
}