<script>
    import dayjs from "dayjs";

    import IconMenu from "./icon_menu.svelte";
    import GeneralPanelButton from "../button/GeneralPanelButton.svelte";
    import { translate } from "../../i18n/i18next";
    import GeneralSelect from "../select/GeneralSelect.svelte";
    import SyncBorderGroup from "./SyncBorderGroup.svelte"
    import { saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage";

    import {
        ResultDialogVisible,
        resultStartColumn,
        resultColumnTotal,
        resultColumnLength,
        monthColumns,
        selectedDisplayMode,
        DisplayMode
    } from "./ResultGraphStore"

    const onClickResultDialogOpen = () => { ResultDialogVisible.set(true); };

    const ScrollMonthColumns = (delta) => {
        resultStartColumn.update((n) => 
            Math.max(0, Math.min($resultColumnTotal - $resultColumnLength, n + delta))
        );
        saveResultGraphConfiguration({"resultStartColumn": $resultStartColumn}, 'default', 'default');
    }

    const MoveButton_Home = () => {
        MoveButton_PrevPage();
    }

    const MoveButton_PrevPage = () => {
        ScrollMonthColumns(-100);
    }

    // set start month to this month
    const MoveButton_Today = () => {
        const thisMonth = dayjs().format('M' + '月');
        const thisMonthIndex = $monthColumns.indexOf(thisMonth);
        if(thisMonthIndex >= 0){
            ScrollMonthColumns(thisMonthIndex - $resultStartColumn);
        }
    }

    const MoveButton_Prev = () => {
        ScrollMonthColumns(-1);
    }

    const MoveButton_Next = () => {
        ScrollMonthColumns(1);
    }

    const MoveButton_NextPage = () => {
        ScrollMonthColumns(100);
    }

    $: console.log("selectedDisplayMode", $selectedDisplayMode);
</script>

<div id="result_upperpane">
    <div id="result_upperpanel_cogwheel" on:click={onClickResultDialogOpen}>
        <IconMenu />
    </div>
    <div id="result_upperpanel_groups">
        <div style="margin-top: 8px; margin-bottom: 8px;">
            <GeneralSelect 
                bind:value={$selectedDisplayMode}
                options={$DisplayMode}
                onChangeEvent={saveResultGraphConfiguration({"selectedDisplayMode": $selectedDisplayMode}, 'default', 'default')}
            />
        </div>
        <SyncBorderGroup />
        <div id="result_upperpanel_Pager">
            <GeneralPanelButton 
            items={[
                {
                // buttonText: translate("generic.startShort"),
                buttonText: "先頭",
                buttonFunction: MoveButton_Home
                },
                {
                buttonImage: '/assets/Pager_FL.svg',
                buttonFunction: MoveButton_PrevPage
                },
                {
                buttonImage: '/assets/Pager_L.svg',
                buttonFunction: MoveButton_Prev
                },
                {
                buttonText: translate("generic.today"),
                buttonFunction: MoveButton_Today
                },
                {
                buttonImage: '/assets/Pager_R.svg',
                buttonFunction: MoveButton_Next
                },
                {
                buttonImage: '/assets/Pager_FR.svg',
                buttonFunction: MoveButton_NextPage
                },
            ]}
            />
        </div>
    </div>
</div>


<style>
    #result_upperpane{
        width: 100%;
        /* height: 100%; */
        display: flex;
        flex-direction: row;
        gap: 36px;
    }

    #result_upperpanel_cogwheel{
        margin-top: 12px;
        margin-left: 36px;
        cursor: pointer;
        align-items: center;
    }

    #result_upperpanel_groups{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 36px;
        flex-grow: 1;
    }

    #result_upperpanel_Pager{
        margin-top: 8px;
        margin-left: auto;
        align-self: flex-end;
    }

</style>