<script>
  export let user
</script>

<div id="username-container">
  {user.name}
</div>

<style>
  #username-container {
    width: 100%;
  }
</style>