<script>
  import DialogBase from "./DialogBase.svelte"
  import DialogTitle from "./DialogTitle.svelte"
  import { translate } from "../../i18n/i18next.js";
  import DialogSplitter from "./DialogSplitter.svelte";
  import DialogContent from "./DialogContent.svelte";
  import DialogBottomControls from "./DialogBottomControls.svelte";
  import DialogBottomControlButton from "./DialogBottomControlButton.svelte";
  import { z } from "zod";
  import { createForm } from "felte";
  import { validator } from "@felte/validator-zod";
  import DialogContentInput from "./DialogContentInput.svelte";

  export let dialog
  export let user
  export let onSubmit
  export let onClose
  export let isUsernameAvailable

  const schema = z.object({
    name: z.string()
      .min(1, translate("frontend:components.editUserDialog.usernameTooShortError"))
      .refine(async val => {
        if (user.name === val) return true
        return isUsernameAvailable(val)
      }, {message: translate("frontend:components.editUserDialog.usernameAlreadyTakenError")})
  })

  const {form, errors, createSubmitHandler} = createForm({
    initialValues: {name: user.name},
    onSubmit: async (values) => {
      await onSubmit(values.name)
    },
    extend: validator({schema})
  })
  const submitHandler = createSubmitHandler()

</script>

<DialogBase bind:dialog={dialog}>
  <DialogTitle onClose={onClose} title={translate("frontend:components.editUserDialog.title")}/>
  <DialogSplitter />
  <DialogContent>
    <form id="form-edit-user-info-${user.id}" use:form class="edit-user-form" on:feltesuccess={onClose}>
      <DialogContentInput
        label={translate("generic.userId")}
        disabled={true}
        value={user.id}
      />
      <DialogContentInput
        label={translate("generic.username")}
        name="name"
        error={$errors.name}
      />
    </form>
  </DialogContent>
  <DialogSplitter />
  <DialogBottomControls>
    <DialogBottomControlButton type="secondary" onClick={onClose}>
      {translate("generic.cancel")}
    </DialogBottomControlButton>
    <DialogBottomControlButton type="primary" onClick={submitHandler}>
      {translate("generic.change")}
    </DialogBottomControlButton>
  </DialogBottomControls>
</DialogBase>

<style>
  .edit-user-form {
    display: flex;
    flex-direction: column;
    gap: 16px
  }
  .edit-user-form-item input {
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
    padding: 0.3em 0.8em;
    border: solid 1px #888888;
    border-radius: 5px;
    outline: none;
  }
  .edit-user-form-item input:focus {
    border-color: #1a3a91;
  }
  .error {
    color: red
  }
</style>