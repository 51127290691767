<script>
  import StandardPlanChip from "../chips/StandardPlanChip.svelte"
  import FreePlanChip from "../chips/FreePlanChip.svelte"
  import ProjectUserProjectAdminChip from "../chips/ProjectUserProjectAdminChip.svelte"
  import ProjectUserResultInputChip from "../chips/ProjectUserResultInputChip.svelte"
  import ProjectUserReadOnlyChip from "../chips/ProjectUserReadOnlyChip.svelte"
  import ServerAdminChip from "../chips/ServerAdminChip.svelte"
  import ViewerPlanChip from "../chips/ViewerPlanChip.svelte"
  import { fade } from 'svelte/transition';
  import MyLink from "../../i18n/MyLink.svelte";
  import { translate } from "../../i18n/i18next.js";

  export let projects
</script>

<div id="content-container">
  {#each projects as project, i}
    <MyLink href="/projects/{project.id}">
      <div class="project-card" in:fade={{ duration: 400, delay: i * 50 }}>
        <div class="project-card-title-container">
          <div class="project-card-title">
            {project.name}
          </div>
          <div class="project-card-description">
            {project.description || translate("frontend:pages.admin.projects.noProjectDescription")}
          </div>
        </div>
        <div class="project-card-info-container">
          <div class="project-card-info-entry">
            <div class="project-card-info-entry-title">
              {translate("generic.plan")}:
            </div>
            {#if project.plan === "standard"}
              <StandardPlanChip />
            {/if}
            {#if project.plan === "viewer"}
              <ViewerPlanChip />
            {/if}
            {#if project.plan === "free"}
              <FreePlanChip />
            {/if}
          </div>
          <div class="project-card-info-entry">
            <div class="project-card-info-entry-title">
              {translate("generic.permission")}:
            </div>
            {#if project.permission === "ServerAdmin"}
              <ServerAdminChip/>
            {/if}
            {#if project.permission === "ProjectAdmin"}
              <ProjectUserProjectAdminChip />
            {/if}
            {#if project.permission === "ResultInput"}
              <ProjectUserResultInputChip />
            {/if}
            {#if project.permission === "ReadOnly"}
              <ProjectUserReadOnlyChip />
            {/if}
          </div>
        </div>
      </div>
    </MyLink>
  {/each}
</div>

<style>
  a {
    text-decoration: none;
    color: inherit;
  }
  #content-container {
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .project-card {
    border-radius: 3px;
    border: 1px solid lightgrey;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .3s;
  }
  .project-card:hover {
    background-color: #e3eaf2;
  }
  .project-card-title {
    color: #184398;
    font-weight: bold;
    font-size: 1.25rem;
    padding-bottom: 1rem
  }
  .project-card-description {
    font-size: 1rem;
  }
  .project-card-info-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  .project-card-info-entry {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
  }
  .project-card-info-entry-title {
    font-size: 0.8rem;
  }
</style>
