import {getConfiguration, saveConfiguration} from "../configurationStorage.js"
import {z} from "zod"

/**
 * @typedef {{
*   selectedDisplayMode?: string,
*   displayResultOrExpected?: boolean,
*   filteredResGroup?: string | null,
*   filteredResCode?: string | null,
*   filteredResName?: string | null,
*   filteredResCompany?: string | null,
*   resultStartRow?: number,
*   resultRowLength?: number,
*   companyStartRow?: number,
*   companyRowLength?: number,
*   resultStartColumn?: number | null,
*   resultColumnLength?: number,
*   syncEarlyTime?: number,
*   syncOverTime?: number,
*   syncEarlyTimeActive?: boolean,
*   syncOverTimeActive?: boolean,
* }} ResultGraphConfiguration
*/

const VIEW_NAME = "ResultGraph"

/**
 * @type {ResultGraphConfiguration}
 */
const defaultValue = {
    selectedDisplayMode: "工程表示",
    displayResultOrExpected: true,

    filteredResGroup: null,
    filteredResCode: null,
    filteredResName: null,
    filteredResCompany: null,

    resultStartRow: 0,
    resultRowLength: 5,

    companyStartRow: 0,
    companyRowLength: 5,

    resultStartColumn: null,
    resultColumnLength: 1,

    syncEarlyTime: 0,
    syncOverTime: 0,
    syncEarlyTimeActive: true,
    syncOverTimeActive: true,
}

const ResultGraphConfigurationSchema = z.object({
    selectedDisplayMode: z.string(),
    displayResultOrExpected: z.boolean(),

    filteredResGroup: z.union([z.string(), z.null()]),
    filteredResCode: z.union([z.string(), z.null()]),
    filteredResName: z.union([z.string(), z.null()]),
    filteredResCompany: z.union([z.string(), z.null()]),

    resultStartRow: z.number().int().min(0),
    resultRowLength: z.number().int().min(0),
    companyStartRow: z.number().int().min(0),
    companyRowLength: z.number().int().min(0),
    resultStartColumn: z.union([z.number().int().min(0), z.null()]),
    resultColumnLength: z.number().int().min(1),

    syncEarlyTime: z.number().int().min(0),
    syncOverTime: z.number().int().min(0),
    syncEarlyTimeActive: z.boolean(),
    syncOverTimeActive: z.boolean(),
})

/**
 * @param {string} userId
 * @param {string} projectId
 * @returns {ResultGraphConfiguration}
 */
export function getResultGraphConfiguration(userId, projectId) {
    return getConfiguration(VIEW_NAME, userId, projectId, defaultValue)
}

/**
 * @param {string} userId
 * @param {string} projectId
 * @param {Partial<ResultGraphConfiguration>} configuration
 */
export function saveResultGraphConfiguration(configuration, userId, projectId) {
    const saved = {...defaultValue, ...getResultGraphConfiguration(userId, projectId), ...configuration}
    try {
        ResultGraphConfigurationSchema.parse(saved)
        saveConfiguration(VIEW_NAME, userId, projectId, saved)
    } catch (e) {
        console.log(e)
        console.error("Malformed configuration for ResultGraph")
    }
}
