import {getConfiguration, saveConfiguration} from "../configurationStorage.js"
import {z} from "zod"

/**
 * @typedef {{
 *   styleObject?: object | null,
 *   displaySpanInSeconds: number,
 *   startDayDisplayTime?: string | null,
 *   endDayDisplayTime?: string | null,
 *   rowCount: number,
 *   rowSearchPattern?: string | null,
 *   currentDisplayedStartDate: string | null,
 *   rowsDisplayedStart: number,
 *   unfoldedOperations: array<number>
 * }} OrderGanttConfiguration
 */

const VIEW_NAME = "OrderGantt"

/**
 * @type {OrderGanttConfiguration}
 */
const defaultValue = {
  styleObject: null,
  displaySpanInSeconds: 60 * 60 * 24 * 7,  // "1Week"
  startDayDisplayTime: "00:00",
  endDayDisplayTime: "23:59",
  rowCount: 5,
  rowSearchPattern: null,
  currentDisplayedStartDate: null,
  rowsDisplayedStart: 0,
  unfoldedOperations: [],
}

const OrderGanttConfigurationSchema = z.object({
  styleObject: z.union([
    z.object({
      StyleName: z.string(),
      ObjectID: z.number().int(),
    }),
    z.null(),
  ]),
  displaySpanInSeconds: z.number().int().min(1),
  startDayDisplayTime: z.union([
    z.string(),
    z.null(),
  ]),
  endDayDisplayTime: z.union([
    z.string(),
    z.null(),
  ]),
  rowCount: z.number().int().min(1),
  rowSearchPattern: z.union([
    z.string(),
    z.null(),
  ]),
  currentDisplayedStartDate: z.union([
    z.string(),
    z.null(),
  ]),
  rowsDisplayedStart: z.number(),
  unfoldedOperations: z.array(z.number()),
})

/**
 * @param {string} userId
 * @param {string} projectId
 * @returns {OrderGanttConfiguration}
 */
export function getOrderGanttConfiguration(userId, projectId) {
  return getConfiguration(VIEW_NAME, userId, projectId, defaultValue)
}

/**
 * @param {string} userId
 * @param {string} projectId
 * @param {Partial<OrderGanttConfiguration>} configuration
 */
export function saveOrderGanttConfiguration(configuration, userId, projectId) {
  const saved = {...defaultValue, ...getOrderGanttConfiguration(userId, projectId), ...configuration}
  try {
    OrderGanttConfigurationSchema.parse(saved)
    saveConfiguration(VIEW_NAME, userId, projectId, saved)
  } catch (e) {
    console.log(e)
    console.error("Malformed configuration for OrderGantt")
  }
}
