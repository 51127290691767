import {SourceMapConsumer} from "source-map-js";

let smc = null

export async function initSourceMapConsumer() {
  // fetch source map
  const sourceMap = await fetch("/build/bundle.js.map").then(res => res.json()).catch(() => null)
  // if sourcemap has not been fetched for any reason, SourceMapConsumer is not available
  if (!sourceMap) return

  // make the source map consumer available for later usage
  smc = new SourceMapConsumer(sourceMap)
}

export function getSourceMapConsumer() {
  return smc
}
