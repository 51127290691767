<script>
  import AdminSidePanel from "./AdminSidePanel.svelte"
  import { usePageContext } from "../../../pages/pageContext.js"

  export let title

  const {setHeaderProps} = usePageContext()
  setHeaderProps({title})
</script>

<div id="main-container">
  <AdminSidePanel />
  <div id="content-container">
    <slot />
  </div>
</div>


<style>
  #main-container {
    display: flex;
  }
  #content-container {
    flex-grow: 1;
    background-color: white;
    padding: 1.5rem;
    overflow-y: auto;
  }
</style>