<script>
    import ResultChartContent from "./ResultChartContent.svelte";
    import ResultChartHeader from "./ResultChartHeader.svelte";

    import {resultStartColumn, resultColumnLength, resultColumnTotal, resultColumnLimit } from "./ResultGraphStore"
    import {ColumnDisplay,  monthColumns } from "./ResultGraphStore"
    import { saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage";

    const ScrollMonthColumns = (delta) => {
        resultStartColumn.update((n) => 
            Math.max(0, Math.min($resultColumnTotal - $resultColumnLength, n + delta))
        );
        saveResultGraphConfiguration({'resultStartColumn': $resultStartColumn}, 'default', 'default')
    }

    const ScrollMonthColumnsWheel = (e) => {
        e.preventDefault();
        
        if(e.ctrlKey){
            if(e.deltaY > 0){
                resultColumnLength.update((n) => (n + 1 > Math.min($resultColumnTotal - $resultStartColumn, $resultColumnLimit) ? n : n + 1));
            }
            else if(e.deltaY < 0){
                resultColumnLength.update((n) => (n > 1 ? n - 1 : n));
            }
            saveResultGraphConfiguration({'resultColumnLength': $resultColumnLength}, 'default', 'default')
        }
        else {
            ScrollMonthColumns(Math.floor(e.deltaY / 100));
        }
    }
</script>


<div id="result_chartarea" on:wheel={ScrollMonthColumnsWheel}>
    <ResultChartHeader />
    <ResultChartContent />
    <div id="result_chartarea_footerpadding" />
</div>


<style>
    #result_chartarea{
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 2px solid #888888;
        background-color: white;
    }

    #result_chartarea_footerpadding{
        height: 40px;
        width: 100%;
        background-color: white;
        border-top: 2px solid #888888;
        border-left: 2px solid #888888;
        border-right: 2px solid #888888;
    }
</style>