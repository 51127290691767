<script>
  import DialogBase from "../dialog/DialogBase.svelte";
  import DialogTitle from "../dialog/DialogTitle.svelte";
  import { translate } from "../../i18n/i18next.js";
  import DialogSplitter from "../dialog/DialogSplitter.svelte";
  import DialogBottomControls from "../dialog/DialogBottomControls.svelte";
  import DialogBottomControlButton from "../dialog/DialogBottomControlButton.svelte";
  import DialogContent from "../dialog/DialogContent.svelte";
  import {createForm} from "felte"
  import GeneralInput from "../input/GeneralInput.svelte";

  export let dialog
  export let onSubmit
  export let onClose
  export let requireOldPassword = true

  let message = {}
  const randomId = Math.random().toString(32).substring(2)
  const {form, errors, reset, createSubmitHandler} = createForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    },
    onSubmit: async (data) => {
      const submissionData = {newPassword: data.newPassword}
      if (requireOldPassword) {
        submissionData.oldPassword = data.oldPassword
      }
      const submissionResult = await onSubmit(submissionData)
      if (submissionResult) reset()
    },
    validate: values => {
      const errors = {}
      if (!values.newPassword) {
        errors.newPassword = translate("frontend:components.changePasswordDialog.emptyPasswordError")
      }
      if (!values.newPasswordRepeat) {
        errors.newPasswordRepeat = translate("frontend:components.changePasswordDialog.emptyPasswordError")
      }
      if (values.newPassword && values.newPassword !== values.newPasswordRepeat) {
        errors.newPasswordRepeat = translate("frontend:components.changePasswordDialog.passwordsNotMatchingError")
      }
      return errors
    }
  })
  const submitHandler = createSubmitHandler()
</script>

<DialogBase bind:dialog={dialog}>
  <DialogTitle title={translate("generic.changePassword")}/>
  <DialogSplitter />
  <DialogContent>
    <form class="change-password-form" use:form={form}>
      <div>
        <label for="input-old-password-{randomId}">{translate("frontend:components.changePasswordDialog.currentPassword")}</label>
        <input disabled={!requireOldPassword} name="oldPassword" type="password"/>
        {#if $errors.oldPassword}<span class="error">{$errors.oldPassword}</span>{/if}
      </div>
      <div>
        <label for="input-new-password-{randomId}">{translate("frontend:components.changePasswordDialog.newPassword")}</label>
        <input name="newPassword" type="password"/>
        {#if $errors.newPassword}<span class="error">{$errors.newPassword}</span>{/if}
      </div>
      <div>
        <label for="input-new-password-repeat-{randomId}">{translate("frontend:components.changePasswordDialog.newPasswordRepeat")}</label>
        <input name="newPasswordRepeat" type="password"/>
        {#if $errors.newPasswordRepeat}<span class="error">{$errors.newPasswordRepeat}</span>{/if}
      </div>
    </form>
  </DialogContent>
  <DialogSplitter />
  <DialogBottomControls>
    <DialogBottomControlButton type="secondary" onClick={onClose}>
      {translate("generic.cancel")}
    </DialogBottomControlButton>
    <DialogBottomControlButton type="primary" onClick={submitHandler}>
      {translate("generic.change")}
    </DialogBottomControlButton>
  </DialogBottomControls>
</DialogBase>

<style>
  input {
    border: solid 2px #cccccc;
    border-radius: 6px;
    padding: 0.3em 0.8em;
    outline: none;
  }
  input:focus {
    border: solid 2px #00468f;
  }
  .change-password-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .change-password-form > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .error {
    color: #de5555;
    font-size: .9rem;
  }
</style>