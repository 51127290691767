<script>
  import { useQuery } from "@sveltestack/svelte-query";
  import UsersListUserEntry from "./UsersListUserEntry.svelte";
  import { derived, writable } from "svelte/store";
  import { translate } from "../../i18n/i18next.js";

  const queryResult = useQuery("/api/users", () => fetch("/api/users").then(res => res.json()))
  const users = derived(queryResult, $queryResult => $queryResult.data?.users ?? [])
  const sortOrder = writable('name_asc');  // Default sort order

  const sortedUsers = derived([users, sortOrder], ([$users, $sortOrder]) => {
    if ($sortOrder === 'name_asc') {
      return $users.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if ($sortOrder === 'name_desc') {
      return $users.slice().sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    } else if ($sortOrder === 'created_at_asc') {
      return $users;
    } else if ($sortOrder === 'created_at_desc') {
      return $users.slice().reverse();
    }
  });

  async function isUsernameAvailable(name) {
    return $users.findIndex(user => user.name === name) === -1
  }
</script>

<div style="text-align: right;">
  <label for="sort-order">{translate("generic.sortOrder")}</label>
  <select bind:value={$sortOrder} style="width: 125px" id="sort-order">
    <option value="name_asc">{translate("generic.sortOrders.nameAsc")}</option>
    <option value="name_desc">{translate("generic.sortOrders.nameDesc")}</option>
    <option value="created_at_asc">{translate("generic.sortOrders.createdAtAsc")}</option>
    <option value="created_at_desc">{translate("generic.sortOrders.createdAtDesc")}</option>
  </select>
</div>

<div>
  {#if $queryResult.isSuccess}
    {#each $sortedUsers as user}
      <UsersListUserEntry user={user} isUserNameAvailable={isUsernameAvailable} />
    {/each}
  {/if}
</div>

