<script>
  import PercentageCell from "../../components/loadgraph/PercentageCell.svelte";


  export let rowHeight;
  export let cellWidth;

  export let selectedUnitPeriod;
  
  export let timeGroupsDay;
  export let timeGroupsWeek;
  export let timeGroupsMonth;

  export let visibleDays;
  export let visibleWeeks;
  export let visibleMonths;

  export let visibleRows;

  export let viewMode;

  export let handleScrollPeriod;
  
  function parseGroupToDate(group) {
    const [yearStr, monthStr] = group.split("年");
    const year = parseInt(yearStr);
    const month = parseInt(monthStr); // Assuming monthStr is like "8月", "9月", etc.

    if (!isNaN(year) && !isNaN(month)) {
        // Month in Date constructor is 0-based, so subtract 1
        return new Date(year, month - 1);
    }
    return null; // Return null if parsing fails
  }

</script>

<table on:wheel={handleScrollPeriod}>
  <thead>
    <tr>
      {#if selectedUnitPeriod === "day"}

        {#each Object.keys(timeGroupsDay) as group}
          {#if timeGroupsDay[group].some((day) => visibleDays.includes(day))}
            <th
              class="month-header"
              colspan={timeGroupsDay[group].filter((day) =>
                visibleDays.includes(day)
              ).length}
              style="max-width: {cellWidth}px;"
            >
              <div>
                <span style="margin-left: 10px;">{group}</span>
              </div>
            </th>
          {/if}
        {/each}

      {:else if selectedUnitPeriod === "week"}
        {#each Object.keys(timeGroupsWeek) as group}
          {#if timeGroupsWeek[group].some((week) => visibleWeeks.includes(week))}
            <th
              class="month-header"
              colspan={timeGroupsWeek[group].filter((week) =>
                visibleWeeks.includes(week)
              ).length}
              style="max-width: {cellWidth}px;"
            >
              <span style="margin-left: 10px;">{group}</span>
            </th>
          {/if}
        {/each}

      {:else if selectedUnitPeriod === "month"}
        {#each Object.keys(timeGroupsMonth) as group}
          {#if timeGroupsMonth[group].some((month) => visibleMonths.includes(month))}
            <th
              class="month-header"
              colspan={timeGroupsMonth[group].filter((month) =>
                visibleMonths.includes(month)
              ).length}
              style="max-width: {cellWidth}px;"
            >
              <span style="margin-left: 10px;">
                {parseGroupToDate(group).toLocaleDateString("ja-JP", { year: "numeric" })}
              </span>
            </th>
          {/if}
        {/each}

      {/if}
    </tr>
    <!-- Original Weeks header part -->
    <tr>
      {#if selectedUnitPeriod === "day"}
        {#each Object.entries(timeGroupsDay) as [group, days]}
          {#each days as day, index}
            {#if visibleDays.includes(day)}
              <th
                class="week-header {index === 0
                  ? 'first-week'
                  : ''} {index === days.length - 1 ? 'last-week' : ''}
                  {day === visibleDays[0] ? 'first-week' : ''}
                  "
                >
                  <div style="width: {cellWidth}px;">{day}</div>
                
              </th>
            {/if}
          {/each}
        {/each}

      {:else if selectedUnitPeriod === "week"}
        {#each Object.entries(timeGroupsWeek) as [group, weeks]}
          {#each weeks as week, index}
            {#if visibleWeeks.includes(week)}
              <th
              class="week-header {index === 0 ? 'first-week' : ''} 
                    {index === weeks.length - 1 ? 'last-week' : ''} 
                    {week === visibleWeeks[0] ? 'first-week' : ''}
                    "
              style="width: {cellWidth}px;"
              >
                <div style="width: {cellWidth}px;">{week.replace("-", "\n-")}</div>
              </th>
            {/if}
          {/each}
        {/each}

      {:else if selectedUnitPeriod === "month"}
        {#each Object.entries(timeGroupsMonth) as [group, months]}
          {#each months as month, index}
            {#if visibleMonths.includes(month)}
              <th
                class="week-header {index === 0
                  ? 'first-week'
                  : ''} {index === months.length - 1 ? 'last-week' : ''}"
                >
                <div style="width: {cellWidth}px;">{month}</div>
              </th>
            {/if}
          {/each}
        {/each}
      {/if}
    </tr>
  </thead>
  <tbody>
    {#each visibleRows as row, index1} 
      <tr>
        {#if selectedUnitPeriod === "day" }
          {#each Object.entries(timeGroupsDay) as [month, intervals]}
            {#each intervals as day, index2}
              {#if visibleDays.includes(day)}
                <td
                  class={`${
                    viewMode === "colorScale"
                      ? "percentage-cell-colorScale"
                      : "percentage-cell-dataBar"
                  } percentage-cell ${
                    index1 === visibleRows.length - 1 ? "last-row" : ""
                  } ${index2 === 0 ? "first-entry" : ""} ${
                    index2 === intervals.length - 1 ? "last-entry" : ""
                  } ${day === visibleDays[0] ? "first-entry" : ""}`}
                >
                  <div style="width: {cellWidth}px; height: {rowHeight}px;">
                    <PercentageCell
                      percentage={row.percentages[month][
                        intervals.indexOf(day)
                      ]}
                      {viewMode}
                    />
                  </div>
                </td>
              {/if}
            {/each}
          {/each}

        {:else if selectedUnitPeriod === "week"}
          {#each Object.entries(timeGroupsWeek) as [month, intervals]}
            {#each intervals as week, index2}
              {#if visibleWeeks.includes(week)}
                <td
                  class={`${
                    viewMode === "colorScale"
                      ? "percentage-cell-colorScale"
                      : "percentage-cell-dataBar"
                  } percentage-cell ${
                    index1 === visibleRows.length - 1 ? "last-row" : ""
                  } ${index2 === 0 ? "first-entry" : ""} ${
                    index2 === intervals.length - 1 ? "last-entry" : ""
                  } ${week === visibleWeeks[0] ? "first-entry" : ""}`}
                >
                  <div style="width: {cellWidth}px; height: {rowHeight}px;">
                    <PercentageCell
                      percentage={row.percentages[month][
                        intervals.indexOf(week)
                      ]}
                      {viewMode}
                    />
                  </div>
                </td>
              {/if}
            {/each}
          {/each}

        {:else if selectedUnitPeriod === "month"}
          {#each Object.entries(timeGroupsMonth) as [month, intervals]}
            {#each intervals as month1, index2}
              {#if visibleMonths.includes(month1)}
                <td
                  class={`${
                    viewMode === "colorScale"
                      ? "percentage-cell-colorScale"
                      : "percentage-cell-dataBar"
                  } percentage-cell ${
                    index1 === visibleRows.length - 1 ? "last-row" : ""
                  } ${index2 === 0 ? "first-entry" : ""} ${
                    index2 === intervals.length - 1 ? "last-entry" : ""
                  }`}
                >
                  <div style="width: {cellWidth}px; height: {rowHeight}px;">
                    <PercentageCell
                      percentage={row.percentages[month][
                        intervals.indexOf(month1)
                      ]}
                      {viewMode}
                    />
                  </div>
              
                </td>
              {/if}
            {/each}
          {/each}

        {/if}

      </tr>
    {/each}
  </tbody>
</table>
  
<style>

  table {
    height: 100%;
    border-collapse: collapse;
    table-layout: fixed;

  }

  th,
  td {
    text-align: center;
    box-sizing: border-box;
  }

  th {
    padding: 0 !important;
    border: 2px solid #888888;
  }

  td {
    background-color: #ffffff;
    padding: 0;
  }

  .month-header {
    font-size: 12px;
    font-weight: 500;
    background-color: white;
    height: 25px;
    border-bottom: 2px solid #d5d5d5;
    border-right: 2px solid #888888;
    border-left: 2px solid #888888;
    overflow: hidden;
    white-space: nowrap;
  }

  .week-header {
    white-space: pre-line;
    background-color: white;
    font-size: 12px;
    font-weight: 500;
    border-left: 2px solid #c9c6c6;
    border-right: 2px solid #c9c6c6;
    border-top: 2px solid #c9c6c6;
    border-bottom: 2px solid #888888;
    height: 55px;
  }

  .first-week {
    border-left: 2px solid #888888;;
  }

  .last-week {
    border-right: 2px solid #888888;

  }

/*   .last-week::before {
    content: "";
    width: 2px;
    height: 100%;
    background-color: #888888;
    position: absolute;
    right: -2px;
    top: -3px;
  } */

  th.month-header {
    text-align: left;
    /* height:20px; */
  }

  /* th.week-header {
    
  } */

  .normal-data {
    background-color: #e9e9ef;
    font-size: 16px;
    font-weight: 500;
  }

  .percentage-cell-dataBar {
    border: 2px solid #c9c6c6;
  }

  .percentage-cell-colorScale {
    border: 2px dotted #c9c6c6;
  }

  .first-entry {
    border-left: 2px solid #888888;
  }

  .last-entry {
    border-right: 2px solid #888888;
  }
/*   .last-entry::before {
    content: "";
    width: 2px;
    height: 100%;
    background-color: #888888;
    position: absolute;
    right: -2px;
    bottom: -2px;
  } */

  .last-row {
    border-bottom: 2px solid #888888;
  }
</style>