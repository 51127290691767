<script>
  import GeneralButton from "../button/GeneralButton.svelte";

  export let onClick
  export let type
</script>

<GeneralButton
  buttonType={type}
  buttonFunction={onClick}
  buttonExtraStyle="min-width: 160px"
>
  <slot />
</GeneralButton>
