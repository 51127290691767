<script>
  import ProjectsList from "../../components/project/ProjectsList.svelte"
  import EditProjectDialog from "../../components/project/EditProjectDialog.svelte"
  import {useQueryClient} from "@sveltestack/svelte-query"
  import {getNotificationsContext} from "svelte-notifications"
  import {useMe} from "../../hooks/useMe.js"
  import Alert from "../../components/Alerts/Alert.svelte"
  import {useLicenseData} from "../../hooks/useLicenseData.js"
  import AdminPageLayout from "../../components/layout/pageLayouts/admin/AdminPageLayout.svelte"
  import PrimaryButton from "../../components/button/generic/PrimaryButton.svelte"
  import { translate } from "../../i18n/i18next.js";
  import { tick } from "svelte";


  const {addNotification} = getNotificationsContext()
  const queryClient = useQueryClient()
  const me = useMe()
  const licenseData = useLicenseData()

  let createProjectDialog
  let isCreateProjectDialogOpen
  async function handleAddProject(){
    isCreateProjectDialogOpen = true
    await tick()
    createProjectDialog.showModal()
    createProjectDialog.addEventListener("click", (event) => {
      if (event.target === createProjectDialog) {
        createProjectDialog.close()
      }
    })
  }
  async function handleAddProjectSubmission(values) {
    if (!me) return

    const payload = {
      name: values.name,
      description: values.description,
      plan: values.plan,
      timezone: "Asia/Tokyo",
      permissions: [
        {
          userId: $me.id,
          permission: {
            type: "ProjectAdmin",
          }
        }
      ]
    }

    const response = await fetch(`/api/projects`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      addNotification({
        position: "top-right",
        type: "success",
        removeAfter: 4000,
        text: translate("frontend:pages.admin.projects.projectCreated"),
      })
      await queryClient.invalidateQueries("/api/projects")
      createProjectDialog.close()
    } else {
      const responseData = await response.json()
      if (responseData.type === "error:license:max-standard-plan-projects-exceeded") {
        addNotification({
          position: "top-right",
          type: "error",
          text: translate("frontend:pages.admin.projects.projectCreationFailedStandardPlanProjectsLimitExceeded"),
          removeAfter: 4000,
        })
      if (responseData.type === "error:license:max-viewer-plan-projects-exceeded") {
        addNotification({
          position: "top-right",
          type: "error",
          text: translate("frontend:pages.admin.projects.projectCreationFailedViewerPlanProjectsLimitExceeded"),
          removeAfter: 4000,
        })
      }
      } else {
        addNotification({
          position: "top-right",
          type: "error",
          text: translate("frontend:pages.admin.projects.projectCreationFailed"),
          removeAfter: 4000,
        })
      }
    }
  }
</script>

<AdminPageLayout title={translate("frontend:pages.admin.projects.title")}>
  <div id="content-container">
    <p>
      {translate("frontend:pages.admin.projects.caption")}
      <Alert type="warn">
        {translate("frontend:pages.admin.projects.standardPlanProjectsLimit")}: {$licenseData?.maxStandardPlanProjects ?? translate("generic.retrieving")}　<br />
        {translate("frontend:pages.admin.projects.viewerPlanProjectsLimit")}: {$licenseData?.maxViewerPlanProjects ?? translate("generic.retrieving")}
      </Alert>
    </p>
    <ProjectsList />
    <div>
      <PrimaryButton onClick={handleAddProject}>
        <span style="font-weight: bold">
          ＋
        </span>
        {translate("frontend:pages.admin.projects.addProject")}
      </PrimaryButton>
    </div>
    {#if isCreateProjectDialogOpen}
      <EditProjectDialog
        bind:dialog={createProjectDialog}
        project={{}}
        type="create"
        onClose={() => {createProjectDialog.close(); isCreateProjectDialogOpen = true}}
        onSubmit={handleAddProjectSubmission}
      />
    {/if}
  </div>
</AdminPageLayout>

<style>
  #content-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
</style>
