/**
 * Function to log in as a user.
 * @param username
 * @param password
 * @returns {Promise<boolean>}
 */
export async function login(username, password) {
  const request = await fetch(`/api/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({name: username, password})
  })

  return request.ok
}
