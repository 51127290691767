<script>
  import { fly } from "svelte/transition";
  import { useParams } from "svelte-navigator";
  import { onMount, tick} from "svelte";
  import dayjs from "dayjs";
  import { fetchProjectData, fetchResultGraphMeta, fetchResultGraphObject } from "../../utils/getData";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import Loading from "../../components/pages/Loading.svelte";
  import UpperPane from "../../components/resultgraph/UpperPane.svelte";
  import ResultGraphDialog from "../../components/resultgraph/ResultGraphDialog.svelte";
  import ChartContainer from "../../components/resultgraph/ChartContainer.svelte";
  import { useMyProjectPermission } from "../../hooks/useMyProjectPermission"
  import { getResultGraphConfiguration, saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage"
  import {
    ResultDialogVisible,
    TimeStamp,
    FilteredProcessIndices, 
    monthColumns,
    resultStartColumn,
    resultColumnLength,
    resultColumnTotal,
    RawData,
    ResCompanyArray,
    FilteredCompanyIndices,
    ResGroupChoices,
    ResCodeChoices,
    ResNameChoices,
    ResCompanyChoices,
    resultRowTotal,
    isAdmin,
    syncEarlyTime,
    filteredResGroup,
    filteredResCode,
    filteredResName,
    filteredResCompany,
    resultStartRow,
    resultRowLength,
    companyStartRow,
    companyRowLength,
    selectedDisplayMode,
    DisplayResultOrExpected,
    syncOverTime,
    syncEarlyTimeActive,
    syncOverTimeActive,
    companyRowTotal,
  } from "../../components/resultgraph/ResultGraphStore"

  const params    = useParams();
  const projectId = $params.project;
  const {permission} = useMyProjectPermission(projectId)

  let uploadTimeStamp = undefined;

  let ResData = null;
  let allMonths = null;


  function formatDate(date) {
    return dayjs(date).format('M' + '月');
  }

  function getMonthRange(){
    let earliestMonth = dayjs('3000/01/01 00:00:00');
    let latestMonth   = dayjs('2000/01/01 00:00:00');
    ResData.forEach((obj) => {
      obj.Operations.forEach((operation) => {
        let nowMonth = dayjs(operation.Work_StartTime).startOf('month');
        if(nowMonth < earliestMonth){
          earliestMonth = nowMonth;
        }
        if(nowMonth > latestMonth){
          latestMonth = nowMonth;
        }
      })
    })

    allMonths = [];
    let monthColumn = dayjs(earliestMonth);
    while(monthColumn <= latestMonth){
      allMonths.push(formatDate(monthColumn));
      monthColumn = monthColumn.add(1, 'M');
    }

  }

  function FilterItemFunc(){
      const newFilteredProcessIndices = [];

      for(let index = 0; index < $RawData.length; index++){
          let isFilteredItem = true;
          if($filteredResGroup !== null && $filteredResGroup !== $RawData[index].Res_Group) isFilteredItem = false;
          if($filteredResCode !== null && $filteredResCode !== $RawData[index].Res_Code) isFilteredItem = false;
          if($filteredResName !== null && $filteredResName !== $RawData[index].Res_Name) isFilteredItem = false;
          if($filteredResCompany !== null && $filteredResCompany !== $RawData[index].Res_Company) isFilteredItem = false;
          if(isFilteredItem) newFilteredProcessIndices.push(index);
      }

      FilteredProcessIndices.set(newFilteredProcessIndices);
      resultRowTotal.set(newFilteredProcessIndices.length);
      
      const newFilteredCompanyIndices = [];
      for(let index = 0; index < $ResCompanyArray.length; index++){
          if($filteredResCompany !== null && $filteredResCompany !== $ResCompanyArray[index])continue; 
          newFilteredCompanyIndices.push(index);
      }
      FilteredCompanyIndices.set(newFilteredCompanyIndices);
      companyRowTotal.set(newFilteredCompanyIndices.length);
  }

  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({ title: `${"Sample1"} - 実績グラフ` });
  $: setHeaderProps({ uploadTime: uploadTimeStamp})

  async function initResultGraph() {
    const projectData = await fetchProjectData(projectId, "ResultGraph");
    uploadTimeStamp = projectData.UploadTimeStamp;
    const metaResources = await fetchResultGraphMeta(projectId);
    ResData = await Promise.all(metaResources.Resources.map(async (itemCode) => {
      return await fetchResultGraphObject(projectId, itemCode);
    }));

    // for store
    isAdmin.set($permission == 'ServerAdmin');
    TimeStamp.set(uploadTimeStamp);
    RawData.set(ResData);

    ResGroupChoices.set([...new Set(ResData.map(item => item.Res_Group))]);
    ResCodeChoices.set([...new Set(ResData.map(item => item.Res_Code))]);
    ResNameChoices.set([...new Set(ResData.map(item => item.Res_Name))]);
    ResCompanyChoices.set([...new Set(ResData.map(item => item.Res_Company))]);
    ResCompanyArray.set([... new Set(ResData.map(item => item.Res_Company))]);

    resultRowTotal.set(ResData.length);
    companyRowTotal.set($ResCompanyArray.length);



    getMonthRange();
    monthColumns.set(allMonths);
    if(allMonths.length === 0) allMonths.push(formatDate(dayjs()));
    resultColumnTotal.set(allMonths.length);
    resultColumnLength.update(initValue => Math.min(initValue, allMonths.length));
    resultStartColumn.update(initValue => (initValue !== null ? initValue : allMonths.length - $resultColumnLength));
  }


  async function getResultConfig() {
    const savedSettings = getResultGraphConfiguration("default", "default");
    console.log("savedSettings", savedSettings)

    selectedDisplayMode.set(savedSettings.selectedDisplayMode);
    DisplayResultOrExpected.set(savedSettings.displayResultOrExpected);

    filteredResGroup.set(savedSettings.filteredResGroup);
    filteredResCode.set(savedSettings.filteredResCode);
    filteredResName.set(savedSettings.filteredResName);
    filteredResCompany.set(savedSettings.filteredResCompany);

    resultStartRow.set(savedSettings.resultStartRow);
    resultRowLength.set(savedSettings.resultRowLength);
    companyStartRow.set(savedSettings.companyStartRow);
    companyRowLength.set(savedSettings.companyRowLength);
    resultStartColumn.set(savedSettings.resultStartColumn);
    resultColumnLength.set(savedSettings.resultColumnLength);

    syncEarlyTime.set(savedSettings.syncEarlyTime);
    syncOverTime.set(savedSettings.syncOverTime);
    syncEarlyTimeActive.set(savedSettings.syncEarlyTimeActive);
    syncOverTimeActive.set(savedSettings.syncOverTimeActive);
  }

  let isSetted = false;
  onMount(async () => {
    console.log("onMount");

    await getResultConfig();

    await initResultGraph();

    FilterItemFunc();

    isSetted = true;
  });

  // $: saveResultConfig();
</script>

{#if isSetted}
  <div id="result_wrapper">
    {#if $ResultDialogVisible}
      <div class="settings-menu-wrapper" style="overflow-x: hidden; overflow-y: auto;" in:fly={{ x: -300, duration: 300 }} out:fly={{ x: -300, duration: 300 }}>
        <ResultGraphDialog />
      </div>
    {/if}
    <UpperPane />
    <ChartContainer />
  </div>
{:else}
  <Loading />
{/if}

<style>
  #result_wrapper {
    height: calc(100% - 5px);
    width: 100%;
    user-select: none;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
  }

  .settings-menu-wrapper {
    position: fixed;
    top: 50px;
    left: 0;
    height: calc(100% - 50px);
    width: 300px;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 99999;
  }
</style>