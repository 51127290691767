<script>
  import GeneralButton from "../button/GeneralButton.svelte";
  import { translate } from "../../i18n/i18next.js";
  import { navigateI18n } from "../../i18n/navigate.js";

  export let backTo = "/";
</script>

<GeneralButton
  class="back-button"
  buttonType="secondary"
  buttonFunction={() => navigateI18n(backTo)}
>
  <span class="back-button-root">
    <svg width="16" height="16">
      <path d="M 4 8 L 12 2 L 12 14 L 4 8" />
    </svg>
    <span class="back-button-text">
      {translate("generic.back")}
    </span>
  </span>
</GeneralButton>

<style>
  .back-button-root {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
  .back-button-text {
    font-size: .9rem;
    white-space: nowrap;
  }
  @media (max-width: 767px) {
    .back-button-text {
      display: none;
    }
  }
</style>