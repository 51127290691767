<script>
    import ResGroupTableArea from "./ResGroupTableArea.svelte";
    import ResCodeTableArea from "./ResCodeTableArea.svelte";
    import ResNameTableArea from "./ResNameTableArea.svelte";
    import ResCompanyTableArea from "./ResCompanyTableArea.svelte";

    import ResultChartArea from "./ResultChartArea.svelte";

    import {selectedDisplayMode} from "./ResultGraphStore"
    
    $: leftTableWidth = 450;
    $: widthPerColumn = leftTableWidth / 3;

    function handleDrag(e) {
        const newTableWidth = e.clientX;
        if (newTableWidth < 200 || window.innerWidth - newTableWidth < 300) return; 
        leftTableWidth = newTableWidth;
    }

    function stopDrag(e) {
        window.removeEventListener('mousemove', handleDrag);
        window.removeEventListener('mouseup', stopDrag);
    }

    function startDrag(e) {
        window.addEventListener('mousemove', handleDrag);
        window.addEventListener('mouseup', stopDrag);
    }

</script>

<div id="result_chartcontainer">
    <div class="table-area" style="width: {leftTableWidth}px;">
        {#if $selectedDisplayMode==="工程表示"}
            <ResGroupTableArea width={widthPerColumn}/>
            <ResCodeTableArea width={widthPerColumn}/>
            <ResNameTableArea width={widthPerColumn}/>
        {:else}
            <ResCompanyTableArea/>
        {/if}
    </div>
    <div class="resizer" on:mousedown={startDrag}></div>
    <div class="chart-area">
        <ResultChartArea />
    </div>
</div>

<style>
    #result_chartcontainer{
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: row;
        z-index: 60;
    }

    .table-area{
        height: 100%;
        background-color: white;
        display: flex; 
    }

    .chart-area{
        flex-grow: 1;
        height: 100%;
        background-color: white;
    }
    .resizer {
        width: 5px;
        cursor: ew-resize;
        background-color: #ccc;
        height: 100%;
    }
</style>