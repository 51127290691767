import { useQuery } from "@sveltestack/svelte-query";
import { derived } from "svelte/store";

export function useEnabledExperimentalViews() {
  const queryResult = useQuery("/api/features/experimental/views", {
    retry: false,
    refetchInterval: false,
  })
  return derived(queryResult, $queryResult => {
    return $queryResult.data?.views ?? []
  })
}
