<script>
  export let type = "text";
  export let value = null;
  export let onChange = null;
  export let onInput = null;
  export let min = null;
  export let max = null;
  export let extraStyle = null;
  export let placeholder = null;
</script>

{#if type == "text"}
  <input
    bind:value
    {...{ type }}
    class="general-input"
    on:change={onChange}
    on:input={onInput}
    style={extraStyle}
    {placeholder}
  />
{:else if type == "number" || type == "time"}
  <input
    bind:value
    {...{ type }}
    class="general-input"
    on:change={onChange}
    on:input={onInput}
    {max}
    {min}
    style={extraStyle}
    {placeholder}
  />
{/if}

<style>
  .general-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: solid 2px #00468f;
    border-radius: 6px;
    padding: 0.3em 0.8em;
    font-size: 1em;
    flex-grow: 1;
    font-family: sans-serif;
  }
</style>
