<script>
  import UsersPage from "../../routes/admin/UsersPage.svelte"
  import AuthenticationRequired from "../../components/functional/AuthenticationRequired.svelte"
  import PageBase from "../../components/pages/PageBase.svelte"
  import ServerAdminRequired from "../../components/functional/ServerAdminRequired.svelte";
</script>

<AuthenticationRequired>
  <ServerAdminRequired>
    <PageBase backTo={null}>
      <UsersPage />
    </PageBase>
  </ServerAdminRequired>
</AuthenticationRequired>
