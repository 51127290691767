<script>
  import {Route, Router} from "svelte-navigator"
  import {QueryClient, QueryClientProvider} from "@sveltestack/svelte-query"
  import Notifications from "svelte-notifications"
  import Routes from "./pages/_routes.svelte"
  import NotFound from "./routes/NotFound.svelte"
  import {getFetcher} from "./lib/getFetcher.js"
  import NotificationBase from "./components/notification/NotificationBase.svelte"
  import FetchErrorHandler from "./components/functional/FetchErrorHandler.svelte"
  import {init} from './i18n/i18next'
  import { onMount } from "svelte";
  import Loading from "./components/pages/Loading.svelte";
  import {getLanguagesConfiguration, saveLanguagesConfiguration} from "./utils/configurationStorage/languageConfigurationStorage.js";

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: ({queryKey}) => getFetcher(queryKey)
      }
    }
  })

  let isLoaded = false
  onMount(async () => {
    const serverLanguage = await fetch("/api/features/language").then(res => res.json()).catch(() => ({}))
    const displayLanguage = serverLanguage?.displayLanguage ?? "en"

    if (!(await getLanguagesConfiguration("default")).language) {
      await saveLanguagesConfiguration({language: displayLanguage}, "default")
    }
    await init()
    isLoaded = true
  })
</script>

{#if isLoaded}
  <QueryClientProvider client={queryClient}>
    <Notifications item={NotificationBase}>
      <Router primary={false}>
        <FetchErrorHandler>
          <Routes />
        </FetchErrorHandler>
        <Route path="*" component={NotFound} />
      </Router>
    </Notifications>
  </QueryClientProvider>
{:else}
  <Loading />
{/if}

<style>
  :global(body) {
    background-color: #f5f5f5;
    margin: 0;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
  }
  /* To ensure notifications by svelte-notifications displayed on top of the headers (#135) */
  :global(.position-top-center),
  :global(.position-top-left),
  :global(.position-top-right) {
    z-index: 1000;
  }
</style>
