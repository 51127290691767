import { useQuery } from "@sveltestack/svelte-query"
import { FetchError, fetchLoadGraphMPSProject } from "../../../../utils/getData.js"

/**
 * svelte query hook for LoadGraph view project data
 * @param {string} projectId
 * @param fetchErrorHandler
 */
export function useLoadGraphProjectQuery(projectId, fetchErrorHandler) {
  return useQuery(`/api/projects/${projectId}/LoadGraph/project.json`, () => fetchLoadGraphMPSProject(projectId), {
    onError: err => {
      console.log(err)
      if (err instanceof FetchError) {
        fetchErrorHandler(err.error)
      }
    },
    retry: false,
  })
}