<script>
  import CalendarBlock from '../CalendarBlock.svelte';
  import GanttHelper from "../../ganttchart/GanttHelper.js";
  import dayjs from "dayjs";

  export let store

  const {
    CurrentDisplayedEndDate,
    CurrentDisplayedStartDate,
    DisplayedSpanInSeconds,
    ValidEndDate,
    ValidStartDate,
  } = store


  // Format (Sorted by timestamp)
  /*
  ** {
  **  StartX: 5.5,
  **  EndX: 21,
  **  TextUp: "3",
  **  TextDown: "Tue", // If null, TextUp is centered
  **  BlockColor: "red",
  ** }
  */
  let calendarUpperBlocks = [];
  let calendarLowerBlocks = [];

  const ReloadCalendarBlocks = () => {
    // dayjs is so stupid, setting weekStart=1 breaks locales
    function getDayString(weekday) {
      return "日月火水木金土".at(weekday)
    }


    let tmp_calendarUpperBlocks = [];
    let tmp_calendarLowerBlocks = [];

    // construct upper blocks
    {
      const unit = "W"
      let timestamp = $CurrentDisplayedStartDate;
      while (timestamp < $CurrentDisplayedEndDate) {
        const nextWeekStart = dayjs(timestamp).locale(dayjs.locale(), {weekStart: 1}).add(1, "week").startOf("week")
        const next = nextWeekStart.toDate()
        const thisWeekStart = dayjs(timestamp).locale(dayjs.locale(), {weekStart: 1}).startOf("week")
        const thisWeekEnd = nextWeekStart.subtract(1, "day")
        const text = [
          `${thisWeekStart.format("M/D")}(${getDayString(thisWeekStart.format("d"))})`
            + "-"
            + `${thisWeekEnd.format("M/D")}(${getDayString(thisWeekEnd.format("d"))})`,
          null
        ]
        const color = GanttHelper.GetDateCalendarBlockColor(timestamp, unit);
        const insertable = {
          StartX: 100 * GanttHelper._gantHelper_scale(timestamp.getTime(), $CurrentDisplayedEndDate.getTime(), $CurrentDisplayedStartDate.getTime()),
          EndX: 100 * GanttHelper._gantHelper_scale(Math.min(next.getTime(), $CurrentDisplayedEndDate.getTime()), $CurrentDisplayedEndDate.getTime(), $CurrentDisplayedStartDate.getTime()),
          TextUp: text[0],
          TextDown: text[1],
          BlockColor: color,
        }

        tmp_calendarUpperBlocks.push(insertable)
        timestamp = next;
      }
    }
    // construct lower blocks
    {
      const unit = "D"
      let timestamp = $CurrentDisplayedStartDate;
      while (timestamp < $CurrentDisplayedEndDate) {
        const date = new Date(timestamp)
        const next = GanttHelper.GetNextDateGraduation(timestamp, unit);
        const text = [date.getDate().toString(10), null]
        const color = date.getDay() === 0
          ? "#FFE7E5"
          : date.getDay() === 6
            ? "#E9F0FF"
            : "#ffffff"
        const insertable = {
          StartX: 100 * GanttHelper._gantHelper_scale(timestamp.getTime(), $CurrentDisplayedEndDate.getTime(), $CurrentDisplayedStartDate.getTime()),
          EndX: 100 * GanttHelper._gantHelper_scale(Math.min(next.getTime(), $CurrentDisplayedEndDate.getTime()), $CurrentDisplayedEndDate.getTime(), $CurrentDisplayedStartDate.getTime()),
          TextUp: text[0],
          TextDown: text[1],
          BlockColor: color,
        }

        tmp_calendarLowerBlocks.push(insertable);

        timestamp = next;
      }
    }

    calendarUpperBlocks = tmp_calendarUpperBlocks;
    calendarLowerBlocks = tmp_calendarLowerBlocks;
    // GanttStore.InternalLowerSplits.update((_) => calendarLowerBlocks);
  };

  DisplayedSpanInSeconds.subscribe(() => ReloadCalendarBlocks());

  CurrentDisplayedEndDate.subscribe(() => ReloadCalendarBlocks())

  const ScrollTime = (e) => {
    e.preventDefault();
    if (e.ctrlKey) {
      ```
      const currentDisplayedSpanInSeconds = $DisplayedSpanInSeconds
      let newDisplayedSpanInSeconds
      // Zoom
      if (e.deltaY > 0) {
        // If we want a more strict restriction use the following code. Currently, I think it's unnecessary
        // GanttStore.DisplayedSpanInSeconds.update((d) => Math.min(d * 1.05, GanttHelper.GHYEAR, (validEndDate.getTime() - validStartDate.getTime()) / 1000));
        newDisplayedSpanInSeconds = Math.min(currentDisplayedSpanInSeconds * 1.05, GanttHelper.GHYEAR)
      } else if (e.deltaY < 0) {
        newDisplayedSpanInSeconds = Math.max(currentDisplayedSpanInSeconds / 1.05, GanttHelper.GHDAY)
      }
      DisplayedSpanInSeconds.set(newDisplayedSpanInSeconds)
      ```
    }
    else {
      // Move
      let newCurrentDisplayedStartDate
      if (e.deltaY > 0) {
        if (isNaN($ValidEndDate.getTime())) {
          newCurrentDisplayedStartDate = new Date($CurrentDisplayedStartDate.getTime() + ($CurrentDisplayedEndDate.getTime() - $CurrentDisplayedStartDate.getTime()) / 48);
        } else {
          newCurrentDisplayedStartDate = new Date(Math.min($ValidEndDate.getTime() - $DisplayedSpanInSeconds * 1000, $CurrentDisplayedStartDate.getTime() + 1000 * 60 * 60 * 24));
        }
        if (newCurrentDisplayedStartDate < $CurrentDisplayedStartDate) {
          newCurrentDisplayedStartDate = $CurrentDisplayedStartDate;
        }
      }
      else if (e.deltaY < 0) {
        if (isNaN($ValidStartDate.getTime())) {
          newCurrentDisplayedStartDate = new Date($CurrentDisplayedStartDate.getTime() - ($CurrentDisplayedEndDate.getTime() - $CurrentDisplayedStartDate.getTime()) / 48);
        } else {
          newCurrentDisplayedStartDate = new Date(Math.max($ValidStartDate.getTime(), $CurrentDisplayedStartDate.getTime() - 1000 * 60 * 60 * 24))
        }
        if (newCurrentDisplayedStartDate > $CurrentDisplayedStartDate) {
          newCurrentDisplayedStartDate = $CurrentDisplayedStartDate;
        }
      }
      CurrentDisplayedStartDate.set(newCurrentDisplayedStartDate)
    }
  }
</script>

<div
  id="chartArea_calendarHeader"
  on:wheel={ScrollTime}
>
  <svg id="chartArea_calendarSvg">
    {#each calendarUpperBlocks as upperBlock}
      <CalendarBlock
        StartY=2
        Item={upperBlock}
      />
    {/each}
    {#each calendarLowerBlocks as lowerBlock}
      <CalendarBlock
        alignCenter={true}
        StartY=50
        Item={lowerBlock}
      />
    {/each}
    <line
      x1="0"
      x2="100%"
      y1="50%"
      y2="50%"
      stroke="#888888"
      stroke-width="2"
    />
  </svg>
</div>

<style>
  div#chartArea_calendarHeader {
    width: 100%;
    height: 80px;
  }

  svg#chartArea_calendarSvg {
    border-bottom: 2px solid #888888;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 80px;
    position: relative;
  }
</style>
