<script>
    import DialogDisplaySection from "./DialogDisplaySection.svelte";
    import DialogFilterSection from "./DialogFilterSection.svelte";

    import { ResultDialogVisible } from "./ResultGraphStore";

    const onClickResultDialogClose = () => { ResultDialogVisible.set(false); };

</script>

<div class="dialog">
    <div class="title-container">
        <button class="close-button" on:click={onClickResultDialogClose}>＜</button>
        <span class="title">メニュー</span>
    </div>

    <DialogDisplaySection />

    <DialogFilterSection />
</div>

<style>
    .dialog{
        padding: 32px 32px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
    }
    .title {
        flex-grow: 1;
        flex-shrink: 1;
        font-weight: bold;
    }
    .close-button {
        color: darkgrey;
    }
    .title-container {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        font-size: 1.5rem;
    }
</style>
