<p class="demo-projects-list-title">
  <slot />
</p>

<style>
  .demo-projects-list-title {
    font-weight: 500;
    background-color: #eff2f5;
    border-radius: 3px;
    padding: .5em;
    margin: 1em 0 0 0;
  }
</style>
