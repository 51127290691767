<script>
  import {useIsDemoServer} from "../../hooks/useIsDemoServer.js";

  const {isDemoServer} = useIsDemoServer()
</script>

{#if $isDemoServer}
  <div class="banner_wrap">
    <a href="https://www.asprova.jp/products/my-schedule" target="_blank">
      <div class="banner">
        <div class="banner_wrap_upper">
          <div class="banner_title"><img alt="products page banner title" src="/assets/demo/banner_title.svg"></div>
          <div class="banner_img"><img alt="products page banner eye catch" src="/assets/demo/eyecatch.png"></div>
        </div>
        <div class="banner_text">詳しくはこちら＞</div>
      </div>
    </a>
  </div>
{/if}

<style>
  .banner_wrap {
    position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 100;
  }

  .banner {
    display: flex;
    flex-direction: column;
    width: 248px;
    height: 112px;
    margin: 0 2.5rem 0 auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 6px;
    border-radius: 4px;
    transition: .3s;
    background: radial-gradient(circle farthest-side at 200px 200px,
    #fff 70%,
    #e3f4f3 100%);
    &:hover {
      opacity: .7;
    }
  }

  .banner_wrap_upper {
    display: flex;
    flex-direction: row;
    height: 90px;
  }

  .banner_title {
    padding: 2rem .5rem .5rem .5rem;
  }

  .banner_img {
    height: calc(82px + .5rem);
  }

  .banner_img img {
    height: 82px;
    width: auto;
    margin: .5rem .5rem 0 0;
  }

  .banner_text {
    height: 22px;
    color: #fff;
    font-size: .7rem;
    font-weight: 500;
    text-align: right;
    background-color: #6493bd;
    padding: .3rem .5rem .2rem .5rem;
    border-radius: 0 0 4px 4px;
  }

  a {
    text-decoration: none;
  }

</style>