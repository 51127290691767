<script>
  import { I18N_ProcessHeaderDate } from "../../utils/i18n";
  import { onMount, onDestroy } from 'svelte';
  import GanttHelper from './GanttHelper.js';
  import { createSettingsEntries } from './GanttSettings.js';
  import GeneralSelect from '../select/GeneralSelect.svelte';
  import GeneralPanelButton from '../button/GeneralPanelButton.svelte';
  import SettingsMenuWrapper from '../settings/SettingsMenuWrapper.svelte';
  import { saveResGanttConfiguration } from "../../utils/configurationStorage/views/ResGanttConfigurationStorage";
  import { saveOrderGanttConfiguration } from "../../utils/configurationStorage/views/OrderGanttConfigurationStorage";
  import { fly } from 'svelte/transition';
  import { get } from "svelte/store";
  import GeneralButton from "../button/GeneralButton.svelte";
  import DisplayColumnsSettings from "./DisplayColumnsSettings.svelte";
  import DataFetcher from './DataFetcher.js';
  import ToggleSwitch from "../input/ToggleSwitch.svelte";
  import { translate } from "../../i18n/i18next.js";
  import { getNotificationsContext } from "svelte-notifications";
  import MenuBar from "../menuBar/MenuBar.svelte";
  import MenuBarItem from "../menuBar/MenuBarItem.svelte";

  export let GanttStore
  export let projectId
  export let userId

  const settings = createSettingsEntries(GanttStore);
  const { addNotification } = getNotificationsContext();

  let startDate;
  let endDate;
  let yearElement;

  let displayStart;

  let validStartDate;
  let validEndDate;

  let styleObjects = [];
  $: currentStyleObjectID = -1;

  $: {
    if (currentStyleObjectID != -1) {
      let newStyleObject = styleObjects.find((o) => o.ObjectID == currentStyleObjectID);
      if (newStyleObject) {
        GanttStore.CurrentStyle.set(newStyleObject);
      } else {
        console.error(`Style Object ${currentStyleObjectID} not found`);
      }
    }
  }

  let transactionsUrl = null;
  let lastDownloadTimestamp = null;
  let autoUpdate = true;
  let autoUpdateInterval = null;

  let ganttChartType;

  let settingsMenuActive = false;
  let settingsDialogVisible = false;
  let usingSimpleVersion = false;

  let consecutiveRetries = 0;
  const maxRetries = 10;

  function removeAutoUpdateInterval() {
    if (autoUpdateInterval !== null) {
      clearInterval(autoUpdateInterval);
      autoUpdateInterval = null;
    }
  }

  $: showColumnSettings = false;

  function openColumnSettings() {
    showColumnSettings = true;
  }

  function closeColumnSettings() {
    showColumnSettings = false;
  }


  // function onChangeSettings(settingName, newValue) {
  //   if (ganttChartType === GanttStore.GanttChartTypes.ResGantt || ganttChartType === GanttStore.GanttChartTypes.SimpleResGantt || ganttChartType === GanttStore.GanttChartTypes.CustomizedResGantt) {
  //     if (newValue) {
  //       GanttStore.GanttChartType.update((_) => GanttStore.GanttChartTypes.SimpleResGantt);
  //     } else {
  //       GanttStore.GanttChartType.update((_) => GanttStore.GanttChartTypes.ResGantt);
  //     }
  //   } else {
  //     if (newValue) {
  //       GanttStore.RowsUnfoldedForOrderGantt.update((_) => new Set()); // Fold all unfolded orders
  //       GanttStore.GanttChartType.update((_) => GanttStore.GanttChartTypes.SimpleOrderGantt);
  //     } else {
  //       GanttStore.GanttChartType.update((_) => GanttStore.GanttChartTypes.OrderGantt);
  //     }
  //   }
  //   // TO-DO: Save settings here, do not implement for now
  // }

  function onClickSettingsDialogOpen() {
    settingsDialogVisible = true;
  }
  function onClickSettingsDialogClose() {
    settingsDialogVisible = false;
  }

  GanttStore.StyleObjects.subscribe((newStyles) => styleObjects = newStyles);
  GanttStore.CurrentStyle.subscribe((newStyle) => {
    currentStyleObjectID = newStyle.ObjectID;
  });
  GanttStore.GanttChartType.subscribe((newType) => ganttChartType = newType);

  onDestroy(() => {
    removeAutoUpdateInterval();
  });

  onMount(() => {
    // Date
    GanttStore.CurrentDisplayedStartDate.subscribe(
      (newDate) => {
        if (yearElement !== null) yearElement.textContent = I18N_ProcessHeaderDate(newDate, "年")
        startDate = newDate;
      });
    GanttStore.CurrentDisplayedEndDate.subscribe(
      (newDate) => {
        endDate = newDate;
      });
    GanttStore.ValidEndDate.subscribe(
      (newDate) => {
        validEndDate = newDate;
    });
    GanttStore.ValidStartDate.subscribe(
      (newDate) => {
        validStartDate = newDate;
    });
    GanttStore.DisplayedStartDate.subscribe(
      (newDate) => {
        displayStart = newDate;
    });
    GanttStore.TransactionsUrl.subscribe(
      (url) => {
        transactionsUrl = url;
    });
    GanttStore.LastTransactionDownloadTimestamp.subscribe(
      (ldt) => {
        lastDownloadTimestamp = ldt;
    });

    // Todo: Add to ganttstore and cache as a display setting
    if (autoUpdate = true) {
      autoUpdateInterval = setInterval(UpdateTransactions, 10000);
    }
    else {
      removeAutoUpdateInterval();
    }
  });


  function saveConfigurationHelper(ganttChartType, config) {
    switch (ganttChartType) {
      case GanttStore.GanttChartTypes.ResGantt:
      case GanttStore.GanttChartTypes.SimpleResGantt:
      case GanttStore.GanttChartTypes.CustomizedResGantt:
        return saveResGanttConfiguration(config, userId, projectId);
      default:
        return saveOrderGanttConfiguration(config, userId, projectId)
    }
  }

  const MoveButton_Home = () => {
    GanttStore.CurrentDisplayedStartDate.update((_) => new Date(displayStart));
    get(GanttStore.SaveConfigurationFunc)({currentDisplayedStartDate: displayStart.toISOString()})
  }

  const MoveButton_PrevPage = () => {
    const oldDate = get(GanttStore.CurrentDisplayedStartDate)
    const newDate = new Date(Math.max(validStartDate.getTime(), oldDate.getTime() - (endDate.getTime() - oldDate.getTime())))
    GanttStore.CurrentDisplayedStartDate.set(newDate);
    get(GanttStore.SaveConfigurationFunc)({currentDisplayedStartDate: newDate.toISOString()})
  }

  const MoveButton_Prev = () => {
    const oldDate = get(GanttStore.CurrentDisplayedStartDate)
    const newDate = new Date(Math.max(validStartDate.getTime(), oldDate.getTime() - GanttHelper.GHDAY * 1000))
    GanttStore.CurrentDisplayedStartDate.set(newDate);
    get(GanttStore.SaveConfigurationFunc)({currentDisplayedStartDate: newDate.toISOString()})
  }

  const MoveButton_Today = () => {
    const today = new Date()
    if (today > validEndDate || today < validStartDate) return;
    GanttStore.CurrentDisplayedStartDate.set(today)
    get(GanttStore.SaveConfigurationFunc)({currentDisplayedStartDate: today.toISOString()})
  }

  const MoveButton_Next = () => {
    const oldDate = get(GanttStore.CurrentDisplayedStartDate)
    const span = get(GanttStore.DisplayedSpanInSeconds);
    const newDate = new Date(Math.min(validEndDate.getTime() - span * 1000, oldDate.getTime() + GanttHelper.GHDAY * 1000))
    if (newDate > oldDate)
      GanttStore.CurrentDisplayedStartDate.set(newDate)
    get(GanttStore.SaveConfigurationFunc)({currentDisplayedStartDate: newDate.toISOString()})
  }

  const MoveButton_NextPage = () => {
    const span = get(GanttStore.DisplayedSpanInSeconds);
    const newDate = new Date(Math.min(validEndDate.getTime() - span * 1000, endDate.getTime()))
    GanttStore.CurrentDisplayedStartDate.set(newDate)
    get(GanttStore.SaveConfigurationFunc)({currentDisplayedStartDate: newDate.toISOString()})
  }

  const _updateSuccess = (newTransactions) => {
    if (newTransactions !== null) {
      consecutiveRetries = 0;
      GanttStore.Transactions.update((old) => {
        let trs = old;
        for (const key of Object.keys(newTransactions)) {
          trs[key] = newTransactions[key];
        }
        GanttStore.LastTransactionDownloadTimestamp.update((_) => newTransactions.DownloadedTimeStamp);
        return trs;
      });
    }
  }

  const _updateError = () => {
    consecutiveRetries += 1;
    addNotification({
      text: "自動更新：データの取得は失敗しました",
      position: "top-right",
      removeAfter: 4000,
      type: "error",
    });
    if (consecutiveRetries >= maxRetries) {
      consecutiveRetries = 0;
      removeAutoUpdateInterval();
      autoUpdate = false;
    }
  }

  const UpdateTransactions = () => {
    // Delete here if not resource gantt
    if (ganttChartType !== GanttStore.GanttChartTypes.ResGantt && ganttChartType !== GanttStore.GanttChartTypes.CustomizedResGantt) {
      autoUpdate = false;
      removeAutoUpdateInterval();
      return;
    }

    if (transactionsUrl === null
      || lastDownloadTimestamp === null
      || document.hidden === true
    ) return;

    DataFetcher.FetchTransactionDataAsync(transactionsUrl, lastDownloadTimestamp, _updateSuccess, _updateError);
  }

</script>

<MenuBar onClickDetailedMenuOpen={onClickSettingsDialogOpen}>
  <span slot="title" style="white-space: nowrap" bind:this={yearElement}>(...)</span>
  <svelte:fragment slot="settings">
    <MenuBarItem>
      <GeneralSelect
        selectId="gantt_upperpane_styleselect"
        bind:value={currentStyleObjectID}
        options={styleObjects.map((style)=>({text: style.StyleName, value: style.ObjectID}))}
      />
    </MenuBarItem>
    <!-- <ToggleSwitch bind:on={usingSimpleVersion} label={translate("frontend:ganttChart.simpleVersion")} onChange={e => onChangeSettings("usingSimpleVersion", e.target.checked)} /> -->
    {#if ganttChartType === GanttStore.GanttChartTypes.SimpleResGantt || ganttChartType === GanttStore.GanttChartTypes.SimpleOrderGantt || ganttChartType === GanttStore.GanttChartTypes.ExpectedActualCmp}
      <MenuBarItem>
        <GeneralButton buttonText={translate("frontend:ganttChart.columnSettings.label")} buttonType="secondary" buttonFunction={openColumnSettings} buttonExtraStyle="white-space: nowrap"/>
      </MenuBarItem>
    {/if}
    {#if ganttChartType === GanttStore.GanttChartTypes.ResGantt}
      <MenuBarItem>
        <ToggleSwitch
          bind:on={autoUpdate}
          onChange={(e) => {
            if (e.target.checked) {
              autoUpdateInterval = setInterval(UpdateTransactions, 10000);
            }
            else {
              if (autoUpdateInterval !== null) {
                clearInterval(autoUpdateInterval);
                autoUpdateInterval = null;
              }
            }
          }}
          label={translate("generic.autoUpdate")}
        />
      </MenuBarItem>
    {/if}
    {#if showColumnSettings}
      <MenuBarItem>
        <DisplayColumnsSettings
          GanttStore={GanttStore}
          bind:ganttChartType
          on:close={closeColumnSettings}
        />
      </MenuBarItem>
    {/if}
  </svelte:fragment>
  <GeneralPanelButton
    slot="pagination"
    items={[
      {
        buttonText: translate("generic.startShort"),
        buttonFunction: MoveButton_Home,
      },
      {
        buttonImage: '/assets/Pager_FL.svg',
        buttonFunction: MoveButton_PrevPage,
      },
      {
        buttonImage: '/assets/Pager_L.svg',
        buttonFunction: MoveButton_Prev,
      },
      {
        buttonText: translate("generic.today"),
        buttonFunction: MoveButton_Today,
      },
      {
        buttonImage: '/assets/Pager_R.svg',
        buttonFunction: MoveButton_Next,
      },
      {
        buttonImage: '/assets/Pager_FR.svg',
        buttonFunction: MoveButton_NextPage,
      },
    ]}
  />
</MenuBar>
{#if settingsDialogVisible}
  <div class="settings-menu-wrapper" in:fly={{ x: -300, duration: 300 }} out:fly={{ x: -300, duration: 300 }}>
    <SettingsMenuWrapper
      GanttStore={GanttStore}
      onClose={onClickSettingsDialogClose}
      ImportedSettings={settings}
    />
  </div>
{/if}

<style>
  .settings-menu-wrapper {
    position: fixed;
    top: 48px;
    left: 0;
    height: 100%;
    width: 300px;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 99999;
  }

</style>
