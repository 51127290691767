<script>
  import MenuBar from "../menuBar/MenuBar.svelte";
  import GeneralPanelButton from "../button/GeneralPanelButton.svelte";
  import { translate } from "../../i18n/i18next.js";
  import GanttHelper from "../ganttchart/GanttHelper.js";

  export let store
  const {
    CurrentDisplayedStartDate,
    CurrentDisplayedEndDate,
    DisplayedSpanInSeconds,
    DisplayedStartDate,
    ValidEndDate,
    ValidStartDate,
  } = store

  const MoveButton_Home = () => {
    CurrentDisplayedStartDate.set($DisplayedStartDate)
  }

  const MoveButton_PrevPage = () => {
    const oldDate = $CurrentDisplayedStartDate
    const newDate = new Date(Math.max($ValidStartDate.getTime(), oldDate.getTime() - ($CurrentDisplayedEndDate.getTime() - oldDate.getTime())))
    CurrentDisplayedStartDate.set(newDate);
  }

  const MoveButton_Prev = () => {
    const newDate = new Date(Math.max($ValidStartDate.getTime(), $CurrentDisplayedStartDate.getTime() - GanttHelper.GHDAY * 1000))
    CurrentDisplayedStartDate.set(newDate);
  }

  const MoveButton_Today = () => {
    const today = new Date()
    if (today > $ValidEndDate || today < $ValidStartDate) return;
    CurrentDisplayedStartDate.set(today)
  }

  const MoveButton_Next = () => {
    const oldDate = $CurrentDisplayedStartDate
    const newDate = new Date(Math.min($ValidEndDate.getTime() - $DisplayedSpanInSeconds * 1000, oldDate.getTime() + GanttHelper.GHDAY * 1000))
    if (newDate > oldDate) CurrentDisplayedStartDate.set(newDate)
  }

  const MoveButton_NextPage = () => {
    const newDate = new Date(Math.min($ValidEndDate.getTime() - $DisplayedSpanInSeconds * 1000, $CurrentDisplayedEndDate.getTime()))
    CurrentDisplayedStartDate.set(newDate)
  }
</script>

<MenuBar>
  <span slot="title" style="white-space: nowrap">{$CurrentDisplayedStartDate.getFullYear()}年</span>
  <svelte:fragment slot="settings">
    <slot name="upperPane-settings" />
  </svelte:fragment>
  <div class="upperPane_editbutton" slot="pagination">
    <GeneralPanelButton
        items={[
          {
            buttonText: translate("generic.startShort"),
            buttonFunction: MoveButton_Home,
          },
          {
            buttonImage: '/assets/Pager_FL.svg',
            buttonFunction: MoveButton_PrevPage,
          },
          {
            buttonImage: '/assets/Pager_L.svg',
            buttonFunction: MoveButton_Prev,
          },
          {
            buttonText: translate("generic.today"),
            buttonFunction: MoveButton_Today,
          },
          {
            buttonImage: '/assets/Pager_R.svg',
            buttonFunction: MoveButton_Next,
          },
          {
            buttonImage: '/assets/Pager_FR.svg',
            buttonFunction: MoveButton_NextPage,
          },
        ]}
    />
  </div>
</MenuBar>
