import { fetchLoadGraphProjectResources, fetchLoadGraphProjectLoads } from "./getData";
const fetchData = async (projectId) => {
  console.log('data start to load');
  // Fetch resources.json
  const resourcesData = await fetchLoadGraphProjectResources(projectId);

  const resourceIds = resourcesData.Resources;
  const dates = resourcesData.Dates;

  // Extract table headers
  const tableHeaders = ["グループ", "資源コード", "工程", "勤務シフト"];

  const timeGroups = { day: {}, week: {}, month: {} };
  const data = [];

  // Process each resource
  for (const resourceId of resourceIds) {
    const resourceData = await fetchLoadGraphProjectLoads(projectId, resourceId);

    const resource = {
      resourceCode: resourceData.Res_Code,
      process: resourceData.Res_Name,
      group: resourceData.Res_Group,
      shift: resourceData.Shift,
      percentages: { day: {}, week: {}, month: {} },
    };

    // Initialize sums for weekly and monthly calculations
    let currentWeekSum = { num: 0, den: 0 };
    let currentMonthSum = { num: 0, den: 0 };
    let currentWeekStart = null;
    let currentMonth = null;

    dates.forEach((date, index) => {
      const dateObj = new Date(date);
      const dayKey = dateObj.toLocaleDateString("ja-JP", { year: "numeric", month: "long" });
      const weekKey = getWeekKey(dateObj, dates[0]);
      const monthKey = dateObj.toLocaleDateString("ja-JP", { year: "numeric",  month: "long" });
      const monthValue = dateObj.toLocaleDateString("ja-JP", { month: "long" });


      // Initialize time groups
      if (!timeGroups.day[monthKey]) timeGroups.day[monthKey] = [];
      if (!timeGroups.week[monthKey]) timeGroups.week[monthKey] = [];
      if (!timeGroups.month[monthKey]) timeGroups.month[monthKey] = [`${monthValue}`];

      // Initialize resource percentages
      if (!resource.percentages.day[monthKey]) resource.percentages.day[monthKey] = [];
      if (!resource.percentages.week[monthKey]) resource.percentages.week[monthKey] = [];
      if (!resource.percentages.month[monthKey]) resource.percentages.month[monthKey] = [];

      const loadArray = resourceData.Loads[index];
      let load;

      // Calculate daily load
      if (loadArray[0] === -1 || loadArray[1] === -1) {
        load = -1;
      } else {
        load = loadArray[1] / loadArray[0];
      }
      resource.percentages.day[monthKey].push(load * 100);

      const options = { month: "numeric", day: "numeric", weekday: "short" };
      let formattedDay = dateObj.toLocaleDateString("ja-JP", options);
      const parts = formattedDay.match(/(\d+\/\d+)\((\S+)\)/);
      formattedDay = `${parts[1]}\n(${parts[2]})`;

      if (!timeGroups.day[monthKey].includes(formattedDay)) {
        timeGroups.day[monthKey].push(formattedDay);
      }
/*       console.log(load);
      console.log(formattedDay); */
      // Update weekly sums
      if (loadArray[0] !== -1 && loadArray[1] !== -1) {

        currentWeekSum.num += loadArray[1];
        currentWeekSum.den += loadArray[0];
      }

      // Update monthly sums
      if (loadArray[0] !== -1 && loadArray[1] !== -1) {
        currentMonthSum.num += loadArray[1];
        currentMonthSum.den += loadArray[0];
      }

      // Handle week changes
      if (currentWeekStart === null) {
        currentWeekStart = dateObj;
      } else if (dateObj.getDay() === 0 || index === dates.length - 1) { // End of the week or last day
        let weekLoad = 0;
        if (currentWeekSum.den !== 0) {
          weekLoad = currentWeekSum.num / currentWeekSum.den;
        }
        else {
          weekLoad = 0;
        }
        /* Debug line */
        /* if (resource.resourceCode === '0758K1LTCX') {
          console.log(resource.resourceCode);
          console.log('currentWeekSum is ',currentWeekSum);

          console.log('weekload is ',weekLoad);
        } */
        

        resource.percentages.week[monthKey].push(weekLoad * 100);

        if (!timeGroups.week[monthKey].includes(weekKey)) {
          timeGroups.week[monthKey].push(`${weekKey}`);
        }

        // Reset weekly sums
        currentWeekSum = { num: 0, den: 0 };
        currentWeekStart = dateObj;
      }

      // Handle month changes
      if (currentMonth === null) {
        currentMonth = monthKey;
      } 
      if (isLastDayOfMonth(dateObj) || index === dates.length - 1) { // End of the month or last day

        let monthLoad = 0;
        if (currentMonthSum.den != 0) {
          monthLoad = currentMonthSum.num / currentMonthSum.den;
        }
        else {
          monthLoad = 0;
        }
/*         console.log('monthload is ',monthLoad);
        console.log('currentMonthSum is ',currentMonthSum);
        console.log('currentMonth is ',currentMonth); */
        resource.percentages.month[currentMonth].push(monthLoad * 100);
        /* console.log('resource.percentages.month[currentMonth] is ',resource.percentages.month[currentMonth]); */

        // Reset monthly sums
        currentMonthSum = { num: 0, den: 0 };
        currentMonth = null;
      }
    });

    data.push(resource);
  }

  // Convert timeGroups and percentages to desired format
  const formattedTimeGroups = Object.keys(timeGroups).reduce((acc, period) => {
    acc[period] = Object.keys(timeGroups[period]).reduce((acc2, key) => {
      acc2[key] = timeGroups[period][key].map(date => `${date}`);
      return acc2;
    }, {});
    return acc;
  }, {});

  data.forEach(resource => {
    Object.keys(resource.percentages).forEach(period => {
      Object.keys(resource.percentages[period]).forEach(key => {
        resource.percentages[period][key] = resource.percentages[period][key];
      });
    });
  });

  return { tableHeaders, timeGroups: formattedTimeGroups, data };
};

// Helper function to get the week key in the format yyyy年m月dd日(月) - dd日(日)
const getWeekKey = (dateObj, startDate) => {
  const startOfWeek = new Date(dateObj);
  const day = dateObj.getDay();
  const diff = day === 0 ? -6 : 1 - day;
  startOfWeek.setDate(dateObj.getDate() + diff); // Get Monday of the current week

  // Ensure the start date aligns with the first date in the dataset
  if (startOfWeek < new Date(startDate)) {
    startOfWeek.setDate(new Date(startDate).getDate());
  }

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6); // Get Sunday of the current week

  const start = startOfWeek.toLocaleDateString("ja-JP", { month: "numeric", day: "numeric", weekday: "short" });
  const end = endOfWeek.toLocaleDateString("ja-JP", { month: "numeric", day: "numeric", weekday: "short" });

  return `${start} - ${end}`;
};
const isLastDayOfMonth = (date) => {
  const testDate = new Date(date);
  testDate.setDate(testDate.getDate() + 1);
  return testDate.getDate() === 1;
};
export default fetchData;
