<script>
  export let X  // in percent
  export let Y  // in px
  export let BottomY  // in px
  export let Height  // in px
  export let LeftNeighborHeight  // in px
  export let RightNeighborHeight  // in px
  export let Width  // in percent
  export let Items
  export let GetHeightByLoad
  export let GetColorByItem
  export let ShouldDisplayByItems

  const displayedItems = Object.keys(Items).map((itemCode) => ({
    itemCode,
    load: Items[itemCode],
    color: GetColorByItem(itemCode),
    height: GetHeightByLoad(Items[itemCode]),
    y: Y,
  }))
  displayedItems.forEach((item, index) => {
    if (index > 0) {
     item.y = displayedItems[index-1].y + displayedItems[index-1].height
    }
  })
</script>

{#if ShouldDisplayByItems}
  {#each displayedItems as item, index}
    {#if index < displayedItems.length -1}
      <rect
        x="{X}%"
        y="{item.y}px"
        width="{Width}%"
        height="{item.height}px"
        fill="{item.color}"
        stroke="black"
        stroke-width="0.5"
      />
    {:else}
      <!-- the bottom item gets a special treatment, to align the bottom of itself with the other bars -->
      <rect
        x="{X}%"
        y="{item.y}px"
        width="{Width}%"
        height="{BottomY - item.y}px"
        fill="{item.color}"
        stroke="black"
        stroke-width="0.5"
      />
    {/if}
  {/each}
{:else}
  <rect
    x="{X}%"
    y="{Y}px"
    width="{Width}%"
    height="{BottomY - Y}px"
    fill="#cccccc"
  />
{/if}
<!-- top border -->
<line
  x1="{X}%"
  y1="{Y}px"
  x2="{X+Width}%"
  y2="{Y}px"
  stroke="black"
  stroke-width="0.5"
/>
<!-- left border -->
{#if Height > LeftNeighborHeight}
  <line
    x1="{X}%"
    y1="{Y}px"
    x2="{X}%"
    y2="{Y + Height - LeftNeighborHeight}px"
    stroke="black"
    stroke-width="0.5"
  />
{/if}
<!-- right border -->
{#if Height > RightNeighborHeight}
  <line
    x1="{X+Width}%"
    y1="{Y}px"
    x2="{X+Width}%"
    y2="{Y + Height - RightNeighborHeight}px"
    stroke="black"
    stroke-width="0.5"
  />
{/if}