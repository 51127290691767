<script>
  export let title
</script>

<div class="title-container">
  <div class="title">{title}</div>
</div>

<style>
  .title {
    font-weight: bold;
    flex-grow: 1;
    font-size: 24px;
    color: #888888;
    line-height: 1;
  }
  .title-container {
    padding: 24px;
  }
</style>

