<script>
  import GanttHelper from './GanttHelper.js';
  export let Label;
  export let StartDate;
  export let EndDate;
  export let Height;
  export let OriginY;
  export let AdjustStamp;
  export let CurrentStyle;

  const UnclampedCalculateStampXDifferential = (stamp1, stamp2) => UnclampedCalculateStampX(stamp2) - UnclampedCalculateStampX(stamp1);
  const UnclampedCalculateStampX = (stamp) => 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime());
  const CalculateStampX = (stamp) => Math.max(0, Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime())));
  const CalculateStampXDifferential = (stamp1, stamp2) => CalculateStampX(stamp2) - CalculateStampX(stamp1);

  const VAlign = {
    "Top":"top",
    "Center":"middle",
    "Bottom":"bottom",
  };

  const HAlign = {
    "Left":"start",
    "Center":"center",
    "Right":"end",
  };

  $: LabelContent = ((typeof Label._string === 'string') ? Label._string : ((CurrentStyle in Label._string) ? Label._string[CurrentStyle] : "")).replaceAll('\\n', '\n');
  $: ArrowRelativeHeight = ((typeof Label._height === 'object' && CurrentStyle in Label._height) ? Label._height[CurrentStyle] : 0.8);


</script>

  {#if Label._timePoint !== ""}

    {#if Label._shape === "B"}

      <svg
        x="{Math.min(0, Label._rectRelX)}px"
        y="{Math.min(0, Label._rectRelY)}px"
        height="100%"
        width="100%"
        style="overflow: visible"
        >

      <svg
       id="{Label._objectID}"
       x="{UnclampedCalculateStampX(Label._timePoint)}%"
       y="{OriginY+Label._relYRatio*Height}%"
       height="{Label._height+Math.abs(Label._rectRelY)}px"
       width="{Label._width+Math.abs(Label._rectRelX)}px"
      >

      <!-- Quadrant 1 -->
      {#if Label._rectRelX >= 0 && Label._rectRelY >= 0}
      <polygon
        points="
          {Label._rectRelX},{Label._rectRelY+Label._height*0.2}
          0,0
          {Label._rectRelX+Label._width*0.2},{Label._rectRelY}

          {Label._rectRelX+Label._width},{Label._rectRelY}
          {Label._rectRelX+Label._width},{Label._rectRelY+Label._height}
          {Label._rectRelX},{Label._rectRelY+Label._height}
        "
        style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
      />

      <foreignObject
        x="{Label._rectRelX}px"
        y="{Label._rectRelY}px"
        height="{Label._height - 2}px"
        width="{Label._width}px"
      >
        <div style="
          display: table-cell;
          height:{Label._height}px;
          width:{Label._width}px;
          overflow: hidden;
          vertical-align: {VAlign[Label._textVerticalAlignment]};
          text-align:{HAlign[Label._textHorizontalAlignment]};
          color:{Label._textColor};
          padding: 2px;
          white-space: pre-line;
        ">
          {LabelContent}
        </div>
      </foreignObject>
      {/if}

      <!-- Quadrant 2 -->
      {#if Label._rectRelX < 0 && Label._rectRelY >= 0}
        <!-- Quadrant 2.1 -->
        {#if -Label._rectRelX > Label._width}
        <polygon
          points="
            {Label._width},{Label._rectRelY+Label._height*0.2}
            {-Label._rectRelX},0
            {Label._width*0.8},{Label._rectRelY}

            0,{Label._rectRelY}
            0,{Label._rectRelY+Label._height}
            {Label._width},{Label._rectRelY+Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}
        <!-- Quadrant 2.2 -->
        {#if -Label._rectRelX <= Label._width}
        <polygon
          points="
            {Label._width},{Label._rectRelY}
            {Label._width*0.6},{Label._rectRelY}
            {-Label._rectRelX},0
            {Label._width*0.4},{Label._rectRelY}

            0,{Label._rectRelY}
            0,{Label._rectRelY+Label._height}
            {Label._width},{Label._rectRelY+Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}
        <foreignObject
          x="0px"
          y="{Label._rectRelY}px"
          height="{Label._height - 2}px"
          width="{Label._width}px"
        >
          <div style="
            display: table-cell;
            height:{Label._height}px;
            width:{Label._width}px;
            overflow: hidden;
            vertical-align: {VAlign[Label._textVerticalAlignment]};
            text-align:{HAlign[Label._textHorizontalAlignment]};
            color:{Label._textColor};
            padding: 2px;
            white-space: pre-line;
          ">
            {LabelContent}
          </div>
        </foreignObject>
      {/if}

      <!-- Quadrant 3 -->
      {#if Label._rectRelX >= 0 && Label._rectRelY < 0}
        <!-- Quadrant 3.1 -->
        {#if -Label._rectRelY > Label._height}
        <polygon
          points="
            {Label._rectRelX+Label._width*0.2},{Label._height}
            0,{-Label._rectRelY}
            {Label._rectRelX},{Label._height*0.8}

            {Label._rectRelX},0
            {Label._rectRelX+Label._width},0
            {Label._rectRelX+Label._width},{Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}
        <!-- Quadrant 3.2 -->
        {#if -Label._rectRelY <= Label._height}
        <polygon
          points="
            {Label._rectRelX},{Label._height}
            {Label._rectRelX},{Label._height*0.6}
            0,{-Label._rectRelY}
            {Label._rectRelX},{Label._height*0.4}

            {Label._rectRelX},0
            {Label._rectRelX+Label._width},0
            {Label._rectRelX+Label._width},{Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}
        <foreignObject
          x="{Label._rectRelX}px"
          y="0px"
          height="{Label._height - 2}px"
          width="{Label._width}px"
        >
          <div style="
            display: table-cell;
            height:{Label._height}px;
            width:{Label._width}px;
            overflow: hidden;
            vertical-align: {VAlign[Label._textVerticalAlignment]};
            text-align:{HAlign[Label._textHorizontalAlignment]};
            color:{Label._textColor};
            padding: 2px;
            white-space: pre-line;
          ">
            {LabelContent}
          </div>
        </foreignObject>
      {/if}

      <!-- Quadrant 4 -->
      {#if Label._rectRelX < 0 && Label._rectRelY < 0}
        <!-- Quadrant 4.1 -->
        {#if -Label._rectRelY > Label._height && -Label._rectRelX > Label._width}
        <polygon
          points="
            {Label._width*0.8},{Label._height}
            {-Label._rectRelX},{-Label._rectRelY}
            {Label._width},{Label._height*0.8}

            {Label._width},0
            0,0
            0,{Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}
        <!-- Quadrant 4.2 -->
        {#if -Label._rectRelY <= Label._height && -Label._rectRelX > Label._width}
        <polygon
          points="
          {Label._width},{Label._height}
          {Label._width},{Label._height*0.6}
          {-Label._rectRelX},{-Label._rectRelY}
          {Label._width},{Label._height*0.4}

            {Label._width},0
            0,0
            0,{Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}
        <!-- Quadrant 4.3 -->
        {#if -Label._rectRelY > Label._height && -Label._rectRelX <= Label._width}
        <polygon
          points="
          {Label._width*0.4},{Label._height}
          {-Label._rectRelX},{-Label._rectRelY}
          {Label._width*0.6},{Label._height}
          {Label._width},{Label._height}

            {Label._width},0
            0,0
            0,{Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}
        <!-- Quadrant 4.4 -->
        {#if -Label._rectRelY <= Label._height && -Label._rectRelX <= Label._width}
        <polygon
          points="
          {Label._width},{Label._height}
          {Label._width},0
          0,0
          0,{Label._height}
          "
          style="fill:{Label._backColor};stroke-width:1;stroke:{Label._borderColor}"
        />
        {/if}

        <foreignObject
          x="0px"
          y="0px"
          height="{Label._height - 2}px"
          width="{Label._width}px"
        >
          <div style="
            display: table-cell;
            height:{Label._height}px;
            width:{Label._width}px;
            overflow: hidden;
            vertical-align: {VAlign[Label._textVerticalAlignment]};
            text-align:{HAlign[Label._textHorizontalAlignment]};
            color:{Label._textColor};
            padding: 2px;
            white-space: pre-line;
          ">
            {LabelContent}
          </div>
        </foreignObject>
      {/if}

      </svg>
      </svg>

    {/if}

    {#if Label._shape === "T"}

      <svg
       x="{UnclampedCalculateStampX(Label._timePoint)}%"
       y="{OriginY+Label._relYRatio*Height}%"
       height="{Height*ArrowRelativeHeight}%"
       width="{UnclampedCalculateStampXDifferential(Label._timePoint, new Date(new Date(Label._timePoint).getTime() + Label._timeSpan * 1000))}%"
      >

      <rect
        x="0" y="0" width="100%" height="100%"
        style="
          fill:{Label._backColor};
          stroke-width:1;
          stroke:{Label._borderColor};
          clip-path: polygon(
            0% 50%,
            {Label._arrowDepthRatio*50}% 0%,
            {Label._arrowDepthRatio*50}% {(1-Label._arrowStemHeightRatio)*50}%,
            {100-(Label._arrowDepthRatio*50)}% {(1-Label._arrowStemHeightRatio)*50}%,
            {100-(Label._arrowDepthRatio*50)}% 0%,
            100% 50%,
            {100-(Label._arrowDepthRatio*50)}% 100%,
            {100-(Label._arrowDepthRatio*50)}% {100-((1-Label._arrowStemHeightRatio)*50)}%,
            {Label._arrowDepthRatio*50}% {100-((1-Label._arrowStemHeightRatio)*50)}%,
            {Label._arrowDepthRatio*50}% 100%
            );
          "
      />

      <line
        x1="0%"
        y1="50%"
        x2="{Label._arrowDepthRatio*50}%"
        y2="100%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{Label._arrowDepthRatio*50}%"
        y1="{100-((1-Label._arrowStemHeightRatio)*50)}%"
        x2="{Label._arrowDepthRatio*50}%"
        y2="100%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{Label._arrowDepthRatio*50}%"
        y1="{100-((1-Label._arrowStemHeightRatio)*50)}%"
        x2="{100-(Label._arrowDepthRatio*50)}%"
        y2="{100-((1-Label._arrowStemHeightRatio)*50)}%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{100-(Label._arrowDepthRatio*50)}%"
        y1="100%"
        x2="{100-(Label._arrowDepthRatio*50)}%"
        y2="{100-((1-Label._arrowStemHeightRatio)*50)}%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{100-(Label._arrowDepthRatio*50)}%"
        y1="100%"
        x2="100%"
        y2="50%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{100-(Label._arrowDepthRatio*50)}%"
        y1="0%"
        x2="100%"
        y2="50%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{100-(Label._arrowDepthRatio*50)}%"
        y1="0%"
        x2="{100-(Label._arrowDepthRatio*50)}%"
        y2="{(1-Label._arrowStemHeightRatio)*50}%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{Label._arrowDepthRatio*50}%"
        y1="{(1-Label._arrowStemHeightRatio)*50}%"
        x2="{100-(Label._arrowDepthRatio*50)}%"
        y2="{(1-Label._arrowStemHeightRatio)*50}%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="{Label._arrowDepthRatio*50}%"
        y1="{(1-Label._arrowStemHeightRatio)*50}%"
        x2="{Label._arrowDepthRatio*50}%"
        y2="0%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <line
        x1="0%"
        y1="50%"
        x2="{Label._arrowDepthRatio*50}%"
        y2="0%"
        stroke="{Label._borderColor}"
        stroke-width="1"
      />

      <foreignObject
        x="{Label._arrowDepthRatio*50}%"
        y="{(1-Label._arrowStemHeightRatio)*50}%"
        height="{Label._arrowStemHeightRatio*100}%"
        width="{(1-Label._arrowDepthRatio)*100}%"
      >
      <div style="
        display: table;
        height:100%;
        width:100%;
        overflow: hidden;
      ">
        <div style="
          display: table-cell;
          height:100%;
          width:100%;
          overflow: hidden;
          vertical-align: {VAlign[Label._textVerticalAlignment]};
          text-align:{HAlign[Label._textHorizontalAlignment]};
          color:{Label._textColor};
          padding: 2px;
          white-space: pre-line;
        ">
          {LabelContent}
        </div>
      </div>
      </foreignObject>

      </svg>

    {/if}

  {/if}

<style>

</style>
