<script>
  import TableArea from './TableArea.svelte';
  import ChartArea from './ChartArea.svelte';
  import ExtraTableArea from './ExtraTableArea.svelte'
  import { onMount } from 'svelte';

  export let GanttStore

  let tableWidth = 200;
  GanttStore.LeftTableWidth.subscribe((width) => tableWidth = width);

  let extraColumns = Array();
  let width = 100;
  let minWidthPerColumnInPx = 150;
  let maxWidthPerColumnInPx = 250;

  function handleDrag(e) {
    const newTableWidth = e.clientX;
    if (newTableWidth < 100 || window.innerWidth - newTableWidth < 300) return; 
    GanttStore.LeftTableWidth.update((_) => newTableWidth);
  }

  function stopDrag(e) {
    window.removeEventListener('mousemove', handleDrag);
    window.removeEventListener('mouseup', stopDrag);
  }

  function startDrag(e) {
    window.addEventListener('mousemove', handleDrag);
    window.addEventListener('mouseup', stopDrag);
  }

  onMount( async() => {
    GanttStore.ExtraColumns.subscribe((ec) => {
      let sameLength = (ec.length == extraColumns.length);
      extraColumns = ec;
      if (sameLength) return;
      width = 100 / (1 + ec.length);
      if (tableWidth * width / 100 < minWidthPerColumnInPx) {
        GanttStore.LeftTableWidth.update((_) => minWidthPerColumnInPx * (1 + ec.length));
      } else if (tableWidth * width / 100 > maxWidthPerColumnInPx) {
        GanttStore.LeftTableWidth.update((_) => maxWidthPerColumnInPx * (1 + ec.length));
      }
    });
  });
</script>

<div id="gantt_chartcontainer">
  <div class="table-area" style="width: {tableWidth}px; display: flex">
    <TableArea
      GanttStore={GanttStore}
      bind:width
    />
    {#each extraColumns as ec}
      <ExtraTableArea
        GanttStore={GanttStore}
        bind:width
        column={ec}
      />
    {/each}
  </div>
  <div class="resizer" on:mousedown={startDrag}></div>
  <div class="chart-area" >
    <ChartArea
      GanttStore={GanttStore}
    />
  </div>
</div>

<style>
  div#gantt_chartcontainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    z-index: 60;
  }
  .table-area {
    height: 100%;
    background-color: white;
    border-right: 2px solid #888888;
  }
  .chart-area {
    flex-grow: 1;
    height: 100%;
    background-color: white;
  }
  .resizer {
    width: 5px;
    cursor: ew-resize;
    background-color: #ccc;
    height: 100%;
  }
</style>
