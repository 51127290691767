import { writable } from "svelte/store";

export const selectedResourceCodeS = writable(null);
export const selectedProcessS = writable(null);
export const selectedGroupS = writable(null);
export const selectedShiftS = writable(null);
export const selectedUnitPeriodS = writable("week");
export const selectedDisplayPeriodS = writable("1month");
export const selectedDisplayRowNumS = writable(10);

export const timeGroupsWeek = writable({});
export const timeGroupsMonth = writable({});
export const timeGroupsDay = writable({});
export const startDayIndex = writable(0);
export const startWeekIndex = writable(0);
export const startMonthIndex = writable(0);
export const daysToShow = writable(14);
export const weeksToShow = writable(2);
export const monthsToShow = writable(1);

export const dataDay = writable([]);
export const dataWeek = writable([]);
export const dataMonth = writable([]);
export const startRowIndex = writable(-1);
export const rowsToShow = writable(10);
export const tmpMaxRow = writable(-1);

export const border = writable(0);
export const viewportHeight = writable(window.innerHeight);
export const viewportWidth = writable(window.innerWidth);

export const mostRecentChange = writable({
  group: 0,
  resourceCode: 0,
  process: 0,
  shift: 0
});