<script>
  import { getContext, onDestroy } from "svelte";
  import { useQuery } from "@sveltestack/svelte-query";
  import { derived } from "svelte/store";
  import { checkCompatibilityWithThisFrontend } from "../../../../utils/compatibility/versionCompatibility.js";
  import { usePageContext } from "../../pageContext.js";

  export let projectId
  export let viewName

  const {handleFetchErrors} = getContext("fetchErrorHandler")
  const pageContext = usePageContext()

  const dataMetadataQuery = useQuery(`/api/projects/${projectId}/views/${viewName}/meta`, {retry: 1})
  const dataCompatibilityStatus = derived(dataMetadataQuery, metadataQuery => {
    if (metadataQuery.isLoading) return {status: "loading"}

    if (metadataQuery.data.status === "noData") {
      return {
        status: "noData"
      }
    }

    // if no plugin version is given, assume it is generated by v.1.4.3 plugin.
    const pluginVersion = metadataQuery.data?.pluginVersion
    const assumedPluginVersion = pluginVersion ?? "1.4.3"
    const checkResult = checkCompatibilityWithThisFrontend(assumedPluginVersion, viewName)
    if(checkResult.isCompatible) {
      return {
        status: "compatible",
        pluginVersion,
        pluginVersionRequirement: checkResult.pluginVersionRequirement,
        thisFrontendVersion: checkResult.thisFrontendVersion,
      }
    }

    return {
      status: "incompatible",
      pluginVersion,
      pluginVersionRequirement: checkResult.pluginVersionRequirement,
      thisFrontendVersion: checkResult.thisFrontendVersion,
    }
  })
  dataCompatibilityStatus.subscribe(status => {
    if (status.status === "noData") {
      handleFetchErrors({
        type: "error:file-not-found"
      })
    }

    // if it is incompatible, raise a fetch error.
    if (status.status === "incompatible") {
      handleFetchErrors({
        type: "error:incompatible-plugin-version",
        currentPluginVersion: status.pluginVersion ?? "<1.5.0",
        pluginVersionRequirement: status.pluginVersionRequirement,
        thisFrontendVersion: status.thisFrontendVersion
      })
    }

    if (status.status !== "loading") {
      pageContext.setVersions({plugin: status.pluginVersion})
    }
  })

  onDestroy(() => {
    pageContext.setVersions({plugin: null})
  })
</script>

<slot />