<script>
  import { usePageContext } from "./pageContext.js";
  import BackButton from "./BackButton.svelte";
  import User from "../layout/header/User.svelte";
  import { translate } from "../../i18n/i18next.js";
  import HelpIconButton from "../layout/header/HelpIconButton.svelte";

  /**
   * @type {string|null}
   */
  export let backTo = "/";

  const { context } = usePageContext();
</script>

<article id="root">
  <div id="headline-wrap">
    <div id="headline-container">
      <div id="headline-left">
        {#if backTo}<BackButton {backTo} />{/if}
      </div>
      <div id="headline-middle">
        <img src="/icon-192.png" alt="Asprova My Schedule Icon" />
        <span class="header-title">
          {$context.headerTitle}
          <span style="font-size:large; font-weight:normal; color:gray; margin-left:10px">
            {$context.uploadTime
              ? `${((timestamp) =>
                  `${timestamp.slice(2, 4)}/${timestamp.slice(
                    4,
                    6
                  )} ${timestamp.slice(7, 9)}:${timestamp.slice(9, 11)}`)(
                  $context.uploadTime
                )} ${translate("generic.upload")}`
              : ""}
          </span>
        </span>
      </div>
      <div id="headline-right">
        {#if $context.rightPane.href}<HelpIconButton />{/if}
        <User />
      </div>
    </div>
  </div>
</article>

<svelte:head>
  <title>{$context.headerTitle} - Asprova My Schedule</title>
</svelte:head>

<style>
  #root {
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  #headline-wrap {
    width: 100%;
    background-color: #e9e9f0;
    border-bottom: solid #cdcdcd;
  }
  #headline-container {
    width: 95%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  #headline-left {
    flex-basis: content;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  #headline-middle {
    container-type: inline-size;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  #headline-middle > img {
    height: 48px;
    aspect-ratio: 1;
  }
  #headline-right {
    flex-basis: content;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
  }
  .header-title {
    font-size: x-large;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @container (width < 200px) {
    .header-title {
      display: none;
    }
  }
</style>
