<script>
  console.log("script loaded");
  import { tick, onMount, afterUpdate } from "svelte";
  import { useParams } from "svelte-navigator";
  import { fly } from "svelte/transition";
  import { derived } from 'svelte/store';

  import { usePageContext } from "../../components/pages/pageContext.js";
  import GeneralPanelButton from "../../components/button/GeneralPanelButton.svelte";
  import GeneralSelect from "../../components/select/GeneralSelect.svelte";
  import Loading from "../../components/pages/Loading.svelte";

  import fetchData from "../../utils/loadGraphFetchData.js";
  import LoadTableArea from "../../components/loadgraph/LoadTableArea.svelte";
  import LoadExtraTableArea from "../../components/loadgraph/LoadExtraTableArea.svelte";
  import LoadDataArea from "../../components/loadgraph/LoadDataArea.svelte";
  import LoadGraphFilter from "../../components/loadgraph/LoadGraphFilter.svelte";
  import { fetchProjectData } from "../../utils/getData";

  import {
    selectedResourceCodeS,
    selectedProcessS,
    selectedGroupS,
    selectedShiftS,
    selectedUnitPeriodS,
    selectedDisplayPeriodS,
    selectedDisplayRowNumS,
    timeGroupsDay,
    timeGroupsWeek,
    timeGroupsMonth,
    startDayIndex,
    startWeekIndex,
    startMonthIndex,
    daysToShow,
    weeksToShow,
    monthsToShow,
    dataDay,
    dataWeek,
    dataMonth,
    startRowIndex,
    rowsToShow,
    tmpMaxRow,
    border,
    viewportHeight,
    viewportWidth,
    mostRecentChange
  } from "../../components/loadgraph/loadGraphStore.js";


  const params = useParams();
  const projectId = $params.project;
  let uploadTimeStamp = undefined;

  let tableHeaders = [];

/*   let timeGroupsDay = {};
  let timeGroupsWeek = {};
  let timeGroupsMonth = {}; */

/*   let dataDay = [];
  let dataWeek = [];
  let dataMonth = []; */

  // visible data for dates in 3 time formats
/*   let visibleDays = [];
  let visibleWeeks = [];
  let visibleMonths = []; */

  // started index for visible data for dates in 3 time formats
/*   let startDayIndex = 0;
  let startWeekIndex = 0;
  let startMonthIndex = 0; */

/*   let startRowIndex = 0; */
/*   let tmpMaxRow = -1; */

  let openFilter = false;

  let emptyText;
  // The unit period options and display period options
  const unitPeriod = [
    { text: "日", value: "day" },
    { text: "週", value: "week" },
    { text: "月", value: "month" }
  ];

  let displayPeriod = [
    { text: "1日", value: "1day" },
    { text: "3日", value: "3days" },
    { text: "1週間", value: "1week" },
    { text: "2週間", value: "2weeks" },
    { text: "1ヶ月", value: "1month" },
    { text: "2ヶ月", value: "2months" },
    { text: "3ヶ月", value: "3months" }
  ];

  let selectedResourceCode;
  let selectedProcess;
  let selectedGroup;
  let selectedShift;
  let selectedUnitPeriod;
  let selectedDisplayPeriod;
  let selectedDisplayRowNum;
  /* console.log("viewportWidth", viewportWidth);
  console.log("viewportHeight", viewportHeight); */
  let toolbar;
  let tableHeader;
  let botButtons;

/*   let rowHeight = 0; */
/*   let cellWidth = 0; */

  let isLoading = true;

  // subscribe to the selected filters
  selectedResourceCodeS.subscribe((value) => (selectedResourceCode = value));
  selectedProcessS.subscribe((value) => (selectedProcess = value));
  selectedGroupS.subscribe((value) => (selectedGroup = value));
  selectedShiftS.subscribe((value) => (selectedShift = value));
  selectedUnitPeriodS.subscribe((value) => (selectedUnitPeriod = value));
  selectedDisplayPeriodS.subscribe((value) => (selectedDisplayPeriod = value));
  selectedDisplayRowNumS.subscribe((value) => (selectedDisplayRowNum = value));
  let resourceCodes = [];
  let processes = [];
  let groups = [];
  let shifts = [];

  let groupFilterInput;
  let resFilterInput;
  let procFilterInput;
  let shiftFilterInput;


  // default setting for display mode
  let viewMode = "colorScale";

  // default setting for how many rows and column(data for each week) to display
/*   let rowsToShow = 10; */

/*   let daysToShow = 14;
  let weeksToShow = 2;
  let monthsToShow = 1; */

  const displayOptions = [
    { text: "カラースケール", value: "colorScale" },
    { text: "データバー", value: "dataBar" },
  ];

  // set up header title
  // load graph have not added to i18n.js
  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({
    title: `${"Sample1"} - 負荷率グラフ`,
  });
  $: setHeaderProps({ uploadTime: uploadTimeStamp});

  const rowHeight = derived(
    [viewportHeight, rowsToShow, border],
    ([$viewportHeight, $rowsToShow, $border]) => {
      const titleHeight = 51;
      const toolbarHeight = 50;
      const tableHeaderHeight = 80;
      const footerHeight = 30;
      const remainingHeight = $viewportHeight - titleHeight - toolbarHeight - tableHeaderHeight - footerHeight - $border*(2+$rowsToShow);
      return remainingHeight / $rowsToShow;
    }
  );

  const cellWidth = derived(
    [selectedUnitPeriodS, viewportWidth, daysToShow, weeksToShow, monthsToShow, border],
    ([$selectedUnitPeriodS, $viewportWidth, $daysToShow, $weeksToShow, $monthsToShow, $border]) => {
      /* const remainingWidth = containerWidth - 180*2 - 150 - 190 - border*5; */
      let remainingWidth = $viewportWidth - 180*2 - 150 - 190;

      if ($selectedUnitPeriodS === "day") {
        remainingWidth -= $border*(4+ ($daysToShow+1));
        return (remainingWidth / $daysToShow);
      } else if ($selectedUnitPeriodS === "week") {
        remainingWidth -= $border*(4+ ($weeksToShow+1));
        return (remainingWidth / $weeksToShow);
      } else if ($selectedUnitPeriodS === "month") {
        remainingWidth -= $border*(4+ ($monthsToShow+1));
        return (remainingWidth / $monthsToShow);
      }
    }
  );

  const filteredData = derived(
    [dataDay, dataWeek, dataMonth, selectedResourceCodeS, selectedProcessS, selectedGroupS, selectedShiftS, selectedUnitPeriodS],
    ([$dataDay, $dataWeek, $dataMonth, $selectedResourceCodeS, $selectedProcessS, $selectedGroupS, $selectedShiftS, $selectedUnitPeriodS]) => {
      let sourceData;
      if ($selectedUnitPeriodS === 'day') {
        sourceData = $dataDay;
      } else if ($selectedUnitPeriodS === 'week') {
        sourceData = $dataWeek;
      } else if ($selectedUnitPeriodS === 'month') {
        sourceData = $dataMonth;
      }

      return sourceData.filter(d => 
        ($selectedResourceCodeS ? d.resourceCode === $selectedResourceCodeS : true) &&
        ($selectedProcessS ? d.process === $selectedProcessS : true) &&
        ($selectedGroupS ? d.group === $selectedGroupS : true) &&
        ($selectedShiftS ? d.shift === $selectedShiftS : true)
      );
    }
  );

/*   let previousValues = {
    selectedUnitPeriodS: $selectedUnitPeriodS,
    startRowIndex: $startRowIndex,
    rowsToShow: $rowsToShow,
    tmpMaxRow:  $tmpMaxRow,
    filteredData: $filteredData
  }; */

  
  const visibleRows = derived(
    [dataDay, dataWeek, dataMonth, selectedUnitPeriodS, startRowIndex, rowsToShow, tmpMaxRow, filteredData],
    ([$dataDay, $dataWeek, $dataMonth, $selectedUnitPeriodS, $startRowIndex, $rowsToShow, $tmpMaxRow, $filteredData]) => {

/*       if ($selectedUnitPeriodS !== previousValues.selectedUnitPeriodS) {
        console.log("selectedUnitPeriodS changed");
      }
      if ($startRowIndex !== previousValues.startRowIndex) {
        console.log("startRowIndex changed");
      }
      if ($rowsToShow !== previousValues.rowsToShow) {
        console.log("rowsToShow changed");
      }
      if ($tmpMaxRow !== previousValues.tmpMaxRow) {
        console.log("tmpMaxRow changed");
      }
      if ($filteredData !== previousValues.filteredData) {
        console.log("filteredData changed");
      }

      // Update previous values
      previousValues = {
        selectedUnitPeriodS: $selectedUnitPeriodS,
        startRowIndex: $startRowIndex,
        rowsToShow: $rowsToShow,
        tmpMaxRow: $tmpMaxRow,
        filteredData: $filteredData
      }; */

      if ($selectedUnitPeriodS === "day") {
        if ($tmpMaxRow >= 0) {
          return $filteredData.slice($startRowIndex, $startRowIndex + $rowsToShow);
        } else {
          return $dataDay.slice($startRowIndex, $startRowIndex + $rowsToShow);
        }
      } else if ($selectedUnitPeriodS === "week") {
        if ($tmpMaxRow >= 0) {
          return $filteredData.slice($startRowIndex, $startRowIndex + $rowsToShow);
        } else {
          return $dataWeek.slice($startRowIndex, $startRowIndex + $rowsToShow);
        }
      } else if ($selectedUnitPeriodS === "month") {
        if ($tmpMaxRow >= 0) {
          return $filteredData.slice($startRowIndex, $startRowIndex + $rowsToShow);
        } else {
          return $dataMonth.slice($startRowIndex, $startRowIndex + $rowsToShow);
        }
      }

      return [];  // Default return value if no conditions are met
    }
  );

  const visibleDays = derived(
    [timeGroupsDay, startDayIndex, daysToShow],
    ([$timeGroupsDay, $startDayIndex, $daysToShow]) => {
      let visibleDays = [];
      const allDays = Object.values($timeGroupsDay).flat();
      for (let i = $startDayIndex; i < $startDayIndex + $daysToShow; i++) {
        if (i < allDays.length) {
          visibleDays.push(allDays[i]);
        }
      }
      return visibleDays;
    }
  );

  const visibleWeeks = derived(
    [timeGroupsWeek, startWeekIndex, weeksToShow],
    ([$timeGroupsWeek, $startWeekIndex, $weeksToShow]) => {
      let visibleWeeks = [];
      const allWeeks = Object.values($timeGroupsWeek).flat();
      for (let i = $startWeekIndex; i < $startWeekIndex + $weeksToShow; i++) {
        if (i < allWeeks.length) {
          visibleWeeks.push(allWeeks[i]);
        }
      }
      return visibleWeeks;
    }
  );

  const visibleMonths = derived(
    [timeGroupsMonth, startMonthIndex, monthsToShow],
    ([$timeGroupsMonth, $startMonthIndex, $monthsToShow]) => {
      let visibleMonths = [];
      const allMonths = Object.values($timeGroupsMonth).flat();
      for (let i = $startMonthIndex; i < $startMonthIndex + $monthsToShow; i++) {
        if (i < allMonths.length) {
          visibleMonths.push(allMonths[i]);
        }
      }
      return visibleMonths;
    }
  );

  const rowNumMaxValue = derived(
    [dataWeek, startRowIndex],
    ([$dataWeek, $startRowIndex]) => {
      return Math.min($dataWeek.length - $startRowIndex, 30)
    }
  );

  $: {
    if (
      selectedResourceCode ||
      selectedProcess ||
      selectedGroup ||
      selectedShift
    ) {
      if (selectedUnitPeriod === "day") {
        tmpMaxRow.set($filteredData.length);
        startRowIndex.set(0);
        /* updateVisibleRows(); */
      } else if (selectedUnitPeriod === "week") {
        tmpMaxRow.set($filteredData.length);
        startRowIndex.set(0);
        /* updateVisibleRows(); */
      } else if (selectedUnitPeriod === "month") {
        tmpMaxRow.set($filteredData.length);
        startRowIndex.set(0);
        /* updateVisibleRows(); */
      }
      /* console.log("tmpMaxRow now is ", $tmpMaxRow);
      console.log("filteredData", $filteredData); */
      if ($filteredData.length === 0) {
        if ($mostRecentChange.group) {
          emptyText = "グループ";
        } else if ($mostRecentChange.resourceCode) {
          emptyText = "資源コード";
        } else if ($mostRecentChange.process) {
          emptyText = "工程";
        } else if ($mostRecentChange.shift) {
          emptyText = "勤務シフト";
        }
      }

    }
  }

/*   $: {
    if (
      selectedUnitPeriod
    ) {
      updateVisibleRows();
    }
  } */

  function updateDatesToShow() {
    if (selectedDisplayPeriod === "1day") {
      daysToShow.set(1);
      weeksToShow.set(1);
      monthsToShow.set(1);
    } else if (selectedDisplayPeriod === "3days") {
      daysToShow.set(3);
      weeksToShow.set(1);
      monthsToShow.set(1);
    } else if (selectedDisplayPeriod === "1week") {
      daysToShow.set(7);
      weeksToShow.set(1);
      monthsToShow.set(1);
    } else if (selectedDisplayPeriod === "2weeks") {
      daysToShow.set(14);
      weeksToShow.set(2);
      monthsToShow.set(1);
    } else if (selectedDisplayPeriod === "1month") {
      daysToShow.set(30);
      weeksToShow.set(5);
      monthsToShow.set(1);
    } else if (selectedDisplayPeriod === "2months") {
      daysToShow.set(60);
      weeksToShow.set(9);
      monthsToShow.set(2);
    } else if (selectedDisplayPeriod === "3months") {
      daysToShow.set(90);
      weeksToShow.set(13);
      monthsToShow.set(3);
    }
  }

  $: {
    if (
      selectedDisplayPeriod
    ) {
      updateDatesToShow();
    }
  }

  $: {
    if (selectedDisplayRowNum) {
      (async () => {
        await tick(); // Wait for the DOM to be fully initialized

        rowsToShow.set(+selectedDisplayRowNum);
      })();
    }
  }

  // Reset startedRowIndex to 0 when the filter is used
  $: {
    if (
      selectedResourceCode ||
      selectedProcess ||
      selectedGroup ||
      selectedShift
    ) {
      startRowIndex.set(0);
    }
  }

  // Fetch data from the JSON file
  async function loadData(projectId) {
    const projectData = await fetchProjectData(projectId, "LoadGraph");
    uploadTimeStamp = projectData.UploadTimeStamp;

    const result = await fetchData(projectId);
    tableHeaders = result.tableHeaders;

    // Separate the time groups and data for day, week, and month
    timeGroupsDay.set(result.timeGroups.day);
    timeGroupsWeek.set(result.timeGroups.week);
    timeGroupsMonth.set(result.timeGroups.month);

    dataDay.set(
    result.data.map(resource => ({
      ...resource,
      percentages: resource.percentages.day
    }))
    );
    dataWeek.set(
      result.data.map(resource => ({
        ...resource,
        percentages: resource.percentages.week
      }))
    );
    dataMonth.set(
      result.data.map(resource => ({
        ...resource,
        percentages: resource.percentages.month
      }))
    );

    // Extract unique options for filters
    resourceCodes = [...new Set($dataWeek.map((d) => d.resourceCode))]
      .filter((key) => key !== "");

    processes = [...new Set($dataWeek.map((d) => d.process))]
      .filter((key) => key !== "");

    groups = [...new Set($dataWeek.map((d) => d.group))]
      .filter((key) => key !== "");

    shifts = [...new Set($dataWeek.map((d) => d.shift))]
      .filter((key) => key !== "");

    startRowIndex.set(0);
  }

  // Update the visible data
/*   function updateVisibleData() {
    updateVisibleDays();
    updateVisibleWeeks();
    updateVisibleMonths();
  } */
/* 
  // Update the visible days
  function updateVisibleDays() {
    visibleDays = [];
    const allDays = Object.values(timeGroupsDay).flat();
    for (let i = startDayIndex; i < startDayIndex + daysToShow; i++) {
      if (i < allDays.length) {
        visibleDays.push(allDays[i]);
      }
    }
  }
  // Update the visible weeks
  function updateVisibleWeeks() {
    visibleWeeks = [];
    const allWeeks = Object.values(timeGroupsWeek).flat();
    for (let i = startWeekIndex; i < startWeekIndex + weeksToShow; i++) {
      if (i < allWeeks.length) {
        visibleWeeks.push(allWeeks[i]);
      }
    }
  }
  // Update the visible months
  function updateVisibleMonths() {
    visibleMonths = [];
    const allMonths = Object.values(timeGroupsMonth).flat();
    for (let i = startMonthIndex; i < startMonthIndex + monthsToShow; i++) {
      if (i < allMonths.length) {
        visibleMonths.push(allMonths[i]);
      }
    }
  } */



  // Update the visible rows
/*   function updateVisibleRows() {
    console.log("updateVisibleRows");
    visibleRows = [];
    if (selectedUnitPeriod === "day") {
      console.log("check 1");
      if (tmpMaxRow >= 0) {
        for (let i = startRowIndex; i < startRowIndex + rowsToShow; i++) {
          if (i < $filteredData.length) {
            visibleRows.push($filteredData[i]);
          }
        }
      } else {
        for (let i = startRowIndex; i < startRowIndex + rowsToShow; i++) {
          if (i < dataDay.length) {
            visibleRows.push(dataDay[i]);
          }
        }

      }
    } else if (selectedUnitPeriod === "week") {
      console.log("check 2");
      if (tmpMaxRow >= 0) {
        console.log("1");
        console.log(tmpMaxRow);
        for (let i = startRowIndex; i < startRowIndex + rowsToShow; i++) {
          if (i < $filteredData.length) {
            visibleRows.push($filteredData[i]);
            
          }
        }
      } else {
        console.log("2");
        console.log(tmpMaxRow);
        for (let i = startRowIndex; i < startRowIndex + rowsToShow; i++) {
          if (i < dataWeek.length) {
            visibleRows.push(dataWeek[i]);
          }
        }

      }
    } else if (selectedUnitPeriod === "month") {
      console.log("check 3");
      if (tmpMaxRow >= 0) {
        for (let i = startRowIndex; i < startRowIndex + rowsToShow; i++) {
          if (i < $filteredData.length) {
            visibleRows.push($filteredData[i]);
          }
        }
      } else {
        for (let i = startRowIndex; i < startRowIndex + rowsToShow; i++) {
          if (i < dataMonth.length) {
            visibleRows.push(dataMonth[i]);
          }
        }
      }
    }
    console.log("visibleRows", visibleRows);    
  } */

  // Navigate to the next week
  function nextWeek(flag) {
    // flag is used to determine if the user clicked the double arrow button
    // 0 for single arrow, 1 for double arrow
    // separate the calculation for day, week and month
    if (selectedUnitPeriod === "day") {
      if (flag === 0) {
        startDayIndex.set(Math.min(
          $startDayIndex + 1,
          Math.max(Object.values($timeGroupsDay).flat().length - $daysToShow, 0)
        ));
      } else {
        startDayIndex.set(Math.min(
          $startDayIndex + $daysToShow,
          Math.max(Object.values($timeGroupsDay).flat().length - $daysToShow, 0)
        ));
      }
      startWeekIndex.set(Math.floor($startDayIndex / 7));
      startMonthIndex.set(Math.floor($startDayIndex / 30));

    } else if (selectedUnitPeriod === "week") {
      if (flag === 0) {
        startWeekIndex.set(Math.min(
          $startWeekIndex + 1,
          Math.max(Object.values($timeGroupsWeek).flat().length - $weeksToShow, 0)
        ));
      } else {
        startWeekIndex.set(Math.min(
          $startWeekIndex + $weeksToShow,
          Math.max(Object.values($timeGroupsWeek).flat().length - $weeksToShow, 0)
        ));
      }
      startDayIndex.set($startWeekIndex * 7);
      startMonthIndex.set(Math.floor($startDayIndex / 30));

    } else if (selectedUnitPeriod === "month") {
      if (flag === 0) {
        startMonthIndex.set(Math.min(
          $startMonthIndex + 1,
          Math.max(Object.values($timeGroupsMonth).flat().length - $monthsToShow, 0)
        ));
      } else {
        startMonthIndex.set(Math.min(
          $startMonthIndex + $monthsToShow,
          Math.max(Object.values($timeGroupsMonth).flat().length - $monthsToShow, 0)
        ));
      }
      startDayIndex.set($startMonthIndex * 30);
      startWeekIndex.set(Math.floor($startDayIndex / 7));
    }
  }

  // Navigate to the previous week
  function previousWeek(flag) {
    // flag is used to determine if the user clicked the double arrow button
    // 0 for single arrow, 1 for double arrow
    // separate the calculation for day, week and month
    if (selectedUnitPeriod === "day") {
      if (flag === 0) {
        startDayIndex.set(Math.max($startDayIndex - 1, 0));
      } else {
        startDayIndex.set(Math.max($startDayIndex - $daysToShow, 0));
      }
      startWeekIndex.set(Math.floor($startDayIndex / 7));
      startMonthIndex.set(Math.floor($startDayIndex / 30));

    } else if (selectedUnitPeriod === "week") {
      if (flag === 0) {
        startWeekIndex.set(Math.max($startWeekIndex - 1, 0));
      } else {
        startWeekIndex.set(Math.max($startWeekIndex - $weeksToShow, 0));
      }
      startDayIndex.set($startWeekIndex * 7);
      startMonthIndex.set(Math.floor($startDayIndex / 30));
    } else if (selectedUnitPeriod === "month") {
      if (flag === 0) {
        startMonthIndex.set(Math.max($startMonthIndex - 1, 0));
      } else {
        startMonthIndex.set(Math.max($startMonthIndex - $monthsToShow, 0));
      }
      startDayIndex.set($startMonthIndex * 30);
      startWeekIndex.set(Math.floor($startDayIndex / 7));
    }
  }

  // Navigate to the next row
  function nextRow(flag) {
    // flag is used to determine if the user clicked the double arrow button
    // 0 for single arrow, 1 for double arrow
    if (flag === 0) {
      // max function is used to prevent the startRowIndex from being negative
      if ($tmpMaxRow >= 0) {
        startRowIndex.set(Math.min(
          $startRowIndex + 1,
          Math.max(Math.min($tmpMaxRow, $dataWeek.length) - $rowsToShow, 0)
        ));
      } else {
        startRowIndex.set(Math.min(
          $startRowIndex + 1,
          Math.max($dataWeek.length - $rowsToShow, 0)
        ));
      }
    } else {
      // max function is used to prevent the startRowIndex from being negative
      if ($tmpMaxRow >= 0) {
        startRowIndex.set(Math.max(
          Math.min($tmpMaxRow, $dataWeek.length) - $rowsToShow,
          0
        ));
      } else {
        startRowIndex.set(Math.min(
          $startRowIndex + $rowsToShow,
          Math.max($dataWeek.length - $rowsToShow, 0)
        ));
      }
    }
    /* updateVisibleRows(); */
  }

  // Navigate to the previous row
  function previousRow(flag) {
    // flag is used to determine if the user clicked the double arrow button
    // 0 for single arrow, 1 for double arrow
    if (flag === 0) {
      startRowIndex.set(Math.max($startRowIndex - 1, 0));
    } else {
      startRowIndex.set(Math.max($startRowIndex - $rowsToShow, 0));
    }

    /* updateVisibleRows(); */
  }

  // Function for the button "開始" to move the data to start point
  function MoveButton_ToStart() {
    startDayIndex.set(0);
    startWeekIndex.set(0);
    startMonthIndex.set(0);
  }

  // Function for the button "今月" to move the data to the current month
  function MoveButton_Today() {
    // Get the current date
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    // Format the date as "YYYY年M月"
    const formattedDate = `${year}年${month}月`;

    /* console.log(formattedDate); */
    const months = Object.keys($timeGroupsWeek);
    /* console.log(months); */

    // Find the index of the current month in the timeGroups object
    let weekIndex = 0;
    let dayIndex = 0;
    let monthIndex = 0;
    for (let i = 0; i < months.length; i++) {
      if (months[i] === formattedDate) {
        startWeekIndex.set(weekIndex);
        startDayIndex.set(dayIndex);
        startMonthIndex.set(monthIndex);
        break;
      }
      weekIndex += $timeGroupsWeek[months[i]].length;
      dayIndex += $timeGroupsDay[months[i]].length;
      monthIndex += 1;
    }
    // maybe add some pseudo dates without data
    daysToShow.set(Math.min($daysToShow, Object.values($timeGroupsWeek).flat().length - $startDayIndex));
    weeksToShow.set(Math.min($weeksToShow, Object.values($timeGroupsWeek).flat().length - $startWeekIndex));
    monthsToShow.set(Math.min($monthsToShow, Object.values($timeGroupsMonth).flat().length - $startMonthIndex));
  }

  async function onClickOpenFilter() {
    openFilter = true;
    await tick();
    handleSelect(document.getElementById("displayPeriodSelect"));
    if (selectedGroup) {
      groupFilterInput.setQuery(selectedGroup);
    }
    if (selectedResourceCode) {
      resFilterInput.setQuery(selectedResourceCode);
    }
    if (selectedProcess) {
      procFilterInput.setQuery(selectedProcess);
    }
    if (selectedShift) {
      shiftFilterInput.setQuery(selectedShift);
    }
  }

  function onClickCloseFilter() {
    openFilter = false;
  }

  function handleInputFocus(event) {
    console.log(event.target.value);
    console.log(event.target.select());
    console.log("check");
  }

  function reset() {
    if (selectedGroup) {
      groupFilterInput.setQuery("");
    }
    if (selectedResourceCode) {
      resFilterInput.setQuery("");
    }
    if (selectedProcess) {
      procFilterInput.setQuery("");
    }
    if (selectedShift) {
      shiftFilterInput.setQuery("");
    }
    selectedResourceCodeS.set(null);
    selectedProcessS.set(null);
    selectedGroupS.set(null);
    selectedShiftS.set(null);


    startWeekIndex.set(0);
    tmpMaxRow.set(-1);
  }


  async function handleSelect(target) {
    if (!target) {
        return; // Exit the function early if selectElement is null
    }

    await tick();

    if (target.options.length != 8) {
        return;
    }
    const selectedOption = target.options[target.options.length - 1];

    // Check if the selected option is the custom one (with isCustom = true)
    target.style.color = 'grey';
    const options = target.options;
    for (let i = 0; i < options.length; i++) {
        if (i === options.length - 1) {
            break;
        }
        const option = options[i];
        option.style.color = 'black';
    }
  }
  
  let customOption = null;

  function handleScrollPeriod(event) {
    event.preventDefault();
    if (event.ctrlKey) {
      let newPeriodText, newPeriodValue;
      if (selectedUnitPeriod === "week") {
        if (event.deltaY < 0) {
          weeksToShow.set(Math.max($weeksToShow - 1, 1));
        } else {
          weeksToShow.set(Math.min($weeksToShow + 1, Object.values($timeGroupsWeek).flat().length - $startWeekIndex));
          
        }
        daysToShow.set($weeksToShow * 7);
        monthsToShow.set(Math.ceil($daysToShow / 30));
        newPeriodText = `${$weeksToShow}週間`;
        newPeriodValue = `${$weeksToShow}weeks`;
      } else if (selectedUnitPeriod === "day") {
        if (event.deltaY < 0) {
          daysToShow.set(Math.max($daysToShow - 1, 1));
        } else {
          
          daysToShow.set(Math.min($daysToShow + 1, Object.values($timeGroupsDay).flat().length - $startDayIndex));
        }
        weeksToShow.set(Math.ceil($daysToShow / 7));
        monthsToShow.set(Math.ceil($daysToShow / 30));
        newPeriodText = `${$daysToShow}日`;
        newPeriodValue = `${$daysToShow}days`;
      } else if (selectedUnitPeriod === "month") {
        if (event.deltaY < 0) {
          monthsToShow.set(Math.max($monthsToShow - 1, 1));
        } else {
          monthsToShow.set(Math.min($monthsToShow + 1, Object.values($timeGroupsMonth).flat().length- $startMonthIndex));
        }
        daysToShow.set($monthsToShow * 30);
        weeksToShow.set(Math.ceil($daysToShow / 7));
        newPeriodText = `${$monthsToShow}ヶ月`;
        newPeriodValue = `${$monthsToShow}months`;
      }
      
      customOption = { text: newPeriodText, value: newPeriodValue, isCustom: true };
      displayPeriod = [...displayPeriod.filter(option => !option.isCustom), customOption];

      selectedDisplayPeriodS.set(newPeriodValue);

      /* handleOption(document.getElementById("displayPeriodSelect")); */
      handleSelect(document.getElementById("displayPeriodSelect"));
    } else {
      if (event.deltaY < 0) {
          previousWeek(0);
      } else {
          nextWeek(0);
      }
    }
  }

  function handleScrollRowNum(event) {
    event.preventDefault();
    if (event.ctrlKey) {
      if (event.deltaY < 0) {
        rowsToShow.set(Math.max($rowsToShow - 1, 1));
      } else {
        // add "+" to convert string to number
        rowsToShow.set(Math.min(+$rowsToShow + 1, $rowNumMaxValue));
        
      }
      selectedDisplayRowNumS.set($rowsToShow);
      /* updateVisibleRows(); */
    } else {
      if (event.deltaY < 0) {
          previousRow(0);
      } else {
          nextRow(0);
      }
    }
  }

/*   const updateRowWidth = () => {
    const containerWidth = viewportWidth;
    let remainingWidth = containerWidth - 180*2 - 150 - 190;

    if (selectedUnitPeriod === "day") {
      remainingWidth -= border*(4+ ($daysToShow+1));
      cellWidth = remainingWidth / $daysToShow;
    } else if (selectedUnitPeriod === "week") {
      remainingWidth -= border*(4+ ($weeksToShow+1));
      cellWidth = remainingWidth / $weeksToShow;
    } else if (selectedUnitPeriod === "month") {
      remainingWidth -= border*(4+ ($monthsToShow+1));
      cellWidth = remainingWidth / $monthsToShow;
    }
  }; */

/*   const updateRowHeight = () => {
    const containerHeight = viewportHeight;
    const titleHeight = 51;
    const toolbarHeight = 50;
    const tableHeaderHeight = 80;
    const footerHeight = 30;
    const remainingHeight = containerHeight - titleHeight - toolbarHeight - tableHeaderHeight - footerHeight - border*(2+$rowsToShow);
    rowHeight = remainingHeight / $rowsToShow;

  }; */

  const updateDimension = () => {
    viewportHeight.set(window.innerHeight);
    viewportWidth.set(window.innerWidth);
    border.set(parseFloat(getComputedStyle(document.querySelector('th')).borderWidth) || 0);
  };

  $: if (!isLoading) {
    afterUpdate(updateDimension); // Ensure updateRowHeight runs after DOM updates
  }

/*   $: {
    console.log("isLoading changed:", isLoading);
  } */

/*   $: {
    console.log("startRowIndex changed:", $startRowIndex);
  } */

  const handleResize = () => {
    updateDimension();
  };

/*   function handleOverflow() {
    if (rowsToShow > 12) {
      console.log("overflow");
      return "normal-data-over";
    } else {
      console.log("not overflow");
      return "normal-data";
    }
  }; */

  onMount(() => {
    console.log("rendered");
    loadData(projectId).then(() => {
      // Simulate a loading state with a timeout
      isLoading = false;
      window.addEventListener('resize', handleResize);
    });
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

</script>

{#if !isLoading}
  <div class="table-body">
    {#if openFilter}
      <div
        class="settings-menu-wrapper"
        in:fly={{ x: -300, duration: 300 }}
        out:fly={{ x: -300, duration: 300 }}
      >
        <LoadGraphFilter
          bind:groupFilterInput={groupFilterInput}
          bind:resFilterInput={resFilterInput}
          bind:procFilterInput={procFilterInput}
          bind:shiftFilterInput={shiftFilterInput}
          {reset}
          onClose={onClickCloseFilter}
          handleFocus={handleInputFocus}
          {resourceCodes}
          {processes}
          {groups}
          {shifts}
          {unitPeriod}
          {displayPeriod}
          maxValue = {$rowNumMaxValue}
        />
      </div>
    {/if}
    <div class="tool-bar" bind:this={toolbar}>
      <div class="tool-bar-left">
        <span class="hamburger" on:click={onClickOpenFilter}>☰</span>
        <div class="select-bar">
          <GeneralSelect options={displayOptions} bind:value={viewMode} />
        </div>
      </div>
      <div class="tool-bar-right">
        <GeneralPanelButton
          items={[
            {
              buttonText: "開始",
              buttonFunction: MoveButton_ToStart,
            },
            {
              buttonImage: "/assets/Pager_FL.svg",
              buttonFunction: () => previousWeek(1),
            },
            {
              buttonImage: "/assets/Pager_L.svg",
              buttonFunction: () => previousWeek(0),
            },
            {
              buttonText: "今月",
              buttonFunction: MoveButton_Today,
            },
            {
              buttonImage: "/assets/Pager_R.svg",
              buttonFunction: () => nextWeek(0),
            },
            {
              buttonImage: "/assets/Pager_FR.svg",
              buttonFunction: () => nextWeek(1),
            },
          ]}
        />
      </div>
    </div>

    <div class="table-container">
      <LoadTableArea 
        header="グループ"
        rowHeight={$rowHeight}
        bind:normalHeader={tableHeader}
        visibleRows={$visibleRows}
        previousRow={previousRow}
        handleScrollRowNum={handleScrollRowNum}
        border={border}
      />
      <LoadExtraTableArea 
        header="資源コード"
        rowHeight={$rowHeight}
        visibleRows={$visibleRows}
        handleScrollRowNum={handleScrollRowNum}
        border={border}
      />
      <LoadExtraTableArea 
        header="工程"
        rowHeight={$rowHeight}
        visibleRows={$visibleRows}
        handleScrollRowNum={handleScrollRowNum}
        border={$border}
      />
      <LoadExtraTableArea 
        header="勤務シフト"
        rowHeight={$rowHeight}
        visibleRows={$visibleRows}
        handleScrollRowNum={handleScrollRowNum}
        border={$border}
      />
      <LoadDataArea 
        rowHeight={$rowHeight}
        cellWidth={$cellWidth}
        selectedUnitPeriod={selectedUnitPeriod}
        timeGroupsDay={$timeGroupsDay}
        timeGroupsWeek={$timeGroupsWeek}
        timeGroupsMonth={$timeGroupsMonth}
        visibleDays={$visibleDays}
        visibleWeeks={$visibleWeeks}
        visibleMonths={$visibleMonths}
        visibleRows={$visibleRows}
        viewMode={viewMode}
        handleScrollPeriod={handleScrollPeriod}
      />

    </div>
    {#if $visibleRows.length == 0}
      <div class="empty-container">
        <p class="empty-statement">該当する{emptyText}は存在しませんでした</p>
      </div>
    {/if}
    <div class="bottom-buttons" bind:this={botButtons} style="margin-left: {border*2}px;">
        
      <div class="bottom-button3" on:click={() => nextRow(0)}>
        <!-- on:click={() => nextRow(0)} -->
        <img src="/assets/rss_down.svg" alt="rss_down" />
      </div>
      <div class="bottom-button4" on:click={() => nextRow(1)}>
        <img src="/assets/rss_pagedown.svg" alt="rss_pagedown" />
      </div>
    </div> 
  </div>
{:else}

  <Loading />
{/if}

<style>
  .table-body{
    height: 100%;
    width: 100%;
    overflow: hidden; 
  }

  .table-container {
    display: flex;
    flex-direction: row;
  }

  .tool-bar {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 70;
  }

  .tool-bar-left,
  .tool-bar-right {
    display: flex;
    align-items: center;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .tool-bar-left {
    margin-left: 2vw;
    gap: 16px;
  }

  .tool-bar-right {
    margin-left: auto;
    align-self: flex-end;
    flex-shrink: 1;
    user-select: none;
  }

  .hamburger {
    font-size: 32px;
    cursor: pointer;
    color: black;
    user-select: none;
  }

  .select-bar {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  .bottom-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border: none;
    background-color: #f5f5f5;
    height: 30px;
    user-select: none;
    width: 180px;
  }

  .bottom-button3,
  .bottom-button4 {
    width: 88px;
    height: 26px;
    border-radius: 0 0 7px 7px;
    background-color: #d2d0d2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .bottom-button3 img,
  .bottom-button4 img {
    max-width: 100%;
    max-height: 100%;
  }

  .settings-menu-wrapper {
    position: fixed;
    top: 51px;
    left: 0;
    height: calc(100vh - 51px);
    width: 300px;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    overflow-y: auto;

  }

  .empty-container {
    border-bottom: 2px solid #888888; /* Border on the right */
    border-right: 2px solid #888888;  /* Border on the left */
    border-left: 2px solid #888888;
  }
  .empty-statement {
    font-size: 16px;
    color: #888888;

    padding: 16px;
    margin: 0;
  }
</style>
