<script>
  import { onMount, afterUpdate } from "svelte";
  import TimelineCard from './TimelineCard.svelte';
  import TimelineSplit from './TimelineSplit.svelte';
  import { translate } from "../../i18n/i18next.js";
  import { SelectRectangle } from "../../utils/selectRectangle";

  export let GanttStore

  const {
    RowsAllFiltered,
    PreventMistakes

  } = GanttStore;

  let displayedEntries = 0;
  let processedTimelineEntries = {};
  let lockedOperations = {}; // If true the operation cannot be started
  let rowToColor = {};
  let highlightedBlock = null;
  let style = "Default";

  // For individual cards
  let cachedRows = {};
  let transactionRegisteringFunc = () => {};

  // For timeline / highlightedbloc synchronisation
  let requestedTimelineHighlightChange = undefined;
  let recenterTimelineTimeoutRequested = true;
  let recenterTimelineTimeout = null;

  // Timeline card callbacks (same callback as in map area)
  const JumpToDateCallback = (data) => {
    GanttStore.CurrentDisplayedStartDate.set(data._productionArr[0]);
    const _index = $RowsAllFiltered.findIndex((el) => el.Row === data._row);
    if (_index >= 0) {
      GanttStore.RowsDisplayedStart.update((_) => _index);
    }
    else {
      console.warn("Filtered row");
      // Display warning notification
    }
    SelectRectangle.ForceSelect([data._objectID], data._row);
  }

  const ProcessPreviousProcessGuards = () => {
    lockedOperations = {};

    // No locked operations if prevent mistakes is off
    if (!$PreventMistakes) return lockedOperations;

    Object.entries(processedTimelineEntries).forEach((_row) => {
      const _res = _row[0];
      const _opeArr = _row[1];

      const _cRow = cachedRows[_res];

      if (_cRow !== undefined) {
        _opeArr.forEach((_ope) => {
          const _opeId = parseInt(_ope);

          const _opeObj = _cRow.Data.find((el) => el._objectID === parseInt(_opeId));
          if (_opeObj !== undefined) {

            const _prevLL = _opeObj._prevTaskLocalLocs;
            const _kasanari = _opeObj._timeConstraintMethod;

            if (_prevLL !== undefined) {
              let _found_unitary_constraints = false;
              let _started_unitary_constraints = false;

              _prevLL.forEach((_loc, idx) => {
                const _prevObj = cachedRows[_loc[0]]?.Data[_loc[1]];
                const _prevTcm = (_kasanari[idx] === undefined) ? "SS" : _kasanari[idx];

                if (_prevObj !== undefined) {
                  // If ES, lock if not finished
                  if (_prevTcm === "ES" && _prevObj._resStatus !== "B")
                    lockedOperations[_opeId] = true;
                  // If SSEE, lock if not started
                  else if  (_prevTcm === "SSEE" && !["B","T"].includes(_prevObj._resStatus))
                    lockedOperations[_opeId] = true;
                  // If ESE, EES, SSEEE, ESSEEE, lock if none started
                  else if (["ESE", "EES", "SSEEE", "ESSEE"].includes(_prevTcm)) {
                    _found_unitary_constraints = true;
                    if (["B","T"].includes(_prevObj._resStatus))
                      _started_unitary_constraints = true;
                  }
                }
              });

              if (_found_unitary_constraints !== _started_unitary_constraints)
                lockedOperations[_opeId] = true;
            }
          }
        });
      }
    });

  }

  $: cachedRows, processedTimelineEntries, $PreventMistakes, ProcessPreviousProcessGuards();

  export let project;

  onMount(async () => {
    GanttStore.TimelineHighlight.subscribe((hb) => {
      highlightedBlock = hb;
    });
    GanttStore.RowsCached.subscribe((rc) => cachedRows = rc);
    GanttStore.CurrentStyle.subscribe((st) => style = st);
    GanttStore.RowsAllOwnedByStyle.subscribe((ra) => {
      rowToColor = ra.reduce((acc, { Row, Color }) => ({ ...acc, [Row]: Color }), {});
    });
    GanttStore.TimelineItems.subscribe((entries) => {
      const countEntries = entries.length;
      // Rubberband selection
      if (countEntries > 1) {
        processedTimelineEntries = entries.reduce((acc, key) => ({ ...acc, [key[1]]: [...(acc[key[1]] || []), key[0]] }), {});
        displayedEntries = countEntries;
        //GanttStore.TimelineHighlight.update((_) => null);
        requestedTimelineHighlightChange = null;
      }
      // Single resource selection
      else if (countEntries === 1) {
        const __res = entries[0][1];
        processedTimelineEntries = {
          [__res]: cachedRows[__res].Data.map((el) => el._objectID)
        };
        //GanttStore.TimelineHighlight.update((_) => entries[0]);
        requestedTimelineHighlightChange = entries[0][0];
        displayedEntries = processedTimelineEntries[__res].length;
      }
      // Empty
      else {
        processedTimelineEntries = {};
        displayedEntries = 0;
        //GanttStore.TimelineHighlight.update((_) => null);
        requestedTimelineHighlightChange = null;
      }
    });
    GanttStore.TransactionRegisteringFunction.subscribe((trf) => transactionRegisteringFunc = trf);
  });

  afterUpdate(async () => {
    if (requestedTimelineHighlightChange !== undefined) {
      GanttStore.TimelineHighlight.update((_) => requestedTimelineHighlightChange);
      if (requestedTimelineHighlightChange !== null) {
        // Cancel the previous recenter request if a new one happens
        if (recenterTimelineTimeout !== null) {
          clearTimeout(recenterTimelineTimeout);
          recenterTimelineTimeout = null;
        }

        // Skip animation wait or not depending on the previous result
        const _refCpy = requestedTimelineHighlightChange;
        if (recenterTimelineTimeoutRequested === true) {
          // Slightly shorter than the CSS animation, to be sure that there is the minimal margin of error while keeping the animation smooth enough
          recenterTimelineTimeout = setTimeout(function(){scrollToTask(_refCpy)}, 300);
        }
        else {
          scrollToTask(_refCpy);
        }

        // Timeline to timeline => no animation timeout
        recenterTimelineTimeoutRequested = false;
      }
      else {
        recenterTimelineTimeoutRequested = true;
      }
      requestedTimelineHighlightChange = undefined;
    }
  });

  function scrollToTask(taskId) {
    const element = document.getElementById(`gantt_timelinearea_timelinecard_${taskId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

</script>

<div
  id="gantt_timelinearea"
  style="width: {displayedEntries === 0 ? 0 : 300}px; margin-left: {displayedEntries === 0 ? 0 : 10}px;"
>
  <div id="gantt_timelinearea_head">
    <span id="gantt_timelinearea_elemcount">{translate("frontend:views.resGantt.timeline.displayedElementsCount", null, {count: displayedEntries})}</span>
  </div>

  <div id="gantt_timelinearea_sub">
  {#each Object.keys(processedTimelineEntries) as resource}
    <TimelineSplit
      Row={(cachedRows[resource] === undefined) ? "(...)" : cachedRows[resource].Data[0]._res}
      Color={rowToColor[(cachedRows[resource] === undefined) ? "#333" : cachedRows[resource].Data[0]._row]}
    />
    {#each processedTimelineEntries[resource] as taskId}
      {#key taskId}
        <TimelineCard
          GanttStore={GanttStore}
          bind:ID={taskId}
          Details={(cachedRows[resource] === undefined) ? null : cachedRows[resource].Data.find((el) => el._objectID === parseInt(taskId))}
          Style={style}
          projectId={project}
          TransactionRegisteringFunction={transactionRegisteringFunc}
          Highlighted={highlightedBlock == taskId}
          JumpToDateCallback={JumpToDateCallback}
          Locked={lockedOperations[taskId] === true}
        />
      {/key}
    {/each}
  {/each}
  </div>
</div>

<style>
  span#gantt_timelinearea_elemcount {
    width: 100%;
    text-align: center;
    display: block;
  }

  div#gantt_timelinearea_head {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #eeeeee;
    border-bottom: solid #cdcdcd;
  }

  div#gantt_timelinearea_sub {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  div#gantt_timelinearea {
    display: flex;
    flex-direction: column;
    border-left: solid #cdcdcd;
    border-bottom: solid #cdcdcd;
    background-color: white;
    transition: width 0.5s ease;
    overflow-y: auto;
  }
</style>
