<script>
  import GeneralSelect from "../select/GeneralSelect.svelte";
  export let value;
  export let choices = [10, 20, 30, 50, 100];
  export let onChange = undefined;
</script>

<article>
  <GeneralSelect
    bind:value
    options={choices.map((choice) => ({
      text: choice,
      value: choice,
    }))}
    onChangeEvent={onChange}
  />
</article>

<style>
</style>
