<script>
  import Home from "../routes/Common/Home.svelte"
  import AuthenticationRequired from "../components/functional/AuthenticationRequired.svelte"
  import PageBase from "../components/pages/PageBase.svelte"
  import {useIsDemoServer} from "../hooks/useIsDemoServer.js";
  import DemoHome from "../components/pages/DemoHome.svelte";
  import { useMe } from "../hooks/useMe.js";

  const {isDemoServer} = useIsDemoServer()
  const me = useMe()
</script>

<AuthenticationRequired>
  <PageBase backTo={null}>
    {#if $isDemoServer && $me && $me.id.startsWith("legacy-Guest")}
      <DemoHome />
    {:else}
      <Home />
    {/if}
  </PageBase>
</AuthenticationRequired>