<script>
  import { getContext, onMount } from "svelte";
  import Loading from "../../components/pages/Loading.svelte";

  import {
    addDateColumn,
    FetchError,
    fetchResource,
    fetchResourceDetailWithStyleIdx,
    fetchStyles,
    getOperationCode,
    getStartDate,
    getStatus,
  } from "../../utils/getData";
  import { applyTransactionsToOperations } from "../../utils/mergeData";
  import { postStartEndData } from "../../utils/putData";
  import { useIsInputAllowed } from "../../hooks/useIsInputAllowed";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import DispatchingViewControl from "./DispatchingViewControl.svelte";
  import {
    getDispatchingViewConfiguration
  } from "../../utils/configurationStorage/views/DispatchingViewConfigurationStorage.js";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import { translate } from "../../i18n/i18next.js";
  import { navigateI18n } from "../../i18n/navigate.js";
  import { useProject } from "../../hooks/useProject.js";
  import { getTimeStringSec } from "../../utils/time.js";
  import { useTimezone } from "../../hooks/useTimezone.js";

  export let projectId
  export let code
  export let user
  export let transactions
  export let reloadTransactions

  const resourceObjectID = code

  const { isInputAllowed } = useIsInputAllowed(projectId);
  const {getOffset} = useTimezone();

  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({ uploadTime: UploadTimeStamp});
  const project = useProject(projectId)

  $: iStyle = 0;
  $: styles = [];

  // if UseInst_StartTime exist, show measure button in the UseInst_StartTime/EndTime column and hide Work_ResultStartTime/EndTime.
  // otherwise, show measure button in the Work_ResultStartTime/EndTime and does not hide them.
  let isUseInstStartEndTimeExisted = true;

  let usingDatePageMode = false;
  let allData; // all operation data including columns which are never displayed
  let title;
  let title_keys = [];
  let UploadTimeStamp = null;
  let DownloadTimeStamp = null;
  let itemPerPage = 10
  let currentPageIndex = 0

  let dateList = [];
  let displayCompletedTasks = false;

  let transactionsAppliedData = []
  $: transactionsAppliedData = applyTransactionsToOperations(allData, transactions)
  $: allDisplayableOperations = transactionsAppliedData?.filter(operation => displayCompletedTasks || !isOperationCompleted(operation)) ?? []
  $: totalPageNumber = usingDatePageMode ? dateList.length : Math.floor(allDisplayableOperations.length / itemPerPage) + 1
  $: displayedOperations = filterOperationsToBeDisplayed(allDisplayableOperations, usingDatePageMode, itemPerPage, dateList, currentPageIndex)
  function filterOperationsToBeDisplayed(allOperations, usingDatePageMode, itemPerPage, dateList, currentPageIndex) {
    return allOperations.filter((operation, index) => {
      if (usingDatePageMode) return getStartDate(operation) === dateList[currentPageIndex]
      return currentPageIndex * itemPerPage <= index && index <= (currentPageIndex + 1) * itemPerPage - 1
    })
  }
  $: displayedHeaderKeys = title_keys.filter(key => key !== "MyScheReservedFixedProperty" && !(isUseInstStartEndTimeExisted && (key === "Work_ResultStartTime" || key === "Work_ResultEndTime")))

  function styleSelectOnChange() {
    refresh();
  }

  async function pageModeOnChange() {
    currentPageIndex = 0
    await refresh();
    console.log(getDispatchingViewConfiguration(user.id, projectId))
  }

  const { handleFetchErrors, handleNonBlockingFetchErrors } = getContext("fetchErrorHandler");

  async function refresh() {
    try {
      styles = await fetchStyles(projectId);

      let tmp_operations;
      [
        tmp_operations,
        allData,
        title,
        title_keys,
        UploadTimeStamp,
        DownloadTimeStamp,
      ] = await fetchResourceDetailWithStyleIdx(projectId, resourceObjectID, iStyle);

      // add Date column
      [tmp_operations, title, title_keys] = addDateColumn(
        tmp_operations,
        title,
        title_keys
      );

      isUseInstStartEndTimeExisted = title_keys.includes("UseInst_StartTime") && title_keys.includes("UseInst_EndTime");

      dateList = [];
      for (const allDataRow of allData) {
        const formattedDate = getStartDate(allDataRow);
        if (
          !dateList.length ||
          dateList[dateList.length - 1] !== formattedDate
        ) {
          dateList.push(formattedDate);
        }
      }
    } catch (e) {
      console.error(e);
      if (e instanceof FetchError) {
        handleFetchErrors(e.error);
      }
    }
  }

  onMount(async () => {
    const resources = await fetchResource(projectId);
    if (resources.isCustomized) {
      navigateI18n(`/projects/${projectId}/DispatchingView_Profile1/${resourceObjectID}`);
    }
    currentPageIndex = 0;
    const resource = resources.Rows.find((r) => (r[r.length - 1].toString() === resourceObjectID))
    const resCode = resource[0]
    setHeaderProps({ title: resCode });
    await loadSavedSettings(user.id, projectId)
    await refresh();
  });

  async function loadSavedSettings(userId, projectId) {
    // load settings from localStorage and apply them
    if (userId && projectId) {
      const savedSettings = getDispatchingViewConfiguration(userId, projectId);
      // apply style settings
      //   before applying it, check if the saved settings value is valid
      if (savedSettings.styleName) {
        const styles = await fetchStyles(projectId);
        const index = styles.findIndex((s) => s === savedSettings.styleName);
        if (index !== -1) {
          iStyle = index;
        }
      }
      // apply itemsPerPage
      if (savedSettings.itemPerPage) {
        itemPerPage = savedSettings.itemPerPage;
      }
      if (savedSettings.displayCompletedTasks) {
        displayCompletedTasks = savedSettings.displayCompletedTasks;
      }
      // apply usingDatePageMode
      if (savedSettings.usingDatePageMode) {
        usingDatePageMode = savedSettings.usingDatePageMode;
        return pageModeOnChange();
      }
    }
  }

  // check whether that operation has started
  function isOperationStarted(operation) {
    return operation["Work_ResultStartTime"] !== ""
  }
  // check whether that operation has ended
  function isOperationCompleted(operation) {
    return operation["Work_ResultEndTime"] !== ""
  }

  // transactions: start button click
  async function onStartEndButtonClick(event, operation, transactionType) {
    // if project data is not ready yet, reject submission
    if (!$project?.timezone) return
    const currentDateTimeString = getTimeStringSec(new Date(), $project.timezone, $getOffset)

    // disable buttons while the update is in progress
    // if the update is to end an operation and the view is set not to display completed operations, disable all the buttons
    //   this is to avoid update mistakes (a feature requested by a PoC user)
    // otherwise disable only the pressed button
    const disabledButtons = [];
    if (transactionType === "end" && !displayCompletedTasks) {
      // 全てのボタンを押せなくする
      const startEndButtons = document.getElementsByClassName("button-start-end");
      for (const button of startEndButtons) {
        button.disabled = "disabled";
        disabledButtons.push(button);
      }
    } else {
      event.target.disabled = true;
      disabledButtons.push(event.target);
    }

    // prepare the payload
    const operationCode = getOperationCode(operation);
    const payload = JSON.stringify({
      [operationCode]: {
        Work_Status: transactionType === "start" ? "T" : "B",
        Work_ResultStartTime:
          transactionType === "start" ? currentDateTimeString : undefined,
        Work_ResultEndTime:
          transactionType === "end" ? currentDateTimeString : undefined,
        Work_ResultMainRes: "",
      },
    });

    try {
      await postStartEndData(projectId, payload);

      // To avoid duplicate time texts, one of them will be hidden automatically by the css.
      // Look for ":global(div.result-time-text + div.result-time-text)" in the style tag for the actual css.
      const divElement = document.createElement("div");
      divElement.textContent = currentDateTimeString;
      divElement.style.color = "#249E8E";
      divElement.className = "result-time-text"
      event.target.replaceWith(divElement);

      setTimeout(() => {
        disabledButtons.forEach((button) => (button.disabled = false));
        reloadTransactions()
      }, 1000);
    } catch (e) {
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error);
      }
      disabledButtons.forEach((button) => (button.disabled = false));
    }
  }
</script>

<div class="content-root">
  <DispatchingViewControl
    bind:iStyle
    {styles}
    {styleSelectOnChange}
    bind:displayCompletedTasks
    bind:itemPerPage={itemPerPage}
    {pageModeOnChange}
    bind:usingDatePageMode
    {totalPageNumber}
    {dateList}
    projectId={projectId}
    userId={user.id}
    bind:currentIndex={currentPageIndex}
  />

  <!-- Main Table -->
  {#if allData}
    {#if allData.length > 0}
      <div class="tables-container">
        <div class="headers-table-container">
          <table>
            <tr id="head">
              {#each displayedHeaderKeys as _, keyIndex}
                <th>{title[keyIndex]}</th>
              {/each}
            </tr>
          </table>
        </div>
        <div class="operations-table-container">
          <table>
            {#each displayedOperations as operation, i (getOperationCode(operation))}
              <tr id={`row-${i}`}>
                {#each displayedHeaderKeys as key, j}
                  {#if (isUseInstStartEndTimeExisted && key === "UseInst_StartTime") || (!isUseInstStartEndTimeExisted && key === "Work_ResultStartTime")}
                    <!--if useinststart/endtime exist, show button in that column, or in the resultstart/endtime columns-->
                    <td>
                      <div style="padding: 6px;">
                        {operation[key]}
                        <div
                          style="height: 1px; background-color: #B5BDC3; width: 90%; margin: 0 auto;"
                        />
                        {#if isOperationStarted(operation)}
                          <div style="color:#249E8E;" class="result-time-text">
                            {operation["Work_ResultStartTime"]}
                          </div>
                        {:else if operation[key] === "" && getStatus(operation) === "B"}
                          <div style="color:#249E8E;">
                            {translate("asprova.terms.operationStatuses.completed")}
                          </div>
                        {:else}
                          <GeneralButton
                            buttonDisabled={!$isInputAllowed}
                            buttonFunction={(e) => onStartEndButtonClick(e, operation, "start")}
                            buttonValue={`startEndButton$${i}`}
                            buttonText={translate("generic.startShort")}
                            buttonExtraStyle="width: 96%"
                          />
                        {/if}
                      </div>
                    </td>
                  {:else if (isUseInstStartEndTimeExisted && key === "UseInst_EndTime") || (!isUseInstStartEndTimeExisted && key === "Work_ResultEndTime")}
                    <td>
                      {#key operation[key]}
                        <div style="padding: 6px;">
                          {operation[key]}
                          <div
                            style="height: 1px; background-color: #B5BDC3; width: 90%; margin: 0 auto;"
                          />
                          {#if isOperationCompleted(operation)}
                            <div style="color:#249E8E;" class="result-time-text">
                              {operation["Work_ResultEndTime"]}
                            </div>
                          {:else if operation[key] === "" && getStatus(operation) === "B"}
                            <div style="color:#249E8E;">
                              {translate("asprova.terms.operationStatuses.completed")}
                            </div>
                          {:else}
                            <GeneralButton
                              buttonDisabled={!$isInputAllowed || operation["Work_ResultStartTime"] === ""}
                              buttonFunction={(e) => onStartEndButtonClick(e, operation, "end")}
                              buttonValue={`startEndButton$${i}`}
                              buttonText={translate("generic.finishShort")}
                              buttonExtraStyle="width: 96%"
                            />
                          {/if}
                        </div>
                      {/key}
                    </td>
                  {:else}
                    <td>{operation[key]}</td>
                  {/if}
                {/each}
              </tr>
            {/each}
          </table>
        </div>
      </div>
    {:else}
      <Loading message={translate("frontend:views.dispatchingView.noOperation")}/>
    {/if}
  {:else}
    <Loading/>
  {/if}
</div>

<style>
  .content-root {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .tables-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  table tr#head {
    background-color: #bfc6cc;
    color: black;
  }
  table {
    width: 100%;
    text-align: center;
    border: 1px solid #ebebeb;
    border-collapse: collapse;
    table-layout: fixed;
  }
  td {
    border: 1px solid #d3d3d3;
    border-collapse: collapse;
    padding-left: 5px;
    line-height: 30px;
  }
  .headers-table-container {
    flex: 0;
  }
  .operations-table-container {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
  }
  /**
    This is to hide one of the duplicate elements caused by manually replacing button with a div with a text.
    See onStartEndButtonClick().
  */
  :global(div.result-time-text + div.result-time-text) {
    display: none;
  }
</style>
