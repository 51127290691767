<script>
  import { useParams } from "svelte-navigator"
  import { getContext, onMount } from "svelte"
  import { FetchError, fetchStyleOperationTable } from "../../utils/getData"
  import GeneralButton from "../../components/button/GeneralButton.svelte"
  import { reset } from "../../utils/putData"
  import { usePageContext } from "../../components/pages/pageContext.js"
  import Loading from "../../components/pages/Loading.svelte";
  import { translate } from "../../i18n/i18next.js";
  import { navigateI18n } from "../../i18n/navigate.js";

  const params = useParams();
  const projectId = $params.project;
  const {setHeaderProps} = usePageContext()
  setHeaderProps({title: translate("mySchedule.views.operationTable.name")})

  let styleOperationTable;

  let resourceBox;

  const {handleFetchErrors, handleNonBlockingFetchErrors} = getContext("fetchErrorHandler")
  onMount(async () => {
    try {
      styleOperationTable = await fetchStyleOperationTable(projectId);
    } catch (e) {
      if (e instanceof FetchError) {
        handleFetchErrors(e.error)
      }
    }

    console.log(styleOperationTable);
  });

  async function onReset() {
    try {
      alert(translate("frontend:views.operationTable.resetAllTransactionsConfirmation"));
      await reset(projectId);
    } catch (e) {
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error)
      }
    }
  }

</script>

{#if styleOperationTable}
  <div class="container">
    <GeneralButton
      buttonText={translate("frontend:views.operationTable.resetAllTransactions")}
      buttonFunction={onReset}
      width="100"
      height="30"
      buttonType="negative"
    />
  </div>
  {#if styleOperationTable.length > 0}
    <div bind:this={resourceBox} style="margin-top: 25px;">
      {#each styleOperationTable as col, _}
        <GeneralButton
          buttonText={col[0]}
          buttonFunction={() =>
            navigateI18n(
              `/projects/${projectId}/OperationTable/${col[1]}`
            )}
          buttonValue={col[0]}
          width="150"
          height="50"
          buttonType="secondary"
        />
      {/each}
    </div>
  {:else}
    <p>{translate("frontend:views.operationTable.noStyleError")}</p>
  {/if}
{:else}
  <Loading/>
{/if}

<style>
  .container {
    display: flex;
    align-items: center;
    height: 50px;
  }
</style>
