<script>
  import { FetchError, fetchIntegratedMasterData, fetchIntegratedMasterTransactionData, } from "../../utils/getData.js";
  import { getContext, onMount } from "svelte";
  import { writable } from "svelte/store";
  import { getTimeString } from "../../utils/time";
  import { registerIntegratedMasterTransaction } from "../../utils/putData.js";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import GuestIntegratedMasterControls from "./IntegratedMasterControls.svelte";
  import IntegratedMasterMeasure from "./IntegratedMasterMeasure.svelte";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import GeneralInput from "../../components/input/GeneralInput.svelte";
  import Loading from "../../components/pages/Loading.svelte";
  import { translate } from "../../i18n/i18next.js";
  import {
    getIntegratedMasterConfiguration
  } from "../../utils/configurationStorage/views/IntegratedMasterConfigurationStorage.js";
  import { useMe } from "../../hooks/useMe.js";
  import { useProject } from "../../hooks/useProject.js";
  import { useTimezone } from "../../hooks/useTimezone.js";

  export let projectId

  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({ title: translate("mySchedule.views.integratedMaster.name") });
  $: setHeaderProps({ uploadTime: fullJson?.UploadTimeStamp});

  const me = useMe()
  const project = useProject(projectId)
  const {getOffset} = useTimezone()

  // IntegratedMaster view internal states
  let totalPageNumber = 0;
  let totalLength;
  let currentPageIndex = 0

  $: {
    totalLength = recalcTotalLength();
    totalPageNumber = calcTotalPageNumber();
  }

  const itemPerPage = writable(10);

  let itemPerPageValue;
  itemPerPage.subscribe((val) => {
    itemPerPageValue = val;
    totalPageNumber = calcTotalPageNumber();
  });

  function recalcTotalLength() {
    if (!fullJson) {
      return 0;
    } else {
      return fullJson.Data[iStyle].Rows.length;
    }
  }

  function calcTotalPageNumber() {
    return Math.floor((totalLength - 1) / itemPerPageValue + 1);
  }

  $: searchMode = false;
  function isItemDisplayed(idx, currentPageIndex, searchWords, rowData) {
    if (searchWords.length > 0) return searchWords.some((word) => rowData.includes(word));
    const firstItem = (currentPageIndex) * itemPerPageValue;
    const endItem = firstItem + itemPerPageValue - 1;
    return firstItem <= idx && idx <= endItem;
  }

  let iStyle = 0;
  let fullJson = null;
  let newCaps = [];

  let lastDownloadTimeStamp;

  let measureIndex = -1;

  let idxMap;

  // Properties to display in timer window

  $: setDisplayConfig = () => {
    let i = 0;

    for (const style of fullJson.Styles) {
      const option = document.createElement("option");
      option.text = style;
      option.value = i;
      i++;
    }
    idxMap = Array.from({ length: fullJson.Styles.length }, () => new Map());

    for (let is = 0; is < fullJson.Styles.length; is++) {
      i = 0;
      for (const [col, _] of Object.entries(
        fullJson.Data[is].DisplayedStrings
      )) {
        idxMap[is].set(col, i);
        i++;
      }
    }
  };

  function onCloseMeasurement(newVal = null) {
    // newCaps[measureIndex] = conclusion + unit_asprova;
    if (newVal) newCaps[measureIndex] = newVal;
    measureIndex = -1;
  }

  async function applyUpdates() {
    const updates = await fetchIntegratedMasterTransactionData(
      projectId,
      lastDownloadTimeStamp
    );
    for (let is = 0; is < fullJson.Styles.length; is++) {
      fullJson.Data[is].Rows.forEach((row, i) => {
        let id = fullJson.Data[is].FixedRows[i][6];
        if (updates[id]) {
          fullJson.Data[is].Rows[i][
            idxMap[is].get("UseBomInstructionUser_Task02CapacityExpr_Old")
          ] = updates[id].UseBomInstructionUser_Task02CapacityExpr_Old;
          fullJson.Data[is].Rows[i][
            idxMap[is].get(
              "UseBomInstructionUser_Task02CapacityExpr_UpdatedTime"
            )
          ] = updates[id].UseBomInstructionUser_Task02CapacityExpr_UpdatedTime;
          fullJson.Data[is].Rows[i][idxMap[is].get("EM_Task2")] =
            updates[id].UseBomInstructionUser_Task02CapacityExpr_Curr;
          console.log(
            is,
            i,
            fullJson.Data[is].Rows[i][idxMap[is].get("EM_Task2")]
          );
        }
      });
    }
  }

  const { handleFetchErrors, handleNonBlockingFetchErrors } = getContext("fetchErrorHandler");

  async function loadSavedSettings(userId, projectId, fullJson) {
    if (!userId || !projectId) return

    const savedSettings = getIntegratedMasterConfiguration(userId, projectId)

    // apply style settings
    if (savedSettings.styleName && fullJson?.Styles) {
      console.log(fullJson.Styles)
      const styles = fullJson.Styles
      const index = styles.findIndex((s) => s === savedSettings.styleName);
      if (index !== -1) {
        iStyle = index;
      }
    }

    // apply search keyword
    if (savedSettings.searchKeyword) {
      searchKeyword = savedSettings.searchKeyword
      searchMode = true
    }
  }

  onMount(async () => {
    try {
      // load the base data
      const data = await fetchIntegratedMasterData(projectId);
      lastDownloadTimeStamp = data["LastDownloadTimeStamp"];
      fullJson = data;
      setDisplayConfig();
      totalLength = recalcTotalLength();
      totalPageNumber = calcTotalPageNumber();
      // then fetch the updates and apply them
      await applyUpdates();
    } catch (e) {
      if (e instanceof FetchError) {
        handleFetchErrors(e.error);
      }
    }
  });

  $: loadSavedSettings($me?.id, projectId, fullJson)

  async function send() {
    const updatedTime = getTimeString(new Date(), $project.timezone, $getOffset);
    const body = {};

    newCaps.forEach((cap, i) => {
      if (!cap) return;
      if (
        cap + "" ===
        fullJson.Data[iStyle].Rows[i][idxMap[iStyle].get("EM_Task2")]
      )
        return;
      body[fullJson.Data[iStyle].FixedRows[i][6]] = {
        UseBomInstructionUser_Task02CapacityExpr_Curr: cap + "",
        UseBomInstructionUser_Task02CapacityExpr_UpdatedTime: updatedTime,
        UseBomInstructionUser_Task02CapacityExpr_Old:
          fullJson.Data[iStyle].Rows[i][idxMap[iStyle].get("EM_Task2")],
      };
    });
    if (body.length === 0) return;

    try {
      const response = await registerIntegratedMasterTransaction(projectId, body);
      if (response.ok) {
        for (let i = 0; i < newCaps.length; i++) {
          if (
            !newCaps[i] ||
            newCaps[i] + "" ===
              fullJson.Data[iStyle].Rows[i][
                idxMap[iStyle].get(
                  "UseBomInstructionUser_Task02CapacityExpr_Curr"
                )
              ]
          )
            continue;
          newCaps[i] = undefined;
        }
      }
      await applyUpdates();
    } catch (e) {
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error);
      }
    }
  }

  let searchKeyword = "";
  let searchWords = []

  function onClickSearch() {
    searchWords = searchKeyword
      .trim()
      .replaceAll("　", " ")
      .split(" ")
      .filter((str) => str.trim());
    searchMode = searchWords.length > 0;
  }
</script>

<main>
  {#if fullJson}
    <GuestIntegratedMasterControls
      styles={fullJson.Styles}
      bind:iStyle
      bind:searchKeyword
      bind:currentPageIndex
      onClickSearch={onClickSearch}
      {send}
      {itemPerPage}
      bind:hidePagination={searchMode}
      totalPageNumber={totalPageNumber}
      userId={$me?.id}
      projectId={projectId}
    />

    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            {#each Object.entries(fullJson.Data[iStyle].DisplayedStrings) as [col, displayedString]}
              {#if col === "EM_Task2"}
                <th colspan="3">{displayedString}</th>
              {:else}
                <th class:short-title={displayedString.length < 6}>{displayedString}</th>
              {/if}
            {/each}
          </tr>
        </thead>
        <tbody>
          {#each fullJson.Data[iStyle].Rows as rowData, i}
            {#if isItemDisplayed(i, currentPageIndex, searchWords, rowData)}
              <tr>
                {#each Object.entries(fullJson.Data[iStyle].DisplayedStrings) as [col, _]}
                  {#if col === "EM_Task2"}
                    <td colspan="1" style="border-right: none; ">
                      {rowData[idxMap[iStyle].get(col)] === undefined
                        ? ""
                        : rowData[idxMap[iStyle].get(col)]}
                    </td>
                    <td
                      colspan="1"
                      style="border-left: none;border-right: none; "
                    >
                      <GeneralInput
                        bind:value={newCaps[i]}
                        extraStyle="text-align: center; max-width: 50px"
                      />
                    </td>
                    <td
                      colspan="1"
                      style="white-space: nowrap;border-left: none;"
                    >
                      <GeneralButton
                        buttonFunction={() => (measureIndex = i)}
                        buttonText={translate("frontend:views.integratedMaster.measureButtonLabel")}
                      />
                    </td>
                  {:else}
                    <td
                      >{rowData[idxMap[iStyle].get(col)] === undefined
                        ? ""
                        : rowData[idxMap[iStyle].get(col)]}</td
                    >
                  {/if}
                {/each}
              </tr>
            {/if}
          {/each}

          {#if fullJson.Data[iStyle].Rows.length === 0}
            <Loading message={translate("frontend:generic.errors.noDataToDisplay")}/>
          {/if}
        </tbody>
      </table>
    </div>
  {:else}
    <Loading/>
  {/if}

  {#if measureIndex !== -1}
    <IntegratedMasterMeasure
      {fullJson}
      {iStyle}
      {measureIndex}
      {onCloseMeasurement}
    />
  {/if}
</main>

<style>
  .table-wrapper {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 10px;
  }

  table {
    display: block;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #ebebeb;
    border-collapse: collapse;
    text-align: center;
    margin-top: 5px;
    min-width: 50%;
  }
  table tr:nth-child(odd) {
    background-color: #f2f2f2; 
  }

  table tr:nth-child(even) {
    background-color: white; 
  }

  table th {
    background-color: #28385d;
    color: white;
    font-size: 1rem;
    padding: 8px;
    word-wrap: break-word;
    text-align: center;
  }
  table th.short-title {
    min-width: 100px;
  }

  table td {
    border: 1px solid #d3d3d3;
    padding: 8px;
    white-space: normal;
    text-align: center;
  }

  @media (max-width: 600px) {
    table th,
    table td {
      font-size: 0.875rem;
      padding: 6px;
    }
  }
</style>
