<script>
  /* Definition
  ** [{
  **  buttonText: "value",
  **  buttonFunction: () => {}
  **  buttonImage: "path"
  ** }]
  */
  export let items;
</script>

<div class="gpb-wrapper">
  {#each items as item}
    <div
      class="gpb-item"
      on:click={item.buttonFunction}
    >
      {#if item.buttonText !== undefined}
        {item.buttonText}
      {/if}
      {#if item.buttonImage !== undefined}
        <img src={item.buttonImage}>
      {/if}
    </div>
  {/each}
</div>

<style>
  div.gpb-wrapper {
    position: relative;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0;
    width: fit-content;
  }
  div.gpb-item {
    position: relative;
    padding: 0.5em 1em;
    color: #00468F;
    text-align: center;
    background-color: white;
    border-top: solid 2px #888;
    cursor: pointer;
  }
  div.gpb-item:hover {
    background-color: #EEEEEE;
  }
  div.gpb-item:active {
    background-color: #DDDDDD;
  }
  div.gpb-item img {
    padding-top: 0.25em;
  }
  div.gpb-item:first-child {
    border-left: solid 2px #888;
    border-top-left-radius: 0.6em;
  }
  div.gpb-item:last-child {
    border-right: solid 2px #888;
    border-top-right-radius: 0.6em;
  }
  div.gpb-item:not(:last-child)::after {
    content: '';
    display: block;
    width: 2px;
    height: 28px;
    background-color: #CCC;
    position: absolute;
    right: -1px;
    top: calc((100% - 28px)/2);
  }
</style>
