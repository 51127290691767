<script>
  import GeneralSelect from "../select/GeneralSelect.svelte";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import GeneralInput from "../../components/input/GeneralInput.svelte";
  import FilterInput from "../../components/input/FilterInput.svelte";
  import {
    selectedResourceCodeS,
    selectedProcessS,
    selectedGroupS,
    selectedShiftS,
    selectedUnitPeriodS,
    selectedDisplayPeriodS,
    selectedDisplayRowNumS,
    mostRecentChange,
  } from "../../components/loadgraph/loadGraphStore.js";

  export let onClose;
  export let reset;
  export let handleFocus;

  // Props for the selection boxes
  export let resourceCodes = [];
  export let processes = [];
  export let groups = [];
  export let shifts = [];
  export let unitPeriod = [];
  export let displayPeriod = [];
  const minValue = 1;
  export let maxValue = 30;

  export let groupFilterInput;
  export let resFilterInput;
  export let procFilterInput;
  export let shiftFilterInput;

  function handleChange(event) {
    const selectElement = event.target;
    const selectedValue = selectElement.value;
    const customOptionIndex = displayPeriod.findIndex(option => option.isCustom);
    if (customOptionIndex !== -1) {
        displayPeriod.splice(customOptionIndex, 1);
    }
    selectElement.style.color = '';
  }

  function handleInput(event) {
    const selectElement = event.target;
    const selectedValue = selectElement.value;

    if (selectedValue >= minValue && selectedValue <= maxValue) {
      selectElement.style.color = '';
      selectedDisplayRowNumS.set(selectedValue);
    } else {
      selectElement.style.color = 'red';
      /* selectElement.value = `${selectedValue} (Invalid Value)`; */
    }
  }

  function handleInputChange(event) {
    const selectElement = event.target;
    const selectedValue = selectElement.value;
  }

  function handleGroupSelection(selectedGroup) {
    selectedGroupS.set(selectedGroup);

    mostRecentChange.set({
      group: 1,
      resourceCode: 0,
      process: 0,
      shift: 0
    });
  }

  function handleResourceCodeSelection(selectedResourceCode) {
    selectedResourceCodeS.set(selectedResourceCode);

    mostRecentChange.set({
      group: 0,
      resourceCode: 1,
      process: 0,
      shift: 0
    });
  }

  function handleProcessSelection(selectedProcess) {
    selectedProcessS.set(selectedProcess);

    mostRecentChange.set({
      group: 0,
      resourceCode: 0,
      process: 1,
      shift: 0
    });
  }

  function handleShiftSelection(selectedShift) {
    selectedShiftS.set(selectedShift);

    mostRecentChange.set({
      group: 0,
      resourceCode: 0,
      process: 0,
      shift: 1
    });
  }
  
</script>

<div class="filter">
  <div class="title-container">
    <button class="close-button" on:click={onClose}>＜</button>
    <span class="title">メニュー</span>
  </div>
  <div id="display-menu">
    <span class="section-title">表示設定</span>
    <div class="display-item">
      <label class="block-title">単位期間</label>
      <GeneralSelect options={unitPeriod} bind:value={$selectedUnitPeriodS} extraStyle="width: 100%;"/>
    </div>
    <div class="display-item">
      <label class="block-title">表示期間</label>
      <GeneralSelect options={displayPeriod} bind:value={$selectedDisplayPeriodS} onChangeEvent={handleChange} selectId="displayPeriodSelect" extraStyle="width: 100%;"/>
    </div>
    <div class="display-item">
      <label class="block-title">表示数</label>
      <GeneralInput value={$selectedDisplayRowNumS} min={minValue} max={maxValue} onChange={handleInputChange} onInput={handleInput} extraStyle="width: 100%;" on:focus={handleFocus} type="number"/>
    </div>
  </div>
  <div id="filter-menu">
    <span class="section-title">フィルター</span>
    <div class="filter-item">
      <label class="block-title">グループ</label>
      <!-- <GeneralSelect options={groups} bind:value={$selectedGroupS} extraStyle="width: 100%;"/> -->
      <FilterInput
        bind:this={groupFilterInput}
        choices={groups}
        onQueryDetermined={handleGroupSelection}
        displayAdvancedFilter={false}
      />
    </div>
    <div class="filter-item">
      <label class="block-title">資源コード</label>
      <!-- <GeneralSelect
        options={resourceCodes}
        bind:value={$selectedResourceCodeS}
        extraStyle="width: 100%;"
      /> -->
      <FilterInput
        bind:this={resFilterInput}
        choices={resourceCodes}
        onQueryDetermined={handleResourceCodeSelection}
        displayAdvancedFilter={false}
      />
    </div>
    <div class="filter-item">
      <label class="block-title">工程</label>
      <!-- <GeneralSelect options={processes} bind:value={$selectedProcessS} extraStyle="width: 100%;"/> -->
      <FilterInput
        bind:this={procFilterInput}
        choices={processes}
        onQueryDetermined={handleProcessSelection}
        displayAdvancedFilter={false}
      />
    </div>
    <div class="filter-item">
      <label class="block-title">勤務シフト</label>
      <!-- <GeneralSelect options={shifts} bind:value={$selectedShiftS} extraStyle="width: 100%;"/> -->
      <FilterInput
        bind:this={shiftFilterInput}
        choices={shifts}
        onQueryDetermined={handleShiftSelection}
        displayAdvancedFilter={false}
      />
    </div>
  </div>
  <div class="reset">
    <GeneralButton 
      buttonFunction={reset}
      buttonText="フィルターのクリア"
      buttonType="negative" 
      buttonExtraStyle="width: 100%; margin: 0;s"
    />
  </div>
</div>

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }

  .filter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 32px 16px;
  }

  .title {
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: bold;
    font-size: 24px;
  }

  .section-title {
    font-size: 16px;
    color: #CCCCCC;
    padding-bottom: 16px;
  }

  .block-title {
    font-size: 16px;
    padding-bottom: 8px;
  }

  .close-button {
    color: #CCCCCC;
    user-select: none;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    padding-bottom: 32px;
  }

  #display-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 32px;
    width: 100%;
    width: 100%;
  }

  #display-menu > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    padding-bottom: 16px;
    width: 100%;
    width: 100%;
  }

  #display-menu > div:last-child {
    padding-bottom: 0;
  }

  #filter-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 32px;
    width: 100%;
  }

  #filter-menu > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    padding-bottom: 16px;
    width: 100%;
  }

  #filter-menu > div:last-child {
    padding-bottom: 0;
  }

  .reset {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  div :global(.filter-input-container) {
    width: 100%;
  }
</style>
