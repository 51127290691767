<script>
  import { onMount } from "svelte";
  import { I18N_GetString } from "../../utils/i18n";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import { fly } from "svelte/transition";
  import { getTimeStringSec } from "../../utils/time";
  import { translate } from "../../i18n/i18next.js";

  export let fullJson;
  export let iStyle;
  export let measureIndex;
  export let onCloseMeasurement;

  let watchStarted = false;
  let timeLog = Array(3);
  for (let i = 0; i < 3; i++) {
    timeLog[i] = { value: 0, date: "", start: 0 };
  }
  let logLength = 0;

  const displayInWindow = [
    translate("asprova.terms.itemName"),
    translate("asprova.terms.itemCode"),
    translate("asprova.terms.processCode") + ":" + translate("asprova.terms.instructionCode"),
    translate("asprova.terms.processSelector"),
    translate("asprova.terms.taskSelector"),
    translate("asprova.terms.resource") + "/" + translate("asprova.terms.itemCode")
  ];

  let propertiesPerRow = 3; // Number of properties per row in the timer window

  let conclusion = 0;

  const QUs = [
    {
      name: "selectSec",
      text: `${translate("asprova.terms.useBomInstructionUnits.s")} (s)`,
      unit: "sec",
      unit_asprova: "s",
      unit_gluarity: 1,
    },
    {
      name: "selectSecPer",
      text: `${translate("asprova.terms.useBomInstructionUnits.sp")} (sp)`,
      unit: "sec",
      unit_asprova: "sp",
      unit_gluarity: 1,
    },
    {
      name: "selectPerSec",
      text: `${translate("asprova.terms.useBomInstructionUnits.ps")} (ps)`,
      unit: "sec",
      unit_asprova: "ps",
      unit_gluarity: 1,
    },
    {
      name: "selectMin",
      text: `${translate("asprova.terms.useBomInstructionUnits.m")} (m)`,
      unit: "min",
      unit_asprova: "m",
      unit_gluarity: 60,
    },
    {
      name: "selectMinPer",
      text: `${translate("asprova.terms.useBomInstructionUnits.mp")} (mp)`,
      unit: "min",
      unit_asprova: "mp",
      unit_gluarity: 60,
    },
    {
      name: "selectPerMin",
      text: `${translate("asprova.terms.useBomInstructionUnits.pm")} (pm)`,
      unit: "min",
      unit_asprova: "pm",
      unit_gluarity: 60,
    },
    {
      name: "selectHour",
      text: `${translate("asprova.terms.useBomInstructionUnits.h")} (h)`,
      unit: "hour",
      unit_asprova: "h",
      unit_gluarity: 3600,
    },
    {
      name: "selectHourPer",
      text: `${translate("asprova.terms.useBomInstructionUnits.hp")} (hp)`,
      unit: "hour",
      unit_asprova: "hp",
      unit_gluarity: 3600,
    },
    {
      name: "selectPerHour",
      text: `${translate("asprova.terms.useBomInstructionUnits.ph")} (ph)`,
      unit: "hour",
      unit_asprova: "ph",
      unit_gluarity: 3600,
    },
  ];

  let selectQU = QUs[0].name;
  let unit = QUs[0].unit;
  let unit_text = QUs[0].text;
  let unit_asprova = QUs[0].unit_asprova;
  let unit_gluarity = QUs[0].unit_gluarity;

  const CHOICES = [
    { name: "select1", text: translate("frontend:views.integratedMaster.measure.choices.measurementFirst")},
    { name: "selectMean", text: translate("frontend:views.integratedMaster.measure.choices.mean")},
    { name: "select2", text: translate("frontend:views.integratedMaster.measure.choices.measurementSecond")},
    { name: "selectMax", text: translate("frontend:views.integratedMaster.measure.choices.max")},
    { name: "select3", text: translate("frontend:views.integratedMaster.measure.choices.measurementThird")},
    { name: "selectMin", text: translate("frontend:views.integratedMaster.measure.choices.min")},
  ];

  let selectMethod = "selectMean";
  let select_text = translate("frontend:views.integratedMaster.measure.choices.mean");

  $: {
    switch (selectQU) {
      default:
        QUs.forEach((radio) => {
          if (radio.name == selectQU) {
            for (let i = 0; i < timeLog.length; i++) {
              if (timeLog[i].value != 0) {
                timeLog[i].value /= radio.unit_gluarity / unit_gluarity;
              }
            }
            unit = radio.unit;
            unit_asprova = radio.unit_asprova;
            unit_gluarity = radio.unit_gluarity;
            unit_text = radio.text;
          }
        });
    }
  }

  $: {
    switch (selectMethod) {
      case "selectMean": {
        select_text = translate("frontend:views.integratedMaster.measure.choices.mean");
        if (logLength == 0) break;
        let sum = 0;
        for (let i = 0; i < logLength; i++) sum += timeLog[i].value;
        conclusion = sum / logLength;
        break;
      }
      case "selectMax": {
        select_text = translate("frontend:views.integratedMaster.measure.choices.max");
        if (logLength == 0) break;
        conclusion = timeLog[0].value;
        for (let i = 0; i < logLength; i++)
          conclusion = Math.max(conclusion, timeLog[i].value);

        break;
      }
      case "selectMin": {
        select_text = translate("frontend:views.integratedMaster.measure.choices.min");
        if (logLength == 0) break;
        conclusion = timeLog[0].value;
        for (let i = 0; i < logLength; i++)
          conclusion = Math.min(conclusion, timeLog[i].value);
        break;
      }
      case "select1": {
        if (logLength > 0) conclusion = timeLog[0].value;
        select_text = translate("frontend:views.integratedMaster.measure.choices.measurementFirst");
        break;
      }
      case "select2": {
        if (logLength > 1) conclusion = timeLog[1].value;
        select_text = translate("frontend:views.integratedMaster.measure.choices.measurementSecond");
        break;
      }
      case "select3": {
        if (logLength > 2) conclusion = timeLog[2].value;
        select_text = translate("frontend:views.integratedMaster.measure.choices.measurementThird");
        break;
      }
    }

    switch (unit_asprova) {
      case "ps":
      case "pm":
      case "ph":
        conclusion = 1 / conclusion;
    }
    if (conclusion) conclusion = parseFloat(conclusion.toFixed(3));
  }

  function startTimer() {
    watchStarted = true;
    requestAnimationFrame(initCount);
  }

  function initCount(timeStamp) {
    if (logLength == 3) {
      timeLog[0] = timeLog[1];
      timeLog[1] = timeLog[2];
      timeLog[2] = { value: 0, date: getTimeStringSec(), start: timeStamp };
    } else {
      timeLog[logLength].value = 0;
      timeLog[logLength].start = timeStamp;
      timeLog[logLength].date = getTimeStringSec();
      logLength++;
    }
    requestAnimationFrame(countUP);
  }

  function countUP(timeStamp) {
    timeLog[logLength - 1].value = timeStamp - timeLog[logLength - 1].start;
    timeLog[logLength - 1].value /= 1000;
    switch (unit) {
      case "hour": {
        timeLog[logLength - 1].value /= 60;
      }
      case "min": {
        timeLog[logLength - 1].value /= 60;
        break;
      }
    }
    if (watchStarted) requestAnimationFrame(countUP);
  }

  function stopTimer() {
    watchStarted = false;
  }

  function cancel() {
    timeLog = Array(3);
    for (let i = 0; i < 3; i++) {
      timeLog[i] = { value: 0, date: "", start: 0 };
    }

    logLength = 0;
    conclusion = 0;

    onCloseMeasurement();
  }

  function confirm() {
    timeLog = Array(3);
    for (let i = 0; i < 3; i++) {
      timeLog[i] = { value: 0, date: "", start: 0 };
    }

    let newVal = conclusion + unit_asprova;
    logLength = 0;
    conclusion = 0;

    onCloseMeasurement(newVal);
  }
</script>

<div id="root" in:fly={{ duration: 200 }} out:fly={{ duration: 200 }}>
  <div>
    <div>
      <table style="border:none; width: 100%;">
        <colgroup>
          <col style="width: 15%;" />
          <col style="width: 18%;" />
          <col style="width: 18%;" />
          <col style="width: 15%;" />
          <col style="width: 18%;" />
          <col style="width: 15%;" />
        </colgroup>
        <tbody>
          {#each Array(Math.ceil(displayInWindow.length / propertiesPerRow)) as _, iRow}
            <tr style="border:none;">
              {#each Array(propertiesPerRow) as _, iCol}
                <th style="border:none; color:#aeaeae;">
                  {displayInWindow[iRow * propertiesPerRow + iCol]}
                </th>
                <td style="border:none;">
                  {#if measureIndex || measureIndex === 0}
                    {fullJson.Data[iStyle].FixedRows[measureIndex][
                      iRow * propertiesPerRow + iCol
                    ]}
                  {/if}
                </td>
              {/each}
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
    <hr style="margin: 1em" />
    <div class="row" style="margin-bottom: 0;">
      <div class="flex-col">
        <div class="measurements">
          {#each [0, 1, 2] as i}
            <div class="measurement-row">
              <div class="flex-col">
                <span class="title-text" style="float: right">{translate("generic.measurement")}{i + 1}</span>
              </div>
              <div class="flex-col" style="margin-left:10%">
                {#if timeLog[i] && timeLog[i].date}
                  <span class="big-text">{Math.floor(timeLog[i].value)}</span
                  ><span>.</span><span class="title-text"
                    >{(timeLog[i].value - Math.floor(timeLog[i].value))
                      .toFixed(2)
                      .slice(2)}</span
                  >
                {:else if timeLog[i]}
                  <span class="big-text">--</span>.<span class="title-text">--</span>
                {/if}
              </div>
              <div class="flex-col" style="float: left">
                {#if timeLog[i]}
                  <span class="title-text"><b>[{unit}]</b></span>
                {/if}
              </div>
              <div class="flex-col" style="float: left; flex: 2">
                {#if timeLog[i]}
                  <span style="color:#aeaeae;">
                    <b>
                      {timeLog[i].date ? translate("frontend:views.integratedMaster.measure.measurementStartedAt", null, {dateTime: timeLog[i].date}) : ""}
                    </b>
                  </span>
                {/if}
              </div>
            </div>
          {/each}
        </div>
        <div class="row buttons-row">
          <div class="flex-col">
            <GeneralButton
              buttonFunction={startTimer}
              buttonText="START"
              buttonExtraStyle="width: 90%; height: 2.5em"
              buttonDisabled={watchStarted}
            />
          </div>
          <div class="flex-col">
            <GeneralButton
              buttonFunction={stopTimer}
              buttonType="secondary"
              buttonText="STOP"
              buttonExtraStyle="width: 90%; height: 2.5em"
              buttonDisabled={!watchStarted}
            />
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div class="unit-selection">
          <div class="row">
            <span class="title-text">{translate("generic.unit")}</span>
          </div>
          <div class="row unit-options">
            {#each QUs as QU}
              <div class="col">
                <div class="selectQU">
                  <input
                    type="radio"
                    name="selectQU"
                    id={QU.name}
                    value={QU.name}
                    bind:group={selectQU}
                  />
                  <label for={QU.name}>&nbsp;<b>{QU.text}</b></label>
                </div>
              </div>
            {/each}
          </div>
          <div class="row">
            <span class="title-text">{translate("frontend:views.integratedMaster.measure.selectMeasurementToApply")}</span>
          </div>
          <div class="row method-options">
            {#each CHOICES as choice, i}
              <div class="col">
                <div>
                  {#if choice.name == "select1" || choice.name == "select2" || choice.name == "select3"}
                    <input
                      type="radio"
                      name="selectMethod"
                      id={choice.name}
                      value={choice.name}
                      bind:group={selectMethod}
                      disabled={parseInt(choice.name.slice(-1)) > logLength}
                    />
                    <label for={choice.name}><b>{choice.text}</b></label>
                  {:else}
                    <input
                      type="radio"
                      name="selectMethod"
                      id={choice.name}
                      value={choice.name}
                      bind:group={selectMethod}
                    />
                    <label for={choice.name}><b>{choice.text}</b></label>
                  {/if}
                </div>
              </div>
              {#if i % 2 == 1}
                <div class="col" />
              {/if}
            {/each}
          </div>
        </div>
        <div class="row result-display">
          <div class="flex-col">
            <span class="big-text" style="color: #1a3a91; margin-left: 10%"
              >{select_text}:</span
            >
          </div>
          <div class="flex-col" style="color: #1a3a91;">
            <span class="big-text" style="margin-left: 5%"
              >{Math.floor(conclusion)}</span
            ><span>.</span><span class="title-text"
              >{(conclusion - Math.floor(conclusion)).toFixed(2).slice(2)}</span
            >
          </div>
          <div class="flex-col">
            <span class="title-text" style="color: #1a3a91">{unit_text}</span>
          </div>
        </div>
        <div class="row action-buttons">
          <div class="flex-col">
            <GeneralButton
              buttonFunction={cancel}
              buttonText={translate("generic.cancel")}
              buttonType="negative"
              buttonExtraStyle="width: 90%; height: 2.5em"
            />
          </div>
          <div class="flex-col">
            <GeneralButton
              buttonFunction={confirm}
              buttonText="OK"
              buttonExtraStyle="width: 90%; height: 2.5em"
              buttonDisabled={conclusion == 0}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  #root {
    background-color: white;
    z-index: 102;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 80%;
    max-width: 92%;
    max-height: 92%;
    overflow-y: auto;
    border: 2px solid rgb(40, 56, 93);
    border-radius: 10px;
    padding: 10px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .measurement-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .flex-col {
    flex: 1;
  }
  .buttons-row {
    margin-top: 20px;
  }
  .unit-options,
  .method-options {
    display: flex;
    flex-wrap: wrap;
  }
  .unit-options .col {
    flex: 1 1 30%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .method-options .col {
    flex: 1 1 30%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .result-display {
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    align-items: center;
    height: 4em;
  }
  .action-buttons {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .title-text {
    font-size: 1.2em;
    font-weight: bold;
  }
  .big-text {
    font-size: 1.8em;
    font-weight: bold;
  }
</style>
