<script>
  export let StartY;
  export let Item;
</script>

<rect
  x="{Item.StartX}%"
  y="{StartY}%"
  height="50%"
  width={(parseFloat(Item.EndX) - parseFloat(Item.StartX)) + "%"}
  fill={Item.BlockColor}
/>
{#if Item.TextDown === null}
<text
  fill="black"
  font-size="1em"
  x={(Item.StartX + 1) + "%"}
  y={(parseFloat(StartY) + 24) + "%"}
  text-anchor="start"
  dominant-baseline="middle"
  pointer-events="none"
>
  {Item.TextUp}
</text>
{:else}
<text
  fill="black"
  font-size="1em"
  x={((Item.StartX + Item.EndX) / 2) + "%"}
  y={(parseFloat(StartY) + 8) + "%"}
  text-anchor="middle"
  dominant-baseline="hanging"
  pointer-events="none"
>
  <tspan
    x={((Item.StartX + Item.EndX) / 2) + "%"}
    dy="0"
  >
    {Item.TextUp}
  </tspan><tspan
    x={((Item.StartX + Item.EndX) / 2) + "%"}
    dy="1em"
  >
    {Item.TextDown}
  </tspan>
</text>
{/if}
<line
  x1="{Item.EndX}%"
  x2="{Item.EndX}%"
  y1="{StartY}%"
  y2={(parseInt(StartY) + 50) + "%"}
  stroke="#888888"
  stroke-width="2"
/>

<style>

</style>
