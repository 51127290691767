<script>
  import { createEventDispatcher, onMount } from "svelte";
  import GeneralButton from "../button/GeneralButton.svelte";
  import ToggleSwitch from "../input/ToggleSwitch.svelte";
  import { fly } from "svelte/transition";
  import { translate } from "../../i18n/i18next.js";

  export let GanttStore
  export let ganttChartType;

  const dispatcher = createEventDispatcher();

  let extraColumnProperties = [];

  if (
    ganttChartType == GanttStore.GanttChartTypes.ResGantt ||
    ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt ||
    ganttChartType === GanttStore.GanttChartTypes.CustomizedResGantt
  ) {
    extraColumnProperties = [
      { name: "資源グループ", nameTranslationKey: "$t(asprova.terms.resourceGroup)", code: "ResourceGroup" },
      { name: "資源名", nameTranslationKey: "$t(asprova.terms.resourceName)", code: "ResourceName" }
    ];
  } else {
    extraColumnProperties = [
      { name: "製番", nameTranslationKey: "製番", code: "Order_Seiban" },
      { name: "品目コード", nameTranslationKey: "$t(asprova.terms.itemCode)", code: "Order_ItemCode" },
      { name: "品目名", nameTranslationKey: "$t(asprova.terms.itemName)", code: "Order_ItemName" },
      { name: "納期", nameTranslationKey: "$t(asprova.terms.due)", code: "Order_LET" },
      { name: "納期遅れ", nameTranslationKey: "$t(asprova.terms.lateness)", code: "Order_Lateness" },
    ];
  }

  let uses = new Array(extraColumnProperties).fill(false);

  let extraColumns;

  function closeWindow() {
    extraColumns = new Array();
    extraColumnProperties.forEach((prop, idx) => {
      if (uses[idx]) {
        extraColumns.push(prop);
      }
    });
    console.log(extraColumns);
    GanttStore.ExtraColumns.set(extraColumns);
    dispatcher("close");
  }

  onMount(async () => {
    GanttStore.ExtraColumns.subscribe((ec) => {
      extraColumns = ec;
      extraColumns.forEach(
        (val) =>
          (uses[
            extraColumnProperties.findIndex((prop) => prop.name === val.name)
          ] = true)
      );
    });
  });
</script>

<div
  class="settings-window"
  in:fly={{ y: -300, duration: 300 }}
  out:fly={{ y: -300, duration: 300 }}
>
  <span class="title-text">{translate("frontend:ganttChart.columnSettings.label")}</span>
  {#each extraColumnProperties as prop, idx}
    <div class="settings-item">
      <span class="label-text">{translate(prop.nameTranslationKey)}</span>
      <ToggleSwitch bind:on={uses[idx]} />
    </div>
  {/each}
  <GeneralButton buttonText="OK" buttonFunction={closeWindow} />
</div>

<style>
  .settings-window {
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 200px;
    max-height: 92%;
    overflow-y: auto;
    border: 2px solid rgb(40, 56, 93);
    border-radius: 10px;
  }

  .title-text {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .settings-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .label-text {
    flex: 1;
    margin-right: 10px;
  }
</style>
