<script>
  export let message;
  export let mark = null;
</script>

<div class="loading-container">
  {#if mark}
    <div class="icon">{mark}</div>
  {:else}
    <div class="spinner" />
  {/if}
  <div class="loading-text">{message ?? "Loading..."}</div>
</div>

<style>
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #00468f;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .icon {
    font-size: 4rem;
    color: #00468f;
    margin-bottom: 1rem;
  }

  .loading-text {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #333;
  }
</style>
