<script>
  export let percentage;
  export let viewMode;

  function getColorClass(percentage) {
    const value = parseInt(percentage);
    if (value == -100) {
      return "";
    } else if (value == 0) {
      return "zero-color";
    } else if (value <= 20) {
      return "twenty-color";
    } else if (value <= 40) {
      return "forty-color";
    } else if (value <= 60) {
      return "sixty-color";
    } else if (value <= 90) {
      return "eighty-color";
    } else {
      return "hundred-color";
    }
  }

  function getBarColor(percentage) {
    // percentage in [-100,0-100]

    const value = parseInt(percentage);

    if (value == -100) {
      return "";
    } else if (value <= 20) {
      return "#EBF6FF";
    } else if (value <= 40) {
      return "#CCE8FF";
    } else if (value <= 60) {
      return "#A6D6FF";
    } else if (value <= 80) {
      return "#80C5FF";
    } else {
      return "#4DADFF";
    }
  }

  function overHundred(percentage) {
    const value = parseInt(percentage);
    if (value > 100) {
      return "bolder";
    }
  }
</script>

{#if viewMode === "colorScale" || viewMode === ""}
  <div class="percentage-inner {getColorClass(percentage)}">
    <span class="{overHundred(percentage)}">{percentage == -100 ? "" : `${Math.floor(percentage)}%`}</span>
  </div>
{:else if viewMode === "dataBar"}
  <div class="data-bar-container">
    <span class="{overHundred(percentage)}">{percentage == -100 ? "" : `${Math.floor(percentage)}%`}</span>
    {#if percentage > 100}
      <div class="percentage-inner {getColorClass(percentage)}">
      </div>
    {:else}
      {#if percentage != -100}
        <div class="bar">
          <div
            class="percentage-inner"
            style="height: {100 -
              parseInt(percentage)}%; background-color: #FFFFFF;"
          />
          <div
            class="percentage-inner"
            style="height: {percentage}%; background-color: {getBarColor(
              percentage
            )};"
          />
        </div>
      {:else}
        <div class="bar" />
      {/if}
    {/if}
  </div>
{/if}

<style>
  .data-bar-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .data-bar-container span {
    position: absolute;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bar {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
  }

  .data-bar-container span {
    width: 100%;
    text-align: center;
  }
  .bolder {
    font-weight: 900;
  }
  .percentage-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-weight: 500;
    /* font-size: 26px; */
  }

  .zero-color {
    background-color: #ffffff;
  }
  .twenty-color {
    background-color: #ebf6ff;
  }

  .forty-color {
    background-color: #cce8ff;
  }

  .sixty-color {
    background-color: #a6d6ff;
  }

  .eighty-color {
    background-color: #80c5ff;
  }

  .hundred-color {
    background-color: #4dadff;
  }
</style>
