<script>
  import SectionTitle from "../../layout/SectionTitle.svelte"
  import { getNotificationsContext } from "svelte-notifications"
  import { useQueryClient } from "@sveltestack/svelte-query"
  import { createForm } from "felte"
  import { z } from "zod"
  import { validator } from "@felte/validator-zod"
  import PrimaryButton from "../../button/generic/PrimaryButton.svelte"
  import { translate } from "../../../i18n/i18next.js"
  import { useMe } from "../../../hooks/useMe.js"
  import { isServerAdmin } from "../../../utils/user.js"
  import { getNextTimeShift, useTimezone } from "../../../hooks/useTimezone.js";
  import { derived } from "svelte/store";
  import { getFormattedOffsetDate } from "../../../utils/time.js";

  export let project

  const me = useMe()
  const {addNotification} = getNotificationsContext()
  const queryClient = useQueryClient()
  const {data: timezoneData} = useTimezone()

  const schema = z.object({
    name: z.string().min(1, translate("frontend:pages:projects.project.admin.sections.generalInfo.form.projectName.errors.noProjectName")),
    description: z.union([
      z.string(),
      z.null(),
    ]),
    timezone: z.string(),
    plan: z.union([
      z.literal("standard"),
      z.literal("viewer"),
      z.literal("free"),
    ]),
  })

  async function handleSubmit(values) {
    const response = await fetch(`/api/projects/${project.id}`, {
      method: "PUT",
      body: JSON.stringify({
        name: values.name,
        description: values.description,
        timezone: values.timezone,
        plan: values.plan,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      addNotification({
        text: translate("frontend:generic.notifications.saved"),
        type: "success",
        removeAfter: 4000,
        position: "top-right",
      })
      await queryClient.invalidateQueries(`/api/projects/${project.id}`)
    } else {
      addNotification({
        text: translate("frontend:generic.notifications.saveFailed"),
        type: "error",
        removeAfter: 4000,
        position: "top-right",
      })
    }
  }

  const timezones = derived(timezoneData, data => {
    if (!data) return []
    const now = Date.now()
    return Object.keys(data).map(key => {
      let current = null
      for (let i=0; i < data[key].length; i++) {
        if (now / 1000 > data[key][i].timestamp) {
          current = data[key][i]
          break
        }
      }
      const offsetString = current.offset >= 0 ? `+${current.offset / 3600}` : `${current.offset / 3600}`
      return {label: `${key} (${current.gen_abb}, UTC${offsetString})`, value: key}
    })
  })

  const {form, errors, data} = createForm({
    onSubmit: handleSubmit,
    initialValues: {...project},
    extend: validator({schema})
  })

  $: timeShift = getNextTimeShift($data.timezone, $timezoneData)
</script>

<SectionTitle title={translate("frontend:pages:projects.project.admin.sections.generalInfo.title")} />
<div class="caption">
  {translate("frontend:pages:projects.project.admin.sections.generalInfo.caption")}
</div>
<form id="form-project-general-info" use:form>
  <div class="input-container">
    <label for="input-name">{translate("frontend:pages:projects.project.admin.sections.generalInfo.form.projectName.label")}</label>
    <input
      id="input-name"
      name="name"
    />
    {#if $errors.name}
      <span class="error">{$errors.name}</span>
    {/if}
  </div>
  <div class="input-container">
    <label for="input-description">{translate("frontend:pages:projects.project.admin.sections.generalInfo.form.projectDescription.label")}</label>
    <input
      id="input-description"
      name="description"
    />
    {#if $errors.description}
      <span class="error">{$errors.description}</span>
    {/if}
  </div>
  <div class="input-container">
    <label for="input-timezone">{translate("generic.timezone")}</label>
    <select id="input-timezone" name="timezone">
      {#each $timezones as timezone}
        <option value={timezone.value} selected={timezone.value === project.timezone}>{timezone.label}</option>
      {/each}
    </select>
    {#if timeShift.next}
      <span style="font-size: .9rem">{translate("frontend:pages.projects.project.admin.sections.generalInfo.form.timezone.nextTimeShift", null, {
        at: `${getFormattedOffsetDate(new Date(timeShift.next.timestamp * 1000), "YYYY/MM/DD HH:mm:ss", timeShift.next.offset)} (${timeShift.next.gen_abb})`
      })}</span>
    {/if}
  </div>
  <div class="input-container">
    <label for="input-plan">{translate("frontend:pages.projects.project.admin.sections.generalInfo.form.plan.label")}</label>
    <select id="input-plan" name="plan" value={project.plan} disabled={!isServerAdmin($me)}>
      <option value="free">{translate("mySchedule.plans.free.name")}</option>
      <option value="viewer">{translate("mySchedule.plans.viewer.name")}</option>
      <option value="standard">{translate("mySchedule.plans.standard.name")}</option>
    </select>
    {#if !isServerAdmin($me)}
      <span class="supplemental-info">
        {translate("frontend:pages:projects.project.admin.sections.generalInfo.form.plan.caption")}
      </span>
    {/if}
  </div>
  <div>
    <PrimaryButton type="submit">{translate("generic.save")}</PrimaryButton>
  </div>
</form>

<style>
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }
  input, select {
    font-size: 1rem;
    padding: 0.4rem;
    outline: none;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
  }
  input:focus, select:focus {
    outline: 2px solid #006bdf;
    outline-offset: -1px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
  }
  .error {
    color: red;
    font-size: 0.9rem;
  }
  .supplemental-info {
    padding: 5px;
    font-size: 0.8rem;
    color: #666666;
  }
  .caption {
    font-size: 0.9rem;
  }
</style>