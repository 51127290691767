import {writable} from "svelte/store"

const errorStore = writable(null)

function setError(error) {
  errorStore.set(error)
}

function clearError() {
  errorStore.set(null)
}

export const fetchErrorHandlerContextValueStore = {
  error: errorStore,
  setError,
  clearError,
}
