<script>
  import {useParams} from "svelte-navigator"
  import {getContext, onMount} from "svelte"
  import {FetchError, fetchOrderLeadTimeOrders} from "../../utils/getData.js"
  import {usePageContext} from "../../components/pages/pageContext.js"
  import Loading from "../../components/pages/Loading.svelte";
  import { translate } from "../../i18n/i18next.js";

  const _COLOR_FOR_DEMO = [
    "RGB(249,89,122)",
    "RGB(35,211,199)",
    "RGB(131,124,255)",
    "RGB(253,208,47)",
    "RGB(80,218,251)",
    "RGB(252,79,166)",
    "RGB(173,228,49)",
    "RGB(178,98,244)",
    "RGB(252,117,40)",
    "RGB(57,145,255)",
    "RGB(254,125,253)",
    "RGB(44,217,129)",
    "RGB(151,169,189)",
    "RGB(253,167,41)",
    "RGB(189,178,151)",
    "RGB(253,198,212)",
    "RGB(239,121,116)",
    "RGB(68,178,203)",
    "RGB(154,120,236)",
    "RGB(233,239,81)",
    "RGB(108,181,239)",
    "RGB(240,107,141)",
    "RGB(141,219,81)",
    "RGB(195,105,217)",
    "RGB(238,175,75)",
    "RGB(121,114,255)",
    "RGB(245,146,219)",
    "RGB(76,209,174)",
    "RGB(163,169,192)",
    "RGB(239,214,76)",
    "RGB(192,192,163)",
    "RGB(249,207,207)",
  ];

  const params = useParams();

  const projectId = $params.project;

  const gridlinesNum = 5;
  const timeUnits = [
    { title: "時間", titleTranslationKey: "time.range.hour_one", unit: 60 * 60 },
    { title: "日", titleTranslationKey: "time.range.day_one", unit: 60 * 60 * 24 },
    { title: "週", titleTranslationKey: "time.range.week_one", unit: 60 * 60 * 24 * 7 },
  ];
  let ordersData = null;
  let projectData = null;
  let itemCodes = [];
  let itemFlags = {};
  let selectedTimeUnit = timeUnits[0];
  let isBarExists = true;
  $: gridlines = [];
  $: maxTime = 0;

  const {handleFetchErrors} = getContext("fetchErrorHandler")
  async function fetchOrderInfo(flag = true) {
    try {
      const data = await fetchOrderLeadTimeOrders(projectId)
      ordersData = data.Orders;
      projectData = data.Project;
      init(flag);
    } catch (e) {
      console.log(e)
      if (e instanceof FetchError) {
        handleFetchErrors(e.error)
      }
    }
  }

  function init(flag) {
    ordersData.forEach((el) => {
      if (!itemCodes.includes(el.Order_Item)) {
        itemCodes.push(el.Order_Item);
        itemFlags[el.Order_Item] = false;
      }
      el.Work_LeadTime = convertSecToTimeUnit(el.Work_LeadTime);
    });
    itemFlags[""] = false;
    sortOrders();
    if (flag) assignColorToItem();
    getMaxTime();
    updateAllCheckbox();
  }

  // sec の変換と小数第二位未満を切り捨て
  function convertSecToTimeUnit(sec) {
    return floorToSecondDecimalPlace(sec / selectedTimeUnit.unit);
  }

  // 小数第二位未満を切り捨て
  function floorToSecondDecimalPlace(num) {
    return Math.floor(num * 100) / 100;
  }

  // オーダをリードタイムが降順になるようにソート
  function sortOrders() {
    const compareLeadTime = (order1, order2) => {
      return order2.Work_LeadTime - order1.Work_LeadTime;
    };
    ordersData.sort(compareLeadTime);
  }

  // 各品目に色を対応付ける
  let itemToColorList = {};
  function assignColorToItem() {
    let itemList = [];
    ordersData.forEach((order) => {
      if (!itemList.includes(order.Order_Item)) {
        itemToColorList[order.Order_Item] =
          _COLOR_FOR_DEMO[itemList.length % _COLOR_FOR_DEMO.length];
        itemList.push(order.Order_Item);
        itemFlags[order.Order_Item] = true;
      }
    });
    itemToColorList[""] = "#cdcdcd";
    itemFlags[""] = true;
  }

  // 選択された品目コードのなかで最長の LT を取得
  $: getMaxTime = () => {
    maxTime = 0;
    ordersData.forEach((order) => {
      if (itemFlags[order.Order_Item] && maxTime < Number(order.Work_LeadTime))
        maxTime = Number(order.Work_LeadTime);
    });
    calcGridlines();
  };

  // グラフの補助線を計算
  function calcGridlines() {
    const maxTimeDigits = String(Math.ceil(maxTime)).length;
    const maxTimeDigits10 = Math.pow(10, maxTimeDigits - 2);
    const gridlineInterval =
      (Math.floor(maxTime / maxTimeDigits10) * maxTimeDigits10) / gridlinesNum;
    gridlines = [];
    for (let i = 0; i < gridlinesNum; i++) {
      gridlines.push(gridlineInterval * (i + 1));
    }
  }

  // 各オーダの棒グラフの style を取得
  $: getBarGraphStyle = (order) => {
    // 各オーダの棒グラフの相対的な長さを計算
    const getBarGraphWidth = () => {
      return (
        "width: calc((100% - 5em) * " +
        String(Number(order.Work_LeadTime) / maxTime) +
        ");"
      );
    };

    // 各オーダの棒グラフの色を取得
    const getBarGraphColor = () => {
      return "background-color: " + itemToColorList[order.Order_Item] + ";";
    };
    return getBarGraphWidth() + " " + getBarGraphColor();
  };

  // 各品目の checkbox の style を取得
  $: getCheckboxStyle = (code) => {
    let res = "border-color: " + itemToColorList[code] + ";";
    if (itemFlags[code])
      res += " background-color: " + itemToColorList[code] + "; color: white;";
    return res;
  };

  // 各補助線の style を取得
  $: getGridlineStyle = (gridline) => {
    return "width: calc((100% - 5em) * " + String(gridline / maxTime) + ");";
  };

  // 品目の選択状況の切り替え
  function toggleCheckbox(code) {
    if (code === "") {
      itemCodes.forEach((el) => {
        if (itemFlags[el] === itemFlags[code]) itemFlags[el] = !itemFlags[el];
      });
    }
    itemFlags[code] = !itemFlags[code];
    getMaxTime();
    updateAllCheckbox();
  }

  // すべての checkbox の状態を更新
  function updateAllCheckbox() {
    let allFlag = true;
    isBarExists = false;
    itemCodes.forEach((code) => {
      if (!itemFlags[code]) allFlag = false;
      isBarExists |= itemFlags[code];
    });
    itemFlags[""] = allFlag;
  }

  // ラジオボタンによる更新
  function updateByRadioButton(index) {
    selectedTimeUnit = timeUnits[index];
    fetchOrderInfo(false);
  }

  onMount(async () => {
    await fetchOrderInfo();
  });

  const {setHeaderProps} = usePageContext()
  $: setHeaderProps({title: projectData ? `${projectData[5]} (${translate("mySchedule.views.orderLeadTime.name")})`: translate("mySchedule.views.orderLeadTime.name")})
</script>

<!-- Main Div -->
{#if ordersData}
  <div class="wrapper">
    <div class="params">
      <div class="timeSpan">
        <p class="title">{translate("generic.unit")}</p>
        {#each timeUnits as unit, index}
          <div class="item">
            <button
              class="radiobutton"
              on:click={() => updateByRadioButton(index)}
            >
              <span
                style={selectedTimeUnit === unit
                  ? "background-color: deepskyblue;"
                  : "background-color: rgba(0,0,0,0);"}
              />
            </button>
            <div class="name">{translate(unit.titleTranslationKey)}</div>
          </div>
        {/each}
      </div>
      <div class="itemCode">
        <p class="title">{translate("asprova.terms.itemCode")}</p>
        <div class="item">
          <button
            class="checkbox"
            style={getCheckboxStyle("")}
            on:click={() => toggleCheckbox("")}
          >
            {#if itemFlags[""]}
              ✔
            {/if}
          </button>
          <div class="name">{translate("generic.all")}</div>
        </div>
        <div class="others">
          {#each itemCodes as code}
            <div class="item">
              <button
                class="checkbox"
                style={getCheckboxStyle(code)}
                on:click={() => toggleCheckbox(code)}
              >
                {#if itemFlags[code]}
                  ✔
                {/if}
              </button>
              <div class="name">{code}</div>
            </div>
          {/each}
        </div>
      </div>
    </div>
    <div class="content">
      {#if isBarExists}
        <div class="graph">
          <div class="note vertical">{translate("asprova.terms.orderCode")}（{translate("asprova.terms.itemCode")}）</div>
          <div class="data">
            <div class="names">
              {#each ordersData as order}
                {#if itemFlags[order.Order_Item]}
                  <div class="ind">
                    {order.Code}({order.Order_Item})
                  </div>
                {/if}
              {/each}
            </div>
            <div class="orders">
              {#each ordersData as order}
                {#if itemFlags[order.Order_Item]}
                  <div class="ind">
                    <div class="bar" style={getBarGraphStyle(order)} />
                    <div class="time">{order.Work_LeadTime}</div>
                  </div>
                {/if}
              {/each}
              <div class="gridlines">
                {#each gridlines as gridline}
                  <div class="gridline" style={getGridlineStyle(gridline)} />
                {/each}
              </div>
            </div>
            <div class="note horizontal">
              {translate("asprova.terms.leadTime")}({translate(selectedTimeUnit.titleTranslationKey)})
            </div>
          </div>
        </div>

        <div class="dummy">
          <div class="names">
            {#each ordersData as order}
              {#if itemFlags[order.Order_Item]}
                <div class="ind" style="visibility: hidden;">
                  {order.Code}({order.Order_Item})
                </div>
              {/if}
            {/each}
          </div>
          <div class="orders" style="border: none;">
            <div class="gridlines">
              {#each gridlines as gridline}
                <div
                  class="gridline"
                  style={getGridlineStyle(gridline) + "border: none;"}
                >
                  <div class="number">
                    {floorToSecondDecimalPlace(gridline)}
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </div>
      {:else}
        <p>
          {translate("frontend:views.orderLeadTime.noOrderToDisplay")}
        </p>
      {/if}
    </div>
  </div>
{:else}
  <Loading/>
{/if}

<style>
  .wrapper {
    width: calc(100% - 50px);
    padding: 25px;
    display: flex;
  }

  .params {
    margin-right: 1em;
    width: max-content;
    min-width: 10em;
  }

  .timeSpan {
    padding-bottom: 1em;
    border-bottom: 2px solid #cdcdcd;
  }

  .itemCode {
    padding-top: 1em;
  }

  .params .title {
    font-weight: bold;
  }

  .item {
    margin: 1em 0 1em 1em;
    display: flex;
    align-items: center;
  }

  .radiobutton,
  .checkbox {
    margin-right: 0.5em;
    width: 24px;
    height: 24px;
    border-style: solid;
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
  }

  .radiobutton {
    position: relative;
    border-color: #cdcdcd;
    border-radius: 50%;
  }

  .radiobutton span {
    position: absolute;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    transition: 0.3s;
  }

  .checkbox {
    border-radius: 5px;
  }

  .others {
    width: 100%;
    border-top: 2px dotted #cdcdcd;
    min-height: 16em;
    max-height: calc(100vh - 440px);
    overflow-y: scroll;
  }

  .content {
    flex-grow: 1;
    max-height: calc(100vh - 200px);
    min-height: 30.5em;
    padding: 20px;
    padding-bottom: 2em;
    border-radius: 7px;
    border: 3px solid #cdcdcd;
    background-color: white;
    color: gray;
  }

  .graph,
  .dummy {
    position: relative;
    width: calc(100% - 2em);
    max-height: 100%;
    margin-left: 2em;
    font-size: 1em;
  }

  .dummy {
    height: 2em;
    min-height: 2em;
    display: flex;
    overflow: hidden;
    scrollbar-gutter: stable;
  }

  .note {
    position: absolute;
    font-size: 1.1em;
  }

  .vertical {
    top: calc(50% - 1.3em);
    height: 2em;
    transform: rotate(270deg) translateX(-50%);
    transform-origin: bottom left;
  }

  .horizontal {
    left: 50%;
    bottom: -2.8em;
  }

  .data {
    max-height: max(28.5em, calc(100vh - 230px));
    min-height: min(28.5em, 100%);
    overflow-y: scroll;
    display: flex;
  }

  .ind {
    position: relative;
    z-index: 1;
    height: 1.8em;
    display: flex;
    align-items: center;
    color: black;
  }

  .names {
    width: max-content;
    margin-right: 0.5em;
  }

  .orders {
    position: relative;
    flex-grow: 1;
    height: 100%;
    min-height: 15em;
    border-left: 2px solid #cdcdcd;
  }

  .gridlines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .gridline {
    position: absolute;
    top: 0;
    height: 100%;
    border-right: 2px dotted #cdcdcd;
  }

  .number {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(calc(50% + 0.1em), 0.2em);
  }

  .bar {
    height: 0.5em;
    background-color: black;
  }

  .time {
    margin-left: 0.2em;
  }
</style>
