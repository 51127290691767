<script>
  export let label = ""
  export let on = false
  export let onChange = null
  export let disabled = false;
</script>

<label id="root">
  {#if label}<span class="nowrap">{label}</span>{/if}
  <span class="switch">
    <input type="checkbox" bind:checked={on} on:change={onChange} disabled={disabled}>
    <span class="slider round"  style={disabled ? "display:none" : ""}></span>
  </span>
</label>

<style>
  #root {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 46px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #00468F;
  }

  input:checked + .slider:before {
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 26px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .nowrap {
    white-space: nowrap;
  }
</style>