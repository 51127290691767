<script>
  import { getContext, onMount } from "svelte"
  import GanttChartBox from "../../components/ganttchart/GanttChartBox.svelte"
  import { postStartEndData } from "../../utils/putData.js"
  import { usePageContext } from "../../components/pages/pageContext.js"
  import { FetchError, fetchResGanttProjectInfo, validatePath } from "../../utils/getData.js";
  import Loading from "../../components/pages/Loading.svelte"
  import { initGanttStore, printGanttStore } from "../../components/ganttchart/ganttStore/init.js";
  import { GanttChartTypes } from "../../components/ganttchart/ganttStore/enums.js";
  import { translate } from "../../i18n/i18next.js";

  export let projectId
  export let user

  const resource_url = `/api/projects/${projectId}/ResGantt/res/`;
  const transaction_url = `/api/projects/${projectId}/transactions`;
  let projectInfo = null;
  let styleInfo = null;
  //let resourceInfo = {};
  let ganttChart = null;

  let loaded_resources = {};

  // New GanttChart vars
  let projectData;
  let uploadTimeStamp;
  let downloadTimeStamp;
  let styleDict;
  let transactions;
  let readyToRender = false;
  let ganttChartType = GanttChartTypes.ResGantt;

  const {setHeaderProps} = usePageContext()
  // const helpLink = "/knowledge/docs/frontend/views/res-gantt"
  $: setHeaderProps({
    title: projectInfo?.Project[5] ? `${projectInfo.Project[5]} (${translate("mySchedule.views.resGantt.name")})`: translate("mySchedule.views.resGantt.name"),
    uploadTime: uploadTimeStamp,
  })

  const ganttStore = initGanttStore()

  function fetchTransactionData(timestamp) {
    console.log("timestamp: " + timestamp);
    let _trUrl = transaction_url;
    if (timestamp)
      _trUrl += "?timestamp=" + timestamp;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", _trUrl, false);
    xhr.send();

    if (xhr.status === 200) {
      const data = JSON.parse(xhr.responseText);

      return data;
    }
    else {
      console.error("Failed to fetch transactions");
      return null;
    }
  }

  async function registerTransaction(operations) {
    // console.log("operations: ");
    // console.log(operations);
    let data = {};
    operations.forEach((ope) => {
      data[ope._operation] = {
        "Work_ResultStartTime": ope._resultStart,
        "Work_ResultEndTime": ope._resultEnd,
        "Work_Status": ope._resStatus,
        "Work_ResultQty": ope._resultQty,
      };
    });

    return postStartEndData(projectId, JSON.stringify(data))
  }


  function fetchStyleInfo(styles) {
    const _styleDict = {};

    styles.forEach((style) => {
      const _styleUrl = `/api/projects/${projectId}/ResGantt/styles/${style.ObjectID}.json`;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", _styleUrl, false);
      xhr.send();

      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);

        _styleDict[style.StyleName] = data;
      }
      else {
        console.error("Failed to fetch style " + style);
      }

    });
    transactions = fetchTransactionData(downloadTimeStamp);
    styleDict = _styleDict;
    readyToRender = true;
  }

  const {handleFetchErrors} = getContext("fetchErrorHandler")

  onMount(async () => {
    try {
      const data = await fetchResGanttProjectInfo(projectId)
      projectInfo = data;
      projectData = projectInfo.Project;
      uploadTimeStamp = projectInfo.UploadTimeStamp;
      downloadTimeStamp = projectInfo.LastDownloadTimeStamp;
      fetchStyleInfo(projectData[7]);
    } catch (e) {
      console.error(e);
      if (e instanceof FetchError) {
        handleFetchErrors(e.error)
      }
    }
  });

  $: console.log(projectId, user.id)
  $: printGanttStore(ganttStore)
</script>

{#if readyToRender === true}
<GanttChartBox
  GanttStore={ganttStore}
  boxDimensions="height: calc(100% - 5px);"
  styleDict={styleDict}
  rowDataUrl={resource_url}
  transactionsUrl={transaction_url}
  uploadTimeStamp={uploadTimeStamp}
  downloadTimeStamp={downloadTimeStamp}
  projectData={projectData}
  transactions={transactions}
  registerTransaction={registerTransaction}
  projectId={projectId}
  userId={user.id}
  {ganttChartType}
/>
{/if}
{#if projectInfo == null}
   <Loading/>
{/if}

<style type="text/css">
  table tr#head {
    background-color: #bfc6cc;
    color: black;
    position: sticky;
    top: 100px;
  }
  table td#cell-head {
    background-color: #ebebeb;
    /* width: 20%; */
  }
  table {
    border: 1px solid #b5bdc3;
    border-collapse: collapse;
    table-layout: fixed;
    word-break: break-all;
  }
  td {
    border: 1px solid #d3d3d3;
    border-collapse: collapse;
    padding-left: 5px;
    line-height: 30px;
  }
  #main-table {
    width: 100%;
    text-align: center;
  }
  .red-button {
    background-color: red;
    color: white;
  }
  .red-button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
</style>
