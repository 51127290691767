<script>
    import GeneralInput from "../input/GeneralInput.svelte";
    import ToggleSwitch from "../input/ToggleSwitch.svelte";
    import { getResultGraphConfiguration, saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage"

    import { 
        syncEarlyTime, 
        syncOverTime, 
        syncEarlyTimeActive, 
        syncOverTimeActive,
        isAdmin
    } from "./ResultGraphStore";

    const syncMin = 0;
    const syncMax = 24;

    // Why is resultRowLength converted to String Obj through GeneralInput(DialogDisplaySection.svelte) ???
    $: syncEarlyTime.set(Number($syncEarlyTime));
    $: syncOverTime.set(Number($syncOverTime));

    $: {
        const configuration = {
            "syncEarlyTime": $syncEarlyTime,
            "syncOverTime": $syncOverTime,
        }
        saveResultGraphConfiguration(configuration, 'default', 'default');
    }

</script>

<div id="sync_border_group">
    <div id="sync_title">計画同調率</div>
    <div id="border_line" />
    <div id="sync_border_small_group">
        早作り
        <GeneralInput 
            type="number"
            bind:value={$syncEarlyTime}
            min={syncMin}
            max={syncMax}
        />
        時間以上
        {#if $isAdmin}
            <ToggleSwitch 
                label={$syncEarlyTimeActive ? "有効" : "無効"}
                bind:on={$syncEarlyTimeActive}
                onChange={saveResultGraphConfiguration({'syncEarlyTimeActive': $syncEarlyTimeActive}, 'default', 'default')};
            />
        {/if}
    </div>
    <div id="border_line" />
    <div id="sync_border_small_group">
        遅れ
        <GeneralInput 
            bind:value={$syncOverTime}
            type="number"
            min={syncMin}
            max={syncMax}
        />
        時間以上
        {#if $isAdmin}
            <ToggleSwitch 
                label={$syncOverTimeActive ? "有効" : "無効"}
                bind:on={$syncOverTimeActive}
                onChange={saveResultGraphConfiguration({'syncOverTimeActive': $syncOverTimeActive}, 'default', 'default')};
            />
        {/if}
    </div>
    <div id="border_line" />
</div>

<style>
    #sync_border_group{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 4px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    #sync_title{
        position: relative;
        font-weight: bold;
        font-size: 16px;
    }

    #sync_border_small_group{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    #border_line{
        width: 1px;
        height: 28px;
        top: calc((100% - 28px) / 2);
        background-color: #cccccc;
    }
</style>