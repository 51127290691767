import dayjs from "dayjs";
import i18next from "i18next";

const I18N_AllStrings = {
  // Login Page
  "ユーザ": {
    "en": "Username",
    "ja": "ユーザ"
  },
  "パスワード": {
    "en": "Password",
    "ja": "パスワード"
  },
  // Generic Header texts
  // System messages
  "UploadAgain": {
    "en": "Please upload again using newest-version plugin",
    "ja": "お手数ですが、最新版のプラグインを使ってもう一回アップロードしてください。"
  },
  "読み込み中...": {
    "en": "Loading...",
    "ja": "読み込み中..."
  },
  "404": {
    "en": "The requested resource was not found on this server.",
    "ja": "リクエストしたアドレスのページ（ファイル）が見つからない、またはそのサーバーが落ちている状態です。"
  },
  "500": {
    "en": "Internal server error.",
    "ja": "内部サーバエラー"
  },
  "ErrGeneric": {
    "en": "The server failed to fetch the requested resource.",
    "ja": "ページの読み込みに失敗しました"
  },
  "EmptyData": {
    "en": "The uploaded data is empty.",
    "ja": "アップロードされたデータは空です"
  },
  "NoOperations": {
    "en": "No operation found.",
    "ja": "作業が有りません"
  },
  // Generic components specific
  "グループ": {
    "en": "Group",
    "ja": "グループ"
  },
  "リソース": {
    "en": "Resource",
    "ja": "リソース"
  },
  "表示件数": {
    "en": "Displayed elements",
    "ja": "表示件数"
  },
  "完了作業を表示": {
    "en": "Display completed tasks",
    "ja": "完了作業を表示"
  },
  // Guest account specific
  "ResetPrompt": {
    "en": "Do you want to reset the results?\nIf yes please press the Reset button.",
    "ja": '本当に実績を初期化しますか？\n初期化する場合"Reset"と入力してください'
  },
  "ResetConfirm": {
    "en": "Results has been reset.",
    "ja": "実績を初期化しました"
  },
  "ResetDeny": {
    "en": "Cancelled, results has not been reset.",
    "ja": '入力された文字列が"Reset"ではありませんでした\n実績を初期化しませんでした'
  },
  "ResetProcess": {
    "en": "Results has been reset.",
    "ja": "実績をリセットします"
  },
  // Dispatching View Global
  "未指定": {
    "en": "Unspecified",
    "ja": "未指定"
  },
  "日付": {
    "en": "Date",
    "ja": "日付"
  },
  "資源コード": {
    "en": "Resource Code",
    "ja": "資源コード"
  },
  "資源グループ": {
    "en": "Resource Group",
    "ja": "資源グループ"
  },
  // Dispatching View Custom
  "担当者CD選択": {
    "en": "Person in charge selection",
    "ja": "担当者CD選択"
  },
  "リリーフする": {
    "en": "Relief",
    "ja": "リリーフする"
  },
  "リリーフ": {
    "en": "Relief",
    "ja": "リリーフ"
  },
  "担当者CD": {
    "en": "Person in charge",
    "ja": "担当者CD"
  },
  "すべての実績をリセット": {
    "en": "Reset results",
    "ja": "すべての実績をリセット"
  },
  // Operation Table Global
  "作業テーブル": {
    "en": "Operation Table",
    "ja": "作業テーブル"
  },
  // Integrated Master Global
  "製造BOM": {
    "en": "Integrated Master",
    "ja": "製造BOM"
  },
  "計測": {
    "en": "Measure",
    "ja": "計測"
  },
  // Integrated Master Evaluate Submenu
  "単位": {
    "en": "Unit",
    "ja": "単位"
  },
  "秒 (s)": {
    "en": "Seconds",
    "ja": "秒 (s)"
  },
  "分 (m)": {
    "en": "Minutes",
    "ja": "分 (m)"
  },
  "時間 (h)": {
    "en": "Hours",
    "ja": "時間 (h)"
  },
  "秒/1個 (sp)": {
    "en": "Seconds (per piece)",
    "ja": "秒/1個 (sp)"
  },
  "分/1個 (mp)": {
    "en": "Minutes (per piece)",
    "ja": "分/1個 (mp)"
  },
  "時間/1個 (hp)": {
    "en": "Hours (per piece)",
    "ja": "時間/1個 (hp)"
  },
  "個/1秒 (ps)": {
    "en": "Pieces (per second)",
    "ja": "個/1秒 (ps)"
  },
  "個/1分 (pm)": {
    "en": "Pieces (per minute)",
    "ja": "個/1分 (pm)"
  },
  "個/時間 (ph)": {
    "en": "Pieces (per hour)",
    "ja": "個/時間 (ph)"
  },
  "記録": {
    "en": "Recorded",
    "ja": "記録",
  },
  "反映する計測値の選択": {
    "en": "Value to apply",
    "ja": "反映する計測値の選択"
  },
  "平均値": {
    "en": "Mean value",
    "ja": "平均値"
  },
  "最大値": {
    "en": "Biggest value",
    "ja": "最大値"
  },
  "最小値": {
    "en": "Smallest value",
    "ja": "最小値"
  },
  "1値": {
    "en": "1st value",
    "ja": "計測1"
  },
  "2値": {
    "en": "2nd value",
    "ja": "計測2"
  },
  "3値": {
    "en": "3rd value",
    "ja": "計測3"
  },
  "計測時間": {
    "en": "Measured time",
    "ja": "計測時間"
  },
  "品目コード": {
    "en": "Item code",
    "ja": "品目コード"
  },
  "工程セレクタ": {
    "en": "Process Selector",
    "ja": "工程セレクタ"
  },
  "指図コード": {
    "en": "Instruction Code",
    "ja": "指図コード"
  },
  "工程コード:指図コード": {
    "en": "Process Code:Instruction Code",
    "ja": "工程コード:指図コード"
  },
  "品目名": {
    "en": "Item Name",
    "ja": "品目名"
  },
  "タスクセレクタ": {
    "en": "Task Selector",
    "ja": "タスクセレクタ"
  },
  "資源/品目コード": {
    "en": "Resource/Item Code",
    "ja": "資源/品目コード"
  },

  // Order Gantt Global
  "オーダガントチャート": {
    "en": "Order Gantt Chart",
    "ja": "オーダガントチャート"
  },
  "オーダ": {
    "en": "Order",
    "ja": "オーダ"
  },
  "作業": {
    "en": "Operation",
    "ja": "作業"
  },
  // Order Gantt Display Settings
  "オーダ表示数": {
    "en": "Order Count",
    "ja": "オーダ表示数"
  },
  "オーダ検索": {
    "en": "Order Search",
    "ja": "オーダ検索"
  },
  // Resource Gantt Global
  "資源ガントチャート": {
    "en": "Resource Gantt Chart",
    "ja": "資源ガントチャート"
  },
  "資源ガントチャート(シンプル版)": {
    "en": "Simple Resource Gantt Chart",
    "ja": "資源ガントチャート(シンプル版)"
  },
  "資源": {
    "en": "Resource",
    "ja": "資源"
  },
  // Resource Gantt Timeline
  "選択中の内容": {
    "en": "Selected Elements",
    "ja": "選択中の内容"
  },
  // Resource Gantt Display Settings
  "表示設定": {
    "en": "Display Settings",
    "ja": "表示設定"
  },
  "表示期間": {
    "en": "Displayed Timespan",
    "ja": "表示期間"
  },
  "日": {
    "en": " Day",
    "ja": "日"
  },
  "日s": {
    "en": " Days",
    "ja": "日"
  },
  "週間": {
    "en": " Week",
    "ja": "週間"
  },
  "週間s": {
    "en": " Weeks",
    "ja": "週間"
  },
  "ヵ月": {
    "en": " Month",
    "ja": "ヵ月"
  },
  "ヵ月s": {
    "en": " Months",
    "ja": "ヵ月"
  },
  "年間": {
    "en": " Year",
    "ja": "年間"
  },
  "表示時間帯": {
    "en": "Displayed Hours",
    "ja": "表示時間帯"
  },
  "資源表示数": {
    "en": "Resource Count",
    "ja": "資源表示数"
  },
  "資源検索": {
    "en": "Resource Search",
    "ja": "資源検索"
  },
  // Result input specific
  "開始": {
    "en": "Start",
    "ja": "開始"
  },
  "実績編集": {
    "en": "Result Edit",
    "ja": "実績編集"
  },
  "終了日時は開始日時より後の日時にご指定ください": {
    "en": "The end time should be later than start time",
    "ja": "終了日時は開始日時より後の日時にご指定ください"
  },
  "開始日時をご指定ください": {
    "en": "Please set the start time",
    "ja": "開始日時をご指定ください"
  },
  "終了日時をご指定ください": {
    "en": "Please set the end time",
    "ja": "終了日時をご指定ください"
  },
  "実績を編集しました": {
    "en": "The results are edited",
    "ja": "実績を編集しました"
  },
  "終了": {
    "en": "End",
    "ja": "終了"
  },
  "取消す": {
    "en": "Cancel",
    "ja": "取消す"
  },
  "ステータス": {
    "en": "Status",
    "ja": "ステータス"
  },
  "実績開始時間": {
    "en": "Result Start Time",
    "ja": "実績開始時間"
  },
  "実績終了時間": {
    "en": "Result End Time",
    "ja": "実績終了時間"
  },
  "クリア": {
    "en": "Clear",
    "ja": "クリア"
  },
  "年": {
    "en": "",
    "ja": "年"
  },
  "月": {
    "en": "",
    "ja": "月"
  },
  "時": {
    "en": "",
    "ja": "時"
  },
  "分": {
    "en": "",
    "ja": "分"
  },
  "秒": {
    "en": "",
    "ja": "秒"
  },
  "STATUS_COMPLETE": {
    "en": "Complete",
    "ja": "完了"
  },
  "STATUS_STARTED": {
    "en": "Started",
    "ja": "着手済み"
  },
  "STATUS_PLANNED": {
    "en": "Scheduled",
    "ja": "計画済み"
  },
  "STATUS_RELEASED": {
    "en": "Released",
    "ja": "指示済み"
  },
  "STATUS_CONFIRMED": {
    "en": "Frozen",
    "ja": "確定"
  },
  "STATUS_UNSET": {
    "en": "Unscheduled",
    "ja": "未計画"
  },


  "反映": {
    "en": "Apply",
    "ja": "反映"
  },
  "キャンセル": {
    "en": "Cancel",
    "ja": "キャンセル"
  },
  "検索": {
    "en": "Search",
    "ja": "検索"
  },
  "更新": {
    "en": "Update",
    "ja": "更新"
  },
  "最早開始日時": {
    "en": "EST",
    "ja": "最早開始日時"
  },
  "納期": {
    "en": "LET",
    "ja": "納期"
  },
  "プロジェクトの詳細設定": {
    "en": "Advanced project settings",
    "ja": "プロジェクトの詳細設定",
  },
  "スタイル": {
    "en": "Style",
    "ja": "スタイル"
  }
};

const I18N_GetString = (key) => {
  const language = i18next.language

  if (I18N_AllStrings[key] === undefined) {
    console.error("KEY NOT FOUND: ", key);
    return "?";
  }
  return I18N_AllStrings[key][language];
};

const I18N_ResourceGanttTimelineFormatDate = (start, end) => {
  const language = i18next.language

  const formatDate = {
    "ja": (dateString) => dayjs(dateString).format("M月D日(ddd) H時mm分"),
    "en": (dateString) => dayjs(dateString).format("M/D(ddd) H:mm"),
    "zh": (dateString) => dayjs(dateString).format("M月D日(ddd) H时mm分"),
  }
  return (formatDate[language](start) + " - " + formatDate[language](end));
}

const I18N_ProcessHeaderDate = (date, unit) => {
  switch (unit) {
    case "曜日":
      return dayjs(date).format("ddd")
    case "月":
      return dayjs(date).format("MMM")
    case "年":
      return dayjs(date).format("YYYY")
    default:
      break;
  }
}

export { I18N_GetString, I18N_ResourceGanttTimelineFormatDate, I18N_ProcessHeaderDate };
