<script>
  import ServerAdminChip from "../ServerAdminChip.svelte"
  import ProjectUserProjectAdminChip from "../ProjectUserProjectAdminChip.svelte"
  import ProjectUserResultInputChip from "../ProjectUserResultInputChip.svelte"
  import ProjectUserReadOnlyChip from "../ProjectUserReadOnlyChip.svelte"

  /**
   * @type {"ServerAdmin"|"ProjectAdmin"|"ResultInput"|"ReadOnly"|null|undefined}
   */
  export let permission
</script>

{#if permission}
  {#if permission === "ServerAdmin"}
    <ServerAdminChip />
  {/if}
  {#if permission === "ProjectAdmin"}
    <ProjectUserProjectAdminChip />
  {/if}
  {#if permission === "ResultInput"}
    <ProjectUserResultInputChip />
  {/if}
  {#if permission === "ReadOnly"}
    <ProjectUserReadOnlyChip />
  {/if}
{/if}