import {useQuery} from "@sveltestack/svelte-query"
import {derived} from "svelte/store"
import {FetchError} from "../utils/getData.js"

export function useProject(projectId, fetchErrorHandler) {
  if (!projectId) return null

  const queryResult = useQuery(`/api/projects/${projectId}`, {
    onError: err => {
      console.log(err)
      if (err instanceof FetchError) {
        fetchErrorHandler(err.error)
      }
    },
    retry: false,
  })
  return derived(queryResult, values => {
    return values.data ?? null
  })
}
