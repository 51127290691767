import {satisfies} from "compare-versions";
import CompatibilityTable from "./compat.json";

/**
 * Compare the two versions and return compatibility as far as the frontend is concerned.
 * @param {string} frontendVersion
 * @param {string} pluginVersion
 * @param {string} viewName
 * @returns {{isCompatible: boolean, pluginVersionRequirement: string}}
 */
function isCompatible(frontendVersion, pluginVersion, viewName) {
  // find the matching requirement
  let pluginVersionRequirement = ""
  for (const frontendVersionRange in CompatibilityTable[viewName]) {
    if (satisfies(frontendVersion, frontendVersionRange)) {
      pluginVersionRequirement = CompatibilityTable[viewName][frontendVersionRange]
    }
  }

  // if no requirement is found, assume there is no requirement and therefore they are compatible
  if (!pluginVersionRequirement) return {isCompatible: true, pluginVersionRequirement}

  return {isCompatible: satisfies(pluginVersion, pluginVersionRequirement), pluginVersionRequirement}
}

const thisFrontendVersion = "__MY_SCHEDULE_VERSION__"

/**
 *
 * @param {string} pluginVersion
 * @param {string} viewName
 * @returns {{isCompatible: boolean, thisFrontendVersion: string, pluginVersionRequirement: string}}
 */
export function checkCompatibilityWithThisFrontend(pluginVersion, viewName) {
  const result = isCompatible(thisFrontendVersion, pluginVersion, viewName)

  return {
    isCompatible: result.isCompatible,
    thisFrontendVersion,
    pluginVersionRequirement: result.pluginVersionRequirement
  }
}
