<script>
  import ViewCardBase from "./ViewCardBase.svelte";
  import {translate} from "../../../i18n/i18next.js";
  import HelpTooltip from "../../tooltip/HelpTooltip.svelte";

  export let onClick
</script>

<ViewCardBase
  viewIconSrc="/assets/icons/icon_order_leadtime_l.png"
  onClick={onClick}
>
  <span>{translate("mySchedule.views.orderLeadTime.name")}</span>
  <HelpTooltip title={translate("mySchedule.views.orderLeadTime.name")}>
    {translate("frontend:help.views.orderLeadTime.description")}
  </HelpTooltip>
</ViewCardBase>
