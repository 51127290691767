<script>
  import { useLocation, Link } from "svelte-navigator";
  import { SUPPORTED_LANGUAGES } from "./languages.js";

  export let href

  const location = useLocation()

  let link = href
  $: {
    const pathname = $location.pathname

    let prefixCurrent = null
    let prefixDest = null
    // determine if the current path and the destination are language-prefixed
    for (const language of SUPPORTED_LANGUAGES) {
      if (pathname.startsWith(`/${language}`)) {
        prefixCurrent = language
      }
      if (href.startsWith(`/${language}`)) {
        prefixDest = language
      }
    }
    if (prefixCurrent && !prefixDest) link = `/${prefixCurrent}` + href
    else link = href
  }
</script>

<Link to={link}>
  <slot />
</Link>
