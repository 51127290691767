<script>
  import MyLink from "../../i18n/MyLink.svelte";
  import {translate} from "../../i18n/i18next.js";

  export let title
  export let linkHref = null

  const tooltipWidth = 300  // in px

  let buttonEl = null
  let tooltipEl = null
  let openedByClick = false

  function positionPopover() {
    if (!buttonEl || !tooltipEl) return

    const buttonElPos = buttonEl.getBoundingClientRect()

    tooltipEl.style.left = buttonElPos.left - (Math.max(buttonElPos.left + tooltipWidth - window.innerWidth + 20, 20)) + "px"
    const top = buttonElPos.top + 20
    if (top + tooltipEl.clientHeight > window.innerHeight) {
      tooltipEl.style.top = buttonElPos.top - tooltipEl.clientHeight - 20 + "px"
    } else {
      tooltipEl.style.top = top + "px"
    }
  }

  function onClickButton(e) {
    e.preventDefault()
    e.stopPropagation()
    if (openedByClick) {
      tooltipEl?.hidePopover()
    } else {
      openedByClick = true
      tooltipEl?.showPopover()
      positionPopover()
    }
  }
  function onMouseenterButton(e) {
    e.preventDefault()
    if (!tooltipEl) return
    if (!tooltipEl.matches(":popover-open")) {
      tooltipEl.showPopover()
      openedByClick = false
      positionPopover()
    }
  }
  function onMouseleaveButton(e) {
    e.preventDefault()
    if (!openedByClick) {
     tooltipEl?.hidePopover()
    }
  }
</script>

<div class="doc-tooltip-root">
  <button
    bind:this={buttonEl}
    class="doc-tooltip-button"
    on:click={onClickButton}
    on:mouseenter={onMouseenterButton}
    on:mouseleave={onMouseleaveButton}
  >
    <img src="/assets/icons/icon_help.svg" alt="help icon"/>
  </button>
  <div class="doc-tooltip" bind:this={tooltipEl} popover="auto" style="--tooltip-width: {tooltipWidth}px">
    <div class="doc-tooltip-title">{title}</div>
    <div class="doc-tooltip-caption">
      <slot />
    </div>
    {#if linkHref}
      <div class="doc-tooltip-link-container">
        <MyLink href={linkHref}>{translate("frontend:components.docTooltip.goToDetails")}</MyLink>
      </div>
    {/if}
  </div>
</div>

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .doc-tooltip-root {
    display: inline;
    position: relative;
    height: 16px;
  }
  .doc-tooltip {
    inset: unset;
    padding: 1rem;
    background-color: white;
    width: var(--tooltip-width);
    min-height: 200px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 18px rgba(0,0,0,.4);
    font-size: .9rem;
    text-align: left;
  }
  .doc-tooltip-title {
    font-weight: bold;
    padding: .2rem 0;
  }
  .doc-tooltip-caption {
    font-weight: normal;
  }
  .doc-tooltip-link-container {
    display: inline-flex;
    justify-content: end;
  }
</style>