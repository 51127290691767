<script>
  import { translate } from "../../i18n/i18next.js";

  export let value
  export let options = []
  export let name
</script>

<div class="radio-group-container">
  {#each options as option}
    <label>
      <input
        class:active={option.value === value}
        checked={option.value === value}
        value={option.value}
        bind:group={value}
        type="radio"
        name={name}
      />
      {#if option.translationKey}
        {translate(option.translationKey)}
      {:else}
        {option.text}
      {/if}
    </label>
  {/each}
</div>

<style>
  .radio-group-container {
    font-size: 20px;
    font-weight: bold;
    color: #00468f;
  }
  .radio-group-container input[type=radio] {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    border: #cccccc;
    color: #00468f;
  }
</style>