import {useQuery} from "@sveltestack/svelte-query"
import {derived} from "svelte/store"

/**
 *
 * @returns {Readable<{
 *   maxStandardPlanProjects: number,
 *   maxViewerPlanProjects: number,
 * }|undefined>}
 */
export function useLicenseData() {
  const queryResult = useQuery("/api/license")
  return derived(queryResult, values => {
    return values.data
  })
}