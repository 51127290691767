<script>
  import {useParams} from "svelte-navigator"
  import PageBase from "../../PageBase.svelte"
  import {useMe} from "../../../../hooks/useMe.js";
  import {useMyProjectPermission} from "../../../../hooks/useMyProjectPermission.js";
  import Loading from "../../Loading.svelte";

  export let parentView = null

  const params = useParams();
  const projectId = $params.project
  const code = $params.code

  const me = useMe()
  const {permission} = useMyProjectPermission(projectId)

  let backTo
  if (parentView) {
    backTo = `/projects/${projectId}/${parentView}`
  } else {
    backTo = `/projects/${projectId}`
  }
</script>

<PageBase backTo={backTo}>
  <!-- We need to discern undefined (loading) from null (no permission). -->
  {#if $me && $permission !== undefined}
    <slot code={code} projectId={projectId} user={$me} permission={$permission} />
  {:else}
    <Loading/>
  {/if}
</PageBase>