<script>
  import SettingsTypes from "./SettingsTypes.js";
  import GeneralSelect from "../select/GeneralSelect.svelte";
  import GeneralInput from "../input/GeneralInput.svelte";
  import { translate } from "../../i18n/i18next.js";
  import FilterInput from "../input/FilterInput.svelte";
  import { onMount } from "svelte";
  import GeneralButton from "../button/GeneralButton.svelte";
  import ToggleSwitch from "../input/ToggleSwitch.svelte";
  import FilterDialog from "../filter/FilterDialog.svelte";
  import { buildAdvancedFilterObj } from "../../utils/filter.js";

  export let Section;
  export let GanttStore

  let filterInput = null
  const {RowSearchPattern} = GanttStore
  RowSearchPattern.subscribe(val => {
    if (filterInput) {
      filterInput.setQuery(val)
    }
  })

  let filterDialog = null
  let filterDialogThis = null
  function openFilterDialog() {
    filterDialog?.showModal()
  }

  RowSearchPattern.subscribe(val => {
    if (filterDialogThis) {
      filterDialogThis.setInternalStates(buildAdvancedFilterObj(val))
    }
  })

  onMount(() => {
    if (filterInput) {
      filterInput.setQuery($RowSearchPattern)
    }
  })

  $: console.log($RowSearchPattern)
</script>

{#if Section !== undefined}
  <div class="settingsMenu_categories_section">
    <div class="settingsMenu_categories_section_header">
      {translate(Section.SectionLabel.translationKey)}
    </div>
    {#each Section.SectionEntries as entry}
      <div
        class={"settingsMenu_categories_section_entryTMP"}
        class:settingsMenu_categories_section_category={entry.Type === SettingsTypes.ESettingsEntryType.CATEGORY}
      >
        {#if entry.LabelTranslationKey}
          {translate(entry.LabelTranslationKey)}
        {:else if entry.Label}
          {entry.Label}
        {/if}
      </div>

      {#if entry.Type === SettingsTypes.ESettingsEntryType.INPUT}
        {#if entry.InputType === SettingsTypes.ESettingsInputType.SELECT}
          <GeneralSelect
            options={entry.SelectOptions}
            onChangeEvent={entry.OnChangeFunction}
            value={entry.DefaultValue}
            extraStyle="width: 100%"
          />
        {/if}
        {#if entry.InputType === SettingsTypes.ESettingsInputType.TEXT}
          <GeneralInput
            bind:value={entry.DefaultValue}
            onInput={entry.OnInputFunction}
            onChange={entry.OnChangeFunction}
            extraStyle="width: 100%"
          />
        {/if}
        {#if entry.InputType === SettingsTypes.ESettingsInputType.NUMBER}
          <GeneralInput
            type="number"
            min={entry.MinValue}
            max={entry.MaxValue}
            bind:value={entry.DefaultValue}
            onInput={entry.OnInputFunction}
            onChange={entry.OnChangeFunction}
            extraStyle="width: 100%"
          />
        {/if}
        {#if entry.InputType === SettingsTypes.ESettingsInputType.TIME}
          <GeneralInput
            type="time"
            min={entry.MinValue}
            max={entry.MaxValue}
            bind:value={entry.DefaultValue}
            onInput={entry.OnInputFunction}
            onChange={entry.OnChangeFunction}
            extraStyle="width: 100%"
          />
        {/if}
        {#if entry.InputType === SettingsTypes.ESettingsInputType.DATE}
          <input
            type="date"
            min={entry.MinValue}
            max={entry.MaxValue}
            value={entry.DefaultValue}
            on:input={entry.OnInputFunction}
            on:change={entry.OnChangeFunction}
          />
        {/if}
        {#if entry.InputType === SettingsTypes.ESettingsInputType.FILTER}
          <FilterInput
            bind:this={filterInput}
            choices={entry.Choices}
            onQueryDetermined={entry.OnQueryDeterminedFunction}
            openAdvancedFilterDialog={openFilterDialog}
          />
          <FilterDialog
            bind:this={filterDialogThis}
            filterTarget={translate(entry.LabelTranslationKey)}
            initialFilterObj={buildAdvancedFilterObj($RowSearchPattern)}
            bind:dialog={filterDialog}
            choices={entry.Choices}
            onFilterDetermined={conditions => {entry.OnAdvancedFilterDeterminedFunction(conditions); filterDialog.close()}}
            onClearFilter={() => {entry.OnClearFilterFunction(); filterDialog.close()}}
          />
        {/if}
      {/if}
      {#if entry.Type === SettingsTypes.ESettingsEntryType.BUTTON}
        <GeneralButton
          buttonType="negative"
          buttonText={translate(entry.ButtonTextTranslationKey)}
          buttonFunction={entry.OnClick}
          buttonExtraStyle="margin: 0; width: 100%"
        />
      {/if}
      {#if entry.Type === SettingsTypes.ESettingsEntryType.TOGGLE}
        <ToggleSwitch
          label=""
          on={entry.DefaultValue}
          onChange={entry.OnChange}
        />
      {/if}
    {/each}
  </div>
{/if}

<style>
  div.settingsMenu_categories_section {
    padding: 5%;
  }

  div.settingsMenu_categories_section_header {
    color: #aaaaaa;
    font-size: 0.9em;
  }
  div.settingsMenu_categories_section_entryTMP {
    padding-top: 0.5em;
  }
  div.settingsMenu_categories_section_category:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
</style>
