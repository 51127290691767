<script>
  export let Row;
  export let Color;
</script>


<div
  class="gantt_timelinearea_timelinesplit"
>
  <div class="gantt_timelinearea_timelinesplit_text" style="--color:{Color};">{Row}</div>
</div>

<style>

div.gantt_timelinearea_timelinesplit {
    width: 90%;
    padding-left: 5%;
}
div.gantt_timelinearea_timelinesplit_text {
    display: flex;
    align-items: center;
    font-size: 14px;
}
div.gantt_timelinearea_timelinesplit_text::before {
    content: "";
    width: 16px;
    height: 3px;
    background-color: var(--color);
    display: block;
    margin-right: 0.5em;
}
div.gantt_timelinearea_timelinesplit_text::after {
    content: "";
    flex-grow: 1;
    height: 3px;
    background-color: var(--color);
    display: block;
    margin-left: 0.5em;
}

</style>
