<script>
  import StandardPlanChip from "../chips/StandardPlanChip.svelte"
  import FreePlanChip from "../chips/FreePlanChip.svelte"
  import EditProjectDialog from "./EditProjectDialog.svelte"
  import { getNotificationsContext } from "svelte-notifications"
  import { useQueryClient } from "@sveltestack/svelte-query"
  import DeleteConfirmationDialog from "../DeleteConfirmationDialog.svelte"
  import PrimaryBorderedButton from "../button/generic/PrimaryBorderedButton.svelte"
  import DangerBorderedButton from "../button/generic/DangerBorderedButton.svelte"
  import ViewerPlanChip from "../chips/ViewerPlanChip.svelte";
  import { translate } from "../../i18n/i18next.js";
  import MyLink from "../../i18n/MyLink.svelte";
  import { tick } from "svelte";

  export let project

  const {addNotification} = getNotificationsContext()
  const queryClient = useQueryClient()

  let editProjectDialog
  let isEditProjectDialogOpen = false
  async function handleEditProject() {
    isEditProjectDialogOpen = true
    await tick()
    editProjectDialog.showModal()
  }
  async function handleEditProjectSubmission(values) {
    const response = await fetch(`/api/projects/${project.id}`, {
      method: "PUT",
      body: JSON.stringify({
        name: values.name,
        description: values.description,
        timezone: values.timezone,
        plan: values.plan,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      addNotification({
        position: "top-right",
        type: "success",
        removeAfter: 4000,
        text: translate("frontend:generic.notifications.saved")
      })
      await queryClient.invalidateQueries("/api/projects")
      await queryClient.invalidateQueries(`/api/projects/${project.id}`)
      editProjectDialog.close()
    } else {
      const responseData = await response.json()
      if (responseData.type === "error:license:max-standard-plan-projects-exceeded") {
        addNotification({
          position: "top-right",
          type: "error",
          text: translate("errors.maxStandardProjectsExceededError"),
          removeAfter: 4000,
        })
      } else if (responseData.type === "error:license:max-viewer-plan-projects-exceeded") {
        addNotification({
          position: "top-right",
          type: "error",
          text: translate("errors.maxViewerProjectsExceededError"),
          removeAfter: 4000,
        })
      } else {
        addNotification({
          position: "top-right",
          type: "error",
          text: translate("frontend:generic.notifications.saveFailed"),
          removeAfter: 4000,
        })
      }
    }
  }

  let deleteConfirmationDialog
  let isDeleteConfirmationDialogOpen
  async function handleDeleteProject() {
    isDeleteConfirmationDialogOpen = true
    await tick()
    deleteConfirmationDialog.showModal()
  }
  async function handleConfirmedDeletion(){
    const response = await fetch(`/api/projects/${project.id}`, {
      method: "DELETE",
    })
    if (response.ok) {
      addNotification({
        position: "top-right",
        type: "success",
        removeAfter: 4000,
        text: translate("generic.removed")
      })
      await queryClient.invalidateQueries("/api/projects")
      await queryClient.invalidateQueries(`/api/projects/${project.id}`)
      deleteConfirmationDialog.close()
    } else {
      addNotification({
        position: "top-right",
        type: "error",
        text: translate("generic.removeFailed"),
        removeAfter: 4000,
      })
    }
  }
</script>

<div>
  <div class="project-container">
    <div class="project-main">
      <div class="title-container">
        <div class="project-name">
          <MyLink href="/projects/{project.id}">
            {project.name}
          </MyLink>
        </div>
        {#if project.plan === "standard"}<StandardPlanChip />{/if}
        {#if project.plan === "viewer"}<ViewerPlanChip />{/if}
        {#if project.plan === "free"}<FreePlanChip />{/if}
      </div>
      <div class="info-container">
        <div class="info-id">
          id: {project.id}
        </div>
      </div>
      <div class="description">
        {project.description || translate("frontend:pages.admin.projects.noProjectDescription")}
      </div>
    </div>
    {#if isEditProjectDialogOpen}
      <EditProjectDialog
        bind:dialog={editProjectDialog}
        project={project}
        type="edit"
        onClose={() => {editProjectDialog.close(); isEditProjectDialogOpen = false}}
        onSubmit={handleEditProjectSubmission}
      />
    {/if}
    {#if isDeleteConfirmationDialogOpen}
      <DeleteConfirmationDialog
        target={`${translate("generic.project")} ${project.name} (id: ${project.id})`}
        bind:dialog={deleteConfirmationDialog}
        onClose={() => {deleteConfirmationDialog.close(); isDeleteConfirmationDialogOpen = false}}
        onConfirmed={handleConfirmedDeletion}
      />
    {/if}
    <div class="project-actions-container">
      <PrimaryBorderedButton onClick={handleEditProject}>
        {translate("generic.edit")}
      </PrimaryBorderedButton>
      <DangerBorderedButton onClick={handleDeleteProject}>
        {translate("generic.remove")}
      </DangerBorderedButton>
    </div>
  </div>
</div>

<style>
  .project-container {
    padding: 1rem 0;
    border-bottom: 1px solid lightgrey;
    display: flex;
    gap: 1rem;
    max-width: 100%;
  }
  .project-container:is(:first-child) {
    border-top: 1px solid lightgrey;
  }
  .project-main {
    flex-grow: 1;
  }
  .project-actions-container {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .project-name {
    font-size: 1.1rem;
    overflow-wrap: anywhere;
  }
  .info-container {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.9rem;
    color: darkgray;
  }
</style>
