<script>
  export let buttonValue = null;
  export let buttonFunction;
  export let buttonText = "";
  export let buttonExtraStyle = "";
  export let buttonDisabled = false;
  export let buttonType = "primary"; // secondary or negative
</script>

<button
  class={buttonType}
  on:click={buttonFunction}
  value={buttonValue}
  style={buttonExtraStyle}
  disabled={buttonDisabled}
>
  {#if buttonText}
    {buttonText}
  {:else}
    <slot />
  {/if}
</button>

<style>
  .primary {
    background-color: #00468f;
    border: none;
    border-radius: 0.6em;
    padding: 0.4em 1.2em;
    font-size: 1rem;
    color: white;
    margin: 2px 10px;
  }

  .primary:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }

  .primary:active {
    filter: brightness(0.75);
    cursor: pointer;
  }
  .secondary {
    background-color: white;
    border: solid 2px #00468f;
    border-radius: 0.6em;
    padding: 0.27em 1.2em;
    font-size: 1rem;
    color: #00468f;
    margin: 2px 10px;
  }

  .secondary:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }

  .secondary:active {
    filter: brightness(0.75);
    cursor: pointer;
  }

  .negative {
    background-color: #de5555;
    border: none;
    border-radius: 0.6em;
    padding: 0.4em 1.2em;
    font-size: 1rem;
    color: white;
    margin: 2px 10px;
  }

  .negative:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }

  .negative:active {
    filter: brightness(0.75);
    cursor: pointer;
  }
  .primary:disabled {
    opacity: 0.3;
    background-color: #00468f;
    color: white;
    cursor: default;
  }
  .secondary:disabled {
    opacity: 0.3;
    background-color: white;
    cursor: default;
  }

  .negative:disabled {
    opacity: 0.3;
    background-color: #de5555;
    color: white;
    cursor: default;
  }
</style>
