<script>
  import ProjectUserProjectAdminChip from "../chips/ProjectUserProjectAdminChip.svelte"
  import ProjectUserReadOnlyChip from "../chips/ProjectUserReadOnlyChip.svelte"
  import ProjectUserResultInputChip from "../chips/ProjectUserResultInputChip.svelte"

  export let projectUser
</script>

<div id="root">
  <div class="user-container">
    <div class="user-main">
      <div class="title-container">
        <div class="user-name">{projectUser.name}</div>
        {#if projectUser.permission === "ProjectAdmin"}<ProjectUserProjectAdminChip />{/if}
        {#if projectUser.permission === "ResultInput"}<ProjectUserResultInputChip />{/if}
        {#if projectUser.permission === "ReadOnly"}<ProjectUserReadOnlyChip />{/if}
      </div>
      <div class="info-container">
        <div class="info-id">
          id: {projectUser.id}
        </div>
      </div>
    </div>
    <div class="user-actions-container">
      <slot />
    </div>
  </div>
</div>

<style>
  #root {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .user-container {
    padding: 1rem 0;
    border-bottom: 1px solid lightgrey;
    flex-grow: 1;
    display: flex;
  }
  .user-container:is(:first-child) {
    border-top: 1px solid lightgrey;
  }
  .user-main {
    flex-grow: 1;
  }
  .user-actions-container {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .user-name {
    font-size: 1.1rem;
  }
  .info-container {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.9rem;
    color: darkgray;
  }
</style>
