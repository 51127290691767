<script>
  import {translate} from "../../../i18n/i18next.js";
  import { useQuery } from "@sveltestack/svelte-query";
  import { derived } from "svelte/store";

  const query = useQuery("/api/license/ccu")

  const currentCcu = derived(query, (query) => {
    return query.data?.paid?.currentCCUs ?? "-"
  })
  const maxCcu = derived(query, query => {
    return query.data?.paid?.maxCCUs ?? "-"
  })
</script>

<div class="ccu-info-root">
  <div class="ccu-info">
    {translate("frontend:components.userCcuInfo.currentCcu", null, {count: $currentCcu})}
  </div>
  <div class="ccu-info">
    {translate("frontend:components.userCcuInfo.maxCcu", null, {count: $maxCcu})}
  </div>
</div>

<style>
  .ccu-info-root {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .ccu-info {
    color: lightgrey;
    white-space: nowrap;
  }
</style>