export const ESettingsEntryType = {
  INPUT: 1,
  CATEGORY: 2,
  BUTTON: 3,
  TOGGLE: 4
};

export const ESettingsInputType = {
  TEXT: 1,
  NUMBER: 2,
  SELECT: 3,
  TIME: 4,
  DATE: 5,
  FILTER: 6
};

export default {
  ESettingsEntryType,
  ESettingsInputType,
};
