import {useQuery} from "@sveltestack/svelte-query"
import {FetchError, fetchResource} from "../../../../utils/getData.js"

/**
 * @param {string} projectId
 * @param {(error: FetchError) => void} fetchErrorHandler
 * @param {false|number} refetchInterval
 */
export function useDispatchingViewResourcesQuery(projectId, fetchErrorHandler, refetchInterval = false) {
  return useQuery(
    `/api/projects/${projectId}/DispatchingView/res.json`,
    () => fetchResource(projectId),
    {
      onError: err => {
        console.log(err)
        if (err instanceof FetchError) {
          fetchErrorHandler(err.error)
        }
      },
      retry: false,
      refetchInterval,
    }
  )
}