import dayjs from "dayjs";

export function getFormattedOffsetDate(date, formatString, offsetToUtc = null) {
  const offset = offsetToUtc + date.getTimezoneOffset() * 60
  return dayjs(date).add(offset, "seconds").format(formatString)
}

/**
 * This function converts Date object into string, in format required by the integratedMaster transactions
 *   timezone has to be null or one of the timezones defined by the IANA. See https://ftp.iana.org/tz/tzdb-2020f/zone1970.tab
 *   if timezone is null, no conversion is performed.
 * @param {Date} date  // this date is assumed to be local time
 * @param {string | null} timezone
 * @param {((timezone: string) => number)|null} getOffset
 * @returns {string}
 */
function getTimeString(date = new Date(), timezone = null, getOffset = null) {
  const formatString = "YYYY/MM/DD"
  if (!timezone) return dayjs(date).format(formatString)
  if (timezone && !getOffset) throw new Error(`getOffset() must be provided if timezone (${timezone}) is given.`)
  return getFormattedOffsetDate(date, formatString, getOffset(timezone))
}

/**
 * This function converts Date object into string, in format required by the transactions
 *   timezone has to be null or one of the timezones defined by the IANA. See https://ftp.iana.org/tz/tzdb-2020f/zone1970.tab
 *   if timezone is null, no conversion is performed.
 * @param {Date} date  // this date is assumed to be local time
 * @param {string | null} timezone
 * @param {((timezone: string) => number)|null} getOffset
 * @returns {string}
 */
function getTimeStringSec(date = new Date(), timezone = null, getOffset = null) {
  const formatString = "YYYY/MM/DD HH:mm:ss"
  if (!timezone) return dayjs(date).format(formatString)
  if (timezone && !getOffset) throw new Error(`getOffset() must be provided if timezone (${timezone}) is given.`)
  return getFormattedOffsetDate(date, formatString, getOffset(timezone))
}

function roundToNearestTime(date, choicesPerDay = 4, backForward = false) {
  const times = Array.from(
    { length: choicesPerDay },
    (_, i) => (24 / choicesPerDay) * i
  );
  times.push(24);

  const h = date.getHours();
  let nearestTime;

  if (backForward) {
    nearestTime = times
      .filter((time) => time <= h)
      .reduce(
        (prev, curr) => (Math.abs(curr - h) < Math.abs(prev - h) ? curr : prev),
        -Infinity
      );
  } else {
    nearestTime = times
      .filter((time) => time >= h)
      .reduce(
        (prev, curr) => (Math.abs(curr - h) < Math.abs(prev - h) ? curr : prev),
        Infinity
      );
  }

  if (nearestTime != 24)
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      nearestTime,
      0,
      0
    );

  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 1);

  return new Date(
    nextDay.getFullYear(),
    nextDay.getMonth(),
    nextDay.getDate(),
    0,
    0,
    0
  );
}

export { getTimeString, getTimeStringSec, roundToNearestTime };
