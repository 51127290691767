<script>
    import BadgeFilterSpace from "./BadgeFilterSpace.svelte";
    import { saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage";
    import {
        resultStartRow, 
        resultRowLength, 
        resultRowTotal,
        resultRowLimit,
        RowDisplay,
        RawData,
        filteredResCode
    } from "./ResultGraphStore"

    export let width = 150;
    let column = "資源コード";

    const RowUp = (e) => {
        e.preventDefault();
        resultStartRow.update((n) => (n > 0 ? n - 1 : n));
    };

    const RowDown = (e) => {
        e.preventDefault();
        resultStartRow.update((n) => (n + 1 >= $resultRowTotal ? n : n + 1));
    };

    const ScrollRows = (e) => {
        e.preventDefault();

        if(e.ctrlKey){
            if (e.deltaY > 0) {
                resultRowLength.update((n) => (n + 1 > $resultRowLimit ? n : n + 1));
            }
            else if (e.deltaY < 0) {
                resultRowLength.update((n) => (n > 1 ? n - 1 : n));
            }
            saveResultGraphConfiguration({'resultRowLength': $resultRowLength}, 'default', 'default')
        }
        else{
            if (e.deltaY > 0) RowDown(e);
            else if (e.deltaY < 0) RowUp(e);
            saveResultGraphConfiguration({'resultStartRow': $resultStartRow}, 'default', 'default')
        }
    };
</script>


<div id="result_tablearea" style="--table-width: {width}px;" on:wheel={ScrollRows}>
    <BadgeFilterSpace filterItem={$filteredResCode}/>

    <div id="result_tablearea_headcolumns">
        <span id="result_tablearea_header">{column}</span>
    </div>

    <div id="main_area">
        {#each $RowDisplay as row, index}
            {#if index < $resultRowLength}
            <div id="item_cell" style="height: {100 / $resultRowLength}%;">{$RawData[row].Res_Code}</div>
            {/if}
        {/each}
    </div>

    <div id="result_tablearea_footerbuttons" />
</div>


<style>
    #result_tablearea{
        height: 100%;
        width: var(--table-width);
        display: flex;
        flex-direction: column;
        overflow: none;
        border: 2px solid #888888;
        background-color: white;
    }

    /* header */
    #result_tablearea_headcolumns{
        width: 100%;
        height: 80px; 
        display: flex;
        flex-direction: column;
        overflow: none;
        text-align: center;
        border-bottom: 2px solid #888888;
    }

    #result_tablearea_header{
        height: 60%;
        line-height: 48px; 
    }

    /* footer */
    #result_tablearea_footerbuttons {
        width: 100%;
        height: 40px;
        gap: 4%;
        line-height: 32px;
        color: white;
        background-color: white;
        display: flex;
        flex-direction: row;
        overflow: none;
        text-align: center;
        border-top: 2px solid #888888;
    }

    /* main_area */
    #main_area{
        flex: 1;
        background-color: white;
        display: flex;
        flex-direction: column;
    }

    #item_cell{
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #888888;
        border-bottom: 1px solid #888888;
        background-color: #e9e9f0;

        white-space: nowrap;
        overflow: hidden;
    }
</style>