import { navigateI18n } from "../i18n/navigate.js";

async function logout() {
  const response = await fetch("/api/auth/logout", {
    method: "POST",
  })
  if (!response.ok) {
    console.log("failed to log out.");
  }
  navigateI18n("/login");
}

export { logout };
