<script>
  import ToggleSwitch from "../../components/input/ToggleSwitch.svelte";
  import ItemPerPageSelectBox from "../../components/select/ItemPerPageSelectBox.svelte";
  import {
    saveOperationTableConfiguration
  } from "../../utils/configurationStorage/views/OperationTableConfigurationStorage.js";
  import { translate } from "../../i18n/i18next.js";
  import MenuBar from "../../components/menuBar/MenuBar.svelte";
  import MenuBarItem from "../../components/menuBar/MenuBarItem.svelte";
  import PageSelector2 from "../../components/layout/PageSelector2.svelte";

  export let style;
  export let itemPerPage;

  export let usingCompactStyle;
  export let totalPageNumber = 1;
  export let currentIndex = 0
  /**
   * @type {string|undefined}
   */
  export let userId
  /**
   * @type {string}
   */
  export let projectId

  function onChangeSettings(settingName, newValue) {
    if (!userId) return
    saveOperationTableConfiguration({[settingName]: newValue}, userId, projectId)
  }
</script>

<MenuBar>
  <svelte:fragment slot="settings">
    <MenuBarItem>
      <div class="control-item">
        <span class="nowrap">{translate("asprova.terms.style")}:&nbsp;&nbsp;{style}</span>
      </div>
    </MenuBarItem>
    <MenuBarItem>
      <div class="control-item">
        <ToggleSwitch bind:on={usingCompactStyle} label={translate("frontend:views.operationTable.compactVersion")} onChange={e => onChangeSettings("usingCompactMode", e.target.checked)} />
      </div>
    </MenuBarItem>
    <MenuBarItem>
      <div class="control-item">
        <span class="nowrap">{translate("frontend:generic.itemsPerPage")}：&nbsp;&nbsp;</span>
        <ItemPerPageSelectBox bind:value={itemPerPage} onChange={e => {onChangeSettings("itemPerPage", parseInt(e.target.value, 10))}} />
      </div>
    </MenuBarItem>
  </svelte:fragment>
  <MenuBarItem slot="pagination">
    <PageSelector2 totalPageNumber={totalPageNumber} bind:currentIndex={currentIndex} />
  </MenuBarItem>
</MenuBar>

<style>
  .control-item {
    align-items: center;
    display: flex;
  }
  .nowrap {
    white-space: nowrap;
  }
</style>
