<script>
  import Profile1DispatchingViewDetails
    from "../../../../routes/WebViewer_DispatchingView1/Profile1DispatchingViewDetails.svelte"
  import AuthenticationRequired from "../../../../components/functional/AuthenticationRequired.svelte"
  import FetchDispatchingViewData
    from "../../../../components/pages/projects/[project]/fetchers/FetchDispatchingViewData.svelte"
  import ProjectPageBase from "../../../../components/pages/projects/[project]/ProjectPageBase.svelte"
  import CheckDataStatus from "../../../../components/pages/projects/[project]/CheckDataStatus.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase parentView="DispatchingView_Profile1" let:user let:projectId let:code>
    <CheckDataStatus projectId={projectId} viewName="DispatchingView">
      <FetchDispatchingViewData
        projectId={projectId}
        itemCode={code}
        refetchInterval={10000}
        let:styles
        let:resourceDetail
        let:resourceGroup
        let:transactions
        let:reloadTransactions
        let:isInputAllowed
      >
        <Profile1DispatchingViewDetails
          itemCode={code}
          styles={styles}
          resourceDetail={resourceDetail}
          resourceGroup={resourceGroup}
          transactions={transactions}
          reloadTransactions={reloadTransactions}
          isInputAllowed={isInputAllowed}
          projectId={projectId}
          user={user}
        />
      </FetchDispatchingViewData>
    </CheckDataStatus>
  </ProjectPageBase>
</AuthenticationRequired>