<script>
  import SectionTitle from "../../layout/SectionTitle.svelte"
  import { useProject } from "../../../hooks/useProject.js"
  import { derived } from "svelte/store"
  import { getNotificationsContext } from "svelte-notifications"
  import { useQueryClient } from "@sveltestack/svelte-query"
  import { translate } from "../../../i18n/i18next.js"
  import PrimaryButton from "../../button/generic/PrimaryButton.svelte"
  import { isResultInputsEnabledPlan } from "../../../utils/project.js"
  import { useEnabledExperimentalViews } from "../../../hooks/useEnabledExperimentalViews.js";
  import Alert from "../../Alerts/Alert.svelte";
  import HelpTooltip from "../../tooltip/HelpTooltip.svelte";

  export let projectId

  const {addNotification} = getNotificationsContext()
  const queryClient = useQueryClient()

  const project = useProject(projectId)
  const views = derived(project, values => {
    const views = values?.views ?? []
    return Object.fromEntries(views.map(view => [view, true]))
  })
  const enabledExperimentalViews = useEnabledExperimentalViews()

  function onClickViewImage(event, viewName) {
    event.preventDefault()
    const checked = document.querySelector(`input#input-checkbox-${viewName}`).checked
    document.querySelector(`input#input-checkbox-${viewName}`).checked = !checked
  }

  async function handleSubmit(event) {
    event.preventDefault()
    const form = event.target

    const views = {}
    form.querySelectorAll("input").forEach(elem => {
      views[elem.name] = elem.checked
    })

    const viewNames = Object.keys(views).filter(viewName => views[viewName])

    const response = await fetch(`/api/projects/${projectId}/views`, {
      method: "PUT",
      body: JSON.stringify({views: viewNames}),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      addNotification({
        text: translate("frontend:generic.notifications.saved"),
        type: "success",
        position: "top-right",
        removeAfter: 4000,
      })
      await queryClient.invalidateQueries(`/api/projects/${projectId}`)
    } else {
      addNotification({
        text: translate("frontend:generic.notifications.saveFailed"),
        type: "error",
        position: "top-right",
        removeAfter: 4000,
      })
    }
  }
</script>

<div id="root">
  <SectionTitle title={translate("frontend:pages:projects.project.admin.sections.ui.title")} />
  <div class="caption">{translate("frontend:pages:projects.project.admin.sections.ui.caption")}</div>
  <form id="form-project-uis" on:submit={handleSubmit}>
    <div class="view-button-cards-container">
      <div class="view-button-card">
        <button on:click={e => onClickViewImage(e, "ResGantt")} class="view-button-card-img-button">
          <img src="/assets/icons/icon_resource_gantt_l.png" alt="icon" class="view-icon" />
        </button>
        <div class="view-button-card-checkbox">
          <input
            type="checkbox"
            id={`input-checkbox-ResGantt`}
            name={"ResGantt"}
            checked={$views["ResGantt"]}
          />
          <label for={`input-checkbox-ResGantt`} class="view-button-card-checkbox-label">
            {translate("mySchedule.views.resGantt.name")}
          </label>
          <HelpTooltip title={translate("mySchedule.views.resGantt.name")}>
            {translate("frontend:help.views.resGantt.description")}
          </HelpTooltip>
        </div>
      </div>
      <div class="view-button-card">
        <button on:click={e => onClickViewImage(e, "DispatchingView")} class="view-button-card-img-button">
          <img src="/assets/icons/icon_dispatching_view_l.png" alt="icon" class="view-icon" />
        </button>
        <div class="view-button-card-checkbox">
          <input
            type="checkbox"
            id={`input-checkbox-DispatchingView`}
            name={"DispatchingView"}
            checked={$views["DispatchingView"]}
          />
          <label for={`input-checkbox-DispatchingView`} class="view-button-card-checkbox-label">
            {translate("mySchedule.views.dispatchingView.name")}
          </label>
          <HelpTooltip title={translate("mySchedule.views.dispatchingView.name")}>
            {translate("frontend:help.views.dispatchingView.description")}
          </HelpTooltip>
        </div>
      </div>
      <div class="view-button-card">
        <button on:click={e => onClickViewImage(e, "OperationTable")} class="view-button-card-img-button">
          <img src="/assets/icons/icon_operation_table_l.png" alt="icon" class="view-icon" />
        </button>
        <div class="view-button-card-checkbox">
          <input
            type="checkbox"
            id={`input-checkbox-OperationTable`}
            name={"OperationTable"}
            checked={$views["OperationTable"]}
          />
          <label for={`input-checkbox-OperationTable`} class="view-button-card-checkbox-label">
            {translate("mySchedule.views.operationTable.name")}
          </label>
          <HelpTooltip title={translate("mySchedule.views.operationTable.name")}>
            {translate("frontend:help.views.operationTable.description")}
          </HelpTooltip>
        </div>
      </div>
      <div class="view-button-card">
        <button on:click={e => onClickViewImage(e, "OrderGantt")} class="view-button-card-img-button">
          <img src="/assets/icons/icon_order_gantt_l.png" alt="icon" class="view-icon" />
        </button>
        <div class="view-button-card-checkbox">
          <input
            type="checkbox"
            id={`input-checkbox-OrderGantt`}
            name={"OrderGantt"}
            checked={$views["OrderGantt"]}
          />
          <label for={`input-checkbox-OrderGantt`} class="view-button-card-checkbox-label">
            {translate("mySchedule.views.orderGantt.name")}
          </label>
          <HelpTooltip title={translate("mySchedule.views.orderGantt.name")}>
            {translate("frontend:help.views.orderGantt.description")}
          </HelpTooltip>
        </div>
      </div>
      <div class="view-button-card">
        <button on:click={e => onClickViewImage(e, "OrderLeadTime")} class="view-button-card-img-button">
          <img src="/assets/icons/icon_order_leadtime_l.png" alt="icon" class="view-icon" />
        </button>
        <div class="view-button-card-checkbox">
          <input
            type="checkbox"
            id={`input-checkbox-OrderLeadTime`}
            name={"OrderLeadTime"}
            checked={$views["OrderLeadTime"]}
          />
          <label for={`input-checkbox-OrderLeadTime`} class="view-button-card-checkbox-label">
            {translate("mySchedule.views.orderLeadTime.name")}
          </label>
          <HelpTooltip title={translate("mySchedule.views.orderLeadTime.name")}>
            {translate("frontend:help.views.orderLeadTime.description")}
          </HelpTooltip>
        </div>
      </div>
      <div class="view-button-card">
        <button on:click={e => onClickViewImage(e, "IntegratedMaster")} class="view-button-card-img-button" disabled={!isResultInputsEnabledPlan($project?.plan)}>
          <img src="/assets/icons/icon_integrated_master_l.png" alt="icon" class="view-icon" />
        </button>
        <div class="view-button-card-checkbox">
          <input
            type="checkbox"
            id={`input-checkbox-IntegratedMaster`}
            name={"IntegratedMaster"}
            checked={$views["IntegratedMaster"]}
            disabled={!isResultInputsEnabledPlan($project?.plan)}
          />
          <label for={`input-checkbox-IntegratedMaster`} class="view-button-card-checkbox-label">
            {translate("mySchedule.views.integratedMaster.name")}
          </label>
          <HelpTooltip title={translate("mySchedule.views.integratedMaster.name")}>
            {translate("frontend:help.views.integratedMaster.description")}
          </HelpTooltip>
        </div>
      </div>
    </div>
    <!-- 試験的な画面が有効になっていれば、それらの画面を選択できるようにする -->
    {#if $enabledExperimentalViews.length > 0}
      <hr style="width: 100%; background-color: darkgray; height: 1px; border: none" />
      <Alert type="info">以下の画面は試験的に利用できる画面です。動作させるには、専用のプラグインからアップロードしたデータが必要です。</Alert>
      <div class="view-button-cards-container">
        {#if $enabledExperimentalViews.includes("LoadGraph")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "LoadGraph")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_load_graph_l.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox">
              <input
                type="checkbox"
                id={`input-checkbox-LoadGraph`}
                name={"LoadGraph"}
                checked={$views["LoadGraph"]}
              />
              <label for={`input-checkbox-LoadGraph`} class="view-button-card-checkbox-label">負荷率グラフ</label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("ProductionOrder")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "ProductionOrder")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_production_order_l.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox">
              <input
                type="checkbox"
                id={`input-checkbox-ProductionOrder`}
                name={"ProductionOrder"}
                checked={$views["ProductionOrder"]}
              />
              <label for={`input-checkbox-ProductionOrder`} class="view-button-card-checkbox-label">生産指示書</label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("ResultGraph")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "ResultGraph")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_result_graph_l.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox">
              <input
                type="checkbox"
                id={`input-checkbox-ResultGraph`}
                name={"ResultGraph"}
                checked={$views["ResultGraph"]}
              />
              <label for={`input-checkbox-ResultGraph`} class="view-button-card-checkbox-label">実績グラフ</label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("SimpleOrderGantt")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "SimpleOrderGantt")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_simple_order_gantt_l.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox" style="display: flex; align-items: center; gap: 1rem;">
              <input
                type="checkbox"
                id={`input-checkbox-SimpleOrderGantt`}
                name={"SimpleOrderGantt"}
                checked={$views["SimpleOrderGantt"]}
              />
              <label for={`input-checkbox-SimpleOrderGantt`} class="view-button-card-checkbox-label">
                [シンプル版] <br/>
                オーダガントチャート
              </label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("SimpleResGantt")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "SimpleResGantt")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_simple_res_gantt_l.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox" style="display: flex; align-items: center; gap: 1rem;">
              <input
                type="checkbox"
                id={`input-checkbox-SimpleResGantt`}
                name={"SimpleResGantt"}
                checked={$views["SimpleResGantt"]}
              />
              <label for={`input-checkbox-SimpleResGantt`} class="view-button-card-checkbox-label">
                [シンプル版] <br/>
                資源ガントチャート
              </label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("ExpectedActualCmp")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "ExpectedActualCmp")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_expected_actual_cmp.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox">
              <input
                type="checkbox"
                id={`input-checkbox-ExpectedActualCmp`}
                name={"ExpectedActualCmp"}
                checked={$views["ExpectedActualCmp"]}
              />
              <label for={`input-checkbox-ExpectedActualCmp`} class="view-button-card-checkbox-label">工程毎予実比較</label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("LoadGraph_MPS")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "LoadGraph_MPS")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_load_graph_mps.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox">
              <input
                type="checkbox"
                id={`input-checkbox-LoadGraph_MPS`}
                name={"LoadGraph_MPS"}
                checked={$views["LoadGraph_MPS"]}
              />
              <label for={`input-checkbox-LoadGraph_MPS`} class="view-button-card-checkbox-label">負荷グラフ (MPS)</label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("PSITable")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "PSITable")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_psi_table.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox">
              <input
                type="checkbox"
                id={`input-checkbox-PSITable`}
                name={"PSITable"}
                checked={$views["PSITable"]}
              />
              <label for={`input-checkbox-PSITable`} class="view-button-card-checkbox-label">PSI表</label>
            </div>
          </div>
        {/if}
        {#if $enabledExperimentalViews.includes("PSIGraph")}
          <div class="view-button-card">
            <button on:click={e => onClickViewImage(e, "PSIGraph")} class="view-button-card-img-button">
              <img src="/assets/icons/icon_psi_graph.png" alt="icon" class="view-icon" />
            </button>
            <div class="view-button-card-checkbox">
              <input
                type="checkbox"
                id={`input-checkbox-PSIGraph`}
                name={"PSIGraph"}
                checked={$views["PSIGraph"]}
              />
              <label for={`input-checkbox-PSIGraph`} class="view-button-card-checkbox-label">PSIグラフ</label>
            </div>
          </div>
        {/if}
      </div>
    {/if}
    <div class="buttons-container">
      <PrimaryButton type="submit">{translate("generic.save")}</PrimaryButton>
    </div>
  </form>
</div>

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  #root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  #form-project-uis {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .view-button-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  input {
    font-size: 1rem;
    padding: 0.4rem;
    outline: none;
  }
  .caption {
    font-size: 0.9rem;
  }
  .view-icon {
    width: 200px;
  }
  .view-button-card {
    border-radius: 3px;
    border: 1px solid darkgrey;
  }
  .view-button-card-img-button:disabled {
    cursor: initial;
  }
  .view-button-card-checkbox {
    padding: 0.5rem;
  }
  .view-button-card-checkbox-label {
    font-size: 0.8rem;
  }
  input[type=checkbox] + label {
    cursor: pointer;
  }
  input[type=checkbox]:disabled + label {
    color: darkgrey;
    cursor: initial;
  }
</style>
