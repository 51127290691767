<script>
    import {resultStartColumn, resultColumnLength} from "./ResultGraphStore"
    import {ColumnDisplay,  monthColumns } from "./ResultGraphStore"
    import {DisplayResultOrExpected} from "./ResultGraphStore"
    import { saveResultGraphConfiguration } from "../../utils/configurationStorage/views/ResultGraphConfigurationStorage";
    import IconSwitching from "./icon_switching.svelte";

    const changeDisplayResultExpected = () => { 
        DisplayResultOrExpected.set($DisplayResultOrExpected ? false : true); 
        saveResultGraphConfiguration({"displayResultOrExpected": $DisplayResultOrExpected}, 'default', 'default');
    };
    
    $: {
        const newArray = [];
        for(let cindex = 0; cindex < $monthColumns.length; cindex++){
            if($resultStartColumn <= cindex && cindex < $resultStartColumn + Number($resultColumnLength)){
                newArray.push(cindex);
            }
        }
        ColumnDisplay.set(newArray);
        console.log("ColumnDisplay", $ColumnDisplay);
    }
</script>

<div id="result_chart_header">
    {#each $ColumnDisplay as cindex}
        <div id="header_cell">
            <div id="month_header">{$monthColumns[cindex]}</div>
            <div id="text_header">
                <div id="load_subcell">
                    <div>{"計画負荷"}</div>
                    <div>{"工数(h)"}</div>
                </div>
                <div id="result_subcell">
                    <div id="result_subcell_text">
                        <div>{$DisplayResultOrExpected ? "実績" : "指定"}</div>
                        <div>{"工数(h)"}</div>
                        <!-- {DisplayResultOrExpected ? "実績" : "指定"}{"工数(h)"} -->
                    </div>
                    <div on:click={changeDisplayResultExpected}><IconSwitching /></div>
                </div>
                <div id="text_subcell">{"達成率"}</div>
                <div id="text_subcell">{"計画同調率"}</div>
            </div>
        </div>
    {/each}
</div>

<style>
    #result_chart_header{
        width: 100%;
        height: 90px;
        border-bottom: 2px solid #888888;
        display: flex;
        flex-direction: row;
    }

    #header_cell{
        height: 100%;
        width: 100%;
        flex: 1;
        border-left: 1px solid #888888;
        border-right: 1px solid #888888;
    }

    #month_header{
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-bottom: 1px solid #888888;
        border-left: 1px solid #888888;
        border-right: 1px solid #888888;
        flex: 1;
    }

    #text_header{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
    }

    #load_subcell{
        flex: 1;
        border: 1px solid #888888;

        display: flex;
        flex-direction: column;
        font-size: 14px;

        align-items: center;
        justify-content: center;
    }

    #result_subcell{
        flex: 1;
        border: 1px solid #888888;

        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: center;
    }

    #result_subcell_text{
        display: flex;
        flex-direction: column;
        font-size: 14px;

        align-items: center;
        justify-content: center;
    }

    #text_subcell{
        flex: 1;
        display: flex;
        border: 1px solid #888888;
        text-align: center;

        font-size: 14px;

        align-items: center;
        justify-content: center;
    }
</style>