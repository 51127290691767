<script>
  import { I18N_ResourceGanttTimelineFormatDate } from "../../utils/i18n";
  import { getTimeStringSec } from '../../utils/time.js';
  import GanttHelper from './GanttHelper.js';
  import GeneralButton from '../button/GeneralButton.svelte';
  import { useIsInputAllowed } from "../../hooks/useIsInputAllowed.js"
  import { FetchError } from "../../utils/getData.js";
  import { getContext } from "svelte";
  import ResultEdit from "./ResultEdit.svelte"
  import { getNotificationsContext } from "svelte-notifications"
  import { translate } from "../../i18n/i18next.js";
  import { useProject } from "../../hooks/useProject.js";
  import { useTimezone } from "../../hooks/useTimezone.js";

  export let GanttStore
  export let ID = -1;
  export let Details = null;
  export let Style;
  export let TransactionRegisteringFunction = async () => {};
  export let projectId;
  export let Highlighted = false;
  export let Locked = false;
  export let JumpToDateCallback = () => {};

  $: showResultEdit = false;

  const {handleNonBlockingFetchErrors} = getContext("fetchErrorHandler")
  const {addNotification} = getNotificationsContext()
  const project = useProject(projectId)
  const {getOffset} = useTimezone()

  let expanded = false;
  const {isInputAllowed} = useIsInputAllowed(projectId);
  const resultsInputButtonStyle = "width: 50%;background-color: #00468f; color: white";
  const resultsEditButtonStyle = "width: 100%;background-color: white; color:#00468f; border-color: #00468f";

  const JumpToDate = (e) => {
    e.preventDefault();
    JumpToDateCallback(Details);
    // GanttStore.CurrentDisplayedStartDate.set(Details._productionArr[0]);
    // To do : Also search the operation ressource and move to it
  }

  const ExpendTimeline = (e) => {
    e.preventDefault();
    expanded = !expanded;
  }

  const GetStatusL = (st) => GanttHelper.GetStatus(st);
  const GetStatus = () => GanttHelper.GetStatus(Details._resStatus);
  const IsComplete = () => GetStatus() === GanttHelper.EStatus.COMPLETE;
  const IsStarted = () => GetStatus() === GanttHelper.EStatus.STARTED;

  const GetStatusSpan = () => {
    const _st = GetStatus();
    return `<span style='color: ${GanttHelper.GetStatusColor(_st)}'>${GanttHelper.GetStatusText(_st)}</span>`;
  }

  const GetLabelSpan = (text) => {
    const LABEL_COLOR = "#aaaaaa";
    return `<span style='color: ${LABEL_COLOR}'>${text}: </span>`;
  }

  const EditResult = async (e) => {
    e.preventDefault();
    e.target.disabled = true
    showResultEdit = true;
    e.target.disabled = false
  }

  /**
   *
   * @param {string} newStartDateTimeString
   * @param {string} newEndDateTimeString
   * @param {string} newStatus
   * @param {number|null} newQty
   */
  async function handleResultEditSubmit(newStartDateTimeString, newEndDateTimeString, newStatus, newQty) {
    showResultEdit = false;
    await RegisterTransaction(newStatus, newStartDateTimeString, newEndDateTimeString, newQty);
    addNotification({
      text: translate("generic.saved"),
      position: "top-right",
      removeAfter: 4000,
      type: "success",
    });
  }

  const CompleteTask = async (e) => {
    if (!$project.timezone) return
    e.preventDefault();
    e.target.disabled = true
    await RegisterTransaction("B", Details._resultStart, getTimeStringSec(new Date(), $project.timezone, $getOffset)); // StartDate as invalid date
    e.target.disabled = false
  }

  const StartTask = async (e) => {
    if (!$project.timezone) return
    e.preventDefault();
    e.target.disabled = true
    await RegisterTransaction("T", getTimeStringSec(new Date(), $project.timezone, $getOffset), null);
    e.target.disabled = false
  }

  // If newStartDateTime/newEndDateTime is null, set to ""
  // If newStartDateTime/newEndDateTime is invalid date, ignore it (called by the end button, to reserve the existing resultStartTime)
  // When newQty=null, do not change the resultQty (called by the start/end button)
  /**
   *
   * @param {string} status
   * @param {string | null} newStartDateTimeString  if set to null, existing "resultStartDate" will be used
   * @param {string | null} newEndDateTimeString  if set to null, existing "resultStartDate" will be used
   * @param {number | null} newQty
   * @constructor
   */
  const RegisterTransaction = async (status, newStartDateTimeString, newEndDateTimeString, newQty=null) => {
    const _start = newStartDateTimeString;
    const _end = newEndDateTimeString;

    let _ope = {};
    if (newQty != null) {
      _ope = {
        _operation: Details._operation,
        _resultStart: _start,
        _resultEnd: _end,
        _resStatus: status,
        _resultQty: newQty,
      };
    } else {
      _ope = {
        _operation: Details._operation,
        _resultStart: _start,
        _resultEnd: _end,
        _resStatus: status,
      };
    }


    try {
      await TransactionRegisteringFunction([_ope]);
    } catch (e) {
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error)
      }
      console.error("Failed to send transactions")
      return
    }

    GanttStore.RowsCached.update((cached) => {
      let _idx = cached[Details._row].Data.findIndex(item => item._objectID === Details._objectID);

      if (_idx !== -1) {
        cached[Details._row].Data[_idx]._resultStart = _start;
        cached[Details._row].Data[_idx]._resultEnd = _end;
        cached[Details._row].Data[_idx]._resStatus = status;
        if (newQty != null)
          cached[Details._row].Data[_idx]._resultQty = newQty;
        Details._resultStart = _start;
        Details._resultEnd = _end;
        Details._resStatus = status;
        if (newQty != null)
          Details._resultQty = newQty;
      }

      return cached;
    });
  }

</script>

{#if Details !== null}
  <div
    class={(Highlighted) ? "gantt_timelinearea_timelinecard_highlighted" : "gantt_timelinearea_timelinecard"}
    id={"gantt_timelinearea_timelinecard_" + ID}
  >
    <div class="gantt_timelinearea_timelinecard_buttonbar">
      <span
        class="gantt_timelinearea_timelinecard_button"
        on:click={ExpendTimeline}>{expanded ? "▼" : "▶"}</span>
      <div
        class="gantt_timelinearea_timelinecard_bar"
        style="--color:{Details._color[Style.StyleName]};--dcolor:{GanttHelper._gantHelper_darkenColor(GanttHelper._gantHelper_rgbToHex(Details._color[Style.StyleName]), 30)};"
      ></div>
    </div>

    <div class="gantt_timelinearea_timelinecard_desc">
      <span class="gantt_timelinearea_timelinecard_date">{I18N_ResourceGanttTimelineFormatDate(Details._productionArr[0], Details._productionArr[Details._productionArr.length - 1])}</span>
      <span class="gantt_timelinearea_timelinecard_title" on:click={JumpToDate}>{Details._operation}</span>


      {#if expanded}
        {#each Details._hoverTooltip[Style.StyleName].split("\n") as line}
          <span class="gantt_timelinearea_timelinecard_details">{line}</span>
        {/each}
        <span
          class="gantt_timelinearea_timelinecard_resultstart"
        >{@html GetLabelSpan(translate("asprova.terms.resultStartTime"))}{Details._resultStart}</span>
        <span
          class="gantt_timelinearea_timelinecard_resultstart"
        >{@html GetLabelSpan(translate("asprova.terms.resultEndTime"))}{Details._resultEnd}</span>
      {/if}
        {#key Details._resStatus}
            <span
              class="gantt_timelinearea_timelinecard_resultstart"
            >{@html GetLabelSpan(translate("asprova.terms.status"))}{@html GetStatusSpan()}</span>
            {#if $isInputAllowed}
              <div class="gantt_timelinearea_timelinecard_buttonflex">
                {#if IsComplete() && !Highlighted}
                  <GeneralButton
                    buttonExtraStyle={resultsEditButtonStyle}
                    buttonText={translate("generic.editResultShort")}
                    buttonDisabled={!$isInputAllowed}
                    buttonFunction={EditResult}
                    buttonType="secondary"
                  />
                {:else}
                  <GeneralButton
                    buttonExtraStyle={resultsInputButtonStyle}
                    buttonText={translate("generic.startShort")}
                    buttonDisabled={Locked || IsStarted() || IsComplete() || !$isInputAllowed}
                    buttonFunction={StartTask}
                  />
                  <GeneralButton
                    buttonExtraStyle={resultsInputButtonStyle}
                    buttonText={translate("generic.finishShort")}
                    buttonDisabled={Locked || !IsStarted() || IsComplete() || !$isInputAllowed}
                    buttonFunction={CompleteTask}
                  />
                {/if}
                {#if showResultEdit}
                  <ResultEdit
                    operationCode={Details._operation}
                    prevStartDateTime={Details._resultStart}
                    prevEndDateTime={Details._resultEnd}
                    prevStatus={Details._resStatus}
                    prevQty={Details._resultQty}
                    itemQty={Details._outputItemQty}
                    scheduledStartDateTime={Details._productionArr[0]}
                    scheduledEndDateTime={ Details._productionArr[Details._productionArr.length - 1]}
                    onSubmit={handleResultEditSubmit}
                    onClose={()=>showResultEdit = false}
                  />
                {/if}
              </div>
            {#if Highlighted}
              <div class="gantt_timelinearea_timelinecard_buttonflex">
                <GeneralButton
                  buttonExtraStyle={resultsEditButtonStyle}
                  buttonText={translate("generic.editResultShort")}
                  buttonDisabled={!$isInputAllowed}
                  buttonFunction={EditResult}
                  buttonType="secondary"
                />
                <!-- <GeneralButton
                  buttonExtraStyle={resultsCancelButtonStyle}
                  buttonText={I18N_GetString("取消す")}
                  buttonFunction={ResetTask}
                  buttonDisabled={!$isInputAllowed}
                /> -->
              </div>
            {/if}
          {/if}
        {/key}

    </div>

  </div>
{/if}

<style>

div.gantt_timelinearea_timelinecard_buttonflex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 2%;
}

span.gantt_timelinearea_timelinecard_date {
  font-size: 0.7em;
  color: #555555;
}

span.gantt_timelinearea_timelinecard_title {
  font-weight: bold;
  cursor: pointer;
  overflow-wrap: anywhere;
}

div.gantt_timelinearea_timelinecard_highlighted {
  display: flex;
  flex-direction: row;
  padding: 10px;
  font-size: 0.8em;
  gap: 5%;
  box-shadow: inset 0 0 0 10px yellow;
}

div.gantt_timelinearea_timelinecard {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  font-size: 0.8em;
  gap: 5%;
}

span.gantt_timelinearea_timelinecard_title:hover {
  background-color: #EEEEEE;
}

span.gantt_timelinearea_timelinecard_title:active {
  background-color: #DDDDDD;
}

div.gantt_timelinearea_timelinecard_buttonbar {
  width: 5%;
  display: flex;
  flex-direction: column;
}

div.gantt_timelinearea_timelinecard_bar {
  width: 30%;
  flex: 1;
  margin-bottom: 4px;
  margin-left: 30%;
  background-color: var(--color);
  border: 1px solid var(--dcolor);
}

div.gantt_timelinearea_timelinecard_desc {
  display: flex;
  flex: 1;
  flex-direction: column;
}

span.gantt_timelinearea_timelinecard_button {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

</style>
