<script>
  import GithubLikeButton from "../button/GithubLikeButton.svelte"
  import { translate } from "../../i18n/i18next.js";

  export let title
  export let onClickReturnToTop
</script>

<div id="error-container">
  <div class="error">
    <div class="error-title">
      {title}
    </div>
    <div class="error-caption">
      <slot />
    </div>
    <div>
      <GithubLikeButton onClick={onClickReturnToTop} color="green" label={translate("frontend:generic.returnToTop")}/>
    </div>
  </div>
</div>

<style>
  #error-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .error-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .error-caption {
    font-size: 0.9rem;
    color: rgb(50, 50, 50);
  }
</style>