/**
 *
 * @param {string} viewName
 * @param {string} userId
 * @param {string} projectId
 */
function getConfigurationStorageName(viewName, userId, projectId) {
  return `AsprovaMyscheduleConfig--${viewName}--${userId}--${projectId}`
}

/**
 * Returns configuration for the user for the view.
 * returns defaultValue either if it does not exist in the localStorage or if it is malformed as a JSON string.
 * it does not check if the configuration is valid, so check it for yourself.
 * @param {string} viewName  use "generic" when looking for generic (view-agnostic) configuration object
 * @param {string} userId  use "default" if the configuration does not need to be specific to a user
 * @param {string} projectId  use "default" if the configuration does not need to be specific to a project
 * @param {object} [defaultValue]  default value
 * @returns object
 */
export function getConfiguration(viewName, userId, projectId, defaultValue = {}) {
  const name = getConfigurationStorageName(viewName, userId, projectId)

  let val
  try {
    val = window.localStorage.getItem(name)
  } catch (e) {
    console.error(e)
  }
  if (!val) return defaultValue

  try {
    return JSON.parse(val)
  } catch (e) {
    console.error(e)
    return defaultValue
  }
}

/**
 * Saves configuration for the user for the view.
 * @param {string} viewName  use "generic" when looking for generic (view-agnostic) configuration object
 * @param {string} userId  use "default" if the configuration does not need to be specific to a user
 * @param {string} projectId  use "default" if the configuration does not need to be specific to a project
 * @param {object} configuration
 */
export function saveConfiguration(viewName, userId, projectId, configuration) {
  const name = getConfigurationStorageName(viewName, userId, projectId)
  try {
    window.localStorage.setItem(name, JSON.stringify(configuration))
  } catch (e) {
    console.error(e)
  }
}
