<script>
  import CalendarHeader from './CalendarHeader.svelte';
  import ChartContent from './ChartContent.svelte';

  export let GanttStore
</script>

<div id="gantt_chartarea">
  <CalendarHeader
    GanttStore={GanttStore}
  />
  <ChartContent
    GanttStore={GanttStore}
  />
  <div id="gantt_chartarea_footerpadding"></div>
</div>

<style>
  div#gantt_chartarea_footerpadding {
    height: 40px;
    width: 100%;
    background-color: white;
    border-top: 2px solid #888888;
  }

  div#gantt_chartarea {
    height: 100%;
    flex: 1;
    display: flex;
    border: 2px solid #888888;
    flex-direction: column;
    background-color: white;
  }
</style>
