import { getContext } from "svelte"
import { writable } from "svelte/store"
import { produce } from "immer";

/**
 * @typedef {{
 *   headerTitle: string,
 *   uploadTime: string | null,
 *   versions: {
 *     frontend: string,
 *     backend: string | null,
 *     plugin: string | null,
 *   },
 *   rightPane: {
 *     href: string | null,
 *     isOpen: boolean,
 *   }
 * }} PageContextValue
 */

const pageContextValue = writable({
  headerTitle: "Asprova My Schedule",
  uploadTime: null,
  versions: {
    frontend: "__MY_SCHEDULE_VERSION__",
    backend: null,
    plugin: null,
  },
  rightPane: {
    href: null,
    isOpen: false,
  },
})

/**
 * @returns {Writable<PageContextValue>}
 */
export function createPageContextValue() {
  return pageContextValue
}

export function usePageContext() {
  /**
   * @type {Writable<PageContextValue>}
   */
  const context = getContext("page")

  /**
   *
   * @param {{
   *   title?: string | null,
   *   uploadTime?: string | null,
   *   helpLink?: string | null,
   * }} props
   */
  function setHeaderProps(props) {
    context.update(values => produce(values, draft => {
      if (props.title) {
        draft.headerTitle = props.title;
      }
      if (props.uploadTime) {
        draft.uploadTime = props.uploadTime;
      }
      if (props.helpLink) {
        draft.rightPane.href = props.helpLink;
      } else {
        draft.rightPane.href = null;
        draft.rightPane.isOpen = false;
      }
    }));
  }

  /**
   *
   * @param {{
   *   backend?: string | null,
   *   plugin?: string | null,
   * }} versions
   */
  function setVersions(versions) {
    const updatedValues = {}
    if ("backend" in versions) {
      updatedValues.backend = versions["backend"]
    }
    if ("plugin" in versions) {
      updatedValues.plugin = versions["plugin"]
    }
    if (updatedValues) {
      context.update(values => ({
        ...values,
        versions: {
          ...values.versions,
          ...updatedValues
        }
      }))
    }
  }

  function setRightPaneHref(href) {
    context.update(values => produce(values, draft => {
      draft.rightPane.href = href
    }))
  }

  function toggleRightPane(open = null) {
    context.update(values => produce(values, draft => {
      draft.rightPane.isOpen = open ?? !values.rightPane.isOpen
    }))
  }

  return {
    context, // deprecated, use pageContextValue instead,
    pageContextValue: context,
    setHeaderProps,
    setVersions,
    setRightPaneHref,
    toggleRightPane,
  }
}