<script>
  import { useMe } from "../../../hooks/useMe.js"
  import ChangePasswordDialog from "../../users/ChangePasswordDialog.svelte"
  import { logout } from "../../../utils/logout.js"
  import { getNotificationsContext } from "svelte-notifications"
  import { useParams } from "svelte-navigator"
  import { useMyProjectPermission } from "../../../hooks/useMyProjectPermission.js"
  import SwitchProjectPermissionChips from "../../chips/helpers/SwitchProjectPermissionChips.svelte"
  import { translate } from "../../../i18n/i18next.js";
  import Languages from "../Languages.svelte";
  import UserCcuInfo from "./UserCcuInfo.svelte";

  const me = useMe()
  const params = useParams()
  const projectId = $params.project
  const {permission} = useMyProjectPermission(projectId)

  function onUserMenuButtonClick() {
    // utilities
    // menuEl を表示する場所を決定する処理
    // positioned ancestor の位置を加味する
    function placeMenuEl(buttonEl, menuEl) {
      menuEl.style.position = "absolute"
      const rect = buttonEl.getBoundingClientRect()
      const offsetParentRect = menuEl.offsetParent.getBoundingClientRect()
      menuEl.style.top = `${rect.bottom - offsetParentRect.top + 5}px`
      menuEl.style.right = `${window.innerWidth - rect.right - offsetParentRect.left}px`
    }

    // menuEl外がクリックされたときにmenuElを閉じるための処理
    function clickListener(event) {
      // buttonEl 内の要素がクリックされた場合はなにもしない
      if (buttonEl.contains(event.target)) return
      // menuEl 外の要素がクリックされた場合は menuElを閉じる
      if (!menuEl.contains(event.target)) {
        menuEl.style.display = 'none';
        document.removeEventListener('click', clickListener);

        // 言語メニューは閉じる
        isSelectingLanguageMenuForceOpened = false
        isSelectingLanguage = false
      }
    }

    // ウィンドウがリサイズされたとき、menuEL の位置を再計算する処理
    function resizeListener() {
      placeMenuEl(buttonEl, menuEl)
    }

    const buttonEl = document.getElementById("user-menu-button")
    const menuEl = document.getElementById("user-menu")

    // もし menuEl がすでに表示されていれば、閉じる
    if (menuEl.style.display && menuEl.style.display !== "none") {
      menuEl.style.display = "none"
      window.removeEventListener("resize", resizeListener)
      return
    }

    // menuEl が表示されていなければ表示して配置する
    menuEl.style.display = "initial"
    placeMenuEl(buttonEl, menuEl)

    document.addEventListener('click', clickListener);

    // ウィンドウがリサイズされたときには menuElの位置を再計算する
    window.addEventListener("resize", resizeListener)
  }

  let changePasswordDialog
  function onClickChangePassword() {
    changePasswordDialog.showModal()
  }

  const {addNotification} = getNotificationsContext()
  async function handleChangePasswordSubmission(values){
    const response = await fetch("/api/me/password", {
      method: "POST",
      body: JSON.stringify({oldPassword: values.oldPassword, newPassword: values.newPassword}),
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (response.ok) {
      addNotification({
        text: translate("frontend:components.user.passwordChanged"),
        position: "top-right",
        removeAfter: 4000,
        type: "success",
      })
      changePasswordDialog.close()
      return true
    } else {
      try {
        const json = await response.json()
        const errorType = json.type

        if (errorType === "error:config:change-password-disallowed") {
          addNotification({
            text: translate("frontend:generic.errors.config.changePasswordDisallowed"),
            position: "top-right",
            removeAfter: 4000,
            type: "error",
          })
        }
        addNotification({
          text: translate("frontend:components.user.passwordChangeFailed"),
          position: "top-right",
          removeAfter: 4000,
          type: "error",
        })
      } catch (e) {
        addNotification({
          text: translate("frontend:components.user.passwordChangeFailed"),
          position: "top-right",
          removeAfter: 4000,
          type: "error",
        })
      }
      return false
    }
  }

  let isSelectingLanguage = false
  let isSelectingLanguageMenuForceOpened = false
  let changeLanguageButtonEl
</script>

<div id="root">
  <button id="user-menu-button" on:click={onUserMenuButtonClick}>
    <img src="/assets/icons/icon_user.png" alt="user icon" class="user-icon"/>
    <span class="user-name-and-permission">
      <span class="user-name">
        {#if $me}
          <span>{$me.name}</span>
        {:else}
          <span>...</span>
        {/if}
      </span>
      {#if $permission}
        <SwitchProjectPermissionChips permission={$permission} />
      {/if}
    </span>
    <svg
      fill="#4e6dae"
      height="10px" width="10px"
      viewBox="0 0 330 330"
      xml:space="preserve"
    >
      <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
    </svg>
  </button>
  <div id="user-menu">
    <!-- This part will be displayed when this information is not shown in the header because of the narrow width. -->
    <div class="user-menu-user-info-container">
      <div class="menu-item disabled user-menu-user-info">
        <span class="user-name-and-permission">
          <span class="user-name">
            {#if $me}
              <span>{$me.name}</span>
            {:else}
              <span>...</span>
            {/if}
          </span>
          {#if $permission}
            <SwitchProjectPermissionChips permission={$permission} />
          {/if}
        </span>
      </div>
      <div class="menu-item disabled">
        <div class="divider"></div>
      </div>
    </div>
    <button class="menu-item" on:click={logout}>
      {translate("generic.logout")}
    </button>
    <button class="menu-item" on:click={onClickChangePassword}>
      {translate("generic.changePasswordShort")}
    </button>
    <button
      class="menu-item"
      style="display: inline-flex; align-items: center; gap: 5px; justify-content: center; position: relative"
      on:mouseenter={() => isSelectingLanguage = true}
      on:mouseleave={() => {
        if (!isSelectingLanguageMenuForceOpened) isSelectingLanguage = false
      }}
      on:click={() => isSelectingLanguageMenuForceOpened = !isSelectingLanguageMenuForceOpened}
      bind:this={changeLanguageButtonEl}
    >
      <img alt="globe-icon" src="/assets/icons/icon_language.svg" />
      <span style="font-size: .9rem">Language</span>
      {#if isSelectingLanguage}
        <Languages buttonEl={changeLanguageButtonEl} />
      {/if}
    </button>
    <div class="menu-item disabled">
      <div class="divider"></div>
    </div>
    <div class="menu-item disabled">
      <UserCcuInfo />
    </div>
  </div>
  <ChangePasswordDialog
    bind:dialog={changePasswordDialog}
    onSubmit={handleChangePasswordSubmission}
    onClose={()=>changePasswordDialog.close()}
  />
</div>

<style>
  #root {
    z-index: 100;
    box-sizing: border-box;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  #user-menu-button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.7rem;
  }
  .user-name-and-permission {
    display: inline-flex;
    flex-direction: column;
    white-space: nowrap;
  }
  @media (max-width: 767px) {
    #user-menu-button .user-name-and-permission {
      display: none;
    }
  }
  .user-menu-user-info-container {
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .user-menu-user-info-container .user-name {
    font-size: 1.1rem;
  }
  @media (max-width: 767px) {
    .user-menu-user-info-container {
      display: inline-flex;
    }
  }
  .user-icon {
    height: 30px;
    aspect-ratio: 1;
    border-radius: 30px;
  }
  .user-name {
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1.3rem;
    color: #4e6dae;
  }
  #user-menu {
    position: absolute;
    display: none;
    min-width: 140px;
    z-index: 100;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    box-shadow: 0 1px 5px rgba(0,0,0,0.1);
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
  }
  .menu-item {
    display: block;
    line-height: 1.5;
    padding: 7px 20px 7px 20px;
    color: #184398;
    background-color: inherit;
    width: 100%;
    white-space: nowrap;
    font-size: .9rem;
  }
  .menu-item:hover:not(.disabled) {
    cursor: pointer;
    background-color: #f3f3f3;
  }
  .menu-item .divider {
    height: 1px;
    background-color: lightgray;
  }
</style>
