<script>
  import { createForm } from "felte";
  import { validator } from "@felte/validator-zod";
  import { z } from "zod";
  import { getNotificationsContext } from "svelte-notifications";
  import { useLocation } from "svelte-navigator";
  import { translate } from "../../i18n/i18next.js";
  import { navigateI18n } from "../../i18n/navigate.js";

  const location = useLocation()

  const {addNotification} = getNotificationsContext()

  const schema = z.object({
    name: z.string().min(1, translate("frontend:pages.signup.usernameTooShortError")),
    password: z.string().min(1, translate("frontend:pages.signup.passwordTooShortError")),
    passwordRepeat: z.string(),
  }).superRefine((values, ctx) => {
    if (values.password !== values.passwordRepeat) {
      ctx.addIssue({
        path: ["passwordRepeat"],
        code: "custom",
        message: translate("frontend:pages.signup.passwordsNotMatchingError")
      })
    }
  })

  async function handleSubmit(values) {
    const response = await fetch("/api/auth/signup", {
      method: "POST",
      body: JSON.stringify({name: values.name, password: values.password}),
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.ok) {
      addNotification({
        position: "top-right",
        type: "success",
        removeAfter: 4000,
        text: translate("frontend:pages.signup.registerSuccess")
      })
      let navigateTo = "/"
      if ($location.search) navigateTo += $location.search
      navigateI18n(navigateTo)
    } else {
      try {
        const data = await response.json()
        const errorType = data.type
        if (errorType === "error:server:user-already-defined") {
          errors.set({name: translate("errors.alreadyDefinedUserError")})
          return
        }
      } catch (e) {}
      addNotification({
        position: "top-right",
        type: "error",
        text: translate("frontend:pages.signup.registerFailed"),
        removeAfter: 4000,
      })
    }
  }

  const {form, errors} = createForm({
    onSubmit: handleSubmit,
    extend: validator({schema})
  })
</script>

<div id="content">
  <h2>{translate("frontend:pages.signup.title")}</h2>
  <form use:form>
    <div class="input-container">
      <input
        name="name"
        id="input-name"
        placeholder={translate("generic.username")}
      />
      {#if $errors.name}
        <span class="error">{$errors.name}</span>
      {/if}
    </div>
    <div class="input-container">
      <input
        name="password"
        id="input-password"
        type="password"
        placeholder={translate("generic.password")}
      />
      {#if $errors.password}
        <span class="error">{$errors.password}</span>
      {/if}
    </div>
    <div class="input-container">
      <input
        name="passwordRepeat"
        id="input-password-repeat"
        type="password"
        placeholder={translate("generic.password") + " (" + translate("generic.confirmation") + ")"}
      />
      {#if $errors.passwordRepeat}
        <span class="error">{$errors.passwordRepeat}</span>
      {/if}
    </div>
    <button type="submit">{translate("generic.register")}</button>
  </form>
</div>

<style>
  #content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
  }
  input {
    height: 40px;
    width: 300px;
    border-radius: 8px;
    text-align: center;
  }
  button {
    background-color: #6280ba;
    height: 40px;
    width: 310px;
    color: #ffffff;
    border-radius: 8px;
  }
  ::placeholder {
    text-align: center;
  }
  .error {
    font-size: 0.8rem;
    color: red;
  }
</style>