/**
 * Returns
 * @param {"free"|"viewer"|"standard"} [plan]
 */
export function isResultInputsEnabledPlan(plan) {
  switch (plan) {
    case "free":
    case "standard":
      return true
    default:
      return false
  }
}