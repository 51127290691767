<div class="dialog-bottom-controls">
  <slot />
</div>

<style>
  .dialog-bottom-controls {
    padding: 24px;
    display: flex;
    justify-content: end;
    gap: 16px;
  }
</style>