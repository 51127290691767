<script>
  export let title
</script>

<div id="title">{title}</div>

<style>
  #title {
    font-size: 1.3rem;
    font-weight: bold;
  }
</style>