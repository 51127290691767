<script>
  import {onMount} from 'svelte';
  import TableAreaRow from './TableAreaRow.svelte';

  export let GanttStore


  export let column;
  export let width;

  const {
    RowsCountTotal,
    RowsCountTotalIncludingUnfoldedOperations,
    DisplayedRowCount,
    GanttChartType,
    RowsCurrentlyDisplayed,
  } = GanttStore;



  const RowUp = (e) => {
    e.preventDefault();
    GanttStore.RowsDisplayedStart.update((n) => (n > 0) ? n - 1 : n);
  }

  const RowDown = (e) => {
    e.preventDefault();
    if ($GanttChartType == GanttStore.GanttChartTypes.OrderGantt)
      GanttStore.RowsDisplayedStart.update((n) => (n + 1 >= $RowsCountTotalIncludingUnfoldedOperations) ? n : n + 1);
    else 
      GanttStore.RowsDisplayedStart.update((n) => (n + 1 >= $RowsCountTotal) ? n : n + 1);
  }
  const ScrollRows = (e) => {
    e.preventDefault();

    if (e.ctrlKey) {
      // Row nb scroll
      if (e.deltaY > 0)
        GanttStore.DisplayedRowCount.update((n) => (n + 1 >= 100) ? n : n + 1);
      else if (e.deltaY < 0)
        GanttStore.DisplayedRowCount.update((n) => (n > 1) ? n - 1 : n);
    }
    else {
      // Row idx scroll
      if (e.deltaY > 0)
        RowDown(e);
      else if (e.deltaY < 0)
        RowUp(e);
    }
  }

</script>

<div
  id="gantt_tablearea"
  style="--table-width: {width}%"
  on:wheel={ScrollRows}
>
  <div id="gantt_tablearea_headcolumns">
    <span id="gantt_tablearea_header">{column.name}</span>

  </div>

  <div id="gantt_tablearea_bottomdiv">
    <svg id="gantt_tablearea_resourcesvg">
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" x1="30" y1="0" y2="0" id="truncateText">
          <stop offset="90%" stop-opacity="1" />
          <stop offset="100%" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="truncateRowText" gradientTransform="translate(0)" xlink:href="#truncateText" />
      </defs>
      {#each $RowsCurrentlyDisplayed as row, index}
        <TableAreaRow
          GanttStore={GanttStore}
          RowIndex={undefined}
          Row={(row === null) ? "" : row[column.code]}
          Color={(row === null) ? "white" : row.Color}
          IsNull={row === null}
          Height={($DisplayedRowCount > 0) ? 100 / $DisplayedRowCount : 100}
          OriginY={(($DisplayedRowCount > 0) ? 100 / $DisplayedRowCount : 100) * index}
          IsOperation={row?.IsOperation}
          IsExtraColumn={true}
          displayedRows={null}
        />
      {/each}
    </svg>
  </div>

  <div id="gantt_tablearea_footerbuttons">
  
  </div>
</div>

<style>
  div#gantt_tablearea_footerbuttons {
    width: 100%;
    height: 40px;
    gap: 4%;
    line-height: 32px;
    color: white;
    background-color: white;
    display: flex;
    flex-direction: row;
    overflow: none;
    text-align: center;
    border-top: 2px solid #888888;
  }

  div#gantt_tablearea_footerbutton {
    background-color: #D2D0D2;
    width: 48%;
    height: 80%;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div#gantt_tablearea_footerbutton:hover {
    background-color: #E2E0E2;
    cursor: pointer;
  }

  div#gantt_tablearea_headbutton {
    background-color: #D2D0D2;
    width: 48%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div#gantt_tablearea_headbutton:hover {
    background-color: #E2E0E2;
    cursor: pointer;
  }

  div#gantt_tablearea_headbuttons {
    background-color: white;
    height: 40%;
    width: 100%;
    gap: 4%;
    line-height: 32px;
    color: white;
    display: flex;
    flex-direction: row;
  }

  span#gantt_tablearea_header {
    height: 60%;
    line-height: 48px;
  }

  div#gantt_tablearea {
    height: 100%;
    width: var(--table-width);
    display: flex;
    flex-direction: column;
    overflow: none;
    border: 2px solid #888888;
    background-color: white;
  }

  div#gantt_tablearea_headcolumns {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    overflow: none;
    text-align: center;
    border-bottom: 2px solid #888888;
  }

  div#gantt_tablearea_bottomdiv {
    flex: 1;
    background-color: white;
  }

  svg#gantt_tablearea_resourcesvg {
    float: left;
    border-right: 2px solid #888888;
    width: 100%;
    height: 100%;
  }
</style>
