<script>
  import {translate} from "../../i18n/i18next.js";
  import MyLink from "../../i18n/MyLink.svelte";

  export let projects
</script>

<div id="content-container">
  <div class="project_plan_label hidden_item">
    <p>{translate("frontend:demo.projectsListWithPricing.headers.maxTimeRange")}</p>
    <p>{translate("frontend:demo.projectsListWithPricing.headers.ccu")}</p>
    <p>{translate("frontend:demo.projectsListWithPricing.headers.styles")}</p>
    <p>{translate("frontend:demo.projectsListWithPricing.headers.resultInput")}</p>
    <p>{translate("frontend:demo.projectsListWithPricing.headers.bomEdit")}</p>
  </div>
  {#each projects as project, i}
    <MyLink href="/projects/{project.id}">
      <div class="project-card">
        <div class="project-card-title-container">
          <div class="project-card-title">{project.name}</div>
          <div class="project-card-description">{project.description}</div>
        </div>
        <div class="project-card-info-container">
          {#if project.plan === "standard"}
            <div class="project_plan_label">
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.maxTimeRange")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.standard.maxTimeRange")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.ccu")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.standard.ccu")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.styles")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.standard.styles")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.resultInput")}<br></span><span class="maru"></span></p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.bomEdit")}<br></span><span class="maru"></span></p>
            </div>
          {/if}
          {#if project.plan === "viewer"}
            <div class="project_plan_label">
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.maxTimeRange")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.viewer.maxTimeRange")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.ccu")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.viewer.ccu")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.styles")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.viewer.styles")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.resultInput")}<br></span><span class="bar"></span></p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.bomEdit")}<br></span><span class="bar"></span></p>
            </div>
          {/if}
          {#if project.plan === "free"}
            <div class="project_plan_label">
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.maxTimeRange")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.free.maxTimeRange")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.ccu")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.free.ccu")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.styles")}<br></span>{translate("frontend:demo.projectsListWithPricing.values.free.styles")}</p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.resultInput")}<br></span><span class="maru"></span></p>
              <p><span class="visible_item">{translate("frontend:demo.projectsListWithPricing.headers.bomEdit")}<br></span><span class="maru"></span></p>
            </div>
          {/if}
        </div>
      </div>
    </MyLink>
  {/each}
</div>

<style>
  #content-container {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .project-card {
    border-radius: 3px;
    border: 1px solid lightgrey;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    transition: .3s;
    align-items: center;
  }
  .project-card:hover {
    background-color: #e3eaf2;
  }
  .project-card-title {
    color: #184398;
    font-weight: bold;
    flex: 1 1;
  }
  .project-card-description {
    font-size: 0.9rem;
  }
  .project-card-info-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  .project-plan-label.hidden-item > p {
    font-size: .8rem;
    font-weight: 500;
    color: #999;
  }
  span.maru {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 2px #888;
    margin-top: .3rem
  }

  span.bar {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-bottom: solid 2px #888;
    border-left: none;
    margin-top: .3rem;
    margin-bottom: .2rem;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    font-family:
      "Meiryo",
      "Helvetica Neue",
      Arial,
      "Hiragino Kaku Gothic ProN",
      "Hiragino Sans",
      sans-serif;
  }

  html {
    color: #333333;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 700;
    color: #4f4f4f;
  }

  .project-card {
    transition: .3s;
    align-items: center;
  }

  .project-card:hover {
    background-color: #e3eaf2;
  }
/* sample_project_list */

  #content-container {
    padding: 2.5rem 0;
  }

  .project-card-title {
    font-size: 1.25rem;
    padding-bottom: 1rem;
  }

  .project-card-description {
    font-size: 1rem;
  }

  .project_plan_label {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 1rem;
    gap: .5em;
    text-align: center;
  }

  .project-card-info-container .project_plan_label {
    padding-right: 0;
    height: 60px;
    line-height: 60px;
  }

  .project_plan_label p {
    font-size: .9rem;
    color: #666;
    border-left: dashed 1px #ddd;
    padding-left: .5em;
    width: 90px;
    margin: 0;
  }

  .project_plan_label.hidden_item p {
    font-size: .8rem;
    font-weight: 500;
    color: #999;
  }

  span.maru {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 2px #888;
  }

  span.bar {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-bottom: solid 2px #888;
    border-left: none;
    margin-bottom: .2rem;
  }

  .banner_img img {
    height: 82px;
    width: auto;
    margin: .5rem .5rem 0 0;
  }

  a {
    text-decoration: none;
  }

  .visible_item {
    font-size: .8rem;
    color: #999;
    display: none;
  }

  @media screen and (max-width: 950px) {
    .project-card {
      flex-wrap: wrap;
    }
    .project-card-info-container {
      width: 100%;
      margin-top: 1rem;
    }
    .hidden_item {
      display: none;
    }
    .visible_item {
      display: block
    }
    .project-card-info-container .project_plan_label {
      height: auto;
      line-height: normal;
    }
    .project_plan_label p:nth-child(1) {
      border-left: none;
      padding-left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    #content-container {
      padding: 1rem;
    }
  }

  .view-button-card.svelte-1lomkx6 img {
    width: 100%;
    overflow: hidden;
  }
  p {
    margin: 0;
  }
</style>
