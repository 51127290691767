<script>
  import Signup from "../routes/Common/Signup.svelte"
  import PageBase from "../components/pages/PageBase.svelte";
  import LoginTitle from "../components/layout/LoginTitle.svelte";

  const version = "__MY_SCHEDULE_VERSION__"
</script>

<PageBase>
  <slot slot="header">
    <LoginTitle title={`Asprova My Schedule (v${version})`} />
  </slot>
  <Signup />
</PageBase>
