<script>
  import { translate } from "../../i18n/i18next.js";

  export let permission
  export let onChange
  export let isDisabled = false
</script>

<select on:change={onChange} disabled={isDisabled} bind:value={permission}>
  <option value="ProjectAdmin">{translate("mySchedule.permissions.projectAdmin.name")}</option>
  <option value="ResultInput">{translate("mySchedule.permissions.resultInput.name")}</option>
  <option value="ReadOnly">{translate("mySchedule.permissions.readOnly.name")}</option>
</select>

<style>
  select {
    font-size: 1rem;
    padding: 0.4rem;
  }
</style>