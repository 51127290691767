<script>
  export let header;
  export let rowHeight;

  export let normalHeader;

  export let visibleRows;

  export let previousRow;

  export let handleScrollRowNum;

  export let border;
  let rowWidth = 180;

</script> 

<table on:wheel={handleScrollRowNum}>
  <thead>
    <tr bind:this={normalHeader} >
      <th>
        <div class="res-cell" style="width: {rowWidth}px;" >
          <div class="header-text">{header}</div>
          <div class="header-buttons">
            <!-- on:click={() => previousRow(0)} and 1 -->
            <div class="header-button1" on:click={() => previousRow(0)}>
              <img src="/assets/rss_up.svg" alt="rss_up" />
            </div>
            <div class="header-button2" on:click={() => previousRow(1)}>
              <img src="/assets/rss_pageup.svg" alt="rss_pageup"/>
            </div>
          </div>
        </div>
      </th>    
    </tr>
  </thead>
  <tbody>
    {#each visibleRows as row}
      <tr>
        <!--  on:wheel={handleScrollRowNum} -->
        <td>
          <div class="normal-data" style="height: {rowHeight}px; max-height: {rowHeight}px; width: {rowWidth}px;">
            <p class="data-text">{row.group}</p>
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>

<style>

  table {
    height: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  th,td {
    padding: 0 !important;
    border-bottom: 2px solid #888888;
    border-top: 2px solid #888888;
    border-left: 2px solid #888888;
  }

  th {
    height: 80px;
    background-color: white;
  }

  td {
    background-color: #ffffff;
  }

  .res-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
  }

  .header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 100%;
  }

  .header-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;
  }

  .header-button1,
  .header-button2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 26px;
    border-radius: 7px 7px 0 0;
    border-bottom: 0;
    background-color: #d2d0d2;
    cursor: pointer;
  }

  .header-button1 img,
  .header-button2 img {
    max-width: 100%;
    max-height: 100%;
  }

  .normal-data {
    background-color: #e9e9ef;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .data-text {
    margin: auto;
  }

</style>