import { navigate } from "svelte-navigator";
import { SUPPORTED_LANGUAGES } from "./languages.js";

/**
 * Language prefix aware navigation function
 * @param {string} to
 * @param {object} navigateOptions  svelte-navigator's navigate() function options
 */
export function navigateI18n(to, navigateOptions = {}) {
  const current = window.location.pathname

  let prefixCurrent = null
  let prefixDest = null
  // determine if the current path and the destination are language-prefixed
  for (const language of SUPPORTED_LANGUAGES) {
    if (current.startsWith(`/${language}`)) {
      prefixCurrent = language
    }
    if (to.startsWith(`/${language}`)) {
      prefixDest = language
    }
  }

  if (prefixCurrent && !prefixDest) navigate(`/${prefixCurrent}` + to, navigateOptions)
  else navigate(to, navigateOptions)
}
