import { writable } from "svelte/store";

export const TimeStamp = writable(null);
export const isAdmin = writable(false);
export const selectedDisplayMode = writable("工程表示");
export const DisplayMode = writable([
    {text: "工程表示", value: "工程表示"},
    {text: "カンパニー表示", value: "カンパニー表示"},
]);
export const ResultDialogVisible = writable(false);
export const DisplayResultOrExpected = writable(true); // true for "実績工数" false for ”指定工数"

export const resultStartRow = writable(0);
export const resultRowLength = writable(5);
export const resultRowTotal = writable(15);
export const resultRowLimit = writable(15);

export const companyStartRow = writable(0);
export const companyRowLength = writable(5);
export const companyRowTotal = writable(15);
export const companyRowLimit = writable(15);

export const resultStartColumn = writable(2);
export const resultColumnLength = writable(3);
export const resultColumnTotal = writable(5);
export const resultColumnLimit = writable(5);

export const RawData = writable([]);



export const FilteredProcessIndices = writable([]);
export const RowDisplay = writable([]);

export const ResCompanyArray = writable([]);
export const FilteredCompanyIndices = writable([]);

export const monthColumns = writable([]);
export const ColumnDisplay = writable([]);

export const filteredResGroup = writable(null);
export const ResGroupChoices = writable([]);
export const filteredResCode = writable(null);
export const ResCodeChoices = writable([]);
export const filteredResName = writable(null);
export const ResNameChoices = writable([]);
export const filteredResCompany = writable(null);
export const ResCompanyChoices = writable([]);

export const syncEarlyTime = writable(0);
export const syncOverTime = writable(0);
export const syncEarlyTimeActive = writable(true);
export const syncOverTimeActive = writable(true);
