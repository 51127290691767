<script>
  import { usePageContext } from "../../pages/pageContext.js";

  const pageContext = usePageContext()
  const {pageContextValue} = pageContext
  function onClick() {
    if ($pageContextValue.rightPane.isOpen) pageContext.toggleRightPane(false)
    else if ($pageContextValue.rightPane.href){
      pageContext.toggleRightPane(true)
      pageContext.setRightPaneHref($pageContextValue.rightPane.href)
    }
  }
</script>

<button style="margin-right: 20px; cursor: pointer; width: 32px; height: 32px" on:click={onClick}>
  <img src="/assets/icons/icon_help_large.svg" alt="help icon" />
</button>

<style>
  button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
</style>
