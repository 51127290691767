import {md5} from "js-md5";

/**
 * Meant to be used in conjunction with Random class
 * @param {string} str
 * @returns {number}
 */
export function createRandomSeedFromString(str) {
  const digest = md5.digest(str)
  return digest[0] * 2 ^ 8 + digest[1]
}

export class Random {
  constructor(seed = 88675123) {
    this.x = 123456789;
    this.y = 362436069;
    this.z = 521288629;
    this.w = seed;
  }

  // XorShift
  next() {
    let t;

    t = this.x ^ (this.x << 11);
    this.x = this.y; this.y = this.z; this.z = this.w;
    return this.w = (this.w ^ (this.w >>> 19)) ^ (t ^ (t >>> 8));
  }

  // min以上max以下の乱数を生成する
  nextInt(min, max) {
    const r = Math.abs(this.next());
    return min + (r % (max + 1 - min));
  }
}