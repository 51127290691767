import { useQuery } from "@sveltestack/svelte-query";
import { derived } from "svelte/store";
import { isInputAllowed } from "../lib/projects.js";

/**
 * Hook to determine if result input should be allowed for the logged-in user,
 *   considering his server role and project permission in addition to the project's plan.
 * @param {string} projectId
 */
export function useIsInputAllowed(projectId) {
  const myPermissionQueryResult = useQuery(
    `/api/me/projects/${projectId}/permission`,
  )
  const myPermission = derived(
    myPermissionQueryResult,
    values => values.data?.type ?? null,
  )
  const projectQueryResult = useQuery(
    `/api/projects/${projectId}`,
  )
  const projectPlan = derived(
    projectQueryResult,
    values => values.data?.plan
  )

  return {
    isInputAllowed: derived([myPermission, projectPlan], ([myPermission, projectPlan]) => {
      if (projectPlan === "viewer") return false
      return isInputAllowed(myPermission)
    })
  }
}